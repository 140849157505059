import * as React from "react";
import { Text, View, StyleSheet, Dimensions, Image, TouchableOpacity } from "react-native";

export const SortIcon = (props) => {
  if (props.sortKey == props.titlekey || props.sortKey == props.titlekey + "desc") {
    return <Image source={require("../assets/images/sorticon.png")} style={styles.sorticon} />;
  } else return null;
};

const styles = StyleSheet.create({
  sorticon: {
    width: 10,
    height: 13,
    marginTop: 2,
    marginLeft: 9,
  },
});
