/**
 * Validate NHS number by the rules defined at
 * https://www.datadictionary.nhs.uk/attributes/nhs_number.html
 * @param {string} nhsNumber 10-digit NHS number to validate
 * @returns {boolean} Whether the NHS number is valid
 */
export function validateNHSNumber(nhsNumber: string): boolean {
  if (!/^\d{10}$/.test(nhsNumber)) return false;

  let result = 0;
  for (let i = 0; i < 9; ++i) {
    // 48 is the char code of 0
    result += (nhsNumber.charCodeAt(i) - 48) * (10 - i);
  }

  const remain = result % 11;
  let check = 11 - remain;
  if (check === 10) return false;
  if (check === 11) check = 0;
  return nhsNumber.charCodeAt(9) - 48 === check;
}
