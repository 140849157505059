import React, { useState } from "react";
import { TextInput, Text, View, StyleSheet, Image, TouchableOpacity } from "react-native";
import CountrySelector from "../components/CountrySelector";
import { OrangeButton } from "./OrangeButton";
import { Hairline } from "./Hairline";
import RadioButton from "../components/RadioButton";

export const SendLink = (props) => {
  const [linknote, setLinknote] = React.useState("");
  const [linkcode, setLinkcode] = React.useState("");
  const [whotosend, setWhotosend] = React.useState(1);
  const [activity1, setActivity1] = React.useState(false);
  const [activity2, setActivity2] = React.useState(false);
  const [activity3, setActivity3] = React.useState(false);
  const [activity4, setActivity4] = React.useState(false);
  const [activity5, setActivity5] = React.useState(false);
  const [activity6, setActivity6] = React.useState(false);
  const [activity7, setActivity7] = React.useState(false);
  const [activity8, setActivity8] = React.useState(false);
  const [activity9, setActivity9] = React.useState(false);

  const [gsm, setGsm] = React.useState("");
  const [gsmcountrycode, setGsmcountrycode] = React.useState("44");
  const [countryCode, setCountryCode] = useState("GB");

  const [errorText, setErrorText] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  function removeView() {
    props.removeView();
  }

  function validateForm() {
    setIsLoading(true);

    setErrorText("");
    if (activity1 || activity2 || activity3 || activity4 || activity5 || activity6 || activity7 || activity8 || activity9) {
      sendform();
    } else {
      setIsLoading(false);
      setErrorText("Please select at least one material to request.");
    }
  }

  function sendform() {
    fetch(global.apiurl + "/clinicians/createlinkform", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
      body: JSON.stringify({
        patientid: props.patient.id,
        activity1,
        activity2,
        activity3,
        activity4,
        activity5,
        activity6,
        activity7,
        activity8,
        activity9,
      }),
    })
      .then(async (res) => {
        if (res.status === 422) {
          setIsLoading(false);

          throw new Error("Request failed.");
        }
        if (res.status !== 200 && res.status !== 201) {
          setIsLoading(false);

          console.log("Error!");
          throw new Error("Could not authenticate you!");
        }
        return await res.json();
      })
      .then((resData) => {
        console.log(resData);
        if (resData.result) {
          console.log("DONE");
          //   alert("pp");
          // props.onFinish();
          //
          setIsLoading(false);

          setLinkcode(resData.linkcode);
        } else {
          setIsLoading(false);

          alert("Unable to invite, please try later");
        }
      })
      .catch((err) => {
        setIsLoading(false);

        alert("Unable to invite, please try later");

        console.log(err);
      });
  }

  function sendsms() {
    setIsLoading(true);

    console.log("sending linksms");

    let activegsm;
    let activegsmcountry;
    switch (whotosend) {
      case 1:
        activegsm = props.patient.gsm;
        activegsmcountry = props.patient.gsmcountrycode;

        break;
      case 2:
        activegsm = props.patient.caretakergsm;
        activegsmcountry = props.patient.caretakergsmcountrycode;

        break;
      case 3:
        activegsm = gsm;
        activegsmcountry = gsmcountrycode;

        break;
    }

    fetch(global.apiurl + "/linkform/sendlinkformtogsm", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
      body: JSON.stringify({
        linkcode,
        gsm: activegsm,
        gsmcountrycode: activegsmcountry,
      }),
    })
      .then(async (res) => {
        setIsLoading(false);
        if (res.status === 422) {
          throw new Error("Request failed.");
        }
        if (res.status === 401) {
          throw new Error("Could not authenticate you!");
        }
        if (res.status === 404) {
          throw new Error("Link not found");
        }
        if (res.status !== 200 && res.status !== 201) {
          throw new Error("Something went wrong");
        }
        return await res.json();
      })
      .then((resData) => {
        console.log(resData);
        if (!resData.result) {
          console.error("Invalid data received from server.");
        }
        alert("Sms sent.");
      })
      .catch((err) => {
        setIsLoading(false);
        alert("Unable to send sms, please try later");
        console.log(err);
      });
  }

  return (
    <View
      style={{
        borderRadius: 8,
        backgroundColor: "#ffffff",
        width: 652,
        height: linkcode ? 738 : 500,
        alignSelf: "center",
      }}>
      <View
        style={{ flexDirection: "row", width: "100%", borderBottomColor: "#E8E9ED", borderBottomWidth: 1, height: 70 }}>
        <Text style={styles.title1}>Send link for additional materials</Text>
        <TouchableOpacity
          style={{ position: "absolute", right: 0 }}
          onPress={() => {
            removeView();
          }}>
          <View
            style={{
              backgroundColor: "#FCF5EF",
              width: 93,
              height: 70,
              flexDirection: "row",
              borderColor: "#E8E9ED",
              borderWidth: 1,
              borderTopRightRadius: 8,
              alignItems: "center",
            }}>
            <Image
              source={require("../assets/images/closecrossicon.png")}
              style={{ width: 10, height: 10, marginRight: 8, marginLeft: 23 }}
            />
            <Text style={{ fontFamily: "Lato", fontSize: 13, color: "#1F2E99" }}>Close</Text>
          </View>
        </TouchableOpacity>
      </View>

      <View style={{ flexDirection: "row" }}>
        <View style={{ width: 652 }}>
          <View style={{}}>
            <Text style={styles.title2}>Choose the kind of materials</Text>

            <View style={{ flexDirection: "row", marginLeft: 24, marginTop: 12 }}>
              <RadioButton
                style={styles.radiobutton}
                selected={activity1}
                onSelected={() => {
                  if (!linkcode) setActivity1(!activity1);
                }}
                title="Wound Photo"
              />
              <RadioButton
                style={styles.radiobutton}
                selected={activity7}
                onSelected={() => {
                  if (!linkcode) setActivity7(!activity7);
                }}
                title="Wound Video"
              />
              <RadioButton
                style={styles.radiobutton}
                selected={activity2}
                onSelected={() => {
                  if (!linkcode) setActivity2(!activity2);
                }}
                title="SSIS"
              />
              <RadioButton
                style={styles.radiobutton}
                selected={activity3}
                onSelected={() => {
                  if (!linkcode) setActivity3(!activity3);
                }}
                title="EQ5D- 5L"
              />
            </View>
            <View style={{ flexDirection: "row", marginLeft: 24, marginTop: 12 }}>
              <RadioButton
                style={styles.radiobutton}
                selected={activity4}
                onSelected={() => {
                  if (!linkcode) setActivity4(!activity4);
                }}
                title="OHS/OKS"
              />
              <RadioButton
                style={styles.radiobutton}
                selected={activity5}
                onSelected={() => {
                  if (!linkcode) setActivity5(!activity5);
                }}
                title="PHQ9"
              />
              <RadioButton
                style={styles.radiobutton}
                selected={activity6}
                onSelected={() => {
                  if (!linkcode) setActivity6(!activity6);
                }}
                title="PREMS"
              />
              <RadioButton
                style={styles.radiobutton}
                selected={activity8}
                onSelected={() => {
                  if (!linkcode) setActivity8(!activity8);
                }}
                title="WQoL"
              />
            </View>
            <View style={{ flexDirection: "row", marginLeft: 24, marginTop: 12 }}>
              <RadioButton
                style={styles.radiobutton}
                selected={activity9}
                onSelected={() => {
                  if (!linkcode) setActivity9(!activity9);
                }}
                title="FHSQ"
              />
            </View>

            <View style={{ marginTop: 36, paddingLeft: 24 }}>
              <Text style={styles.title3}>Add a note (optional)</Text>
              <View style={[styles.shadowinput, { width: 605 }]}>
                <TextInput
                  style={[styles.postopinput, { textAlign: "left", width: 250 }]}
                  keyboardType="name-phone-pad"
                  maxLength={80}
                  caretHidden={!!linkcode}
                  numberOfLines={1}
                  onChangeText={(text) => {
                    if (!linkcode) setLinknote(text);
                  }}
                  value={linknote}
                  returnKeyType="done"
                />
              </View>
            </View>

            {!linkcode && (
              <OrangeButton
                disabled={isLoading}
                onPress={() => {
                  validateForm();
                }}
                title="Generate link"
                style={{ width: 250, marginTop: 72, alignSelf: "center" }}
              />
            )}
            {!linkcode && (
              <Text
                style={{
                  color: "#FF0000",
                  flex: 1,
                  textAlign: "left",
                  fontFamily: "LatoBold",
                  fontSize: 14,
                  lineHeight: 14,
                  alignSelf: "center",
                  position: "relative",
                  top: 20,
                }}>
                {errorText}
              </Text>
            )}

            {linkcode && (
              <View>
                <Hairline marginTop={32} />
                <Text style={styles.title2}>Link</Text>
                <View style={{ marginLeft: 24, marginTop: 8 }}>
                  <View style={{ flexDirection: "row" }}>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                      <View style={[styles.shadowinput, { width: 400 }]}>
                        <TextInput
                          editable={false}
                          style={[styles.postopinput, { textAlign: "left", width: 250 }]}
                          keyboardType="name-phone-pad"
                          maxLength={50}
                          numberOfLines={1}
                          value={global.linkformurl + linkcode}
                          returnKeyType="done"
                        />
                      </View>

                      <TouchableOpacity
                        onPress={async () => {
                          await navigator?.clipboard?.writeText(`${global.linkformurl}${linkcode}`);
                        }}>
                        <Image
                          source={require("../assets/images/copylinkbutton.png")}
                          style={{ width: 126, height: 42, marginLeft: 22 }}
                        />
                      </TouchableOpacity>
                    </View>
                  </View>

                  <Text style={[styles.postopinput, { marginTop: 20, marginLeft: 0 }]}>
                    The link will be active for 24 hours.
                  </Text>
                </View>
                <Hairline marginTop={22} />
                <Text style={styles.title2}>Send to</Text>
                <View style={{ flexDirection: "row", marginLeft: 24, marginTop: 22 }}>
                  <View>
                    <RadioButton
                      style={styles.radiobutton}
                      selected={whotosend == 1}
                      onSelected={() => {
                        setWhotosend(1);
                      }}
                      title="Patient"
                    />
                    {whotosend == 1 && (
                      <View style={{ marginTop: 12, marginLeft: 26 }}>
                        <Text style={styles.title3}>
                          +{props.patient.gsmcountrycode} {props.patient.gsm}
                        </Text>
                      </View>
                    )}
                  </View>
                  <View>
                    <RadioButton
                      style={styles.radiobutton}
                      selected={whotosend == 2}
                      onSelected={() => {
                        if (props.patient.caretakergsm) {
                          setWhotosend(2);
                        } else {
                          alert("Caregiver not set.");
                        }
                      }}
                      title="Caregiver"
                    />
                    {whotosend == 2 && (
                      <View style={{ marginTop: 12, marginLeft: 26 }}>
                        <Text style={styles.title3}>
                          +{props.patient.caretakergsmcountrycode} {props.patient.caretakergsm}
                        </Text>
                      </View>
                    )}
                  </View>
                  <View>
                    <RadioButton
                      style={styles.radiobutton}
                      selected={whotosend == 3}
                      onSelected={() => {
                        setWhotosend(3);
                      }}
                      title="Other"
                    />
                    {whotosend == 3 && (
                      <View style={{ width: 250, marginTop: 6 }}>
                        <Text style={styles.title3}>Phone number</Text>
                        <View style={[styles.shadowinput]}>
                          <CountrySelector
                            alwaysactive
                            countrycode={countryCode}
                            setCountrycode={(value) => {
                              setCountryCode(value);
                            }}
                            setGsmcountrycode={(value) => {
                              setGsmcountrycode(value);
                            }}
                          />
                          <TextInput
                            onChangeText={(text) => {
                              setGsm(text);
                            }}
                            value={gsm}
                            style={{ color: "#1F2E99", fontFamily: "Lato", fontSize: 15, marginRight: 3 }}
                          />
                        </View>
                      </View>
                    )}
                  </View>
                </View>
              </View>
            )}
          </View>
        </View>
      </View>

      {linkcode && (
        <View style={{ position: "absolute", bottom: 16, alignItems: "center", width: 652 }}>
          <OrangeButton
            disabled={isLoading}
            sendicon
            onPress={() => {
              sendsms();
            }}
            title="Send Link"
            style={{ width: 250, marginTop: 16 }}
          />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  select: {
    marginLeft: 20,
    //  "text-align":"center","text-align-last":"center",
    "-moz-appearance": "none",
    "-webkit-appearance": "none",
    appearance: "none",
    backgroundImage:
      "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAVCAYAAAAuJkyQAAAAmUlEQVR42r3OwQmAQAxE0S3JTrUDvairl6ANBG1MySFeFrz8kIGBZRnIK55ZtLOWnPzf3c67r8f1WO2dhannPTZ3TeefXhtmYrxm+UAARTAtyLLKNTEUxyyig28gimNW0akZcxTHAFQCBqAwJgEFMACFMRkojuEojolHcUw8CmLiUQCTgAIYhAKYJBTH8FTRAWLis4t21hKQF5HBtPSQyO2PAAAAAElFTkSuQmCC)",
    backgroundPosition: "87%",
    backgroundSize: "12px 7px",
    "background-repeat": "no-repeat",
    borderWidth: 0,
    width: 250,
    height: 24,
    borderRadius: 20,
    fontFamily: "Lato",
    fontSize: 15,
    color: "#1F2E99",
    textAlign: "center",
  },

  menuitemwait: {
    fontFamily: "Lato",
    fontSize: 15,
    color: "#959DAD",
    marginTop: 27,
    height: 18,
  },
  menuitemdone: {
    fontFamily: "Lato",
    fontSize: 15,
    color: "#1F2E99",
    marginTop: 27,
    height: 18,
  },
  menuitemactive: {
    fontFamily: "LatoBold",
    fontSize: 15,
    color: "#1F2E99",
    marginTop: 27,
    height: 18,
  },

  postopinput: {
    flex: 1,
    outlineWidth: 0,
    color: "#1F2E99",
    fontFamily: "Lato",
    fontSize: 15,
    marginRight: 10,
    marginLeft: 20,
  },
  countryflag: {
    width: 26,
    height: 18,
    marginHorizontal: 16,
    marginVertical: 19,
  },
  shadowinput: {
    marginTop: 8,
    height: 48,
    flexDirection: "row",
    borderRadius: 15,
    shadowColor: "#818181",
    backgroundColor: "#FFFFFF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
  title1: {
    fontSize: 18,
    fontFamily: "LatoBold",
    color: "#1F2E99",
    marginTop: 26,
    marginLeft: 24,
  },

  title2: {
    paddingLeft: 24,
    fontSize: 15,
    fontFamily: "LatoBold",
    color: "#1F2E99",
    marginTop: 24,
  },

  calendaricon: {
    width: 18,
    height: 18,
  },

  title3: {
    fontSize: 13,
    fontFamily: "Lato",
    color: "#798599",
  },
  radiobutton: {
    width: 140,
    alignItems: "flex-start",
    alignContent: "flex-start",
    justifyContent: "flex-start",
  },
});
