import { inject, observer } from "mobx-react";
import React, { useEffect } from "react";
import { FlatList, Image, StyleSheet, Text, TextInput, TouchableOpacity, View } from "react-native";
import Menu, { MenuOption, MenuOptions, MenuTrigger } from "react-native-popup-menu";

import { Avatar } from "../../components/Avatar";
import { Hairline } from "../../components/Hairline";
import { MainFrameClinicalTeam } from "../../components/MainFrameClinicalTeam";
import { removeClinicianFromTeam } from "../../misc/user-utils";

const TempScreen = inject("userStore")(
  observer(({ userStore, route }) => {
    const { clinicalteamid } = route.params;

    const [filterHip, setFilterHip] = React.useState(false);
    const [filterKnee, setFilterKnee] = React.useState(false);

    const [data, setData] = React.useState(null);
    const [clinicalteam, setClinicalteam] = React.useState(null);
    const [tempData, setTempData] = React.useState(null);
    const [searchtext, setSearchtext] = React.useState("");

    function updateSearch(text) {
      setSearchtext(text);
      if (text == "" && !filterKnee && !filterHip) {
        console.log("updatesearch:null");
        setData(tempData);
        return;
      }
      setData(
        tempData.filter(function (item) {
          if (!(filterKnee && filterHip)) {
            if (filterKnee && item.operationtype != 1) return false;
            if (filterHip && item.operationtype != 2) return false;
          }

          return (item.firstname + " " + item.lastname).toLowerCase().includes(text.toLowerCase());
        }),
      );
    }

    async function fetchData() {
      fetch(global.apiurl + "/hospitaladmins/getclinicalteamclinicians", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: global.tokenparam || "",
        },

        body: JSON.stringify({
          clinicalteamid,
        }),
      })
        .then(async (response) => await response.json())
        .then((resData) => {
          console.log(resData);
          if (resData.result) {
            setData(resData.data);
            setTempData(resData.data);
            setClinicalteam(resData.clinicalteam);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    useEffect(() => {
      fetchData();
    }, []);

    const CustomMenu = (props) => {
      const { style, children, layouts, ...other } = props;
      const position = { top: layouts.triggerLayout.y + window.scrollY + 40, left: layouts.triggerLayout.x };
      return (
        <View {...other} style={[style, position, styles.shadowinput, { backgroundColor: "#ffffff", width: 150 }]}>
          {children}
        </View>
      );
    };

    return (
      <View style={styles.container}>
        <MainFrameClinicalTeam
          fetchData={fetchData}
          clinicalteam={clinicalteam}
          admin
          patientlist
          userStore={userStore}
          selected={2}
          patientlist
          style={{}}>
          <View style={[styles.whitebox, { alignItems: "flex-start", width: 1131, marginBottom: 94 }]}>
            <View style={{ flexDirection: "row", alignItems: "center", marginLeft: 25, marginTop: 24 }}>
              <Text style={[styles.title1]}>List of clinicians </Text>
              <Text style={[styles.title1, { position: "absolute", left: 130 }]}>({data?.length})</Text>

              <View style={[styles.shadowinput, { width: 267, marginLeft: 70 }]}>
                <TextInput
                  style={[styles.postopinput, { textAlign: "left", width: 220 }]}
                  keyboardType="email-address"
                  maxLength={50}
                  numberOfLines={1}
                  onChangeText={(text) => {
                    updateSearch(text);
                  }}
                  placeholder={"Search"}
                  placeholderTextColor={"#1F2E99"}
                  value={searchtext}
                />
                <TouchableOpacity style={{ position: "absolute", right: 10, top: 13 }}>
                  <Image source={require("../../assets/images/searchicon.png")} style={styles.searchicon} />
                </TouchableOpacity>
              </View>
            </View>
            <Hairline marginTop={13} />
            <View style={{ flex: 1, width: "100%", paddingHorizontal: 24 }}>
              <View style={{ flexDirection: "row", width: "100%", justifyContent: "space-between", marginTop: 24.5 }}>
                <TouchableOpacity style={{ flex: 0.25 }}>
                  <Text style={[styles.title3, { marginLeft: 24 }]}>Name</Text>
                </TouchableOpacity>
                <TouchableOpacity style={{ flex: 0.147 }}>
                  <Text style={[styles.title3]}>Role</Text>
                </TouchableOpacity>
                <TouchableOpacity style={{ flex: 0.277 }}>
                  {" "}
                  <Text style={[styles.title3]}>Clinical teams</Text>
                </TouchableOpacity>
                <TouchableOpacity style={{ flex: 0.08 }}></TouchableOpacity>
                <TouchableOpacity style={{ flex: 0.03 }}></TouchableOpacity>
              </View>
              <Hairline marginTop={15.5} />
              <FlatList
                scrollEnabled={false}
                xextraData={data}
                style={{ width: "100%" }}
                data={data}
                keyExtractor={(item) => item.id}
                renderItem={({ item, index, separators }) => (
                  <View
                    style={{
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                      backgroundColor: "#ffffff",
                      height: 78,
                      alignItems: "center",
                      borderRadius: 6,
                      borderBottomColor: "#E8E9ED",
                      borderBottomWidth: StyleSheet.hairlineWidth,
                    }}>
                    <View style={{ flex: 0.25, flexDirection: "row", alignItems: "center" }}>
                      {" "}
                      <Avatar
                        url={item?.thumburl && global.imagepathurl + encodeURI(item?.thumburl)}
                        size={46}
                        radius={23}
                        firstname={item.firstname}
                        lastname={item.lastname}
                        style={{ marginLeft: 24, borderWidth: 1, marginRight: 18 }}
                      />
                      <Text style={[styles.title4]}>
                        {item.firstname} {item.lastname}
                      </Text>
                    </View>
                    <Text style={[styles.title4, { flex: 0.147 }]}>{item?.cliniciantype?.title}</Text>
                    <Text style={[styles.title4, { flex: 0.277 }]}>
                      {item.clinicalteams.map((el, index) => (
                        <Text style={styles.title4}>
                          {el.name}
                          {index > item.clinicalteams.length - 2 ? "  " : ", "}
                        </Text>
                      ))}
                    </Text>

                    <Menu style={[{ flex: 0.08 }]} renderer={CustomMenu}>
                      <MenuTrigger>
                        <View
                          style={{
                            height: 20,
                            backgroundColor: "#ffffff",
                            alignItems: "center",
                            justifyContent: "center",
                          }}>
                          <Image source={require("../../assets/images/threedots.png")} style={styles.threedots} />
                        </View>
                      </MenuTrigger>
                      <MenuOptions customStyles={{ borderRadius: 8 }}>
                        <MenuOption
                          text="De-assign clinician"
                          onSelect={async () => {
                            await removeClinicianFromTeam(item.id, clinicalteamid);
                            await fetchData();
                          }}
                        />
                      </MenuOptions>
                    </Menu>

                    <Text style={[styles.title4, styles.titlecenter, { flex: 0.03 }]}></Text>
                  </View>
                )}
              />
            </View>
          </View>
        </MainFrameClinicalTeam>
      </View>
    );
  }),
);

export default TempScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
  },
  title1: {
    fontSize: 18,
    fontFamily: "Lato",
    color: "#1F2E99",
  },
  threedots: {
    width: 16,
    height: 3.66,
  },

  title2: {
    width: "84%",
    fontSize: 20,
    fontFamily: "Lato",
    color: "#1F2E99",
    position: "absolute",
    top: "55%",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },

  searchicon: {
    marginTop: 2,
    width: 12.33,
    height: 13.33,
  },
  postopinput: {
    flex: 1,
    outlineWidth: 0,
    color: "#1F2E99",
    fontFamily: "Lato",
    fontSize: 15,
    marginRight: 10,
    marginLeft: 20,
  },
  shadowinput: {
    height: 44,
    flexDirection: "row",
    borderRadius: 15,
    shadowColor: "#818181",
    backgroundColor: "#FFFFFF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
  whitebox: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    shadowColor: "#818181",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
});
