import React from "react";
import { theme } from "../../misc/constants";
import type { CommonIconProps } from "./icon-types";

export default function StarsIcon({ color = theme.primary, size = 29 }: CommonIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.10468 20.3102L1.64718 17.9352C1.4573 17.8651 1.29347 17.7385 1.17775 17.5724C1.06204 17.4063 1 17.2088 1 17.0064C1 16.804 1.06204 16.6065 1.17775 16.4404C1.29347 16.2743 1.4573 16.1477 1.64718 16.0777L8.10468 13.7027C8.23914 13.6534 8.36125 13.5755 8.46249 13.4742C8.56374 13.373 8.6417 13.2509 8.69093 13.1164L11.0659 6.6589C11.136 6.46902 11.2626 6.30519 11.4287 6.18947C11.5947 6.07376 11.7923 6.01172 11.9947 6.01172C12.1971 6.01172 12.3946 6.07376 12.5607 6.18947C12.7267 6.30519 12.8533 6.46902 12.9234 6.6589L15.2984 13.1164C15.3477 13.2509 15.4256 13.373 15.5269 13.4742C15.6281 13.5755 15.7502 13.6534 15.8847 13.7027L22.3422 16.0777C22.5321 16.1477 22.6959 16.2743 22.8116 16.4404C22.9273 16.6065 22.9894 16.804 22.9894 17.0064C22.9894 17.2088 22.9273 17.4063 22.8116 17.5724C22.6959 17.7385 22.5321 17.8651 22.3422 17.9352L15.8847 20.3102C15.7502 20.3594 15.6281 20.4373 15.5269 20.5386C15.4256 20.6398 15.3477 20.7619 15.2984 20.8964L12.9234 27.3539C12.8533 27.5438 12.7267 27.7076 12.5607 27.8233C12.3946 27.939 12.1971 28.0011 11.9947 28.0011C11.7923 28.0011 11.5947 27.939 11.4287 27.8233C11.2626 27.7076 11.136 27.5438 11.0659 27.3539L8.69093 20.8964C8.6417 20.7619 8.56374 20.6398 8.46249 20.5386C8.36125 20.4373 8.23914 20.3594 8.10468 20.3102Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M20 1V7" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M26 8V12" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17 4H23" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M24 10H28" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
