import * as React from "react";
import { Text, View, StyleSheet, Dimensions, Image, TouchableOpacity } from "react-native";

export const ValidCheckmark = (props) => {
  return (
    <View>
      {!props?.valid && <View style={{ width: 20, height: 20, backgroundColor: "#C4C4C4", borderRadius: 10 }} />}
      {props?.valid && (
        <Image source={require("../assets/images/validitycheckmark.png")} style={{ width: 20, height: 20 }} />
      )}
    </View>
  );
};
