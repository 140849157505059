import React from "react";

import { View, StyleSheet } from "react-native";

import UpdateClinician from "../../components/UpdateClinician";
import UpdateHospitalAdmin from "../../components/UpdateHospitalAdmin";
import UpdatePostopAdmin from "../../components/UpdatePostopAdmin";
import { HeaderMin } from "../../components/HeaderMin";
import { withStore } from "../../misc/utils";

export const ClinicianRegisterUpdateDetailsScreen = withStore(({ userStore }) => {
  return (
    <View style={styles.container}>
      <HeaderMin>
        <UpdateClinician afterregister userstore={userStore} />
      </HeaderMin>
    </View>
  );
});

export const HospitalAdminRegisterUpdateDetailsScreen = withStore(({ userStore }) => {
  return (
    <View style={styles.container}>
      <HeaderMin>
        <UpdateHospitalAdmin afterregister userstore={userStore} />
      </HeaderMin>
    </View>
  );
});

export const PostopAdminRegisterUpdateDetailsScreen = withStore(({ userStore }) => {
  return (
    <View style={styles.container}>
      <HeaderMin>
        <UpdatePostopAdmin afterregister userstore={userStore} />
      </HeaderMin>
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
  },
});
