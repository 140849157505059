import { action, configure, makeObservable, observable } from "mobx";

import AsyncStorage from "@react-native-community/async-storage";
import { requests } from "../misc";
import { RequestError } from "../misc/requests";

configure({
  enforceActions: "never",
});

const UserTypes = {
  Clinician: 1,
  HospitalAdmin: 2,
  PostopAdmin: 3,
};
const inactivityTimeout = 1000 * 60 * 30;

export class UserStore {
  @observable sessiontoken = "";
  @observable usertype = 0;
  @observable unreadmessagecount = 0;
  @observable userloggedin = false;
  @observable readonly = 0;
  useremail = "";
  @observable userfirstname = "";
  @observable userlastname = "";
  @observable userphotothumbnailurl = "";
  @observable fontsize = 1;
  @observable screenupdate = 1;
  @observable messages = [];
  @observable isAuthCalled = false;
  timer = null;
  constructor() {
    makeObservable(this);
  }

  updateLastInactivityTime() {
    window.localStorage.lastInactivityTime = new Date().getTime();
  }

  removeInactivityTime() {
    window.localStorage.removeItem("lastInactivityTime");
  }

  getLastInactivityTime() {
    return window.localStorage.lastInactivityTime;
  }

  async initInactivityTimeout() {
    if (this.timer) {
      clearInterval(this.timer);
      document.removeEventListener("mousemove", this.updateLastInactivityTime);
      document.removeEventListener("keydown", this.updateLastInactivityTime);
    }
    await this.logoutInactive();
    document.addEventListener("mousemove", this.updateLastInactivityTime);
    document.addEventListener("keydown", this.updateLastInactivityTime);
    this.timer = setInterval(() => {
      this.logoutInactive();
    }, 1000);
  }

  @action addmessage(msg) {
    console.log(this.messages);
    this.messages.push(msg);
  }

  @action addmessage2(msg) {
    console.log(this.messages);
    this.messages.unshift(msg);
  }

  @action
  async setusertype(usertype) {
    console.log("usertype:" + usertype);
    this.usertype = usertype;
    await AsyncStorage.setItem("usertype", usertype);
  }

  @action
  async setreadonly(readonlyvalue) {
    console.log("readonlyvalue:" + readonlyvalue);
    this.readonly = readonlyvalue;
    await AsyncStorage.setItem("readonly", readonlyvalue);
  }

  @action setmessages(msg) {
    this.messages = msg;
  }

  @action updatescreen() {
    this.screenupdate = this.screenupdate + 1;
  }

  getApiPath() {
    let apipath = "clinicians";

    switch (this.usertype) {
      case 1:
        apipath = "clinicians";
        break;
      case 2:
        apipath = "hospitaladmins";
        break;
      case 3:
        apipath = "postopadmins";
        break;
    }
    return apipath;
  }

  @action async checktoken() {
    const apipath = this.getApiPath();

    const res = await requests.get(`/${apipath}/checktoken`, { responseType: "response", credentials: "same-origin" });
    if (!res.ok) {
      if (res.status === 401) {
        this.refreshToken();
      } else {
        const err = await RequestError.fromResponse(res);
        console.error("checktoken failed:", err);
      }
    }
    if (!this.isAuthCalled) this.isAuthCalled = true;
  }

  @action async refreshToken() {
    const apipath = this.getApiPath();
    const res = await requests
      .post(`/${apipath}/refreshtoken`, undefined, { responseType: "response", credentials: "include" })
      .catch(async (err) => {
        if (err.status !== 401) {
          console.error("refreshtoken failed:", JSON.stringify(err));
        }
        await this.logout();
      });
    if (!res.ok) {
      await this.logout();
    } else {
      const data = await res.json();
      global.tokenparam = "Bearer " + data.token;
      global.token = data.token;
      await this.setFullLoggedinIsAdmin(
        data.token,
        data.photourl,
        data.firstname,
        data.lastname,
        // usertype in backend has difrent values , fix this !!!
        UserTypes[data.userType],
        data.readonly,
      );
    }
  }

  syncWithAsyncStorage() {
    console.debug("syncWithAsyncStorage");

    AsyncStorage.setItem("sessiontoken", this.sessiontoken);

    const values = [
      ["sessiontoken", this.sessiontoken],
      ["userloggedin", this.userloggedin],

      ["userphotothumbnailurl", this.userphotothumbnailurl],
    ];

    AsyncStorage.multiSet(values);
  }

  @action
  async logoutInactive() {
    const now = new Date().getTime();
    const last = this.getLastInactivityTime();
    if (!last) return;
    if (now - last > inactivityTimeout) {
      await this.logout();
      window.location.reload();
    }
  }

  @action
  async initializeFromAsyncStorage() {
    try {
      console.debug("initializeFromAsyncStorage");

      this.sessiontoken = await AsyncStorage.getItem("sessiontoken");
      global.tokenparam = "Bearer " + this.sessiontoken;
      this.userloggedin = await AsyncStorage.getItem("userloggedin");
      this.userphotothumbnailurl = await AsyncStorage.getItem("userphotothumbnailurl");
      this.userfirstname = await AsyncStorage.getItem("userfirstname");
      this.userlastname = await AsyncStorage.getItem("userlastname");
      this.usertype = parseInt(await AsyncStorage.getItem("usertype"));
      this.readonly = await AsyncStorage.getItem("readonly");
      await this.logoutInactive();
      if (this.userloggedin) {
        global.token = this.sessiontoken;
        await this.initInactivityTimeout();
        await this.checktoken();
      } else {
        await this.refreshToken();
      }
      if (!this.isAuthCalled) this.isAuthCalled = true;

      setInterval(() => {
        if (this.userloggedin) this.checktoken();
      }, 40000);
    } catch (err) {
      if (!this.isAuthCalled) this.isAuthCalled = true;
      throw err;
    }
  }

  @action
  async setUserPhotoThumbnailURL(userphotothumbnailurl) {
    console.log("setUserPhotoThumbnailURL:" + userphotothumbnailurl);

    if (!userphotothumbnailurl?.trim().length) {
      userphotothumbnailurl = null;
    }

    this.userphotothumbnailurl = userphotothumbnailurl;
    await AsyncStorage.setItem("userphotothumbnailurl", userphotothumbnailurl);
  }

  @action setUnreadmessagecount(unreadmessagecount: number) {
    this.unreadmessagecount = unreadmessagecount;
  }

  @action setToken(newSessiontoken: string) {
    console.log("setToken");
    AsyncStorage.setItem("sessiontoken", newSessiontoken);
    global.tokenparam = "Bearer " + this.newSessiontoken;
  }

  @action
  async setLoggedin(newSessiontoken: string, userphotothumbnailurl: string) {
    console.log("setLoggedin");

    if (userphotothumbnailurl.length == 0) {
      userphotothumbnailurl = null;
    }

    await AsyncStorage.setItem("sessiontoken", newSessiontoken);
    await AsyncStorage.setItem("userphotothumbnailurl", userphotothumbnailurl);
    await AsyncStorage.setItem("userloggedin", true);

    this.sessiontoken = newSessiontoken;
    global.tokenparam = "Bearer " + newSessiontoken;

    this.userphotothumbnailurl = userphotothumbnailurl;

    this.userloggedin = true;

    global.loggedin = true;
    global.token = newSessiontoken;

    console.log("loggedin:" + newSessiontoken);
    console.log("loggedin:" + userphotothumbnailurl);
  }

  @action
  async setFullLoggedinIsAdmin(
    newSessiontoken: string,
    userphotothumbnailurl: string,
    userfirstname: string,
    userlastname: string,
    usertype: int,
    readonly: int,
  ) {
    console.log("setLoggedin usertype:" + usertype);
    this.setusertype(usertype);
    this.setreadonly(readonly);
    await this.setFullLoggedin(newSessiontoken, userphotothumbnailurl, userfirstname, userlastname);
  }

  @action
  async setFullLoggedin(
    newSessiontoken: string,
    userphotothumbnailurl: string,
    userfirstname: string,
    userlastname: string,
  ) {
    console.log("setLoggedin");

    if (userphotothumbnailurl == "null") {
      this.userphotothumbnailurl = null;
    } else {
      this.userphotothumbnailurl = userphotothumbnailurl;

      setTimeout(async () => {
        await this.setUserPhotoThumbnailURL(userphotothumbnailurl);
      }, 300);
    }
    await AsyncStorage.setItem("sessiontoken", newSessiontoken);
    await AsyncStorage.setItem("userphotothumbnailurl", userphotothumbnailurl);
    await AsyncStorage.setItem("userfirstname", userfirstname);
    await AsyncStorage.setItem("userlastname", userlastname);
    await AsyncStorage.setItem("userloggedin", true);

    this.sessiontoken = newSessiontoken;

    this.userfirstname = userfirstname;
    this.userlastname = userlastname;

    this.userloggedin = true;
    this.updateLastInactivityTime();
    await this.initInactivityTimeout();
    global.loggedin = true;
    global.token = newSessiontoken;
    global.tokenparam = "Bearer " + newSessiontoken;

    console.log("loggedin:" + newSessiontoken);
    console.log("loggedin:" + userphotothumbnailurl);
  }

  @action
  async logout() {
    console.log("LOGOUT!!");

    await AsyncStorage.removeItem("sessiontoken", "");
    await AsyncStorage.removeItem("userphotothumbnailurl", "");
    await AsyncStorage.removeItem("userloggedin");
    // await AsyncStorage.removeItem('usertype')
    await AsyncStorage.removeItem("readonly");
    await requests.post(`/v1/user/logout`, undefined, { responseType: "response", credentials: "include" });
    global.token = "";
    global.tokenparam = "";

    global.loggedin = false;
    // this.usertype="";
    this.sessiontoken = "";
    this.userphotothumbnailurl = "";
    this.userloggedin = false;
    this.removeInactivityTime();
  }
}

const userStore = new UserStore();
userStore.initializeFromAsyncStorage();

export default userStore;
