import type { CSSProperties, MouseEvent, ReactNode } from "react";
import React, { useCallback, useMemo, useState } from "react";

import { css, cx } from "@emotion/css";

import { theme } from "../../misc/constants";
import type { Nullish } from "../../misc/types";
import Clickable from "../Clickable";
import { EllipsisIcon } from "../icons/EllipsisIcon";
import PopoverMenu from "./PopoverMenu";

export interface DataTableProps<T> {
  records: T[];
  heads: Array<string | [string, string | Nullish]>;
  renderCell?: (key: string, item: T) => ReactNode | Nullish;
  renderActions?: (item: T) => ReactNode | Nullish;
  extractItemKey?: (item: T) => React.Key;
  onClickRow?: (item: T) => void;
  className?: string;
  style?: CSSProperties;
}

interface DataRowProps<T> {
  heads: Array<[string, string]>;
  item: T;
  showActions: boolean;
  renderCell: RenderCellCallback<T>;
  renderActions?: RenderActionsCallback<T>;
  onClickRow?: OnClickRowCallback<T>;
  onClickActions: (item: T | null) => void;
}

export type ExtractItemKeyCallback<T> = Required<DataTableProps<T>>["extractItemKey"];
export type RenderCellCallback<T> = Required<DataTableProps<T>>["renderCell"];
export type RenderActionsCallback<T> = Required<DataTableProps<T>>["renderActions"];
export type OnClickRowCallback<T> = Required<DataTableProps<T>>["onClickRow"];

export function DataTable<T>({
  records,
  heads: _heads,
  className,
  style,
  renderCell,
  renderActions,
  extractItemKey,
  onClickRow,
}: DataTableProps<T>) {
  const heads = _heads.map((head) => (typeof head === "string" ? [head, head] : head) as [string & keyof T, string]);
  const [showActions, setShowActions] = useState(-1);

  const doRenderCell = useCallback<Required<DataTableProps<T>>["renderCell"]>(
    (key, item: any) => {
      let result: ReactNode | Nullish;
      if (renderCell) {
        result = renderCell(key, item);
      }
      if (!result) {
        if (!(key in item)) console.warn(`DataTable: No custom renderCell provided for ${key} and prop is missing`);
        result = item[key] ?? "" + "";
      }
      return result;
    },
    [renderCell],
  );

  return (
    <table className={cx("PODataTable-root", onClickRow && "clickable", styles.root, className)} style={style}>
      <thead>
        <tr>
          {heads.map(([col, head]) => (
            <th key={col}>{head ?? ""}</th>
          ))}
          {renderActions && <th />}
        </tr>
      </thead>
      <tbody>
        {records.length ? (
          records.map((item, i) => (
            <DataRow<T>
              key={extractItemKey ? extractItemKey(item) : i}
              heads={heads}
              item={item}
              showActions={showActions === i}
              renderCell={doRenderCell}
              renderActions={renderActions}
              onClickRow={onClickRow}
              onClickActions={(item) => {
                setShowActions(item ? i : -1);
              }}
            />
          ))
        ) : (
          <tr>
            <td colSpan={heads.length}>No records found</td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

function DataRow<T>({
  heads,
  item,
  showActions,
  renderCell,
  renderActions,
  onClickRow,
  onClickActions,
}: DataRowProps<T>) {
  const actions = useMemo(() => renderActions?.(item), [item, renderActions]);

  return (
    <tr onClick={() => onClickRow?.(item)}>
      {heads.map(([prop]) => (
        <td key={prop}>{renderCell(prop, item)}</td>
      ))}
      {!!actions && (
        <td className="PODataTable-actionsCell" onClick={interceptClick}>
          <PopoverMenu
            show={showActions}
            onClickOutside={() => {
              onClickActions(null);
            }}
            label={
              <div className="PODataTable-actionsWrapper">
                <Clickable
                  onClick={() => {
                    onClickActions(item);
                  }}>
                  <EllipsisIcon size={18} color="grey" />
                </Clickable>
              </div>
            }>
            <div className={cx("PODataTable-actionsPopover", styles.popover)}>{actions}</div>
          </PopoverMenu>
        </td>
      )}
    </tr>
  );
}

function interceptClick(e: MouseEvent) {
  e.stopPropagation();
}

const styles = Object.freeze({
  root: css`
    padding: 0;
    margin: 0;
    vertical-align: middle;
    font-family: Lato;
    font-size: 15px;
    text-align: left;
    border-bottom: 1px solid ${theme.line};
    border-collapse: separate;
    border-spacing: 0 12px;

    thead th {
      padding-bottom: 4px;
      text-align: left;
      font-size: 13px;
      font-weight: normal;
      color: ${theme.primary};
      border-bottom: 1px solid ${theme.line};
    }

    &.clickable tbody tr {
      cursor: pointer;
    }

    .PODataTable-actionsCell {
      .PODataTable-actionsWrapper {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 24px;
        min-height: 24px;
      }
    }
  `,
  popover: css``,
});
