import type { CommonIconProps } from "./icon-types";

export default function PinIcon({ filled, color = "currentColor", size = 20 }: CommonIconProps & { filled?: boolean }) {
  if (filled) {
    return (
      <svg width={(size * 16) / 20} height={size} viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.5556 20L7.77778 14.4444L0 20V2.22222C0 1.63285 0.234126 1.06762 0.650874 0.650874C1.06762 0.234126 1.63285 0 2.22222 0H13.3333C13.9227 0 14.4879 0.234126 14.9047 0.650874C15.3214 1.06762 15.5556 1.63285 15.5556 2.22222V20Z"
          fill={color}
        />
      </svg>
    );
  } else {
    return (
      <svg width={(size * 13) / 16} height={size} viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.8889 15L6.44444 11.1111L1 15V2.55556C1 2.143 1.16389 1.74733 1.45561 1.45561C1.74733 1.16389 2.143 1 2.55556 1H10.3333C10.7459 1 11.1416 1.16389 11.4333 1.45561C11.725 1.74733 11.8889 2.143 11.8889 2.55556V15Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
}
