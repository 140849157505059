import { css, cx } from "@emotion/css";
import type { ReactNode } from "react";
import React from "react";
import { ClipLoader } from "react-spinners";
import { theme } from "../misc/constants";
import type { ClickableProps } from "./Clickable";
import Clickable from "./Clickable";

export interface ButtonProps extends ClickableProps {
  variant?: "filled" | "outlined";
  left?: ReactNode;
  right?: ReactNode;
  loading?: boolean;
}

export function Button({
  children,
  variant = "filled",
  left,
  right,
  disabled,
  loading,
  className,
  ...props
}: ButtonProps): JSX.Element {
  if (loading) {
    disabled = true;
    left = <ClipLoader color="currentColor" />;
  }

  return (
    <Clickable
      {...props}
      disabled={disabled || loading}
      className={cx(
        {
          "POButton-root": true,
          [stylesheet]: true,
          [`POButton-${variant}`]: true,
          disabled,
          loading,
        },
        className,
      )}>
      {left && <div className="POButton-left">{left}</div>}
      <div className="POButton-label">{children}</div>
      {right && <div className="POButton-right">{right}</div>}
    </Clickable>
  );
}

const stylesheet = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 0 16px;
  min-width: 80px;
  min-height: 30px;
  border-radius: 15px;

  font-family: Questrial;
  font-size: 16px;

  svg,
  img {
    display: block;
  }

  &.POButton-filled {
    background: ${theme.secondary};
    color: white;
    &:hover {
      background: ${theme.secondaryLight};
    }
    &.POButton-disabled {
      background: ${theme.secondaryDark} !important;
    }
  }

  &.POButton-outlined {
    color: ${theme.secondary};
    border: 1px solid ${theme.secondary};
    &:hover {
      color: ${theme.secondaryLight};
      border-color: ${theme.secondaryLight};
    }
    &.POButton-disabled {
      color: ${theme.secondaryDark} !important;
      border-color: ${theme.secondaryDark} !important;
    }
  }
`;
