import * as React from "react";
import { Alert, TextInput, View, Text, StyleSheet, Image, TouchableOpacity } from "react-native";
import { OrangeButton } from "../OrangeButton";
import { PasswordStrengthMeter } from "../PasswordStrengthMeter";
import validate from "../../screens/validate_wrapper";
import { useNavigation } from "@react-navigation/native";
import { ValidCheckmark } from "../ValidCheckmark";
import { A } from "@expo/html-elements";

const Temp = (props) => {
  const { userStore } = props;
  const navigation = useNavigation();

  const [formState, setFormState] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(false);

  const [email, setEmail] = React.useState("");
  const [passwordstrength, setPasswordstrength] = React.useState(0);
  const [errorText, setErrorText] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [password2, setPassword2] = React.useState("");
  const [secure, setSecure] = React.useState(true);
  const [secure2, setSecure2] = React.useState(true);
  const [resetpasswordcode, setResetpasswordcode] = React.useState("");

  const apigateway = props?.admin ? "hospitaladmins" : props?.postopadmin ? "postopadmins" : "clinicians";

  function resetpassword() {
    console.log("reseting password");

    fetch(global.apiurl + "/" + apigateway + "/resetpassword", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        newpassword: password,
        resetpasswordcode,
      }),
    })
      .then(async (res) => {
        if (res.status === 422) {
          throw new Error("Request failed.");
        }
        if (res.status !== 200 && res.status !== 201) {
          console.log("Error!");
          throw new Error("Could not authenticate you!");
        }
        return await res.json();
      })
      .then((resData) => {
        console.log(resData);
        if (resData.result) {
          console.log("DONE");
          setFormState(4);
        } else {
          Alert.alert("Unable to reset password", "Please check the form");
        }
      })
      .catch((err) => {
        Alert.alert("Unable to reset password", "Please check the form");
        console.log(err);
      });
  }

  function checkpasswordresetcode() {
    fetch(global.apiurl + "/" + apigateway + "/checkpasswordresetcode", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        resetpasswordcode,
      }),
    })
      .then(async (res) => {
        if (res.status === 422) {
          setErrorText("Code validation failed.");
          throw new Error("Validation failed.");
        }
        if (res.status !== 200 && res.status !== 201) {
          console.log("Error!");
          setErrorText("Code validation failed.");

          throw new Error("Could not authenticate you!2");
        }
        return await res.json();
      })
      .then((resData) => {
        console.log(resData);
        if (resData.result) {
          setFormState(3);
        } else {
          setErrorText("Code validation failed.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function sendresetpasswordscode() {
    console.log("sending reset password mail");

    fetch(global.apiurl + "/" + apigateway + "/sendresetpasswordscode", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
      }),
    })
      .then(async (res) => {
        if (res.status === 422) {
          throw new Error("Request failed.");
        }
        if (res.status !== 200 && res.status !== 201) {
          console.log("Error!");
          setErrorText("Unable to find user.");
          throw new Error("Could not authenticate you!");
        }
        return await res.json();
      })
      .then((resData) => {
        console.log(resData);
        if (resData.result) {
          console.log("DONE");
          setFormState(2);
        } else {
          setErrorText("Unable to find user.");
        }
      })
      .catch((err) => {
        setErrorText("Unable to find user.");
        console.log(err);
      });
  }

  function validateForm() {
    setErrorText("");
    const passwordError = validate("password", password);
    const emailError = validate("email", email);
    const resetpasswordcodeError = validate("resetpasswordcode", resetpasswordcode);

    let password2Error = null;

    if (password != password2) {
      password2Error = "Password's don't match.";
    }
    const passwordStrengthError = null;

    console.log("xxx");

    switch (formState) {
      case 1:
        if (!emailError) {
          sendresetpasswordscode();
        } else {
          setErrorText(emailError);
        }
        break;

      case 2:
        if (!resetpasswordcodeError) {
          checkpasswordresetcode();
        } else {
          setErrorText(resetpasswordcodeError);
        }
        break;
      case 3:
        const passwordMixedError = validate("passwordmixed", password);

        if (!passwordError && !password2Error && !passwordMixedError) {
          resetpassword();
        } else {
          setErrorText(
            (passwordMixedError ? "\n" + passwordMixedError + "\n" : "") +
              (passwordStrengthError ? "\n" + passwordStrengthError + "\n" : "") +
              (passwordError ? "\n" + passwordError + "\n" : "") +
              (password2Error ? "\n" + password2Error + "\n" : ""),
          );
        }
        break;
    }
  }

  return (
    <View
      style={[
        { width: 350, flexShrink: 0, backgroundColor: "#ffffff" },
        { backgroundColor: props.bgcolor ? props.bgcolor : "#ffffff" },
      ]}>
      <Image
        source={require("../../assets/images/logopostop1.1.png")}
        style={{ width: 186.1, height: 61, marginTop: 168 }}
      />

      {formState == 1 && (
        <View>
          <Text style={[styles.title1, { width: 400 }]}>Password reset</Text>
          <Text style={styles.title2}>
            To reset your password, enter the email address that you use to sign in to Post Op.
          </Text>
          <Text style={[styles.title5, { marginTop: 32 }]}>Email address</Text>
          <View style={styles.shadowinput}>
            <TextInput
              style={styles.phoneinput}
              keyboardType="number-pad"
              onChangeText={(text) => {
                setEmail(text.replace(" ", ""));
              }}
              value={email}
              returnKeyType="done"
              onSubmitEditing={() => {
                validateForm();
              }}
            />
          </View>
        </View>
      )}

      {formState == 2 && (
        <View>
          <Text style={styles.title1}>Enter verification code</Text>
          <Text style={styles.title2}>
            We sent you a verification code to the email address connected to this account.
            {"\n"}
            {"\n"}
            Insert it below to reset your password.
          </Text>
          <Text style={[styles.title5, { marginTop: 32 }]}>Verification code</Text>

          <View style={styles.shadowinput}>
            <TextInput
              style={styles.phoneinput}
              keyboardType="number-pad"
              onChangeText={(text) => {
                setResetpasswordcode(text.replace(" ", ""));
              }}
              value={resetpasswordcode}
              returnKeyType="done"
              onSubmitEditing={() => {
                validateForm();
              }}
            />
          </View>
          <View style={{ flexDirection: "row", marginTop: 16, width: 350, justifyContent: "left" }}>
            <Text style={{ fontFamily: "Lato", fontSize: 11, lineHeight: 14, color: "#000000" }}>
              Didn’t receive the email?{" "}
            </Text>
            <TouchableOpacity onPress={sendresetpasswordscode}>
              <Text
                style={{
                  fontFamily: "Lato",
                  marginLeft: 4,
                  fontSize: 11,
                  lineHeight: 14,
                  color: "#0009EC",
                  textDecorationLine: "underline",
                }}>
                Send it again
              </Text>
            </TouchableOpacity>
          </View>
          <Text style={{ fontFamily: "Lato", fontSize: 11, lineHeight: 14, color: "#000000" }}>
            Don’t forget to check your spam box.
          </Text>
        </View>
      )}
      {formState == 3 && (
        <View>
          <Text style={styles.title1}>Set your new password</Text>
          <Text style={styles.title5}>Set new password</Text>

          <View style={styles.shadowinput}>
            <TextInput
              returnKeyType="done"
              style={styles.passwordinput}
              keyboardType="email-address"
              secureTextEntry={secure}
              onChangeText={(text) => {
                setPassword(text.trim());
              }}
              value={password}
            />

            <TouchableOpacity
              onPress={() => {
                setSecure(!secure);
              }}>
              <Image source={require("../../assets/images/passwordshow.png")} style={styles.passwordshow} />
            </TouchableOpacity>
          </View>

          <PasswordStrengthMeter
            style={{ marginTop: 11 }}
            pw={password}
            setStrenght={(x) => {
              setPasswordstrength(x);
              console.log("pw strength:" + x);
            }}
          />

          <View style={{ flexDirection: "row", marginTop: 22 }}>
            <ValidCheckmark valid={password?.length > 11} />
            <Text style={styles.title7}>Minimum of 12 characters</Text>
          </View>
          <View style={{ flexDirection: "row", marginTop: 8 }}>
            <ValidCheckmark valid={!validate("passwordmixed", password)} />
            <Text style={styles.title7}>Must include capital, lowercase, number and a special character</Text>
          </View>

          <Text style={styles.title5}>Repeat new password</Text>
          <View style={styles.shadowinput}>
            <TextInput
              returnKeyType="done"
              style={styles.passwordinput}
              keyboardType="email-address"
              secureTextEntry={secure2}
              onChangeText={(text) => {
                setPassword2(text.trim());
              }}
              value={password2}
            />

            <TouchableOpacity
              onPress={() => {
                setSecure2(!secure2);
              }}>
              <Image source={require("../../assets/images/passwordshow.png")} style={styles.passwordshow} />
            </TouchableOpacity>
          </View>
        </View>
      )}
      {formState == 4 && (
        <View>
          <Text style={styles.title1}>New password was{"\n"}successfully created.</Text>
        </View>
      )}
      <View style={{ justifyContent: "flex-end  " }}>
        <Text style={{ color: "#FF6D6D" }}>{errorText}</Text>
      </View>

      {formState == 1 && (
        <OrangeButton
          disabled={isLoading}
          onPress={() => {
            validateForm();
          }}
          title="Get Verification Code"
          on
          style={{ marginTop: 93 }}
        />
      )}
      {formState == 2 && (
        <OrangeButton
          disabled={isLoading}
          onPress={() => {
            validateForm();
          }}
          title="Verify Code"
          on
          style={{ marginTop: 93 }}
        />
      )}
      {formState == 3 && (
        <OrangeButton
          disabled={isLoading}
          onPress={() => {
            validateForm();
          }}
          title="Set new password"
          on
          style={{ marginTop: 86 }}
        />
      )}

      {formState == 4 && !userStore.userloggedin && !props.admin && !props.postopadmin && (
        <OrangeButton
          disabled={isLoading}
          onPress={() => navigation.push("ClinicianLogin")}
          title="Go to login"
          on
          style={{ marginTop: 86 }}
        />
      )}
      {formState == 4 && userStore.userloggedin && !props.admin && !props.postopadmin && (
        <OrangeButton
          disabled={isLoading}
          onPress={() => navigation.push("ClinicianProfileScreen")}
          title="Back to profile"
          on
          style={{ marginTop: 86 }}
        />
      )}

      {formState == 4 && !userStore.userloggedin && props.admin && (
        <OrangeButton
          disabled={isLoading}
          onPress={() => navigation.push("HospitalAdminLogin")}
          title="Go to login"
          on
          style={{ marginTop: 86 }}
        />
      )}
      {formState == 4 && userStore.userloggedin && props.admin && (
        <OrangeButton
          disabled={isLoading}
          onPress={() => navigation.push("HospitalAdminProfileScreen")}
          title="Back to profile"
          on
          style={{ marginTop: 86 }}
        />
      )}

      {formState == 4 && !userStore.userloggedin && props.postopadmin && (
        <OrangeButton
          disabled={isLoading}
          onPress={() => navigation.push("PostopAdminLogin")}
          title="Go to login"
          on
          style={{ marginTop: 86 }}
        />
      )}
      {formState == 4 && userStore.userloggedin && props.postopadmin && (
        <OrangeButton
          disabled={isLoading}
          onPress={() => navigation.push("PostopadminProfileScreen")}
          title="Back to profile"
          on
          style={{ marginTop: 86 }}
        />
      )}

      {formState == 3 && (
        <A
          href=" https://postop.ai/password-policy/"
          target="_black"
          style={{ marginTop: 11, paddingBottom: 30, alignSelf: "center", width: "100%" }}>
          <View
            style={[
              styles.shadowbutton,
              { backgroundColor: "white", borderColor: "#DFE1E6", borderWidth: 1, borderRadius: 10, width: "100%" },
            ]}>
            <View
              style={{
                flexDirection: "row",
                backgroundColor: "transparent",
                alignContent: "center",
                height: 38,
                alignItems: "center",
                justifyContent: "center",
              }}>
              <Image
                source={require("../../assets/images/passwordpolicyshield.png")}
                style={{ width: 18, height: 22 }}
              />

              <Text style={[{ color: "#1F2E99", fontFamily: "Questrial", fontSize: 18, marginLeft: 17 }]}>
                Read our password policy
              </Text>
            </View>
          </View>
        </A>
      )}
    </View>
  );
};

export default Temp;

const styles = StyleSheet.create({
  phoneinput: {
    flex: 1,
    outlineWidth: 0,
    color: "#1F2E99",
    fontFamily: "Lato",
    fontSize: 15,
    marginRight: 10,
    marginLeft: 20,
  },
  shadowinput: {
    alignItems: "center",
    marginTop: 15,
    height: 48,
    flexDirection: "row",
    borderRadius: 15,
    shadowColor: "#818181",
    backgroundColor: "#FFFFFF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
  title1: {
    fontSize: 32,
    fontFamily: "LatoBold",
    color: "#1F2E99",
    marginTop: 39.6,
  },
  title2: {
    marginTop: 18,
    fontSize: 18,
    fontFamily: "Lato",
    color: "#000000",
    width: 349,
  },
  title5: {
    marginTop: 32,
    fontSize: 13,
    color: "#798599",
    fontFamily: "Lato",
  },
  title7: {
    fontSize: 15,
    lineHeight: 18,
    color: "#798599",
    fontFamily: "Lato",
    marginLeft: 13,
  },
  passwordshow: {
    width: 24,
    height: 15,
    position: "relative",
    right: 15,
  },

  passwordinput: {
    width: "100%",
    flex: 1,
    outlineWidth: 0,
    color: "#1F2E99",
    fontFamily: "Lato",
    fontSize: 15,
    marginRight: 22,
    marginLeft: 20,
  },
});
