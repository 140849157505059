// Linear algebra stuff.
// Used eg. in WoundVizPro.

export interface IVector {
  x: number;
  y: number;
}

export class InlineVector {
  constructor(public x = 0, public y = 0) {}

  add(x: number, y: number): this;
  add(v: IVector): this;
  add(x: number | IVector, y?: number) {
    if (typeof x === "number") {
      this.x += x;
      this.y += y!;
    } else {
      this.x += x.x;
      this.y += x.y;
    }
    return this;
  }

  sub(x: number, y: number): this;
  sub(v: IVector): this;
  sub(x: number | IVector, y?: number) {
    if (typeof x === "number") {
      return this.add(-x, -y!);
    } else {
      return this.add(-x.x, -x.y);
    }
  }

  scale(s: number) {
    this.x *= s;
    this.y *= s;
    return this;
  }

  scaled(s: number) {
    return new InlineVector(this.x * s, this.y * s);
  }

  clone() {
    return new InlineVector(this.x, this.y);
  }
}
