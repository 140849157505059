import React from "react";
import { Text, View, StyleSheet, Image } from "react-native";
import { A } from "@expo/html-elements";
import { ClinicalTeamHeader } from "../components/ClinicalTeamHeader";
import { MainFrame } from "../components/MainFrame";

export const MainFrameMyClinicalTeam = (props) => {
  return (
    <View style={{ flex: 1, width: "100%" }}>
      <MainFrame
        clinicalteam={props.clinicalteam}
        userStore={props.userStore}
        style={[{ alignItems: "flex-start", flexDirection: "row", width: "100%" }]}>
        <View style={{ width: 181, alignItems: "center", height: "100%" }}>
          <View style={{ height: 106, alignItems: "center", justifyContent: "center" }}>
            <A href="/profile">
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Image
                  source={require("../assets/images/backicon.png")}
                  style={{ width: 14.333, height: 10, marginRight: 11.5 }}
                />
                <Text style={styles.title1}>Back to my profile </Text>
              </View>
            </A>
          </View>
          <View style={{ borderTopColor: "#1F2E99", borderTopWidth: 0.3, width: 181 }}></View>
        </View>
        <View style={{ flexGrow: 1, alignSelf: "stretch" }}>
          <ClinicalTeamHeader
            nobuttons
            openmodal={() => {}}
            userStore={props.userStore}
            clinicalteam={props?.clinicalteam}
          />
          <View
            style={[
              {
                paddingLeft: 63,
                paddingRight: 63,
                paddingTop: 20,
                alignSelf: "stretch",
                borderTopColor: "#1F2E99",
                borderTopWidth: 0.3,
                flexDirection: "row",
              },
              props.style,
            ]}>
            {props.children}
          </View>
        </View>
        <View></View>
      </MainFrame>
    </View>
  );
};

const styles = StyleSheet.create({
  whitebox: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    shadowColor: "#818181",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    elevation: 5,
  },
  title1: {
    fontFamily: "Lato",
    fontSize: 13,
    color: "#1F2E99",
  },
});
