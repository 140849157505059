import type { AriaAttributes, ChangeEvent, CSSProperties, ReactNode } from "react";
import React, { useCallback } from "react";

import { css, cx } from "@emotion/css";

import { theme } from "../../misc/constants";

export interface InputProps extends AriaAttributes {
  type?: HTMLInputElement["type"];
  value?: string;
  defaultValue?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  adornFront?: ReactNode;
  adornBack?: ReactNode;
  placeholder?: string;
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
  className?: string;
  style?: CSSProperties;
  validate?: (value: string) => boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeText?: (text: string) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export default function Input({
  disabled,
  adornFront,
  adornBack,
  className,
  style,
  validate,
  onChange,
  onChangeText,
  ...props
}: Readonly<InputProps>) {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e);
      if (e.isDefaultPrevented()) return;
      const value = e.target.value;
      if (!validate || validate(value)) onChangeText?.(value);
    },
    [onChange, onChangeText],
  );

  // TODO: label
  return (
    <div className={cx("POInput-root", styles.root, disabled && "disabled", className)} style={style}>
      {adornFront && <div className={cx("POInput-adornFront")}>{adornFront}</div>}
      <input {...props} readOnly={disabled} onChange={handleChange} />
      {adornBack && <div className={cx("POInput-adornBack")}>{adornBack}</div>}
    </div>
  );
}

export function SearchInput({ className, ...props }: Readonly<InputProps>) {
  return (
    <Input
      placeholder="Search"
      maxLength={50}
      adornBack={<img src={require("../../assets/images/searchicon.png")} style={{ width: 12.33, height: 13.33 }} />}
      {...props}
      className={cx("standard-shadow", styles.search, className)}
    />
  );
}

const styles = Object.freeze({
  root: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    input {
      flex: 1;
      font-size: 15px;
      line-height: 18px;
      padding: 2px 4px;
      border: 0;
      border-bottom: 1px solid ${theme.line};
      color: ${theme.primary};
      background: transparent;
      caret-color: ${theme.primary};
    }

    .POInput-adornFront,
    .POInput-adornBack {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      max-height: 100%;
      overflow: hidden;
    }
  `,
  search: css`
    width: fit-content;
    border-radius: 8px;
    padding: 8px;
  `,
});
