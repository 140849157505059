import type { KeyboardEvent } from "react";
import { useCallback, useState } from "react";

import { css, cx } from "@emotion/css";

import SendIcon from "./icons/SendIcon";
import { Button } from "./Button";
import Input from "./ui/Input";

export interface CommentInputProps {
  disabled?: boolean;
  onSendComment: (comment: string) => void;
}

export default function CommentInput({ disabled, onSendComment }: CommentInputProps) {
  const [comment, setComment] = useState("");

  const sendComment = useCallback(() => {
    if (!comment.trim()) return;
    onSendComment(comment);
    setComment("");
  }, [comment]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      const { key } = e;
      if (key === "Enter") {
        e.preventDefault();
        sendComment();
      }
    },
    [sendComment],
  );

  return (
    <div className={cx("POCommentInput-root", stylesheet)}>
      <Input
        autoFocus
        disabled={disabled}
        value={comment}
        maxLength={50}
        placeholder="Add comment"
        onKeyDown={handleKeyDown}
        onChangeText={setComment}
      />
      <Button
        variant="filled"
        onClick={sendComment}
        className={css`
          min-width: 0;
          width: 42px;
          height: 42px;
        `}>
        <SendIcon color="white" size={20} />
      </Button>
    </div>
  );
}

const stylesheet = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  min-height: 70px;
  padding: 0 30px 0 20px;
  background: white;

  .POInput-root {
    font-family: Lato;
    font-size: 15px;
    color: #959dad;
  }
`;
