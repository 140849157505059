import React from "react";

import { View, StyleSheet } from "react-native";

import ProfileClinician from "../components/ProfileClinician";
import ProfileHospitalAdmin from "../components/ProfileHospitalAdmin";
import ProfilePostopAdmin from "../components/ProfilePostopAdmin";
import { MainFrame } from "../components/MainFrame";
import { withStore } from "../misc/utils";
import PostopAdminLayout from "../components/layouts/PostopAdminLayout";

export const ClinicianProfileScreen = withStore(({ userStore }) => {
  return (
    <View style={styles.container}>
      <MainFrame userStore={userStore}>
        <ProfileClinician userStore={userStore} />
      </MainFrame>
    </View>
  );
});

export const HospitalAdminProfileScreen = withStore(({ userStore }) => {
  return (
    <View style={styles.container}>
      <MainFrame admin userStore={userStore}>
        <ProfileHospitalAdmin userStore={userStore} />
      </MainFrame>
    </View>
  );
});

export const PostopAdminProfileScreen = withStore(({ userStore }) => {
  return (
    <View style={styles.container}>
      <PostopAdminLayout>
        <ProfilePostopAdmin userStore={userStore} />
      </PostopAdminLayout>
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
  },
});
