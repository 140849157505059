import { RefObject, createRef } from "react";
import { IPatientMedia } from "../../interfaces/patientInterfaces";
import { createReducerContext, useReducerContext } from "../../misc/reducer-context";
import { Mask, PromptType, useEmbedding, useSAM } from "../../hooks/useSam";

type SAM = ReturnType<typeof useSAM>;
type Embedding = ReturnType<typeof useEmbedding>;

export type WoundVizState = {
  enabled: boolean;
  media?: IPatientMedia;
  imageRef: RefObject<HTMLImageElement>;
  imageOffset: [number, number];
  imageZoomLevel: number;
  containerRef: RefObject<HTMLDivElement>;
  panTargetRef: RefObject<HTMLDivElement>;
  boxRef: RefObject<HTMLDivElement>;
  sam?: SAM | null;
  embedding?: Embedding | null;
  promptType: PromptType;
  // previewMask is a legacy feature, but it is still here as it's useful for debugging
  previewMask: Mask | null;
  annotations: Annotation[];
  isAnnotationsEnabled: boolean;
  isPanMode: boolean;
  isPanning: boolean;
  isLmbDown: boolean;
  panStart: [number, number] | null;
  box: {
    start: [number, number];
    stop: [number, number];
  } | null;
}

export interface Annotation {
  id: number;
  /** URL (including data URL) */
  mask: string;
  color: string;
  visible: boolean;
  woundTagId?: number;
  tag?: string;
}

export interface Tag {
  id: number;
  label: string;
  color: string;
  selected?: boolean;
}

type ReducerState = {
  state: WoundVizState;
}

export const Context = createReducerContext<ReducerState, Partial<WoundVizState>>(
  ({state}, update) => {
    return {
      state: Object.assign(state, update),
    };
  },
  (): ReducerState => ({
    state: {
      enabled: true,
      imageRef: createRef<HTMLImageElement>(),
      imageOffset: [0, 0],
      imageZoomLevel: 0,
      containerRef: createRef<HTMLDivElement>(),
      panTargetRef: createRef<HTMLDivElement>(),
      boxRef: createRef<HTMLDivElement>(),
      promptType: PromptType.Additive,
      previewMask: null,
      annotations: [],
      isAnnotationsEnabled: true,
      isPanMode: false,
      isPanning: false,
      isLmbDown: false,
      panStart: null,
      box: null,
    },
  }),
);

export function useWoundVizContext() {
  const [{ state }, update] = useReducerContext(Context);
  return [state, update] as const;
}
