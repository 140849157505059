import Moment from "moment";
import React, { useState } from "react";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import Menu, { MenuOption, MenuOptions, MenuTrigger, renderers } from "react-native-popup-menu";

import { Avatar } from "../components/Avatar";
import { withStore } from "../misc/utils";
import { errorMessage, successMessage } from "./Notifications";
import Toggle from "./ui/Toggle";
import { css, cx } from "@emotion/css";
import Dropdown from "./ui/Dropdown";
import { isDefined } from "validate.js";

export interface PatientHeaderProps {
  patient: any;
  clinician: any;
}

export const PatientHeader = withStore<PatientHeaderProps>(({ userStore, patient, clinician, ...props }) => {
  const { Popover } = renderers;

  const [isPinned, setIsPinned] = useState(patient?.ispinned);
  const [watchClosely, setWatchClosely] = useState(patient?.watchclosely);
  const [recoveryStatus, setRecoveryStatus] = useState<number | null>(
    isDefined(patient?.recoverystatus) ? parseInt(patient.recoverystatus) : null,
  );

  function togglePin() {
    pinpatient(patient?.id, Math.abs(1 - isPinned));
  }

  async function pinpatient(patientid, ispinned) {
    fetch(global.apiurl + "/clinicians/pinpatient", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
      body: JSON.stringify({
        patientid,
        ispinned,
      }),
    })
      .then(async (response) => await response.json())
      .then((resData) => {
        console.log(resData);
        if (resData.result) {
          setIsPinned(ispinned);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function toggleSwitch() {
    setwatchclosely(patient?.id, Math.abs(1 - watchClosely));
  }

  async function setwatchclosely(patientid, watchclosely) {
    fetch(global.apiurl + "/clinicians/setpatientwatchclosely", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
      body: JSON.stringify({
        patientid,
        watchclosely,
      }),
    })
      .then(async (response) => await response.json())
      .then((resData) => {
        console.log(resData);
        if (resData.result) {
          setWatchClosely(watchclosely);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function sendInvitation() {
    const response = await fetch(global.apiurl + "/v1/patients/" + patient?.id + "/invite", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
    });

    if (response?.status == 200) {
      successMessage("Invitation sent");
    } else {
      errorMessage("Failed to send invitation", response);
    }
  }

  async function dischargePatient() {
    fetch(global.apiurl + "/clinicians/dischargepatient", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
      body: JSON.stringify({
        patientid: patient?.id,
      }),
    })
      .then(async (response) => await response.json())
      .then((resData) => {
        console.log(resData);
        if (resData.result) {
          location.href = "/patients";
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function updateRecoveryStatus(patientid, recoverystatus) {
    fetch(global.apiurl + "/clinicians/setpatientrecoverystatus", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
      body: JSON.stringify({
        patientid,
        recoverystatus,
      }),
    })
      .then(async (response) => await response.json())
      .then((resData) => {
        console.log(resData);
        if (resData.result) {
          setRecoveryStatus(recoverystatus);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function calculateRecoveryTime(operationdate) {
    if (!operationdate) return "-";
    // 12 days / week 2
    const given = Moment(operationdate.replace("'", ""));
    const current = Moment().startOf("day");
    const totalday = Math.floor(Moment.duration(current.diff(given)).asDays());
    if (totalday > 0) {
      return totalday + " days / week " + (1 + Math.floor((totalday - 1) / 7));
    }

    return "-";
  }

  return (
    <div className={cx("POPatientHeader-root", stylesheet.root)}>
      <View style={{ flexDirection: "row", marginTop: 22 }}>
        <Avatar
          url={patient?.thumburl && global.imagepathurl + encodeURI(patient?.thumburl)}
          size={61}
          radius={16}
          firstname={patient?.firstname}
          lastname={patient?.lastname}
        />

        <View style={{ alignSelf: "center", marginLeft: 14 }}>
          <View style={{ flexDirection: "row", marginTop: 8 }}>
            <Text style={styles.title3}>
              {patient?.firstname} {patient?.lastname}
              {global.environment === "LOCAL" && <Text style={styles.title3}> ({patient?.id})</Text>}
            </Text>

            {clinician?.cliniciantype?.canredflag && userStore.readonly == 0 && (
              <TouchableOpacity
                style={{ marginLeft: 11 }}
                onPress={() => {
                  togglePin();
                }}>
                {isPinned == 1 && (
                  <Image source={require("../assets/images/flagicon.png")} style={{ width: 15, height: 18.333 }} />
                )}
                {!(isPinned == 1) && (
                  <Image source={require("../assets/images/flagemptyicon.png")} style={{ width: 15, height: 18.333 }} />
                )}
              </TouchableOpacity>
            )}

            {clinician?.cliniciantype?.canredflag && userStore.readonly != 0 && (
              <View style={{ marginLeft: 11 }}>
                {isPinned == 1 && (
                  <Image source={require("../assets/images/flagicon.png")} style={{ width: 15, height: 18.333 }} />
                )}
                {!(isPinned == 1) && (
                  <Image source={require("../assets/images/flagemptyicon.png")} style={{ width: 15, height: 18.333 }} />
                )}
              </View>
            )}
          </View>
          <View style={{ flexDirection: "row", marginTop: 8 }}>
            {" "}
            <Text style={styles.title4}>Recovery time </Text>
            <Text style={styles.title5}>{calculateRecoveryTime(patient?.operationdate)}</Text>
          </View>
        </View>
      </View>

      <View style={{ position: "absolute", right: 63, top: 39, flexDirection: "row", alignItems: "center" }}>
        <Text style={{ marginRight: 8 }}>Recovery status</Text>

        <Dropdown
          variant="chip"
          values={[
            { label: "Good", value: 0 },
            { label: "Fair", value: 1 },
            { label: "Poor", value: 2 },
          ]}
          value={recoveryStatus}
          extractId={(item) => item.value + ""}
          equality={(lhs, rhs) => lhs.value === rhs}
          placeholder={"Not set"}
          filter={(item) => item.value !== null}
          onChange={async (item) => {
            await updateRecoveryStatus(patient?.id, item.value);
          }}
          className={cx({
            [stylesheet.status]: true,
            "status-good": recoveryStatus === 0,
            "status-fair": recoveryStatus === 1,
            "status-poor": recoveryStatus === 2,
          })}
        />

        <TouchableOpacity>
          <Image source={require("../assets/images/statsicon.png")} style={{ width: 28, height: 28, marginLeft: 8 }} />
        </TouchableOpacity>

        {clinician?.cliniciantype?.canwatchclosely && (
          <View style={{ marginLeft: 36, flexDirection: "row", alignItems: "center" }}>
            <Text style={{ marginRight: 12 }}>Watch closely</Text>
            <Toggle on={watchClosely} readonly={Boolean(parseInt(userStore.readonly))} onClick={toggleSwitch} />
          </View>
        )}

        {userStore.readonly == 0 && (
          <Menu style={{ marginLeft: 34 }} renderer={Popover} rendererProps={{ placement: "bottom" }}>
            <MenuTrigger style={{}}>
              <Image source={require("../assets/images/threedotsbutton.png")} style={{ width: 42, height: 42 }} />
            </MenuTrigger>
            <MenuOptions customStyles={{ borderRadius: 8, padding: 15 }}>
              {
                <MenuOption
                  text={"Discharge Patient"}
                  onSelect={() => {
                    if (confirm("All patient data will permenantly be HIDDEN. Are you sure?")) {
                      dischargePatient();
                    }
                  }}
                />
              }

              {patient?.inviteaccepted != 1 && (
                <MenuOption
                  text={"Resend invitation"}
                  onSelect={() => {
                    sendInvitation();
                  }}
                />
              )}
            </MenuOptions>
          </Menu>
        )}
      </View>
    </div>
  );
});

const stylesheet = Object.freeze({
  root: css`
    height: 106px;
    padding-left: 63px;
    align-self: stretch;
  `,
  status: css`
    .PODropdown-value {
      width: 70px;
      text-align: center;
      font-size: 13px;
      font-weight: bold;
      color: white;
      border-color: white;
      &:focus {
        border-color: white;
      }
    }

    &.status-good {
      background-color: #6dd803;
    }
    &.status-fair {
      background-color: #f9ce37;
    }
    &.status-poor {
      background-color: #ff6d6d;
    }
  `,
});

const styles = StyleSheet.create({
  title3: {
    fontFamily: "LatoBold",
    fontSize: 18,
    color: "#1F2E99",
  },
  title4: {
    fontFamily: "Lato",
    fontSize: 13,
    color: "#868686",
  },
  title5: {
    fontFamily: "Lato",
    fontSize: 13,
    color: "#1F2E99",
  },
});
