/* eslint-disable @typescript-eslint/ban-types */
import type { ComponentType, Dispatch, ReactNode } from "react";
import React, { useEffect } from "react";

type ContextValue<S, A> = [S, Dispatch<A>];

export type ReducerContext<S, A> = {
  Context: React.Context<ContextValue<S, A>>;
  Provider: React.FC<{ children: ReactNode }>;
};

export function withReducerContext<S, A, P = {}>(
  Component: ComponentType<P>,
  Context: ReducerContext<S, A>,
): ComponentType<P> {
  return (props: P) => (
    <Context.Provider>
      {/* @ts-expect-error no idea why it's acting up... */}
      <Component {...props} />
    </Context.Provider>
  );
}

export function createReducerContext<S, A>(
  reducer: (state: S, action: A) => S,
  initializer: () => S,
): ReducerContext<S, A> {
  const Context = React.createContext<ContextValue<S, A>>([initializer(), () => {}]);
  const Provider = ({ children }: { children?: ReactNode }) => {
    const [state, dispatch] = React.useReducer(reducer, initializer());
    return <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>;
  };
  return { Context, Provider };
}

export function useReducerContext<S, A>(Context: ReducerContext<S, A>, initializer?: (state: S) => void) {
  const value = React.useContext(Context.Context);
  if (initializer) {
    useEffect(() => {
      initializer(value[0]);
    }, []);
  }
  return value;
}
