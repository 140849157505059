/* eslint-disable @typescript-eslint/no-redeclare */
export type SignalListener = () => void | Promise<void>;

export type Signal = ReturnType<typeof Signal>;

export function Signal(listener?: SignalListener) {
  const listeners = new Set<SignalListener>(listener ? [listener] : []);

  const register = async () => {
    for (const listener of listeners) {
      await listener();
    }
  };

  const listen = (listener: SignalListener) => {
    listeners.add(listener);
    return () => {
      listeners.delete(listener);
    };
  };

  return Object.assign(register, { listen });
}
