import * as React from "react";
import { Text, View, StyleSheet, Dimensions, Image, TouchableOpacity } from "react-native";

export const PatientRecoveryStatus = (props) => {
  return (
    <View>
      {props.status == null && (
        <View style={{ width: 76, height: 24, borderRadius: 20, backgroundColor: "#DDDDDD", alignItems: "center" }}>
          <Text
            style={{
              fontFamily: "LatoBold",
              fontSize: 13,
              color: "#ffffff",
              width: "100%",
              textAlign: "center",
              marginTop: 3,
            }}>
            Not set
          </Text>{" "}
        </View>
      )}
      {props.status == 0 && (
        <View style={{ width: 76, height: 24, borderRadius: 20, backgroundColor: "#6DD803", alignItems: "center" }}>
          <Text
            style={{
              fontFamily: "LatoBold",
              fontSize: 13,
              color: "#ffffff",
              width: "100%",
              textAlign: "center",
              marginTop: 3,
            }}>
            Good
          </Text>{" "}
        </View>
      )}
      {props.status == 1 && (
        <View style={{ width: 76, height: 24, borderRadius: 20, backgroundColor: "#F9CE37", alignItems: "center" }}>
          <Text
            style={{
              fontFamily: "LatoBold",
              fontSize: 13,
              color: "#ffffff",
              width: "100%",
              textAlign: "center",
              marginTop: 3,
            }}>
            Fair
          </Text>{" "}
        </View>
      )}
      {props.status == 2 && (
        <View style={{ width: 76, height: 24, borderRadius: 20, backgroundColor: "#FF6D6D", alignItems: "center" }}>
          <Text
            style={{
              fontFamily: "LatoBold",
              fontSize: 13,
              color: "#ffffff",
              width: "100%",
              textAlign: "center",
              marginTop: 3,
            }}>
            Poor
          </Text>{" "}
        </View>
      )}
    </View>
  );
};

export const PatientRecoveryStatusLeftBar = (props) => {
  return (
    <View>
      {props.status == null && null}
      {props.status == 0 && (
        <View
          style={{
            width: 9,
            height: 94,
            borderTopLeftRadius: 6,
            borderBottomLeftRadius: 6,
            backgroundColor: "#6DD803",
            position: "absolute",
            left: 0,
            top: -24,
          }}
        />
      )}
      {props.status == 1 && (
        <View
          style={{
            width: 9,
            height: 94,
            borderTopLeftRadius: 6,
            borderBottomLeftRadius: 6,
            backgroundColor: "#F9CE37",
            position: "absolute",
            left: 0,
            top: -24,
          }}
        />
      )}
      {props.status == 2 && (
        <View
          style={{
            width: 9,
            height: 94,
            borderTopLeftRadius: 6,
            borderBottomLeftRadius: 6,
            backgroundColor: "#FF6D6D",
            position: "absolute",
            left: 0,
            top: -24,
          }}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  listbutton: {
    paddingTop: 13,
    alignItems: "center",
    height: 42,
    width: 350,
    borderRadius: 15,
    backgroundColor: "#1F2E99",

    alignContent: "center",

    shadowColor: "#818181",
    shadowOffset: {
      width: 0,
      height: 16,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
  videoicon: {
    marginTop: 0,
    marginRight: 18,
    width: 34,
    height: 24,
  },
  sendicon: {
    marginRight: 20,
    width: 27,
    height: 27,
  },

  inviteusericon: {
    marginRight: 20,
    width: 16.666,
    height: 14,
  },

  startqa: {
    marginRight: 20,
    width: 20,
    height: 20,
  },

  cameraicon: {
    marginRight: 20,
    width: 29,
    height: 24,
  },

  nextarrow: {
    marginTop: 7,
    marginLeft: 20,
    width: 20,
    height: 11,
  },

  buttonshadowexit: {
    shadowColor: "#818181",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,

    backgroundColor: "transparent",
  },
  buttonshadowretake: {
    shadowColor: "#818181",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
    backgroundColor: "transparent",
  },
  cameraexitbutton: {
    width: 81,
    height: 37,
  },
  cameraretakebutton: {
    width: 117,
    height: 37,
  },

  container: {
    backgroundColor: "#FCF5EF",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    color: "#1F2E99",
    fontFamily: "Montserrat",
  },

  separator: {
    marginVertical: 30,
    height: 1,

    width: "80%",
  },
});
