import type { CommonIconProps } from "./icon-types";

export default function ShieldUserIcon({ size = 22, color = "currentColor", className }: Readonly<CommonIconProps>) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.79167 19.2495C7.75867 19.2495 7.72567 19.2459 7.69358 19.2385C7.61658 19.222 0 17.4593 0 9.10113V3.20788C0 3.01721 0.11825 2.84579 0.297 2.77888L7.63033 0.028875C7.73392 -0.009625 7.8485 -0.009625 7.953 0.028875L15.2863 2.77888C15.4651 2.84579 15.5833 3.01721 15.5833 3.20788V9.10113C15.5833 17.4593 7.96675 19.223 7.88975 19.2395C7.85767 19.2459 7.82467 19.2495 7.79167 19.2495Z"
        fill={color}
      />
      <path
        d="M18.3333 17.4167C17.3222 17.4167 16.5 16.5944 16.5 15.5833C16.5 14.5722 17.3222 13.75 18.3333 13.75C19.3444 13.75 20.1667 14.5722 20.1667 15.5833C20.1667 16.5944 19.3444 17.4167 18.3333 17.4167Z"
        fill={color}
      />
      <path
        d="M21.3128 21.9987H15.3545C14.975 21.9987 14.667 21.6907 14.667 21.3112V20.8529C14.667 19.4623 15.7972 18.332 17.1878 18.332H19.4795C20.8701 18.332 22.0003 19.4623 22.0003 20.8529V21.3112C22.0003 21.6907 21.6923 21.9987 21.3128 21.9987Z"
        fill={color}
      />
    </svg>
  );
}
