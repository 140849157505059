import type { CommonIconProps } from "./icon-types";

export interface EyeIconProps extends CommonIconProps {
  closed?: boolean;
}

export default function EyeIcon({ closed, ...props }: EyeIconProps): JSX.Element {
  if (closed) {
    return <EyeClosedIcon {...props} />;
  }
  return <EyeOpenIcon {...props} />;
}

export function EyeOpenIcon({ color = "currentColor", size = 26, className }: CommonIconProps): JSX.Element {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_65_2794)">
        <path
          d="M12.7777 5.58984C4.79161 5.58984 1.59717 12.7773 1.59717 12.7773C1.59717 12.7773 4.79161 19.9648 12.7777 19.9648C20.7638 19.9648 23.9583 12.7773 23.9583 12.7773C23.9583 12.7773 20.7638 5.58984 12.7777 5.58984Z"
          stroke={color}
          strokeWidth="1.7037"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.7777 16.7713C14.983 16.7713 16.7708 14.9835 16.7708 12.7782C16.7708 10.5729 14.983 8.78516 12.7777 8.78516C10.5724 8.78516 8.78467 10.5729 8.78467 12.7782C8.78467 14.9835 10.5724 16.7713 12.7777 16.7713Z"
          stroke={color}
          strokeWidth="1.7037"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_65_2794">
          <rect width="25.5556" height="25.5556" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function EyeClosedIcon({ color = "currentColor", size = 26, className }: CommonIconProps): JSX.Element {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_63_1312)">
        <path
          d="M4 13C6.10125 15.6012 9.95375 19 16 19C22.0462 19 25.8988 15.6012 28 13"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.0001 21.0005L25.0776 15.8867"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 24.0004L19.1138 18.6816"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 24.0004L12.8862 18.6816"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 21.0005L6.9225 15.8867"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_63_1312">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
