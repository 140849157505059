import React from "react";
import type { CommonIconProps } from "./icon-types";
import { css, cx } from "@emotion/css";

export default function Logo({ size = 150, className }: Readonly<CommonIconProps>) {
  return (
    <div className={cx("POLogo", style, className)}>
      <svg
        width={size}
        height={size / 3}
        viewBox="0 0 188 62"
        fill="none"
        role="img"
        xmlns="http://www.w3.org/2000/svg">
        <title>Post Op Logo</title>
        <path
          d="M2.94513 31.8362L2.88109 31.7715C1.21614 29.5715 0.255587 26.9833 0.0634766 24.2656V24.3303V44.9068C0.0634766 46.4598 1.28017 47.6892 2.81706 47.6892C4.35394 47.6892 5.57064 46.4598 5.57064 44.9068V34.2951C4.67412 33.5833 3.77761 32.7421 2.94513 31.8362Z"
          fill="#FE7235"
        />
        <path
          d="M23.2453 13.4593C20.3636 10.7416 17.1618 9.38281 13.6398 9.38281C10.8222 9.38281 8.19669 10.2887 5.82733 11.9063C1.98514 14.9475 0 18.7652 0 23.2299C0 23.5534 0 23.877 0.0640366 24.2005C0.128073 22.7123 1.34477 21.4828 2.81761 21.4828C4.35449 21.4828 5.57119 22.7123 5.57119 24.2652V34.2946C7.94054 36.1064 10.502 37.0123 13.1275 37.0123H13.896C16.0092 36.9476 18.1224 36.4299 19.9794 35.3946H20.0435C24.8462 32.4829 27.2796 28.4064 27.2796 23.2299C27.2796 19.6064 25.8708 16.3711 23.2453 13.4593ZM21.7084 23.3593C21.7084 24.3946 21.5163 25.4299 21.1321 26.3358C19.4031 29.7005 17.0337 31.3182 13.896 31.3182H13.3196C12.167 31.3182 11.0143 31.0593 9.98972 30.477C6.97999 28.7299 5.50715 26.4005 5.50715 23.2946V22.8417C5.50715 22.0005 5.63522 21.224 5.89137 20.4475C7.55632 16.7593 9.98972 14.9475 13.5117 14.9475C15.1767 14.9475 16.7776 15.4005 18.1224 16.3711C20.4917 18.1828 21.6444 20.2534 21.6444 22.8417V23.3593H21.7084Z"
          fill="#0077FF"
        />
        <path
          d="M2.81706 21.4844C1.34421 21.4844 0.127513 22.7138 0.0634766 24.202C0.319624 26.9197 1.28017 29.5079 2.88109 31.7079L2.94513 31.7726C3.77761 32.7432 4.67412 33.5197 5.57064 34.2315V24.3314C5.57064 22.7785 4.35394 21.4844 2.81706 21.4844Z"
          fill="#1F2E99"
        />
        <path
          d="M140.56 12.4883C137.743 9.83539 134.541 8.47656 131.019 8.47656C128.201 8.54127 125.576 9.38245 123.206 11.0001C119.364 13.9766 117.379 17.7942 117.379 22.2589C117.443 25.3648 118.403 28.2766 120.261 30.8001L120.325 30.8648C123.462 34.2943 126.856 36.0413 130.57 36.0413H131.339C133.452 35.9766 135.565 35.459 137.422 34.4237H137.486C142.289 31.5119 144.723 27.4354 144.723 22.2589C144.659 18.7001 143.25 15.4001 140.56 12.4883ZM122.95 22.0001C122.95 20.8354 123.27 19.6707 123.783 18.7001C125.383 15.5942 127.753 14.106 130.955 14.106C132.62 14.0413 134.221 14.5589 135.565 15.5295C137.935 17.3413 139.087 19.4119 139.087 22.0001V22.4531C139.087 23.2942 138.959 24.0707 138.703 24.9119C138.575 25.2354 138.447 25.6237 138.255 25.9472C136.59 28.9884 134.285 30.4119 131.275 30.4119H130.699C129.546 30.4119 128.393 30.1531 127.369 29.5707C124.359 27.8237 122.886 25.4942 122.886 22.3884L122.95 22.0001Z"
          fill="#0077FF"
        />
        <path
          d="M53.3414 13.3946C50.5238 10.7416 47.322 9.38281 43.8 9.38281C40.9824 9.38281 38.3568 10.2887 35.9875 11.9063C32.1453 14.8828 30.1602 18.7005 30.1602 23.1652C30.2242 26.2711 31.1847 29.1829 33.0418 31.7064L33.1058 31.7711C36.2436 35.2005 39.6376 36.9476 43.3517 36.9476H44.1201C46.2334 36.8829 48.3466 36.3652 50.2036 35.3299H50.2677C55.0704 32.4182 57.5038 28.3417 57.5038 23.1652C57.4398 19.5417 56.095 16.3064 53.3414 13.3946ZM35.7313 22.9064C35.7313 22.0652 35.8594 21.2887 36.1156 20.5123C37.7805 16.824 40.2139 15.0122 43.7359 15.0122C45.4009 15.0122 47.0018 15.4652 48.3466 16.4358C50.7159 18.2475 51.8686 20.3181 51.8686 22.9064V23.3593C51.8686 24.3946 51.6765 25.4299 51.2923 26.3358C49.5633 29.7005 47.1939 31.3182 44.0561 31.3182H43.5438C42.3912 31.3182 41.2385 31.0593 40.2139 30.477C37.2042 28.7299 35.7313 26.4005 35.7313 23.2946V22.9064Z"
          fill="#0077FF"
        />
        <path
          d="M72.1053 20.8971C71.657 20.703 71.2088 20.5088 70.7605 20.3147C68.4552 19.4088 65.8297 18.3088 65.8297 17.1441C65.8297 14.6853 69.2236 14.4912 70.2482 14.4912C72.2974 14.4912 74.2825 15.0088 75.179 15.5265C75.6273 15.7853 76.0756 15.9147 76.5879 15.9147C76.7159 15.9147 76.78 15.9147 76.908 15.9147C77.5484 15.85 78.1247 15.5912 78.509 15.0735C78.8932 14.6206 79.0853 13.9735 79.0853 13.3912C79.0853 12.4206 78.637 11.5147 77.8046 10.9971H77.7405C75.4992 9.83235 72.9378 9.25 70.3763 9.25C64.3568 9.25 60.4506 12.4853 60.4506 17.5324C60.4506 22.1912 66.4701 24.3912 70.0561 25.75C71.9772 26.4618 74.6027 27.5618 74.6027 29.1795C74.6027 32.0912 70.5044 32.1559 70.0561 32.1559C68.0069 32.0912 66.0858 31.4442 64.4209 30.2795C63.9086 29.8912 63.2682 29.6324 62.6279 29.6324H62.5638C61.4752 29.6971 60.1945 30.4089 60.1945 32.4795C60.1304 33.3206 60.5146 34.0971 61.091 34.6148L61.155 34.6795C64.1647 36.6207 66.8543 37.4618 69.928 37.4618C74.6027 37.4618 80.1099 35.2618 80.1099 28.9206C80.1099 24.1971 75.8834 22.45 72.1053 20.8971Z"
          fill="#0077FF"
        />
        <path
          d="M99.3855 33.9707C98.9372 31.7706 97.6565 31.4471 96.9521 31.4471H95.9915L94.0704 31.253C93.5581 31.0589 92.4695 30.4765 92.4695 27.8236V15.2059H94.9029L95.5433 15.2706C96.4398 15.3353 97.3363 15.0118 97.9767 14.4294C98.617 13.8471 98.8732 12.9412 98.617 12.1C98.1047 10.2883 96.9521 9.90002 96.1196 9.90002H92.4055V2.39412C92.4055 1.68236 92.0853 0.452942 90.0361 0C89.9721 0 89.908 0 89.844 0H89.5238C89.5238 0 89.5238 0 89.4598 0C88.6273 0 87.2825 0.388236 87.0264 2.84706C87.0264 2.91177 87.0264 2.91177 87.0264 2.97648V9.83531H84.7851C84.0167 9.83531 82.6079 10.1588 82.2236 12.3588C82.2236 12.4236 82.2236 12.4883 82.2236 12.4883C82.2236 13.3941 82.6079 14.1059 83.4403 14.6236C83.9526 14.9471 84.593 15.1412 85.2974 15.1412H87.0904V28.3412C87.0904 29.3765 87.2185 30.4118 87.4746 31.3824C87.4746 31.4471 87.4746 31.4471 87.5387 31.5118C89.1396 35.0707 91.573 36.8824 94.9029 36.8824H96.824C98.2328 36.8824 99.1934 35.9765 99.4495 34.3589C99.4495 34.2942 99.4495 34.2295 99.4495 34.2295V34.1648C99.4495 34.1001 99.4495 34.0354 99.3855 33.9707Z"
          fill="#0077FF"
        />
        <path
          d="M175.846 51.4372C174.629 50.2078 172.708 50.2725 171.492 51.5019C166.753 56.3549 159.069 56.4196 154.202 51.696C153.625 51.1137 152.857 50.7902 152.025 50.7902C152.025 50.7902 152.025 50.7902 151.96 50.7902C151.128 50.7902 150.36 51.1137 149.783 51.696C149.207 52.2784 148.887 53.0549 148.887 53.8961C148.887 54.7372 149.207 55.5137 149.783 56.0961C152.217 58.4902 155.226 60.1725 158.556 60.949C159.901 61.2725 161.31 61.402 162.655 61.402C167.521 61.402 172.196 59.4608 175.718 55.8372C176.294 55.2549 176.615 54.4784 176.615 53.6372C176.743 52.796 176.422 52.0196 175.846 51.4372Z"
          fill="#FE7235"
        />
        <path
          d="M150.934 30.4768L150.87 30.4121C149.205 28.2121 148.245 25.6239 148.053 22.9062V22.971V43.5475C148.053 45.1004 149.269 46.3298 150.806 46.3298C152.343 46.3298 153.56 45.1004 153.56 43.5475V32.9357C152.663 32.2886 151.767 31.4474 150.934 30.4768Z"
          fill="#FE7235"
        />
        <path
          d="M171.17 12.1624C168.288 9.44476 165.087 8.08594 161.565 8.08594C158.747 8.08594 156.121 8.99182 153.752 10.6095C149.91 13.5859 147.925 17.4036 147.925 21.8683C147.925 22.1918 147.925 22.5154 147.989 22.8389C148.053 21.3507 149.27 20.1213 150.742 20.1213C152.279 20.1213 153.496 21.3507 153.496 22.9036V32.8683C155.865 34.6801 158.427 35.586 161.052 35.586H161.821C163.934 35.5213 166.047 35.0036 167.904 33.9683H167.968C172.771 31.0566 175.204 26.9801 175.204 21.8036C175.268 18.3095 173.86 15.0095 171.17 12.1624ZM169.697 22.0624C169.697 23.0977 169.505 24.133 169.121 25.0389C167.392 28.4036 165.023 30.0213 161.885 30.0213H161.308C160.156 30.0213 159.003 29.7624 157.979 29.1801C154.969 27.433 153.496 25.1036 153.496 21.9977V21.5448C153.496 20.7036 153.624 19.9271 153.88 19.1507C155.545 15.4624 157.979 13.6507 161.501 13.6507C163.166 13.6507 164.766 14.1036 166.111 15.0742C168.481 16.886 169.633 18.9565 169.633 21.5448V22.0624H169.697Z"
          fill="#0077FF"
        />
        <path
          d="M150.806 20.1875C149.333 20.1875 148.117 21.4169 148.053 22.9052C148.309 25.6228 149.269 28.211 150.87 30.4111L150.934 30.4758C151.767 31.4463 152.663 32.2228 153.56 32.9346V22.9699C153.56 21.4816 152.343 20.1875 150.806 20.1875Z"
          fill="#1F2E99"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M184.497 6.55209C186.181 6.55209 187.546 5.18701 187.546 3.5031C187.546 1.81919 186.181 0.454105 184.497 0.454105C182.813 0.454105 181.448 1.81919 181.448 3.5031C181.448 5.18701 182.813 6.55209 184.497 6.55209ZM184.497 7.0062C186.432 7.0062 188 5.43781 188 3.5031C188 1.56839 186.432 0 184.497 0C182.563 0 180.994 1.56839 180.994 3.5031C180.994 5.43781 182.563 7.0062 184.497 7.0062Z"
          fill="#0077FF"
        />
        <path
          d="M183.204 5.26616H183.849V3.98606H184.657L185.56 5.26616H186.326L185.334 3.87589C185.843 3.729 186.205 3.367 186.205 2.76368V2.75318C186.205 2.43316 186.095 2.1656 185.901 1.96099C185.665 1.73015 185.303 1.59375 184.841 1.59375H183.204V5.26616ZM183.849 3.41422V2.18134H184.788C185.271 2.18134 185.549 2.39119 185.549 2.78991V2.8004C185.549 3.17813 185.255 3.41422 184.794 3.41422H183.849Z"
          fill="#0077FF"
        />
      </svg>
    </div>
  );
}

const style = css`
  display: inline-block;
  vertical-align: middle;
`;
