import React, { useEffect } from "react";
import { View, StyleSheet } from "react-native";
import { MainFramePatient } from "../components/MainFramePatient";
import { withStore } from "../misc/utils";
import { useRoute } from "@react-navigation/native";

const TempScreen = withStore((props) => {
  const params = useRoute()?.params ?? {};
  const { patientid } = params as any;
  const [data, setData] = React.useState<any>(null);

  useEffect(() => {
    async function fetchData() {
      fetch(global.apiurl + "/clinicians/getpatientpromsdata", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: global.tokenparam || "",
        },
        body: JSON.stringify({
          patientid,
        }),
      })
        .then(async (response) => await response.json())
        .then((resData) => {
          console.log(resData);
          if (resData.result) {
            setData(resData);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, []);

  return (
    <View style={styles.container}>
      {data && (
        <MainFramePatient
          userStore={props.userStore}
          selected={8}
          patient={data.patient}
          patientlist
          style={{ alignItems: "flex-start", flexDirection: "row", width: "100%" }}></MainFramePatient>
      )}
    </View>
  );
});
export default TempScreen;

const styles = StyleSheet.create({
  whitebox: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    shadowColor: "#818181",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
  title1: {
    fontFamily: "Lato",
    fontSize: 13,
    color: "#1F2E99",
  },
  title2: {
    fontFamily: "Lato",
    fontSize: 15,
    color: "#1F2E99",
  },
  title3: {
    fontFamily: "LatoBold",
    fontSize: 18,
    color: "#1F2E99",
  },

  container: {
    flex: 1,
  },
});
