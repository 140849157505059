import React, { useEffect } from "react";
import { View, Text, StyleSheet } from "react-native";
import { MainFramePatient } from "../components/MainFramePatient";
import { Hairline } from "../components/Hairline";
import { PREMSScoreClientChart } from "../components/Charts";
import { withStore } from "../misc/utils";
import { useRoute } from "@react-navigation/native";

const PatientPREMSScreen = withStore((props) => {
  const params = useRoute()?.params ?? {};
  const { patientid } = params as any;
  const [data, setData] = React.useState<any>();

  useEffect(() => {
    async function fetchData() {
      fetch(global.apiurl + "/clinicians/getpatientpremsdashboard", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: global.tokenparam || "",
        },
        body: JSON.stringify({
          patientid,
        }),
      })
        .then(async (response) => await response.json())
        .then((resData) => {
          console.log(resData);
          if (resData.result) {
            setData(resData);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, []);

  return (
    <View style={styles.container}>
      {data && (
        <MainFramePatient
          userStore={props.userStore}
          selected={4}
          patient={data?.patient}
          clinician={data?.clinician}
          patientlist
          style={{ alignItems: "flex-start", flexDirection: "column", width: "100%" }}>
          <View style={{ flexDirection: "row", alignItems: "flex-end" }}>
            {" "}
            <Text style={[styles.title3, {}]}>PREMS questionnaire data</Text>
          </View>

          <View style={[styles.whitebox, { marginTop: 23, width: 556, height: 370, marginBottom: 32 }]}>
            <View style={{ justifyContent: "space-between", flexDirection: "row" }}>
              <Text style={[styles.title3, { marginLeft: 27, marginTop: 18 }]}>PREMS SCORE</Text>

              <View style={{ flexDirection: "row" }}>
                <Text style={[styles.title5, { marginRight: 10, marginTop: 20 }]}>compliance rate</Text>
                <Text style={[styles.title4, { marginRight: 27, marginTop: 21 }]}>
                  {data?.data.dashinfo.complianceprems ? Math.round(data?.data.dashinfo.complianceprems) : "-"}%
                </Text>
              </View>
            </View>
            <Hairline marginTop={16} />
            <Text style={[styles.title9, { marginTop: 30, marginLeft: 27 }]}>Prems Score</Text>
            <PREMSScoreClientChart
              patient={data?.patient}
              yaxis={[0, 2, 4, 6, 8, 10, 12]}
              data={data?.data.dashchart1}></PREMSScoreClientChart>
            <Text style={[styles.title9, { textAlign: "center" }]}>Patient recovery period</Text>
          </View>
        </MainFramePatient>
      )}
    </View>
  );
});
export default PatientPREMSScreen;
const styles = StyleSheet.create({
  whitebox: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    shadowColor: "#818181",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
  title1: {
    fontFamily: "Lato",
    fontSize: 13,
    color: "#1F2E99",
  },
  title2: {
    fontFamily: "Lato",
    fontSize: 13,
    color: "#1F2E99",
  },
  title3: {
    fontFamily: "LatoBold",
    fontSize: 18,
    color: "#1F2E99",
  },
  title4: {
    fontFamily: "LatoBold",
    fontSize: 13,
    color: "#1F2E99",
  },
  title5: {
    fontFamily: "Lato",
    fontSize: 13,
    color: "#9A9A9A",
  },
  title6: {
    fontFamily: "Lato",
    fontSize: 11,
    color: "#9A9A9A",
  },
  title9: {
    fontFamily: "Lato",
    fontSize: 11,
    color: "#9A9A9A",
  },
  container: {
    flex: 1,
  },
});
