import { DependencyList, useMemo, useRef, useState } from "react";
import { useAsyncEffect } from "./useAsyncEffect";
import { Signal } from "../misc/signal";

export type AsyncResourceState<T> =
  | {
      status: "loading";
      resource: null | undefined;
      refresh: Signal;
    }
  | {
      status: "loaded";
      resource: T;
      refresh: Signal;
    }
  | {
      status: "error";
      resource: null | undefined;
      error: any;
      refresh: Signal;
    };

export default function useAsyncResource<T>(
  factory: () => Promise<T>,
  deps: DependencyList = [],
): AsyncResourceState<T> {
  const [wantsRefresh, setWantsRefresh] = useState(false);
  const refresh = useMemo(() => Signal(() => setWantsRefresh(true)), []);
  const [state, setState] = useState<AsyncResourceState<T>>({ status: "loading", resource: null, refresh });
  const refCounter = useRef(0);

  useAsyncEffect(async () => {
    const id = ++refCounter.current;
    setState({ status: "loading", resource: null, refresh });
    try {
      const resource = await factory();
      if (refCounter.current === id) {
        setState({
          status: "loaded",
          resource,
          refresh,
        });
      }
    } catch (error) {
      if (refCounter.current === id) {
        setState({
          status: "error",
          resource: null,
          error,
          refresh,
        });
      }
    }
  }, [...deps, wantsRefresh]);

  return state;
}
