import { StackNavigationProp } from "@react-navigation/stack";
import type { WelcomeFlowParams, ClinicianParams, HospitalAdminParams, PostopAdminParams } from "../navigation/params";
import { useNavigation } from "@react-navigation/native";

type NavType = 'welcome' | 'clinician' | 'hospitalAdmin' | 'postopAdmin';
type NavigatorMap = {
  welcome: WelcomeFlowParams;
  clinician: ClinicianParams;
  hospitalAdmin: HospitalAdminParams;
  postopAdmin: PostopAdminParams;
};
type NavigatorParams<T extends NavType> = NavigatorMap[T];

export const useNav = <T extends NavType = NavType>() => useNavigation<StackNavigationProp<NavigatorParams<T>>>();
