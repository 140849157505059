import * as React from "react";
import { Text, View, StyleSheet, Image, TouchableOpacity } from "react-native";

export const OrangeButton = (props) => {
  return (
    <TouchableOpacity disabled={props.disabled} style={[styles.listbutton, props.style]} onPress={props.onPress}>
      <View style={{ flex: 1, flexDirection: "row", backgroundColor: "transparent" }}>
        {props?.addhostpital && <Image source={require("../assets/images/adduser.png")} style={styles.adduser} />}
        <Text style={{ color: "#FFFFFF", fontFamily: "Questrial", fontSize: 16 }}>{props.title}</Text>
        {props?.sendicon && <Image source={require("../assets/images/sendsmsicon.png")} style={styles.sendicon} />}
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  listbutton: {
    paddingTop: 13,
    alignSelf: "left",
    alignItems: "center",
    height: 42,
    width: 350,
    borderRadius: 15,
    backgroundColor: "#FE5935",

    alignContent: "center",
  },
  sendicon: {
    marginLeft: 15,
    width: 16,
    height: 16,
  },
  adduser: {
    marginRight: 8.33,
    width: 16.6,
    height: 13,
  },
});
