import type { CSSProperties } from "react";
import React from "react";
import { withStore } from "../misc/utils";
import Clickable from "./Clickable";
import { css, cx } from "@emotion/css";
import { getImageUrl } from "../misc/constants";

export interface AvatarProps {
  /** @deprecated use imageUrl instead */
  url?: string;
  imageUrl?: string;
  size: number;
  /** @deprecated will be replaced with different variants */
  radius?: number | string;
  /** @deprecated use firstName instead */
  firstname?: string;
  firstName?: string;
  /** @deprecated use lastName instead */
  lastname?: string;
  lastName?: string;
  /** @deprecated */
  fontsize?: number;
  /** @deprecated */
  fontcolor?: string;
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
}

export type UserAvatarProps = Omit<
  AvatarProps,
  "url" | "imageUrl" | "firstname" | "firstName" | "lastname" | "lastName" | "fontsize" | "fontcolor"
>;

export const Avatar = ({ onClick, size = 132, radius = "50%", className, style, ...props }: AvatarProps) => {
  const imageUrl = props.imageUrl ?? props.url;
  const firstName = props.firstName ?? props.firstname ?? "";
  const lastName = props.lastName ?? props.lastname ?? "";

  return (
    <Clickable
      onClick={onClick}
      className={cx(
        styles.root,
        css`
          border-radius: ${typeof radius === "number" ? `${radius}px` : radius};
          width: ${size}px;
          height: ${size}px;
        `,
        className
      )}
      style={style}>
      {imageUrl ? (
        <img src={getImageUrl(imageUrl)} className="POAvatar-picture" />
      ) : (
        <span
          className={cx(
            "POAvatar-initials",
            css`
              font-size: ${(size * 60) / 132}px;
              color: ${props.fontcolor ? props.fontcolor : "white"};
            `
          )}>
          {String(firstName).charAt(0)?.toUpperCase()}
          {String(lastName).charAt(0)?.toUpperCase()}
        </span>
      )}
    </Clickable>
  );
};

/** Specialized avatar representing the logged-in user */
export const UserAvatar = withStore<UserAvatarProps>(({ userStore, ...props }) => {
  // TODO: this is a bug in the API, but we need to handle it for now
  let imageUrl: string | undefined = userStore?.userphotothumbnailurl + "";
  if (imageUrl === "null" || imageUrl === "undefined") {
    imageUrl = undefined;
  }

  return (
    <Avatar {...props} imageUrl={imageUrl} firstName={userStore?.userfirstname} lastName={userStore?.userlastname} />
  );
});

const styles = Object.freeze({
  root: css`
    display: flex;
    background: #36c2d5;
    border: 1px solid #f2f3f7;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1;
    overflow: hidden;

    .POAvatar-initials {
      font-family: Lato;
      font-weight: bold;
      color: white;
    }
    .POAvatar-picture {
      width: 100%;
    }
  `,
});
