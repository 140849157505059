/* eslint-disable @typescript-eslint/no-misused-promises */
import type { ChangeEventHandler } from "react";
import React, { useEffect, useState } from "react";
import moment from "moment";
import PostopAdminLayout from "../../components/layouts/PostopAdminLayout";
import { requests } from "../../misc";
import { toastError, toastSuccess } from "../../components/Notifications";

interface PatientList {
  id: number;
  first_name: string;
  last_name: string;
  country_code: string;
  phone: string;
  created_at: Date;
  invite_accepted: boolean;
  discharged: boolean;
  disabled: boolean;
  invitation_code: string;
  hospital_name: string;
  clinician_first_name: string;
  clinician_last_name: string;
}

function page(): JSX.Element {
  const [patients, setPatients] = useState<any[]>();
  const [query, setQuery] = useState("");

  const onQueryChanged: ChangeEventHandler<HTMLInputElement> = async (e) => {
    setQuery(e.target.value);
  };

  const performSearch = async (): Promise<void> => {
    if (!query || query.length < 3) return;
    const resData = await requests.get(`/postopadmins/search-patients?q=${encodeURIComponent(query)}`);
    setPatients(resData.data);
  };

  const reset = async (id: number): Promise<void> => {
    try {
      await requests.post("/postopadmins/reset-patient", { patient_id: id });
      toastSuccess("Phone number reset successfully");
      performSearch();
    } catch (err: any) {
      toastError("Failed to reset phone number", err);
      console.error(err);
    }
  };

  const bool = function (value: any): JSX.Element {
    return <span style={{ fontSize: 20, fontWeight: "bold" }}>{value ? "☒" : "☐"}</span>;
  };

  useEffect(() => {
    performSearch();
  }, [query]);

  return (
    <PostopAdminLayout>
      <h1>Patient Search</h1>
      <input
        id="q"
        name="q"
        type="text"
        placeholder="Name or phone number"
        onChange={onQueryChanged}
        className="search-box"
      />
      {patients && patients.length > 0 ? (
        <table className="POAdminResults">
          <thead>
            <tr>
              <th>Id</th>
              <th>Action</th>
              <th>Name</th>
              <th>Phone</th>
              <th>Hospital</th>
              <th>Invited By</th>
              <th>Created</th>
              <th>Invite Accepted</th>
              <th>Discharged</th>
              <th>Disabled</th>
              <th>Invitiation Code</th>
            </tr>
          </thead>
          <tbody>
            {patients.map(
              (p: PatientList): JSX.Element => (
                <tr key={p.id}>
                  <td>{p.id}</td>
                  <td>
                    <a
                      onClick={() => {
                        reset(p.id);
                      }}
                      href="#">
                      Reset Phone
                    </a>
                  </td>
                  <td>{`${p.first_name} ${p.last_name}`}</td>
                  <td>{`+${p.country_code} ${p.phone}`}</td>
                  <td>{p.hospital_name}</td>
                  <td>{`${p.clinician_first_name} ${p.clinician_last_name}`}</td>
                  <td>
                    <span title={p.created_at as any}>{moment(p.created_at).fromNow()}</span>
                  </td>
                  <td style={{ textAlign: "center" }}>{bool(p.invite_accepted)}</td>
                  <td style={{ textAlign: "center" }}>{bool(p.discharged)}</td>
                  <td style={{ textAlign: "center" }}>{bool(p.disabled)}</td>
                  <td>{p.invitation_code}</td>
                </tr>
              ),
            )}
          </tbody>
        </table>
      ) : (
        <div
          style={{
            margin: "auto",
            textAlign: "center",
            width: "60%",
            marginTop: 30,
          }}>
          Please enter a few letters or numbers from name or phone of patient.
        </div>
      )}
    </PostopAdminLayout>
  );
}

export default page;
