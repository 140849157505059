import * as React from "react";
import { TextInput, Dimensions, View, Text, StyleSheet, Image, TouchableOpacity } from "react-native";
import { A } from "@expo/html-elements";

import { OrangeButton } from "../OrangeButton";
import validate from "../../screens/validate_wrapper";

const Temp = (props) => {
  const { userStore, route, navigation } = props;

  const { email, password } = props;
  const [logincode, setLogincode] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorText, setErrorText] = React.useState("");
  const apigateway = props?.admin ? "hospitaladmins" : props?.postopadmin ? "postopadmins" : "clinicians";

  const dimensions = Dimensions.get("window");

  function sendagain() {
    fetch(global.apiurl + "/" + apigateway + "/checklogin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    })
      .then(async (res) => {
        if (res.status === 422) {
          setIsLoading(false);
          setErrorText("Unable to verify,\nPlease check the your login details.");
          throw new Error("Validation failed.");
        }

        if (res.status === 429) {
          setIsLoading(false);
          setErrorText("Too many requests,\nplease try again in a few minutes.");
          throw new Error("Validation failed.");
        }

        if (res.status !== 200 && res.status !== 201) {
          setIsLoading(false);

          console.log("Error!");
          setErrorText("Unable to verify,\nPlease check the your login details.");

          throw new Error("Could not authenticate you!2");
        }
        return await res.json();
      })
      .then((resData) => {
        console.log(resData);
        if (resData.result) {
          setErrorText("Authentication code sent.");
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setErrorText("Unable to verify,\nPlease check the your login details.");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setErrorText("Unable to connect,\nPlease check your connection.");
        console.log(err);
      });
  }

  function maskemail(email) {
    const maskid = email.replace(/^(.)(.*)(.@.*)$/, (_, a, b, c) => a + b.replace(/./g, "*") + c);

    return maskid;
  }
  function validateForm() {
    setIsLoading(true);
    setErrorText("");
    const passwordError = validate("logincode", logincode);
    console.log("xxx");

    if (!passwordError) {
      login();
    } else {
      setIsLoading(false);
      setErrorText(passwordError ? "\n" + passwordError + "\n" : "");
    }
  }

  function login() {
    fetch(global.apiurl + "/" + apigateway + "/login2fa", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
        logincode,
      }),
      credentials: "include",
    })
      .then(async (res) => {
        if (res.status === 422) {
          setIsLoading(false);
          setErrorText("Unable to verify,\nPlease check the your login details.");
          throw new Error("Validation failed.");
        }

        if (res.status === 429) {
          setIsLoading(false);
          setErrorText("Too many requests,\nplease try again in a few minutes.");
          throw new Error("Validation failed.");
        }

        if (res.status !== 200 && res.status !== 201) {
          setIsLoading(false);
          console.log("Error!");
          setErrorText("Unable to verify,\nPlease check the your login details.");
          throw new Error("Could not authenticate you!2");
        }
        return await res.json();
      })
      .then((resData) => {
        console.log(resData);
        if (resData.result) {
          userStore.setFullLoggedinIsAdmin(
            resData.token,
            resData.photourl,
            resData.firstname,
            resData.lastname,
            props.admin ? 2 : props.postopadmin ? 3 : 1,
            resData.readonly,
          );
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setErrorText("Unable to verify,\nPlease check the your code.");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setErrorText("Unable to connect,\nPlease check your connection.");
        console.log(err);
      });
  }

  function passwordKeyPress(e) {
    if (e.nativeEvent.key == "Enter") {
      validateForm();
    }
  }

  return (
    <View
      style={[
        { width: 350, flexShrink: 0.5, backgroundColor: "#ffffff" },
        { backgroundColor: props.bgcolor ? props.bgcolor : "#ffffff" },
      ]}>
      <Image
        source={require("../../assets/images/logopostop1.1.png")}
        style={{ width: 186.1, height: 61, marginTop: 93 }}
      />
      <Text style={styles.title1}>
        Provide high-level security
        <br />
        for your account
      </Text>
      <Text style={styles.title2}>
        Protecting your data is our top-priority.
        <br />
        <br />
        We sent an authentication code to confirm your account to your email:
        <br />
        {maskemail(props.email)}
      </Text>

      <Text style={[styles.title5, { marginTop: 22 }]}>Authentication code</Text>
      <View style={styles.shadowinput}>
        <TextInput
          style={styles.phoneinput}
          keyboardType="email-address"
          onChangeText={(text) => {
            setLogincode(text.replace(" ", ""));
          }}
          value={logincode}
          returnKeyType="done"
          onSubmitEditing={() => {
            validateForm();
          }}
        />
      </View>

      <View style={{ flexDirection: "row", marginTop: 16, width: 350 }}>
        <Text style={{ fontFamily: "Lato", fontSize: 11, lineHeight: 14, color: "#000000" }}>
          Didn’t receive the email?{" "}
        </Text>
        <TouchableOpacity
          onPress={() => {
            sendagain();
          }}>
          <Text
            style={{
              fontFamily: "Lato",
              marginLeft: 4,
              fontSize: 11,
              lineHeight: 14,
              color: "#0009EC",
              textDecorationLine: "underline",
            }}>
            Send it again
          </Text>
        </TouchableOpacity>
      </View>
      <Text style={{ fontFamily: "Lato", fontSize: 11, lineHeight: 14, color: "#000000" }}>
        Don’t forget to check your spam box.
      </Text>

      <View style={{ justifyContent: "flex-end  ", position: "absolute", top: 568, height: 60 }}>
        <Text style={{ color: "#FF6D6D" }}>{errorText}</Text>
      </View>

      <OrangeButton
        disabled={isLoading}
        onPress={() => {
          validateForm();
        }}
        title="Verify Code"
        on
        style={{ marginTop: 126 }}
      />
    </View>
  );
};

export default Temp;

const styles = StyleSheet.create({
  image: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",
    height: "100%",
  },

  phoneinput: {
    flex: 1,
    outlineWidth: 0,
    color: "#1F2E99",
    fontFamily: "Lato",
    fontSize: 15,
    marginRight: 10,
    marginLeft: 20,
  },
  shadowinput: {
    alignItems: "center",

    marginTop: 8,
    height: 48,
    flexDirection: "row",
    borderRadius: 15,
    shadowColor: "#818181",
    backgroundColor: "#FFFFFF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
  title1: {
    fontSize: 32,
    fontFamily: "LatoBold",
    color: "#1F2E99",
    marginTop: 39.6,
    width: 430,
  },
  title2: {
    marginTop: 18,
    fontSize: 18,
    lineHeight: 21.6,
    fontFamily: "Lato",
    color: "#000000",
  },

  title5: {
    marginTop: 32,
    fontSize: 13,
    color: "#798599",
    fontFamily: "Lato",
  },
});
