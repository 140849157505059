import Constants from "expo-constants";
import { inject, observer } from "mobx-react";
import type { Dispatch, SetStateAction } from "react";
import React, { useState } from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import { CarePlan, CarePlanWeekSelector } from "../components/CarePlan";
import { OverallComplianceClientChart } from "../components/Charts";
import { MainFramePatient } from "../components/MainFramePatient";
import { toast, toastError, toastSuccess } from "../components/Notifications";
import * as request from "../misc/requests";
import { useAsyncEffect } from "../hooks/useAsyncEffect";
import { useFeatureFlag } from "../hooks/useFeatureFlag";

const TempScreen = inject("userStore")(
  observer((props: any) => {
    const isDay0Enabled = useFeatureFlag("showNewHomeScreen");
    const dayOffset = isDay0Enabled ? 0 : 1;

    const [isLoading, setLoading] = React.useState(false);
    const [updateInStagingWarningShown, setUpdateInStagingWarningShown] = React.useState(false);

    const { patientid } = props.route.params;
    const [data, setData] = React.useState<any>(null);
    const [careplanWeek, setCareplanWeek] = React.useState(1);
    const [editmode, setEditmode] = React.useState(false);
    const [complianceWeek, setComplianceWeek] = React.useState(0);
    const [patientActivities, setPatientActivities] = React.useState<any[]>([]);
    const [patientActivitiesOriginal, setPatientActivitiesOrg] = React.useState<typeof patientActivities>([]);
    const [patientActivitiesExtras, setPatientActivitiesExtras] = React.useState<typeof patientActivities>([]);
    const [fromLinkMode, setFromLinkMode] = useState(false);

    async function submit() {
      setLoading(true);
      try {
        await request.post("/clinicians/updatepatientcalender", {
          patientid,
          patientactivities: patientActivities,
        });
        setEditmode(false);
        setPatientActivitiesOrg(patientActivities);
        toastSuccess("Patient care-plan is updated.");
      } catch (err: any) {
        toastError("Unable to update, please try later", err);
        console.error(err);
      } finally {
        setLoading(false);
      }
    }

    function onToggleActivity(activity: string, dayno: number) {
      if (dayno <= data.patient.dayno) {
        if (["STAGING", "LOCAL"].includes(Constants.expoConfig!.extra!.environment)) {
          if (!updateInStagingWarningShown) {
            toast({
              message: "In production, you can only make changes on a future date.",
              type: "warning",
              title: "Caution!",
            });
            setUpdateInStagingWarningShown(true);
          }
        } else {
          toastError("You can only make changes on a future date.");
          return;
        }
      }

      setPatientActivities((currActivities) => {
        const idx = currActivities.findIndex((activity) => activity.dayno === dayno);

        const copy = currActivities.slice();
        if (idx !== -1) {
          copy[idx][activity] = !copy[idx][activity];
        } else {
          copy.push({
            dayno,
            [activity]: true,
          });
        }
        return copy;
      });
    }

    function onPressActivity(activity: string, dayno: number) {
      if (activity === "photo" || activity === "video" || activity === "voice") {
        window.open("/patientmedia?patientid=" + patientid + "&day=" + dayno, "_blank")!.focus();
        return;
      }

      let activitytype = 0;
      switch (activity) {
        case "ssis":
          activitytype = 2;
          break;
        case "eq5d5l":
          activitytype = 3;
          break;
        case "ohsoks":
          activitytype = data.patient.operationtype == 1 ? 5 : 4;
          break;
        case "oks":
          activitytype = 5;
          break;
        case "phq9":
          activitytype = 6;
          break;
        case "prems":
          activitytype = 7;
          break;
        case "wqol":
          activitytype = 9;
          break;
        case "fhs":
          activitytype = 10;
          break;
        case "vhi10":
          activitytype = 11;
          break;
        case "ess6":
          activitytype = 12;
          break;
        case "snot":
          activitytype = 13;
          break;
      }

      const query = {
        patientId: patientid,
        dayno,
        fromLinkMode,
      };
      let queryString = Object.entries(query)
        .map(([key, value]) => `${key}=${value}`)
        .join("&");
      if (queryString) queryString = "?" + queryString;

      const pdfLocation = `${global.apiurl}/v1/questionnaires/${activitytype}/pdf` + queryString;

      // @ts-expect-error
      document.getElementById("modaliframe").src = "/pdf/viewer.html?file=" + encodeURIComponent(pdfLocation);
      document.getElementById("myModal")!.style.display = "block";
    }

    function onPrevCareplanWeek() {
      setCareplanWeek(Math.max(1, careplanWeek - 1));
    }
    function onNextCareplanWeek() {
      setCareplanWeek(Math.min(60, careplanWeek + 1));
    }

    function onPrevComplianceWeek() {
      setComplianceWeek(Math.max(0, complianceWeek - 1));
    }
    function onNextComplianceWeek() {
      setComplianceWeek(Math.min(59, complianceWeek + 1));
    }

    useAsyncEffect(async () => {
      try {
        const data = await request.post("/clinicians/getpatientactivities", { patientid });
        if (!data.result) throw Error(data.message ?? "<missing details>");
        setData(data);

        setPatientActivities(data.data);
        setPatientActivitiesOrg(data.data);
        setPatientActivitiesExtras(data.dataextra);

        setCareplanWeek(Math.max(1, 1 + Math.floor((data.patient.dayno - 1) / 7)));
        if (data.patient.dayno > 7) setComplianceWeek(Math.floor((data.patient.dayno - 1) / 7));
      } catch (err: any) {
        toastError("Failed to load patient care plan.", err);
        console.error(err);
      }
    }, []);

    return (
      <View style={styles.container}>
        {data && (
          <MainFramePatient
            userStore={props.userStore}
            selected={5}
            patient={data?.patient}
            clinician={data?.clinician}
            patientlist
            style={{ alignItems: "flex-start", flexDirection: "row", width: "100%" }}>
            <View style={[styles.whitebox, { paddingBottom: 7, flexDirection: "row", alignItems: "flex-start" }]}>
              <View>
                <View style={{ flexDirection: "row", marginTop: 23, marginLeft: 24, alignItems: "baseline" }}>
                  <Text style={[styles.title3, {}]}>Schedule</Text>
                  <CarePlanControls
                    editmode={editmode}
                    isLoading={isLoading}
                    userStore={props.userStore}
                    clinician={data.clinician}
                    fromLinkMode={fromLinkMode}
                    setEditMode={setEditmode}
                    setFromLinkMode={setFromLinkMode}
                    onEdit={() => {
                      setEditmode(true);
                    }}
                    onSave={submit}
                    onCancelEdit={() => {
                      setPatientActivities(patientActivitiesOriginal);
                      setEditmode(false);
                    }}
                  />
                </View>
                <CarePlanWeekSelector
                  withLabel
                  week={careplanWeek}
                  onPrev={onPrevCareplanWeek}
                  onNext={onNextCareplanWeek}
                  style={{ top: 25, right: 30 }}
                />
                <View>
                  <CarePlan
                    editmode={editmode}
                    operationtype={data.patient.operationtype}
                    patientActivities={(fromLinkMode ? patientActivitiesExtras : patientActivities) ?? []}
                    today={data.patient.dayno}
                    fromDay={dayOffset + (careplanWeek - 1) * 7}
                    toDay={dayOffset + (careplanWeek + 1) * 7 + 1}
                    onPressPoint={onPressActivity}
                    onTogglePoint={onToggleActivity}
                    clinicalTeamId={data.patient.clinicalteam_id}
                    style={{ left: -1, marginTop: 24, overflow: "hidden", width: 862 }}
                  />
                </View>
              </View>
              <View
                style={{ borderColor: "#E8E9ED", borderTopWidth: 1, marginTop: 69, paddingLeft: 19, paddingTop: 19 }}>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Text style={styles.title4}>Overall compliance rate</Text>
                  <Text style={styles.title5}>{Math.round(data.patient.compliance)}%</Text>
                </View>
                <OverallComplianceClientChart
                  week={complianceWeek}
                  data={data.dash.filter(
                    (item: any) => item.x > complianceWeek * 7 && item.x < (complianceWeek + 4) * 7,
                  )}
                />
                <CarePlanWeekSelector
                  week={complianceWeek}
                  onPrev={onPrevComplianceWeek}
                  onNext={onNextComplianceWeek}
                  style={{ top: 245, right: 30 }}
                />
              </View>
            </View>
          </MainFramePatient>
        )}
      </View>
    );
  }),
);
export default TempScreen;

interface CarePlanControlsProps {
  userStore: any;
  clinician: any;
  editmode: boolean;
  isLoading: boolean;
  fromLinkMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  setFromLinkMode: Dispatch<SetStateAction<boolean>>;
  onEdit: () => void;
  onSave: () => void;
  onCancelEdit: () => void;
}
function CarePlanControls({
  userStore,
  clinician,
  editmode,
  isLoading,
  fromLinkMode,
  setFromLinkMode,
  onEdit,
  onSave,
  onCancelEdit,
}: CarePlanControlsProps) {
  if (editmode) {
    return (
      <>
        <TouchableOpacity disabled={isLoading} onPress={onCancelEdit}>
          <Text style={[styles.title5, { textDecorationLine: "underline" }]}>cancel</Text>
        </TouchableOpacity>
        <TouchableOpacity disabled={isLoading} onPress={onSave}>
          <Text style={[styles.title5, { textDecorationLine: "underline" }]}>save</Text>
        </TouchableOpacity>
      </>
    );
  } else {
    const linkModeLabel = fromLinkMode ? "show activity data" : "show link data";

    return (
      <>
        {userStore?.readonly == 0 && clinician?.cliniciantype?.canchangecareplan && (
          <TouchableOpacity onPress={onEdit}>
            <Text style={[styles.title5, { textDecorationLine: "underline" }]}>edit</Text>
          </TouchableOpacity>
        )}
        <TouchableOpacity
          disabled={isLoading}
          onPress={() => {
            setFromLinkMode((curr) => !curr);
          }}>
          <Text style={[styles.title5, { textDecorationLine: "underline" }]}>{linkModeLabel}</Text>
        </TouchableOpacity>
      </>
    );
  }
}

const styles = StyleSheet.create({
  whitebox: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    shadowColor: "#818181",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
  title1: {
    fontFamily: "Lato",
    fontSize: 13,
    color: "#1F2E99",
  },
  title2: {
    fontFamily: "Lato",
    fontSize: 15,
    color: "#1F2E99",
  },
  title3: {
    fontFamily: "LatoBold",
    fontSize: 18,
    color: "#1F2E99",
  },
  title4: {
    fontFamily: "Lato",
    fontSize: 13,
    color: "#868686",
  },
  title5: {
    fontFamily: "LatoBold",
    fontSize: 15,
    color: "#1F2E99",
    marginLeft: 12,
  },

  container: {
    flex: 1,
  },
});
