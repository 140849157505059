import React, { useState, useEffect } from "react";
import { Text, View, StyleSheet, Image, TouchableOpacity } from "react-native";

import { BlueButton } from "./BlueButton";

import { Avatar } from "../components/Avatar";

import Menu, { MenuOptions, MenuOption, MenuTrigger, renderers } from "react-native-popup-menu";

// export function OrangeButton(props) {
export const HospitalAdminHeader = (props) => {
  const { ContextMenu, SlideInMenu, Popover } = renderers;

  async function deleteHospitaladmin() {
    fetch(global.apiurl + "/postopadmins/deletehospitaladmin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
      body: JSON.stringify({
        hospitaladminid: props.hospitaladmin.id,
      }),
    })
      .then(async (response) => await response.json())
      .then((resData) => {
        console.log(resData);
        if (resData.result) {
          location.href = "/postopadmin/hospitaladmins";
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <View style={{ height: 106, alignSelf: "stretch" }}>
      <View style={{ flexDirection: "row", marginTop: 22, width: 1131 }}>
        <View style={{ flexDirection: "row", width: 312, alignItems: "center" }}>
          <Avatar
            url={props.hospitaladmin?.thumburl && global.imagepathurl + encodeURI(props.hospitaladmin?.thumburl)}
            size={56}
            radius={28}
            firstname={props?.hospitaladmin?.firstname}
            lastname={props?.hospitaladmin?.lastname}
          />
          <View style={{ marginLeft: 16 }}>
            <Text style={styles.title3}>
              {props?.hospitaladmin?.firstname} {props?.hospitaladmin?.lastname}
            </Text>
          </View>
        </View>

        <View style={{ marginTop: 8, marginLeft: 10, width: 268 }}>
          {" "}
          <Text style={styles.title4}>Email address</Text>
          <Text style={styles.title5}>{props?.hospitaladmin?.email}</Text>
        </View>

        <View style={{ marginTop: 8, marginLeft: 10, width: 161 }}>
          {" "}
          <Text style={styles.title4}>Phone number</Text>
          <Text style={styles.title5}>
            +{props?.hospitaladmin?.gsmcountry} {props?.hospitaladmin?.gsm}
          </Text>
        </View>

        <Menu
          style={{ position: "absolute", right: 0, top: 10 }}
          renderer={Popover}
          rendererProps={{ placement: "bottom" }}>
          <MenuTrigger style={{}}>
            <Image source={require("../assets/images/morebutton.png")} style={{ width: 42, height: 42 }} />
          </MenuTrigger>
          <MenuOptions customStyles={{ borderRadius: 8, padding: 15 }}>
            <MenuOption
              text={"Delete Hospital Admin"}
              onSelect={() => {
                if (confirm("Hospital Admin will be permenantly DELETED. Are you sure?")) {
                  deleteHospitaladmin();
                }
              }}
            />
          </MenuOptions>
        </Menu>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  title3: {
    fontFamily: "LatoBold",
    fontSize: 18,
    color: "#1F2E99",
  },
  title4: {
    fontFamily: "Lato",
    fontSize: 13,
    color: "#868686",
  },

  title5: {
    fontFamily: "Lato",
    fontSize: 15,
    color: "#1F2E99",
    marginTop: 12,
  },
});
