import type React from "react";
import { withStore } from "../misc/utils";

type EnsureAuthProps = {
  children: React.ReactNode;
};

const EnsureAuth = withStore<EnsureAuthProps>(({ userStore, children }) => {
  if (!userStore.isAuthCalled) return null;
  return children;
});

export default EnsureAuth;
