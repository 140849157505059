import React from "react";

import { View, StyleSheet } from "react-native";

import UpdateClinician from "../components/UpdateClinician";
import { MainFrame } from "../components/MainFrame";
import UpdateHospitalAdmin from "../components/UpdateHospitalAdmin";
import UpdatePostopAdmin from "../components/UpdatePostopAdmin";
import { withStore } from "../misc/utils";
import PostopAdminLayout from "../components/layouts/PostopAdminLayout";

export const ClinicianUpdateProfileScreen = withStore(({ userStore }) => {
  return (
    <View style={styles.container}>
      <MainFrame userStore={userStore}>
        <UpdateClinician userstore={userStore} />
      </MainFrame>
    </View>
  );
});

export const HospitalAdminUpdateProfileScreen = withStore(({ userStore }) => {
  return (
    <View style={styles.container}>
      <MainFrame admin userStore={userStore}>
        <UpdateHospitalAdmin userstore={userStore} />
      </MainFrame>
    </View>
  );
});

export const PostopAdminUpdateProfileScreen = withStore(({ userStore }) => {
  return (
    <View style={styles.container}>
      <PostopAdminLayout>
        <UpdatePostopAdmin userstore={userStore} />
      </PostopAdminLayout>
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
  },
});
