import { toastError } from "../components/Notifications";
import userStore from "../mobx/store";
import * as requests from "./requests";

export type ImpersonateUser =
  | {
      clinician_id: number;
    }
  | {
      hospitaladmin_id: number;
    };

export async function removeClinicianFromTeam(clinicianId: number, clinicalTeamId: number) {
  const confirmed = confirm("Are you sure you want to de-assign this clinician?");
  if (!confirmed) return;
  try {
    await requests.post("/hospitaladmins/removeclinicianfromteam", {
      clinicianid: clinicianId,
      clinicalteamid: clinicalTeamId,
    });
  } catch (err) {
    toastError("Failed to remove the clinician from the team.", err);
    console.error(err);
  }
}

export async function disableHospitalAdmin(adminId: number) {
  const confirmed = confirm("Are you sure you want to de-assign this hospital admin?");
  if (!confirmed) return;
  try {
    await requests.post("/postopadmins/deletehospitaladmin", {
      hospitaladminid: adminId,
    });
  } catch (err) {
    toastError("Failed to disable the hospital admin.", err);
    console.error(err);
  }
}

export const getRecoveryWeek = (recoveryDay: number) => 1 + Math.floor((recoveryDay - 1) / 7);

export async function impersonate(user: ImpersonateUser) {
  try {
    const res = await requests.post("/postopadmins/impersonate", user);
    global.token = res.token;
    // @ts-expect-error globals are bad
    global.tokenParam = `Bearer ${global.token}`;
    await userStore.setFullLoggedinIsAdmin(
      res.token,
      res.photourl || "null",
      res.firstname,
      res.lastname,
      "hospitaladmin_id" in user ? 2 : 1,
      res.readonly,
    );
    return true;
  } catch (err) {
    console.error("Failed to impersonate user:", user, err);
    return false;
  }
}
