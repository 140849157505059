import { useEffect, useReducer } from "react";

// as long as we don't have direct control over the location object, our safest bet is to poll it
const listeners = new Set<() => void>();
let loc = global.window?.location?.pathname;

if (global.window?.location) {
  setInterval(() => {
    if (loc !== location.pathname) {
      loc = location.pathname;
      listeners.forEach((listener) => {
        listener();
      });
    }
  }, 100);
}

export function useLocation() {
  const [, refresh] = useReducer((x) => x + 1, 0);
  useEffect(() => {
    listeners.add(refresh);
    return () => {
      listeners.delete(refresh);
    };
  }, []);
  return loc;
}
