import * as React from "react";
import { Text, View, StyleSheet } from "react-native";
import { Avatar } from "../components/Avatar";

export const Comment = (props) => {
  return (
    <View
      style={{
        flexDirection: "row",
        width: "100%",
        paddingBottom: 32,
        backgroundColor: "#ffffff",
        alignItems: "top",
        borderRadius: 6,
      }}>
      <Avatar
        style={{ marginRight: 17, marginLeft: 19, marginTop: 13 }}
        size={32}
        radius={16}
        url={props?.photourl ? global.imagepathurl + encodeURI(props?.photourl) : null}
        firstname={props.firstname}
        lastname={props.lastname}
      />

      <View>
        <View style={{ flexDirection: "row", alignItems: "center", marginTop: 8 }}>
          <Text style={[styles.title4]}>
            {props.firstname} {props.lastname}
          </Text>
          <Text style={[styles.title5]}>{props.date}</Text>
        </View>
        <View style={{ flexDirection: "row", alignItems: "center", marginTop: 8 }}>
          <Text style={styles.title6}>{props.msg}</Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  title4: {
    color: "#000000",
    fontFamily: "LatoBold",
    fontSize: 13,
  },
  title5: {
    color: "#646464",
    fontFamily: "Lato",
    fontSize: 13,
    marginLeft: 11,
  },
  title6: {
    color: "#1F2E99",
    fontFamily: "Lato",
    fontSize: 16,
    width: 394,
  },
});
