import "react-notifications-component/dist/theme.css";
import "./styles/app.css";
import "./misc/constants";

import { ConfigCatProvider } from "configcat-react";
import Constants from "expo-constants";
import { Provider } from "mobx-react";
import React from "react";
import { MenuProvider } from "react-native-popup-menu";
import { ReactNotifications } from "react-notifications-component";
import * as Sentry from "sentry-expo";

import { ModalTarget } from "./components/Modals";
import userStore from "./mobx/store";
import Navigation from "./navigation/";
import EnsureAuth from "./components/EnsureAuth";

Sentry.init({
  environment: Constants.expoConfig!.extra!.environment,
  dsn: Constants.expoConfig!.extra!.sentryDSN,
  enableInExpoDevelopment: true,
  debug: Constants.expoConfig!.extra!.environment !== "PROD",
});

export default function App(): JSX.Element | null {
  return (
    <Provider userStore={userStore}>
      <EnsureAuth>
        <ConfigCatProvider sdkKey={Constants.expoConfig!.extra!.configCatSdkKey}>
          <MenuProvider>
            <Navigation />
            <ModalTarget />
            <ReactNotifications />
          </MenuProvider>
        </ConfigCatProvider>
      </EnsureAuth>
    </Provider>
  );
}
