import React, { useState, useEffect } from "react";
import { Text, View, StyleSheet, Dimensions, Platform } from "react-native";
// import { WebView } from 'react-native-webview';
import type { IMessage } from "react-native-gifted-chat";
import { GiftedChat, Composer, Bubble, Message, Day, SystemMessage, Send } from "react-native-gifted-chat";
import Moment from "moment";
import { Avatar } from "./Avatar";
import { result } from "validate.js";
const { width } = Dimensions.get("window");

// TODO: add firebase?

function Messages(props) {
  const textInputRef = React.useRef();
  const [messages, setMessages] = React.useState([]);
  const [userid, setUserid] = React.useState(0);

  const [sendingData, setSendingData] = useState(false);
  const [operationNotDone, setOperationNotDone] = React.useState(false);

  async function sendMessage(msg: string) {
    if (msg.length < 1) return;
    if (sendingData) return;
    setSendingData(true);
    console.log("sendMessage");

    fetch(global.apiurl + "/clinicians/sendpatientmessage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
      body: JSON.stringify({
        patientid: props.patientid,
        message: msg,
      }),
    })
      .then(async (response) => await response.json())
      .then((resData) => {
        setSendingData(false);
        fetchData();
        console.log(resData);
        if (resData.result) {
          //    fetchData();
          //   setCommenttext("");
          //   setTimeout(() =>textInputRef.current.focus(), 200);
        } else {
        }
      })
      .catch((err) => {
        setSendingData(false);
        console.log(err);
      });
  }
  useEffect(() => {
    ///   fetchData();
  }, []);

  async function fetchData() {
    fetch(global.apiurl + "/clinicians/getpatientmessages", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
      body: JSON.stringify({
        patientid: props.patientid,
      }),
    })
      .then(async (response) => await response.json())
      .then((resData) => {
        console.log(resData);
        if (resData.result) {
          setUserid(resData.userid);

          const temparr = [];

          resData.messages.forEach((item) => {
            // props.userStore.addmessage(temparr);

            if (!item?.clinician) {
              item.clinician = {
                _id: -10,
                firstname: props.patient.firstname,
                lastname: props.patient.lastname,
                thumburl: props.patient.thumburl,
              };
            } else {
              if (item.clinician?.cliniciantype) {
                item.clinician.firstname = item.clinician?.cliniciantype?.title + " - " + item.clinician?.firstname;
              }
            }
            temparr.push({
              _id: item.id,
              text: item.message,
              createdAt: item.createdAt,
              user: {
                _id: item.clinician.id,
                name: item.clinician.firstname + " " + item.clinician.lastname,
                avatar: item.clinician.thumburl ? global.imagepathurl + item.clinician.thumburl : null,
              },
            });
          });
          if (temparr.length > 0) {
            props.setlastreadmessage(temparr[0]._id);
          }

          props.userStore.setmessages(temparr);
          // props.userStore.setmessages(temparr);
          console.log(props.userStore.message);
          // setMessages([...messages, ...temparr]);
        } else {
          if (resData.message === "chat start after the operation") {
            setOperationNotDone(true);
          }
          console.log("error getting messages");

          console.log(resData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    fetchData();
    // setTimeout(() =>textInputRef.current.focus(), 1200);
  }, []);

  /**
   *
   *
   *     {
      _id: 2,
      text: 'Eating the correct diet is important for your recovery.',
      createdAt: new Date(),
      user: {
        _id: 2,
        name: 'Doctor - Alper Brown',
        avatar: 'https://placeimg.com/140/140/any',
      },
    },
        {
      _id: 1,
      text: 'Hello',
      createdAt: new Date(),
      user: {
        _id: 1,
        name: 'Doctor - Alper Brown',

      },
    },{
      _id: 13,
      text: 'New message',
      createdAt: new Date(),
      system: true,
      // Any additional custom parameters are passed through
    },

    {
      _id: 3,
      text: 'Hello',
      createdAt: new Date(),
      user: {
        _id: 2,
        name: 'Doctor - John Cale',
        avatar: 'https://placeimg.com/140/140/any',
      },
    },
        {
      _id: 4,
      text: 'Caner',
      createdAt: new Date(),
      user: {
        _id: 1,
        name: 'Doctor - Alper Brown',

        avatar: 'https://placeimg.com/140/140/any',
      },
    },
   *
   */

  // const [messages, setMessages] = React.useState<IMessage[]>(new Array());
  const onSend = (newMessages: IMessage[] = []) => {
    sendMessage(newMessages[0].text);

    // setMessages(GiftedChat.append(messages, newMessages[0]));
  };

  const customDay = (props) => {
    return (
      <Day
        {...props}
        containerStyle={{ backgroundColor: "#F8F9FF", height: 42 }}
        textStyle={{ fontFamily: "Lato", fontSize: 16, lineHeight: 18, backgroundColor: "#F8F9FF", color: "#1F2E99" }}
      />
    );
  };

  const ChatComposer = (props) => {
    return (
      <Composer
        {...props}
        textInputProps={{
          ...props.textInputProps,
          // for enabling the Return key to send a message only on web
          blurOnSubmit: Platform.OS === "web",
          onSubmitEditing:
            Platform.OS === "web"
              ? () => {
                  if (props.text && props.onSend) {
                    props.onSend({ text: props.text.trim() }, true);
                  }
                }
              : undefined,
        }}
      />
    );
  };

  const customSend = (props) => {
    return (
      <Send {...props}>
        <View style={{ marginRight: 10, marginBottom: 15 }}>
          <Text style={{ fontFamily: "LatoBold", fontSize: 16 }}>Send</Text>
        </View>
      </Send>
    );
  };

  const customSystemMessage = (props) => {
    return (
      <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
        <View style={{ backgroundColor: "#FE7235", height: StyleSheet.hairlineWidth, flex: 1 }} />
        <Text
          style={{
            backgroundColor: "#FE7235",
            color: "#ffffff",
            paddingLeft: 7,
            paddingRight: 7,
            fontSize: 11,
            fontFamily: "Lato",
            paddingBottom: 3,
            paddingTop: 2,
            marginRight: 14,
            borderRadius: 8,
          }}>
          New
        </Text>
      </View>
    );
  };

  const NewMessageLine = (props) => {
    <View style={{ flex: 1 }}>
      <View style={{ backgroundColor: "#FE7235", height: StyleSheet.hairlineWidth }} />
      <Text style={{ color: "#FE7235" }}>New message</Text>
      <View style={{ backgroundColor: "#FE7235", height: StyleSheet.hairlineWidth }} />
    </View>;
  };
  const customMessage = (props) => {
    return (
      <Message
        {...props}
        containerStyle={{
          left: {
            flexDirection: "row",
            marginTop: 24,
            marginLeft: 34,
          },
          right: {
            marginTop: 24,
            marginRight: 34,
            flexDirection: "row-reverse",
            justifyContent: "flex-start",
          },
        }}
      />
    );
  };

  const customBubble = (props: any) => {
    const sameUserInPrevMessage = false;

    /**
    containerToPreviousStyle={{
              right: { borderTopRightRadius: 15 },
              left: { borderTopLeftRadius: 15 },
            }}
            containerToNextStyle={{
              right: { borderTopRightRadius: 15 },
              left: { borderTopLeftRadius: 15 },
            }}

    **/

    return (
      <View>
        {!sameUserInPrevMessage && (
          <View>
            <View
              style={[
                { flexDirection: "row", alignSelf: "flex-start", alignItems: "center", marginBottom: 12 },
                props.currentMessage.user._id == 1 && { justifyContent: "flex-end" },
              ]}>
              {true && (
                <Avatar
                  url={props.currentMessage.user.avatar}
                  style={{ backgroundColor: "#E8FFDB" }}
                  fontcolor={"#888888"}
                  size={32}
                  radius={16}
                  firstname={props.currentMessage.user.name}
                  lastname={props.currentMessage.user.name}
                />
              )}
              <Text style={{ color: "#000000", marginLeft: 20, marginRight: 10, fontFamily: "LatoBold", fontSize: 13 }}>
                {" "}
                {props.currentMessage.user.name}{" "}
              </Text>

              <Text
                style={[
                  props.currentMessage.user._id == 1 && {
                    alignSelf: "flex-end",
                  },
                  { color: "#646464", fontFamily: "Lato", fontSize: 13 },
                ]}>
                {Moment(props.currentMessage.createdAt).format("LT")}
              </Text>
            </View>
            <Bubble
              {...props}
              wrapperStyle={{
                right: {
                  // Here is the color change
                  backgroundColor: "#FCF5EF",
                  maxWidth: 400,
                  padding: 4,
                  borderRadius: 14,
                },
                left: {
                  // Here is the color change
                  backgroundColor: "#0077FF",
                  maxWidth: 400,
                  borderRadius: 14,
                },
              }}
              textStyle={{
                right: {
                  color: "#000000",
                  fontSize: 15,
                  fontFamily: "Lato",
                },
                left: {
                  color: "#ffffff",
                  fontSize: 15,
                  fontFamily: "Lato",
                },
              }}
            />
          </View>
        )}
      </View>
    );
  };
  const renderMessageVideo = (props: any) => {
    const { currentMessage } = props;
    if (currentMessage.video.includes("vimeo")) {
      if (Platform.OS === "web") {
        return null;
      }
      // return (
      //   <View style={styles.video}>
      //     <WebView
      //       style={{ borderRadius: 13 }}
      //       source={{
      //         uri: `https://player.vimeo.com/video/${getVimeoId(
      //           currentMessage!.video!
      //         )}`,
      //       }}
      //     />
      //   </View>
      // );
    }
    return <View>hello vimeo </View>;
  };

  return (
    <View style={{ flex: 1 }}>
      {!operationNotDone && (
        <GiftedChat
          disableComposer={props.userStore?.readonly != 0}
          placeholder={"Message Patient"}
          renderComposer={ChatComposer}
          renderTime={(props) => {
            return <View />;
          }}
          showAvatarForEveryMessage={true}
          renderUsernameOnMessage={false}
          renderAvatar={(props) => {
            return <View></View>;
          }}
          renderDay={customDay}
          renderBubble={customBubble}
          showUserAvatar={true}
          inverted={true}
          renderMessage={customMessage}
          renderAvatarOnTop={true}
          renderSystemMessage={customSystemMessage}
          messages={props.messages}
          onSend={onSend}
          user={{
            _id: userid,
          }}
        />
      )}
      <Text></Text>{" "}
      {operationNotDone && (
        <View>
          <Text style={styles.title1}> Messaging will be activated after the operation. </Text>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  video: {
    width: width / 1.5,
    height: 10,
    margin: 13,
    borderRadius: 13,
  },
  title1: {
    fontFamily: "LatoBold",
    fontSize: 16,
    color: "#FE7235",

    position: "absolute",
    right: 32,
    top: 36,
  },
});

export default Messages;
