import { theme } from "../../misc/constants";
import type { CommonIconProps } from "./icon-types";

export interface ZoomIconProps extends CommonIconProps {
  variant: "in" | "out";
}

export default function ZoomIcon({ variant, ...props }: ZoomIconProps) {
  if (variant === "in") {
    return <ZoomInIcon {...props} />;
  } else if (variant === "out") {
    return <ZoomOutIcon {...props} />;
  } else {
    console.error("Invalid variant for ZoomIcon");
    return null;
  }
}

function ZoomInIcon({ color = theme.primary, size = 21, ...props }: CommonIconProps) {
  return (
    <svg {...props} width={size} height={size} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.24446 17.497C13.6627 17.497 17.2445 13.9153 17.2445 9.49706C17.2445 5.07879 13.6627 1.49707 9.24446 1.49707C4.82619 1.49707 1.24448 5.07879 1.24448 9.49706C1.24448 13.9153 4.82619 17.497 9.24446 17.497Z"
        stroke={color}
        strokeWidth="1.6875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2458 19.4945L14.8958 15.1445"
        stroke={color}
        strokeWidth="1.6875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.24911 6.49609V12.4961"
        stroke={color}
        strokeWidth="1.6875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.25607 9.49316H12.2561"
        stroke={color}
        strokeWidth="1.6875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function ZoomOutIcon({ color = theme.primary, size = 21, ...props }: CommonIconProps) {
  return (
    <svg {...props} width={size} height={size} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.48238 17.497C13.9007 17.497 17.4824 13.9153 17.4824 9.49706C17.4824 5.07879 13.9007 1.49707 9.48238 1.49707C5.06411 1.49707 1.48239 5.07879 1.48239 9.49706C1.48239 13.9153 5.06411 17.497 9.48238 17.497Z"
        stroke={color}
        strokeWidth="1.6875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.4838 19.4945L15.1338 15.1445"
        stroke={color}
        strokeWidth="1.6875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.49399 9.49316H12.494"
        stroke={color}
        strokeWidth="1.6875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
