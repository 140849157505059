import React from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";

const RadioButton = (props) => {
  const { item, selected, onSelected } = props;

  return (
    <TouchableOpacity style={[styles.radioButton, props.style]} onPress={(x) => onSelected(x)}>
      <View style={styles.button}>{props.selected && <View style={styles.selectedButton} />}</View>
      <Text style={styles.title}>{props.title}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  title: {
    color: "#1F2E99",
    fontFamily: "Lato",
    fontSize: 15,
    marginLeft: 8,
  },
  radioButton: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginRight: 24,
    backgroundColor: "transparent",
  },
  button: {
    height: 18,
    width: 18,
    borderRadius: 18,
    borderWidth: 2,
    borderColor: "#DFE1E6",
    alignItems: "center",
    justifyContent: "center",
  },
  selectedButton: {
    width: 12,
    height: 12,
    borderRadius: 12,
    backgroundColor: "#0009EC",
  },
});

export default RadioButton;
