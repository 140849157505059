// TODO: this is the only use of expo-av. once the ff `showWoundVizPro` is flipped on
import { Video } from "expo-av";
import Moment from "moment";
import type { CSSProperties } from "react";
import React, { useCallback, useEffect, useState } from "react";
import { FlatList, Image, StyleSheet, Text, TextInput, TouchableOpacity, View } from "react-native";

import type { IPatientMedia } from "../interfaces/patientInterfaces";
import { getImageUrl, getMediaTypeLabel, MediaType, mediaTypeLabels } from "../misc/constants";
import { getRecoveryWeek } from "../misc/user-utils";
import { withStore } from "../misc/utils";
import Clickable from "./Clickable";
import { Comment } from "./Comment";
import SpeechBubbleIcon from "./icons/SpeechBubbleIcon";
import { POText } from "./POText";
import { Modal } from "./Modals";
import { css } from "@emotion/css";

export interface MediaDetailsModalProps {
  show: boolean;
  patientMediaId: number;
  onDismiss: () => void;
}

// TODO: modal is obsolete with ff `showWoundVizPro` & can be removed once permanently on
export const MediaDetailsModal = withStore<MediaDetailsModalProps>(
  ({
    show,
    patientMediaId,
    userStore, // injected
    onDismiss,
  }) => {
    let flatList;
    const textInputRef = React.useRef();

    const [isPinned, setIsPinned] = useState(null);
    const [data, setData] = React.useState(null);
    const [sendingData, setSendingData] = useState(false);

    const [commenttext, setCommenttext] = React.useState("");

    async function pinpatientmedia(ispinned) {
      fetch(global.apiurl + "/clinicians/pinpatientmedia", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: global.tokenparam || "",
        },
        body: JSON.stringify({
          patientmediaid: data.id,
          ispinned,
        }),
      })
        .then(async (response) => await response.json())
        .then((resData) => {
          console.log(resData);
          if (resData.result) {
            setIsPinned(ispinned);
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    async function fetchData() {
      fetch(global.apiurl + "/clinicians/getpatientmediadetails", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: global.tokenparam || "",
        },
        body: JSON.stringify({
          patientmediaid: patientMediaId,
        }),
      })
        .then(async (response) => await response.json())
        .then((resData) => {
          console.log(resData);
          if (resData.result) {
            setData(resData.patientmedia);
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    async function sendComment() {
      if (commenttext.length < 3) return;
      if (sendingData) return;
      setSendingData(true);
      console.log("sendComment");

      fetch(global.apiurl + "/clinicians/addpatientmediacomment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: global.tokenparam || "",
        },
        body: JSON.stringify({
          patientmediaid: data?.id,
          message: commenttext,
        }),
      })
        .then(async (response) => await response.json())
        .then((resData) => {
          setSendingData(false);

          console.log(resData);
          if (resData.result) {
            fetchData();
            setCommenttext("");
            if (userStore.readonly == 0) {
              setTimeout(() => textInputRef.current.focus(), 200);
            }
          } else {
          }
        })
        .catch((err) => {
          setSendingData(false);
          console.log(err);
        });
    }

    useEffect(() => {
      fetchData();
    }, [patientMediaId]);

    return (
      <Modal show={show} onDismiss={onDismiss}>
        <View style={{ borderRadius: 8, backgroundColor: "#ffffff", width: 1133, height: 720, alignSelf: "center" }}>
          <View
            style={{
              flexDirection: "row",
              width: "100%",
              borderBottomColor: "#E8E9ED",
              borderBottomWidth: 1,
              height: 70,
              alignItems: "center",
            }}>
            <TouchableOpacity
              onPress={() => {
                onDismiss?.();
              }}>
              <View
                style={{
                  backgroundColor: "#FCF5EF",
                  width: 93,
                  height: 70,
                  flexDirection: "row",
                  borderRightColor: "#E0E3F7",
                  borderRightWidth: 1,
                  borderTopLeftRadius: 8,
                  alignItems: "center",
                }}>
                <Image
                  source={require("../assets/images/closecrossicon.png")}
                  style={{ width: 10, height: 10, marginRight: 8, marginLeft: 23 }}
                />
                <Text style={{ fontFamily: "Lato", fontSize: 13, color: "#1F2E99" }}>Close</Text>
              </View>
            </TouchableOpacity>

            <Text style={styles.title3}>
              Day {data?.dayno} / Week {getRecoveryWeek(data?.dayno)} / {getMediaTypeLabel(data?.phototypeid)}
            </Text>

            {((data?.ispinned && isPinned === null) || isPinned == 1) && (
              <View style={{ positon: "absolute", right: 25.44 }}>
                <Clickable
                  disabled={userStore.readonly}
                  onClick={async () => {
                    await pinpatientmedia(0);
                  }}
                  style={{ marginLeft: 11 }}>
                  <Image
                    source={require("../assets/images/pinselectedicon.png")}
                    style={{ width: 15.56, height: 20 }}
                  />
                </Clickable>
              </View>
            )}

            {((data?.ispinned && isPinned === null) || isPinned == 0) && (
              <View style={{ positon: "absolute", right: 25.44 }}>
                <Clickable
                  disabled={userStore.readonly}
                  onClick={async () => {
                    await pinpatientmedia(1);
                  }}
                  style={{ marginLeft: 11 }}>
                  <Image source={require("../assets/images/pinicon.png")} style={{ width: 15.56, height: 20 }} />
                </Clickable>
              </View>
            )}
          </View>

          <View style={{ flexDirection: "row" }}>
            {!data?.video_url && (
              <Image
                source={{ uri: global.imagepathurl + encodeURI(data?.photourl) }}
                style={{ width: 650, height: 650, borderBottomLeftRadius: 8 }}
              />
            )}

            {data?.video_url && (
              <Video
                style={{
                  width: 650,
                  height: 650,
                  borderBottomLeftRadius: 8,
                  alignSelf: "center",
                  alignContent: "center",
                }}
                source={{
                  uri: data?.video_url,
                }}
                useNativeControls
                resizeMode="cover"
              />
            )}

            <View style={{ width: 483 }}>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  paddingLeft: 24,
                  borderBottomColor: "#E8E9ED",
                  borderBottomWidth: 1,
                  height: 68,
                }}>
                <Image source={require("../assets/images/commenticon.png")} style={{ width: 20, height: 20 }} />
                <POText style={{ marginLeft: 20 }}>Comments ({data?.patientmediacomments.length})</POText>
              </View>

              {data?.patientmediacomments && (
                <FlatList
                  scrollEnabled={false}
                  ref={(ref) => (flatList = ref)}
                  initialNumToRender={1}
                  onLayout={() => flatList.scrollToEnd({ animated: false })}
                  onContentSizeChange={() => flatList.scrollToEnd({ animated: false })}
                  style={{
                    width: "100%",
                    height: 514,
                    overflow: "scroll",
                    borderBottomColor: "#E8E9ED",
                    borderBottomWidth: 1,
                    marginBottom: 68,
                  }}
                  data={data?.patientmediacomments}
                  renderItem={({ item, index, separators }) => (
                    <Comment
                      photourl={item?.clinician?.photourl}
                      firstname={item?.clinician?.firstname}
                      lastname={item?.clinician?.lastname}
                      date={item?.createdAt}
                      msg={item.message}
                    />
                  )}
                />
              )}

              {userStore.readonly != 1 && (
                <View
                  style={{
                    position: "absolute",
                    right: 0,
                    bottom: 0,
                    height: 68,
                    alignItems: "center",
                    backgroundColor: "#ffffff",
                    width: "100%",
                    borderBottomRightRadius: 8,
                  }}>
                  <TextInput
                    editable={!sendingData}
                    ref={textInputRef}
                    onSubmitEditing={sendComment}
                    style={[
                      {
                        fontFamily: "Lato",
                        fontSize: 15,
                        color: "#959DAD",
                        textAlign: "left",
                        position: "absolute",
                        left: 24,
                        marginRight: 24,
                        width: 392,
                        marginTop: 25,
                      },
                    ]}
                    keyboardType="email-address"
                    maxLength={50}
                    placeholder="Add comments"
                    placeholderTextColor={"#959DAD"}
                    numberOfLines={1}
                    onChangeText={(text) => {
                      setCommenttext(text.trim());
                    }}
                    value={commenttext}
                  />

                  <TouchableOpacity style={{ position: "absolute", right: 13, top: 13 }} onPress={sendComment}>
                    <Image
                      source={require("../assets/images/sendcommentbutton.png")}
                      style={{ width: 42, height: 42 }}
                    />
                  </TouchableOpacity>
                </View>
              )}
            </View>
          </View>
        </View>
      </Modal>
    );
  },
);

export interface PatientMediaHeaderProps {
  children?: React.ReactNode;
  style?: CSSProperties;
}

export function PatientMediaHeader({ children, style }: PatientMediaHeaderProps) {
  return (
    <div className="POPatientMediaHeader-root" style={style}>
      <POText style={{ marginLeft: 20 }}>{children}</POText>
    </div>
  );
}

export interface PatientMediaProps {
  patient?: any;
  item?: IPatientMedia;
  onClick?: (item: IPatientMedia) => void;
}

export function PatientMedia({ patient, item = {}, onClick }: PatientMediaProps) {
  const openMediaDetails = useCallback(() => {
    if (!item.id) return;
    onClick?.(item);
  }, [item.id]);

  const thumbnail = item.video_url
    ? // videos live in the cloud
      encodeURI(item.video_url.replace(/\.[a-z0-9]+$/, ".jpg"))
    : // whereas images live on our servers
      getImageUrl(encodeURI(item?.thumburl || item?.photourl));

  return (
    <div className="POPatientMedia-root">
      <View style={[styles.whitebox, { width: 268, height: 316, marginLeft: 20, marginTop: 20 }]}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 16,
            marginLeft: 14,
            marginRight: 15,
            alignItems: "center",
          }}>
          <Text style={styles.title2}>
            Day {item.dayno} / {getMediaTypeLabel(item.phototypeid)}{" "}
            <Text style={styles.title4}>{Moment(item.created_at).format("DD.MM.YY hh:mm")}</Text>
          </Text>

          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <SpeechBubbleIcon filled={item.iscommented} size={14} />

            {item.ispinned ? (
              <Image
                source={require("../assets/images/pinselectedicon.png")}
                style={{ width: 10.89, height: 14, marginLeft: 8 }}
              />
            ) : (
              <Image
                source={require("../assets/images/pinicon.png")}
                style={{ width: 10.89, height: 14, marginLeft: 8 }}
              />
            )}
          </View>
        </View>

        <TouchableOpacity onPress={openMediaDetails}>
          {item?.patientactivityextraid && (
            <View style={styles.bluelabel}>
              <POText fontFamily="LatoBold" fontSize={11.5} color="white">
                uploaded via link
              </POText>
            </View>
          )}

          {/* Pseudo-image */}
          <div
            className={css`
              width: 268px;
              height: 268px;
              margin-top: 16px;
              border-radius: 0 0 8px 8px;
              overflow: hidden;
              background-image: url(${thumbnail});
              background-size: cover;
              background-position: center;
            `}
          />
        </TouchableOpacity>
      </View>
    </div>
  );
}

const styles = StyleSheet.create({
  whitebox: {
    borderColor: "#0077FF",
    borderWidth: 4,
    overflow: "hidden",
    backgroundColor: "#ffffff",
    borderRadius: 8,
    shadowColor: "#818181",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
  title2: {
    color: "#000E29",
    fontFamily: "Lato",
    fontSize: 13,
  },
  title3: {
    color: "#000E29",
    fontFamily: "Lato",
    fontSize: 15,
    marginLeft: 21,
  },
  title4: {
    color: "#868686",
    fontFamily: "Lato",
    marginLeft: 2,
    fontSize: 13,
    marginTop: 5,
  },
  bluelabel: {
    width: 122,
    height: 25,
    backgroundColor: "#1F2E99",
    alignSelf: "auto",
    borderRadius: 14,
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 24,
    left: 74,
    zIndex: 10,
  },
});
