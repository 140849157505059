import { css, cx } from "@emotion/css";
import type { CSSProperties } from "react";
import { useMemo } from "react";
import type { Mask } from "../../hooks/useSam";
import { getMaskDataUrl } from "../../hooks/useSam";

export type ImageMaskProps = Omit<DataMaskProps, "mask"> & { mask: string };

export interface DataMaskProps {
  mask: Mask;
  color?: string;
  className?: string;
  style?: CSSProperties;
}

export function ImageMask({ mask, color, className, ...props }: ImageMaskProps) {
  return (
    <div
      {...props}
      className={cx(
        css`
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0.4;
          width: 100%;
          height: 100%;
          mask: url(${mask});
          mask-position: center;
          mask-size: cover;
          background: ${color};
        `,
        className,
      )}
    />
  );
}

export function DataMask({ mask, ...props }: DataMaskProps) {
  const dataurl = useMemo(() => getMaskDataUrl(mask), [mask]);
  return <ImageMask mask={dataurl} {...props} />;
}
