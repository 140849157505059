import React from "react";
import RadioButton from "./RadioButton";

export type Choice = {
  value: number;
  label: string;
  order?: number;
  disabled?: boolean;
};

export interface PORadioGroupProps {
  selected?: number;
  choices: Choice[];
  /**
   * Offset to add to the first element in the radio group. Defaults to 1, so the choices will be
   * 1-indexed rather than 0-indexed.
   */
  offset?: number;
  className?: string;
  style?: React.CSSProperties;
  onValueChange?: (value: number) => void;
}

export default function PORadioGroup({
  choices,
  offset = 1,
  selected = offset,
  className = "",
  style,
  onValueChange,
}: PORadioGroupProps): JSX.Element {
  const _choices = React.useMemo(
    () =>
      choices
        .filter((c) => !c.disabled)
        .map((c) => (c.order ? c : { ...c, order: 9999999 }))
        .sort((a, b) => a.order! - b.order!),
    [choices],
  );
  console.log(choices, _choices);

  return (
    <div className={`PORadioGroup-root ${className}`} style={style}>
      {_choices.map((choice, i) => (
        <RadioButton
          key={i}
          selected={selected === choice.value}
          onSelected={() => {
            onValueChange?.(choice.value);
          }}
          title={choice.label}
        />
      ))}
    </div>
  );
}
