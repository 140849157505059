import * as React from "react";

import SplitFrame from "../../components/SplitFrame";
import RegisterSetPassword from "../../components/user/RegisterSetPassword";

import { inject, observer } from "mobx-react";
import UnifiedSetPassword from "../../components/user/UnifiedSetPassword";
import { useFeatureFlag } from "../../hooks/useFeatureFlag";

export const ClinicianRegisterSetPassword = inject("userStore")(
  observer(({ userStore, route, navigation }) => {
    const useUnifiedLogin = useFeatureFlag("unifiedlogin");

    if (useUnifiedLogin === null) return <></>;
    if (useUnifiedLogin) return <UnifiedSetPassword />;
    return (
      <SplitFrame bgcolor={"#E8F0FF"}>
        <RegisterSetPassword bgcolor={"#E8F0FF"} userStore={userStore} route={route} navigation={navigation} />
      </SplitFrame>
    );
  }),
);

export const HospitalAdminRegisterSetPassword = inject("userStore")(
  observer(({ userStore, route, navigation }) => {
    return (
      <SplitFrame>
        <RegisterSetPassword admin userStore={userStore} route={route} navigation={navigation} />
      </SplitFrame>
    );
  }),
);

export const PostopAdminRegisterSetPassword = inject("userStore")(
  observer(({ userStore, route, navigation }) => {
    return (
      <SplitFrame>
        <RegisterSetPassword postopadmin userStore={userStore} route={route} navigation={navigation} />
      </SplitFrame>
    );
  }),
);
