import { inject, observer } from "mobx-react";
import Modal from "modal-react-native-web";
import React, { useEffect } from "react";
import {
  Animated,
  Dimensions,
  FlatList,
  Image,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import Menu, { MenuOption, MenuOptions, MenuTrigger } from "react-native-popup-menu";

import { A } from "@expo/html-elements";

import { Avatar } from "../../components/Avatar";
import { Hairline } from "../../components/Hairline";
import { InviteClinician } from "../../components/InviteClinician";
import { MainFrameClinician } from "../../components/MainFrameClinician";
import { toastError } from "../../components/Notifications";
import { PatientFilter } from "../../components/PatientFilter";
import { removeClinicianFromTeam } from "../../misc/user-utils";
// TODO: page seems to be deprecated
const TempScreen = inject("userStore")(
  observer(({ userStore, props, route }) => {
    const { clinicianid } = route.params;

    const [searchtext, setSearchtext] = React.useState("");
    const [filterHip, setFilterHip] = React.useState(false);
    const [filterKnee, setFilterKnee] = React.useState(false);

    const [invitemodalvisible, setInvitemodalvisible] = React.useState(false);
    const dimensions = Dimensions.get("window");
    const [data, setData] = React.useState<any[] | null>(null);
    const [clinician, setClinician] = React.useState(null);

    const [tempData, setTempData] = React.useState<any[] | null>(null);

    const opacity = new Animated.Value(1);

    function fadeOut() {
      Animated.timing(opacity, {
        toValue: 0,
        duration: 175,
      }).start(() => {
        setInvitemodalvisible(false);
      });
      fetchData();
    }

    function updateSearch(text) {
      setSearchtext(text);
      if (text === "") {
        setData(tempData);
        return;
      }
      setData(
        tempData?.filter(function (item) {
          return item.name.toLowerCase().includes(text.toLowerCase());
        }) ?? [],
      );
    }

    const memoizedCallback = (knee, hip) => {
      console.log("searchtext:" + searchtext);
      console.log("filterHip:" + filterHip);
      console.log("filterKnee:" + filterKnee);

      if (searchtext == "" && !hip && !knee) {
        setData(tempData);
        return;
      }

      setData(
        (tempData ?? []).filter(function (item) {
          if (!(knee && hip)) {
            if (knee && item.operationtype != 1) return false;
            if (hip && item.operationtype != 2) return false;
          }

          return (item.firstname + " " + item.lastname + " " + item.clinicalteam.name)
            .toLowerCase()
            .includes(searchtext.toLowerCase());
        }),
      );

      userStore.updatescreen();
    };

    async function fetchData() {
      fetch(global.apiurl + "/hospitaladmins/getclinicianteams", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: global.tokenparam || "",
        },

        body: JSON.stringify({
          clinicianid,
        }),
      })
        .then(async (response) => await response.json())
        .then((resData) => {
          console.log(resData);
          if (resData.result) {
            setData(resData.data);
            setTempData(resData.data);
            setClinician(resData.clinician);
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    useEffect(() => {
      fetchData();
    }, []);

    const CustomMenu = (props) => {
      const { style, children, layouts, ...other } = props;
      const position = { top: layouts.triggerLayout.y + window.scrollY + 40, left: layouts.triggerLayout.x };
      return (
        <View {...other} style={[style, position, styles.shadowinput, { backgroundColor: "#ffffff", width: 150 }]}>
          {children}
        </View>
      );
    };

    return (
      <View style={styles.container}>
        <Modal
          buggy={userStore.screenupdate}
          onBackdropPress={() => {
            setInvitemodalvisible(false);
          }}
          animationType="fade"
          transparent={true}
          visible={invitemodalvisible}
          onDismiss={() => {
            fetchData();
            //  alert('Modal has been closed.');
          }}>
          {!!invitemodalvisible && (
            <Animated.View
              style={{
                position: "absolute",
                top: 0,
                zIndex: 100,
                width: "100%",
                opacity,
                height: Math.max(dimensions.height, 826),
              }}>
              <InviteClinician
                userStore={userStore}
                onFinish={fadeOut}
                visible={invitemodalvisible}
                removeView={fadeOut}></InviteClinician>
            </Animated.View>
          )}
        </Modal>
        {data && !clinician && "Clinician not found"}

        {clinician && (
          <MainFrameClinician
            fetchData={fetchData}
            clinician={clinician}
            admin
            userStore={userStore}
            clinicalteamlist
            style={{}}
            selected={2}>
            <View style={[styles.whitebox, { alignItems: "flex-start", width: 1061, marginBottom: 94 }]}>
              <View style={{ flexDirection: "row", alignItems: "center", marginLeft: 25, marginTop: 24 }}>
                <Text style={[styles.title1]}>List of clinical teams </Text>
                <Text style={[styles.title1, { position: "absolute", left: 160 }]}>({data?.length})</Text>

                <View style={[styles.shadowinput, { width: 267, marginLeft: 70 }]}>
                  <TextInput
                    style={[styles.postopinput, { textAlign: "left", width: 220 }]}
                    keyboardType="email-address"
                    maxLength={50}
                    numberOfLines={1}
                    onChangeText={(text) => {
                      updateSearch(text);
                    }}
                    placeholder={"Search"}
                    placeholderTextColor={"#1F2E99"}
                    value={searchtext}
                  />
                  <TouchableOpacity style={{ position: "absolute", right: 10, top: 13 }}>
                    <Image source={require("../../assets/images/searchicon.png")} style={styles.searchicon} />
                  </TouchableOpacity>
                </View>
              </View>
              <Hairline marginTop={13} />
              <View style={{ flex: 1, width: "100%", paddingHorizontal: 24 }}>
                <View style={{ flexDirection: "row", width: "100%", justifyContent: "space-between", marginTop: 24.5 }}>
                  <TouchableOpacity style={{ flex: 0.24 }}>
                    <Text style={[styles.title3, { marginLeft: 24 }]}>Name</Text>
                  </TouchableOpacity>
                  <TouchableOpacity style={{ flex: 0.5 }}>
                    <Text style={[styles.title3]}>Team members</Text>
                  </TouchableOpacity>
                  <TouchableOpacity style={{ flex: 0.32 }}>
                    {" "}
                    <Text style={[styles.title3]}>Team Contact details</Text>
                  </TouchableOpacity>
                  <TouchableOpacity style={{ flex: 0.08 }}></TouchableOpacity>
                </View>
                <Hairline marginTop={15.5} />
                <FlatList
                  scrollEnabled={false}
                  xextraData={data}
                  style={{ width: "100%", marginBottom: 94 }}
                  data={data}
                  keyExtractor={(item) => item.id}
                  renderItem={({ item, index, separators }) => (
                    <View
                      style={{
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                        marginBottom: 24.5,
                        backgroundColor: "#ffffff",
                        height: 132,
                        alignItems: "center",
                        borderRadius: 6,
                      }}>
                      <View style={{ flex: 0.24 }}>
                        <A
                          href={"clinicalteam?clinicalteamid=" + item.id}
                          key={item.id}
                          style={{ textDecorationLine: "none", marginLeft: 22 }}>
                          <Text style={[styles.title6]}>{item.name} </Text>
                        </A>
                        <Text style={[styles.title7, { marginLeft: 22 }]}>
                          In charge of {item?.patients?.length} patients{" "}
                        </Text>
                      </View>

                      <Text style={[styles.title4, { flex: 0.5 }]}>
                        {item.clinicians.slice(0, 3).map((item) => (
                          <View style={{ width: 220, marginTop: 12, marginBottom: 12, flexDirection: "row" }}>
                            <Avatar
                              url={item?.thumburl && global.imagepathurl + encodeURI(item?.thumburl)}
                              size={36}
                              radius={18}
                              firstname={item.firstname}
                              lastname={item.lastname}
                              style={{ backgroundColor: "#E0E3F7", borderWidth: 1, marginRight: 12 }}
                              fontcolor={"#000000"}
                            />
                            <View>
                              <Text style={styles.title8}>
                                {item.firstname} {item.lastname}{" "}
                              </Text>
                              <Text style={styles.title9}>Doctor</Text>
                            </View>
                          </View>
                        ))}

                        {item.clinicians.length == 4 &&
                          item.clinicians.slice(3, 4).map((item) => (
                            <View style={{ width: 220, marginTop: 12, marginBottom: 12, flexDirection: "row" }}>
                              <Avatar
                                url={item?.thumburl && global.imagepathurl + encodeURI(item?.thumburl)}
                                size={36}
                                radius={18}
                                firstname={item.firstname}
                                lastname={item.lastname}
                                style={{ backgroundColor: "#E0E3F7", borderWidth: 1, marginRight: 12 }}
                                fontcolor={"#000000"}
                              />
                              <View>
                                <Text style={styles.title8}>
                                  {item.firstname} {item.lastname}{" "}
                                </Text>
                                <Text style={styles.title9}>Doctor</Text>
                              </View>
                            </View>
                          ))}

                        {item.clinicians.length > 4 && (
                          <View style={{ width: 220, marginTop: 12, marginBottom: 12, flexDirection: "row" }}>
                            <Avatar
                              url={item?.thumburl && global.imagepathurl + encodeURI(item?.thumburl)}
                              size={36}
                              radius={18}
                              firstname={"+"}
                              lastname={String(item.clinicians.length - 3)}
                              style={{ backgroundColor: "#E0E3F7", borderWidth: 1, marginRight: 12 }}
                              fontcolor={"#000000"}
                            />
                            <Text style={styles.title8}>+{item.clinicians.length - 3} more members </Text>
                          </View>
                        )}
                      </Text>

                      <View style={{ flex: 0.32 }}>
                        <Text style={styles.title4}>
                          +{item.gsmcountrycode} {item.gsm}
                        </Text>
                        <Text style={[styles.title4, { marginTop: 18 }]}>{item.email}</Text>
                      </View>

                      <Menu style={[{ flex: 0.08 }]} renderer={CustomMenu}>
                        <MenuTrigger>
                          <View
                            style={{
                              height: 20,
                              backgroundColor: "#ffffff",
                              alignItems: "center",
                              justifyContent: "center",
                            }}>
                            <Image source={require("../../assets/images/threedots.png")} style={styles.threedots} />
                          </View>
                        </MenuTrigger>
                        <MenuOptions customStyles={{ borderRadius: 8 }}>
                          <MenuOption
                            text="De-assign clinician"
                            onSelect={async () => {
                              await removeClinicianFromTeam(clinicianid, item.id);
                              await fetchData();
                            }}
                          />
                        </MenuOptions>
                      </Menu>
                    </View>
                  )}
                />
              </View>
            </View>
          </MainFrameClinician>
        )}
      </View>
    );
  }),
);

export default TempScreen;

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  openButton: {
    backgroundColor: "#F194FF",
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },

  threedots: {
    width: 16,
    height: 3.66,
  },
  watchcloselycircleicon: {
    width: 15,
    height: 15,
  },
  unreadmsgicon: {
    width: 8,
    height: 8,
  },
  flagicon: {
    width: 15,
    height: 18.333,
  },

  postopinput: {
    flex: 1,
    outlineWidth: 0,
    color: "#1F2E99",
    fontFamily: "Lato",
    fontSize: 15,
    marginRight: 10,
    marginLeft: 20,
  },
  shadowinput: {
    marginTop: 15,
    height: 44,
    flexDirection: "row",
    borderRadius: 15,
    shadowColor: "#818181",
    backgroundColor: "#FFFFFF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",

    height: "100%",
  },
  title1: {
    fontSize: 18,
    fontFamily: "Lato",
    color: "#1F2E99",
  },
  title2: {
    fontSize: 15,
    fontFamily: "Lato",
    color: "#1F2E99",
  },
  title3: {
    fontSize: 13,
    fontFamily: "Lato",
    color: "#1F2E99",
  },
  titlecenter: { width: "100%", textAlign: "center" },
  title4: {
    fontSize: 15,
    fontFamily: "Lato",
    color: "#000000",
    lineHeight: 18,
  },
  title5: {
    color: "#0009EC",
    fontFamily: "Lato",
    fontSize: 13,
    marginLeft: 6,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  title6: {
    fontSize: 18,
    fontFamily: "LatoBold",
    color: "#000000",
  },
  title7: {
    fontSize: 13,
    marginTop: 8,
    fontFamily: "Lato",
    color: "#000000",
  },
  title8: {
    fontSize: 13,
    fontFamily: "Lato",
    color: "#000000",
  },
  title9: {
    fontSize: 13,
    fontFamily: "Lato",
    color: "#868686",
    marginTop: 4,
  },

  whitebox: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    shadowColor: "#818181",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
});
