import React from "react";
import { Animated, View, Dimensions } from "react-native";
import { HospitalAdminHeader } from "../components/HospitalAdminHeader";
import { MainFrame } from "../components/MainFrame";
import { AddClinicianToTeam } from "../components/AddClinicianToTeam";
import Modal from "modal-react-native-web";

export const MainFrameHospitalAdmin = (props) => {
  const [invitemodalvisible, setInvitemodalvisible] = React.useState(false);
  const dimensions = Dimensions.get("window");
  const opacity = new Animated.Value(1);

  function fadeIn() {
    setInvitemodalvisible(true);
  }

  function fadeOut() {
    Animated.timing(opacity, {
      toValue: 0,
      duration: 175,
    }).start(() => {
      setInvitemodalvisible(false);
    });
    props.fetchData;
  }

  return (
    <View style={{ flex: 1, width: "100%" }}>
      <Modal
        buggy={props.userStore.screenupdate}
        onBackdropPress={() => {
          setInvitemodalvisible(false);
        }}
        animationType="fade"
        transparent={true}
        visible={invitemodalvisible}
        onDismiss={props.fetchData}>
        {!!invitemodalvisible && (
          <Animated.View
            style={{
              position: "absolute",
              top: 0,
              zIndex: 100,
              width: "100%",
              opacity,
              height: Math.max(dimensions.height, 826),
            }}>
            <AddClinicianToTeam
              userStore={props.userStore}
              clinicalteam={props?.clinicalteam}
              onFinish={fadeOut}
              visible={invitemodalvisible}
              removeView={fadeOut}
            />
          </Animated.View>
        )}
      </Modal>{" "}
      <MainFrame
        clinicalteam={props.clinicalteam}
        postopadmin
        hospitaladmins
        userStore={props.userStore}
        style={[{ alignItems: "flex-start", flexDirection: "row", width: "1131" }]}>
        <View style={{ width: (dimensions.width - 1131) / 2, height: "100%" }}>
          <View style={{ height: 106, alignItems: "center", justifyContent: "center" }}></View>
          <View style={{ borderTopColor: "#1F2E99", borderTopWidth: 0.3 }}> </View>
        </View>
        <View style={{ width: 1131, alignSelf: "stretch" }}>
          <HospitalAdminHeader openmodal={fadeIn} userStore={props.userStore} hospitaladmin={props?.hospitaladmin} />
          <View
            style={[
              {
                paddingTop: 20,
                alignSelf: "stretch",
                borderTopColor: "#1F2E99",
                borderTopWidth: 0.3,
                flexDirection: "row",
              },
              props.style,
            ]}>
            {props.children}
          </View>
        </View>
        <View style={{ width: (dimensions.width - 1131) / 2, height: "100%" }}>
          <View style={{ height: 106, alignItems: "center", justifyContent: "center" }}></View>
          <View style={{ borderTopColor: "#1F2E99", borderTopWidth: 0.3 }}> </View>
        </View>
        <View></View>
      </MainFrame>
    </View>
  );
};
