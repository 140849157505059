import type { CommonIconProps } from "./icon-types";

export function TrashIcon({ color = "currentColor", size = 31, className }: Readonly<CommonIconProps>) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path d="M26.1562 6.78125H4.84375" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.5938 12.5938V20.3438" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.4062 12.5938V20.3438" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M24.2188 6.78125V25.1875C24.2188 25.4444 24.1167 25.6908 23.935 25.8725C23.7533 26.0542 23.5069 26.1562 23.25 26.1562H7.75C7.49307 26.1562 7.24667 26.0542 7.06499 25.8725C6.88331 25.6908 6.78125 25.4444 6.78125 25.1875V6.78125"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.3438 6.78125V4.84375C20.3438 4.32989 20.1396 3.83708 19.7763 3.47373C19.4129 3.11038 18.9201 2.90625 18.4062 2.90625H12.5938C12.0799 2.90625 11.5871 3.11038 11.2237 3.47373C10.8604 3.83708 10.6562 4.32989 10.6562 4.84375V6.78125"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function TrashAllIcon({ color = "currentColor", size = 31, className }: Readonly<CommonIconProps>) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 31 31"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        d="M23.9999 7L23.9999 17"
        id="Vector-3"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9999 26.1562L7.75 26.1562C7.49307 26.1562 7.24667 26.0542 7.06499 25.8725C6.88331 25.6908 6.78125 25.4444 6.78125 25.1875L6.78125 6.78125"
        id="Shape"
        fill="none"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g id="Group" transform="translate(4.84375 5.78125)">
        <path
          d="M21.3125 1L0 1"
          id="Line"
          fill="none"
          fillRule="evenodd"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g id="Group" transform="translate(11.5938 12.5938)">
        <path
          d="M1 0L1 7.75"
          id="Line"
          fill="none"
          fillRule="evenodd"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g id="Group" transform="translate(17.4062 12.5938)">
        <path
          d="M1 0L1 7.75"
          id="Line"
          fill="none"
          fillRule="evenodd"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M20.3438 6.78125L20.3438 4.84375C20.3438 4.32989 20.1396 3.83708 19.7763 3.47373C19.4129 3.11038 18.9201 2.90625 18.4062 2.90625L12.5938 2.90625C12.0799 2.90625 11.5871 3.11038 11.2237 3.47373C10.8604 3.83708 10.6562 4.32989 10.6562 4.84375L10.6562 6.78125"
        id="Shape"
        fill="none"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.9999 20L23.9999 29"
        id="Vector"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9999 25L23.9999 29L27.9999 25"
        id="Vector-2"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
