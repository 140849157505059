import * as React from "react";
import { Text, View, StyleSheet, Image, TouchableOpacity } from "react-native";

export const BlueButton = (props) => {
  return (
    <TouchableOpacity style={[styles.listbutton, props.style]} onPress={props.onPress}>
      <View style={{ flex: 1, flexDirection: "row", backgroundColor: "transparent", alignItems: "center" }}>
        {props?.invitenewuser && (
          <Image source={require("../assets/images/inviteusericon.png")} style={styles.invitenewuser} />
        )}
        {props?.sendlink && <Image source={require("../assets/images/sendlinkicon.png")} style={styles.sendlink} />}
        {props?.calendar && (
          <Image source={require("../assets/images/whitecalendaricon.png")} style={styles.calendar} />
        )}
        <Text
          style={[
            { color: "#FFFFFF", fontFamily: "Questrial", fontSize: 16 },
            props.fontsize && { fontSize: props.fontsize },
          ]}>
          {props.title}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  listbutton: {
    alignItems: "flex-start",
    height: 42,
    width: 350,
    borderRadius: 15,
    backgroundColor: "#1F2E99",
    paddingRight: 9,
    alignContent: "center",
  },
  invitenewuser: {
    marginLeft: 14,
    marginRight: 10,
    width: 14,
    height: 12,
  },
  sendlink: {
    marginLeft: 15,
    marginRight: 10,
    width: 20,
    height: 20,
  },
  calendar: {
    marginRight: 9.8,
    marginLeft: 14,
    width: 16.2,
    height: 18,
  },
});
