import fuzzy from "fuzzy";
import React, { useCallback, useMemo } from "react";

import { css } from "@emotion/css";

import { Avatar } from "../../components/Avatar";
import { Button } from "../../components/Button";
import { InviteHospitalAdminModal } from "../../components/InviteHospitalAdmin";
import PostopAdminLayout from "../../components/layouts/PostopAdminLayout";
import type { RenderCellCallback } from "../../components/ui/DataTable";
import { DataTable } from "../../components/ui/DataTable";
import { SearchInput } from "../../components/ui/Input";
import useAsyncResource from "../../hooks/useAsyncResource";
import useParams from "../../hooks/useParams";
import { requests } from "../../misc";
import { getImageUrl, theme } from "../../misc/constants";
import type { IHospitalAdmin } from "../../misc/types";

const POAHospitalAdminsScreen = () => {
  const { hospitalId } = useParams("postopAdmin", "HospitalAdminsScreen");

  const [filter, setFilter] = React.useState("");
  const [showInviteModal, setShowInviteModal] = React.useState(false);

  const admins = useAsyncResource<IHospitalAdmin[]>(async () => {
    if (hospitalId !== undefined) {
      return await requests.get(`/v1/hospitals/${hospitalId}/admins`).then(({ admins }) => admins);
    } else {
      return await requests.get(`/v1/hospital-admins`).then(({ admins }) => admins);
    }
  }, [hospitalId]);

  const filteredAdmins = useMemo(() => {
    if (admins.status !== "loaded") return [];
    if (!filter.trim()) return admins.resource;
    const results = fuzzy.filter(filter, admins.resource, {
      extract: (admin) => `${admin.firstname} ${admin.lastname}`,
    });
    return results.map((result) => result.original);
  }, [filter, admins]);

  const renderCell = useCallback<RenderCellCallback<IHospitalAdmin>>((key, item) => {
    switch (key) {
      case "Avatar":
        return (
          <Avatar
            imageUrl={item.thumburl ? getImageUrl(item.thumburl) : undefined}
            size={46}
            firstName={item.firstname}
            lastName={item.lastname}
            className={css`
              margin: 0 24px 0 18px;
            `}
          />
        );
      case "Name":
        return `${item.firstname ?? ""} ${item.lastname ?? ""}`.trim();
      case "Phone number":
        return `(+${item.gsmcountry}) ${item.gsm}`;
      case "Assigned hospital":
        return item.hospital?.name ?? "N/A";
    }
  }, []);

  return (
    <PostopAdminLayout className={`POAHospitalAdmins ${styles.root}`}>
      <InviteHospitalAdminModal
        show={showInviteModal}
        onDismiss={() => {
          setShowInviteModal(false);
          admins.refresh();
        }}
      />

      <header className="ContentHeader">
        <div className="POAHospitalAdmins-title">
          <h1>List of Hospital Admins ({admins.resource?.length ?? "?"})</h1>
        </div>
        <Button
          left={
            <img
              src={require("../../assets/images/adduser.png")}
              style={{
                marginRight: 8.33,
                width: 16.6,
                height: 13,
              }}
            />
          }
          className="POAHospitalAdmins-invite"
          onClick={() => {
            setShowInviteModal(true);
          }}>
          Invite new admin
        </Button>
      </header>

      <SearchInput onChangeText={setFilter} />

      <DataTable
        heads={[["Avatar", ""], "Name", ["email", "Email address"], "Phone number", "Assigned hospital"]}
        records={filteredAdmins}
        renderCell={renderCell}
      />
    </PostopAdminLayout>
  );
};

export default POAHospitalAdminsScreen;

const styles = Object.freeze({
  root: css`
    flex: 1;
    background-color: #fff;
    align-items: center;
    min-height: 100%;

    header.ContentHeader {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .POAHospitalAdmins-title {
        color: ${theme.primary};
        h1 {
          font-family: Lato;
          font-size: 24px;
        }
        h2 {
          font-family: Lato;
          font-size: 15px;
        }
      }

      .POAHospitalAdmins-invite {
        height: 40px;
      }
    }

    .LayoutContent {
      table {
        width: 100%;
      }
    }
  `,
});
