import type { CommonIconProps } from "./icon-types";

export default function SaveIcon({ size = 22, color = "currentColor", className }: Readonly<CommonIconProps>) {
  return (
    <div className={className}>
      <svg width={size} height={(size * 23) / 22} viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.92929 14.8528L4.6776 9.49113C3.20047 8.00976 5.41617 5.78719 6.89382 7.26856L8.6916 9.07834C9.10087 9.49035 9.4328 9.35377 9.4328 8.7731V1.56758C9.4328 0.706553 10.1344 0 10.9998 0C11.8634 0 12.5667 0.701831 12.5667 1.56758V8.7731C12.5667 9.35064 12.8987 9.49043 13.3081 9.07834L15.1062 7.26856C16.5834 5.78719 18.7996 8.00976 17.3225 9.49113L12.1246 14.8454C11.5169 15.4714 10.5362 15.4724 9.92929 14.8528ZM18.8571 18.8556V15.7143C18.8571 14.8464 19.5607 14.1429 20.4286 14.1429C21.2964 14.1429 22 14.8464 22 15.7143V20.431C22 21.5891 21.0558 22.5238 19.9045 22.5238H2.09546C0.939311 22.5238 0 21.5827 0 20.431V15.7143C0 14.8464 0.703553 14.1429 1.57143 14.1429C2.4393 14.1429 3.14286 14.8464 3.14286 15.7143V18.8556C3.14286 19.1516 3.37694 19.381 3.6657 19.381H18.3343C18.6228 19.381 18.8571 19.1457 18.8571 18.8556Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
