import Constants from "expo-constants";
import Logo from "../icons/Logo";

interface UserLayoutProps {
  children?: JSX.Element;
  title: string;
  body: string;
}

const UserLayout = ({ children, title, body }: UserLayoutProps) => {
  return (
    <div className="unified-login">
      <div className="content">
        <header>
          <Logo />
        </header>
        <main>
          <h1>{title}</h1>
          <p className="body">{body}</p>
          {children}
          {Constants.expoConfig!.extra!.environment === "LOCAL" && (
            <div style={{ fontSize: "80%", margin: "2em auto", width: "100%", textAlign: "center" }}>
              <a href="/login">Clinician Login</a> | <a href="/hospitaladmin/login">Hospital Login</a> |{" "}
              <a href="/postopadmin/login">Postop Login</a>
            </div>
          )}
        </main>
        <div className="warning">
          <span className="text">
            <span className="icon">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="16" height="16" rx="8" fill="#FE5935" />
                <path
                  d="M9.08375 4.77V7.63C9.08375 7.93333 9.06875 8.23167 9.03875 8.525C9.00875 8.815 8.97042 9.12333 8.92375 9.45H8.08375C8.03708 9.12333 7.99875 8.815 7.96875 8.525C7.93875 8.23167 7.92375 7.93333 7.92375 7.63V4.77H9.08375ZM7.72875 11.32C7.72875 11.2167 7.74708 11.1183 7.78375 11.025C7.82375 10.9317 7.87708 10.8517 7.94375 10.785C8.01375 10.7183 8.09542 10.665 8.18875 10.625C8.28208 10.585 8.38208 10.565 8.48875 10.565C8.59208 10.565 8.69042 10.585 8.78375 10.625C8.87708 10.665 8.95708 10.7183 9.02375 10.785C9.09375 10.8517 9.14875 10.9317 9.18875 11.025C9.22875 11.1183 9.24875 11.2167 9.24875 11.32C9.24875 11.4267 9.22875 11.5267 9.18875 11.62C9.14875 11.71 9.09375 11.7883 9.02375 11.855C8.95708 11.9217 8.87708 11.9733 8.78375 12.01C8.69042 12.05 8.59208 12.07 8.48875 12.07C8.38208 12.07 8.28208 12.05 8.18875 12.01C8.09542 11.9733 8.01375 11.9217 7.94375 11.855C7.87708 11.7883 7.82375 11.71 7.78375 11.62C7.74708 11.5267 7.72875 11.4267 7.72875 11.32Z"
                  fill="white"
                />
              </svg>
            </span>
            Remember that accessing the system without authorization is prohibited. Please exit this page if you are not
            invited to use Post Op.
          </span>
        </div>
        <footer>
          <div className="left">
            <svg width="28" height="25" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.8693 2.06855C9.54817 0.82463 8.04689 0.1875 6.39548 0.1875C5.07434 0.21784 3.84329 0.612254 2.73234 1.37074C0.930794 2.76636 0 4.5564 0 6.64983C0.0300257 8.10613 0.480411 9.47141 1.35116 10.6546L1.38118 10.685C2.85244 12.293 4.4438 13.1122 6.18529 13.1122H6.5456C7.53645 13.0818 8.5273 12.8391 9.39805 12.3537H9.42807C11.68 10.9884 12.821 9.07699 12.821 6.64983C12.7909 4.98115 12.1304 3.43383 10.8693 2.06855ZM2.61224 6.52847C2.61224 5.98236 2.76236 5.43624 3.00257 4.98115C3.75321 3.52485 4.86416 2.82704 6.36545 2.82704C7.14612 2.7967 7.89676 3.03942 8.5273 3.49451C9.63825 4.34402 10.1787 5.31489 10.1787 6.52847V6.74085C10.1787 7.13526 10.1187 7.49933 9.99856 7.89375C9.93851 8.04545 9.87845 8.22748 9.78838 8.37918C9.00771 9.80514 7.92678 10.4726 6.51558 10.4726H6.24535C5.70488 10.4726 5.16442 10.3513 4.68401 10.0782C3.2728 9.25903 2.58221 8.1668 2.58221 6.71051L2.61224 6.52847Z"
                fill="#0077FF"
              />
              <path
                d="M27.4143 20.3237C26.8438 19.7473 25.943 19.7776 25.3725 20.3541C23.1506 22.6295 19.5475 22.6599 17.2656 20.4451C16.9953 20.172 16.635 20.0203 16.2447 20.0203C16.2447 20.0203 16.2447 20.0203 16.2147 20.0203C15.8243 20.0203 15.464 20.172 15.1938 20.4451C14.9236 20.7181 14.7734 21.0822 14.7734 21.4766C14.7734 21.871 14.9236 22.2351 15.1938 22.5082C16.3348 23.6307 17.746 24.4196 19.3073 24.7836C19.9379 24.9353 20.5984 24.996 21.229 24.996C23.5109 24.996 25.7028 24.0858 27.3542 22.3868C27.6244 22.1138 27.7746 21.7497 27.7746 21.3553C27.8346 20.9608 27.6845 20.5968 27.4143 20.3237Z"
                fill="#FE7235"
              />
              <path
                d="M15.734 10.5028L15.7039 10.4725C14.9233 9.44097 14.4729 8.22739 14.3828 6.95312V6.98346V16.6314C14.3828 17.3596 14.9533 17.936 15.6739 17.936C16.3945 17.936 16.965 17.3596 16.965 16.6314V11.6558C16.5447 11.3524 16.1243 10.9579 15.734 10.5028Z"
                fill="#FE7235"
              />
              <path
                d="M25.2204 1.91139C23.8693 0.63713 22.368 0 20.7166 0C19.3954 0 18.1644 0.424753 17.0534 1.18324C15.2519 2.57886 14.3211 4.36889 14.3211 6.46232C14.3211 6.61402 14.3211 6.76572 14.3511 6.91742C14.3812 6.21961 14.9516 5.64316 15.6422 5.64316C16.3629 5.64316 16.9333 6.21961 16.9333 6.94776V11.62C18.0443 12.4696 19.2453 12.8943 20.4764 12.8943H20.8367C21.8275 12.864 22.8184 12.6212 23.6891 12.1358H23.7191C25.9711 10.7705 27.112 8.85915 27.112 6.43198C27.1421 4.79365 26.4815 3.24633 25.2204 1.91139ZM24.5298 6.55334C24.5298 7.03877 24.4398 7.52421 24.2596 7.94896C23.4489 9.52662 22.338 10.2851 20.8667 10.2851H20.5965C20.056 10.2851 19.5155 10.1637 19.0351 9.89069C17.6239 9.07152 16.9333 7.9793 16.9333 6.523V6.31062C16.9333 5.91621 16.9934 5.55214 17.1135 5.18806C17.8942 3.45871 19.0351 2.6092 20.6866 2.6092C21.4672 2.6092 22.2179 2.82158 22.8484 3.27667C23.9593 4.12618 24.4998 5.09704 24.4998 6.31062V6.55334H24.5298Z"
                fill="#0077FF"
              />
              <path
                d="M15.6739 5.66406C14.9833 5.66406 14.4128 6.24051 14.3828 6.93832C14.5029 8.21259 14.9533 9.42617 15.7039 10.4577L15.734 10.4881C16.1243 10.9431 16.5447 11.3072 16.965 11.641V6.96866C16.965 6.27085 16.3945 5.66406 15.6739 5.66406Z"
                fill="#1F2E99"
              />
            </svg>

            <p className="trademark">Post Op is a registered trademark of Post Op company.</p>
            <p className="copyright">Copyright &copy; 2023 Post Op Company</p>
          </div>
        </footer>
      </div>
    </div>
  );
};
export default UserLayout;
