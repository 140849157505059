import React, { useState } from "react";
import { css, cx } from "@emotion/css";
import Dropdown from "../../components/ui/Dropdown";
import type { IPatientMedia } from "../../interfaces/patientInterfaces";
import { requests } from "../../misc";

enum ReviewStatus {
  Pending = 0,
  Annotated = 1,
  Void = 2,
}
interface ReviewStatusDropdownProps {
  media: IPatientMedia;
  className?: string;
  onChange?: (reviewStatus: ReviewStatus) => void;
}

function ReviewStatusDropdown({
  media: { id: mediaId, ...media },
  className,
  onChange,
}: Readonly<ReviewStatusDropdownProps>) {
  const values = [
    { label: "Pending", value: ReviewStatus.Pending },
    { label: "Annotated", value: ReviewStatus.Annotated },
    { label: "Void", value: ReviewStatus.Void },
  ];

  const [selectedReviewStatus, setSelectedReviewStatus] = useState<ReviewStatus>(parseInt(media.review_status));

  async function updateReviewStatus(id: number, reviewStatus: ReviewStatus) {
    await requests.put(`/v1/patient-media/${mediaId}/review-status`, { reviewStatus });
    setSelectedReviewStatus(reviewStatus);
    onChange?.(reviewStatus);
  }

  const getStatusClassName = () => {
    if (selectedReviewStatus === ReviewStatus.Annotated) return "status-annotated";
    if (selectedReviewStatus === ReviewStatus.Pending) return "status-pending";
    if (selectedReviewStatus === ReviewStatus.Void) return "status-void";
    return "";
  };

  return (
    <Dropdown
      variant="chip"
      values={values}
      value={selectedReviewStatus}
      extractId={(item) => item.value + ""}
      equality={(lhs, rhs) => lhs.value === rhs}
      filter={(item) => item.value !== null}
      onChange={async (item) => {
        await updateReviewStatus(mediaId, item.value);
      }}
      className={cx("POReviewStatusDropdown", stylesheet.status, getStatusClassName(), className)}
    />
  );
}

const stylesheet = Object.freeze({
  status: css`
    .PODropdown-value {
      width: 80px;
      text-align: center;
      font-size: 13px;
      font-weight: bold;
      color: white;
      border-color: transparent;
      &:focus {
        border-color: transparent;
      }
    }

    &.status-annotated {
      background-color: #6dd803;
      width: 100px;
      border-radius: 10px;
    }
    &.status-pending {
      background-color: #f9ce37;
      width: 100px;
      border-radius: 10px;
    }
    &.status-void {
      background-color: #ff6d6d;
      width: 100px;
      border-radius: 10px;
    }
  `,
});

export default ReviewStatusDropdown;
