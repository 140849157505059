import * as React from "react";
import { View, Image } from "react-native";

export const HeaderMin = ({ children }: React.PropsWithChildren) => {
  return (
    <View style={{ backgroundColor: "#FCF5EF", width: "100%", height: "100%" }}>
      {" "}
      <View style={{ backgroundColor: "#FFFFFF", height: 72, width: "100%", alignItems: "center" }}>
        <View style={{ width: 940 }}>
          <Image
            source={require("../assets/images/logopostop1.1.png")}
            style={{ width: 100.1, height: 33, marginTop: 21 }}
          />
        </View>
      </View>
      <View style={{ width: "100%", alignItems: "center" }}>
        <View style={{ width: 940, height: "100%" }}>{children}</View>
      </View>
    </View>
  );
};
