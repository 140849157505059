import * as React from "react";
import Select from "./ui/Select";

const SpecialtiesNames: Record<number, string> = {
  3: "ENT",
  5: "General Surgery",
  6: "T&O",
  0: "Other",
};
const SpecialtyInput = ({
  value,
  onChange,
  disabled,
}: {
  value: number;
  onChange: (value: number) => void;
  disabled?: boolean;
}) => {
  return disabled ? (
    <div>{SpecialtiesNames[value]}</div>
  ) : (
    <Select
      value={String(value)}
      onValueChange={(itemValue) => {
        onChange(parseInt(itemValue));
      }}
      style={{ marginTop: 5 }}
      options={[
        { value: "", label: "" },
        { value: 3, label: "ENT" },
        { value: 5, label: "General Surgery" },
        { value: 6, label: "T&O" },
        { value: 0, label: "Other" },
      ]}
    />
  );
};
export default SpecialtyInput;
