import React from "react";
import { Animated, Text, View, StyleSheet, Dimensions, Image, TouchableOpacity } from "react-native";
import { A } from "@expo/html-elements";
import { ClinicianMenu } from "./LeftMenu";
import { ClinicianHeader } from "./ClinicianHeader";
import { AddTeamToClinician } from "./AddTeamToClinician";
import { EditClinician } from "./EditClinician";
import { MainFrame } from "./MainFrame";
import Modal from "modal-react-native-web";

export const MainFrameClinician = (props) => {
  const [invitemodalvisible, setInvitemodalvisible] = React.useState(false);
  const [editClinicianModalVisible, setEditClinicianModalVisible] = React.useState(false);

  const dimensions = Dimensions.get("window");
  const opacity = new Animated.Value(1);
  const editClinicianOpacity = new Animated.Value(1);

  function fadeIn() {
    setInvitemodalvisible(true);
  }

  function fadeOut() {
    Animated.timing(opacity, {
      toValue: 0,
      duration: 175,
    }).start(() => {
      setInvitemodalvisible(false);
    });
    props.fetchData;
  }

  function fadeInEditClinician() {
    setEditClinicianModalVisible(true);
  }

  function fadeOutEditClinician() {
    Animated.timing(editClinicianOpacity, {
      toValue: 0,
      duration: 175,
    }).start(() => {
      setEditClinicianModalVisible(false);
    });
    props.fetchData;
  }

  return (
    <View style={{ flex: 1, width: "100%" }}>
      <Modal
        buggy={props.userStore.screenupdate}
        onBackdropPress={() => {
          setEditClinicianModalVisible(false);
        }}
        animationType="fade"
        transparent={true}
        visible={editClinicianModalVisible}
        onDismiss={props.fetchData}>
        {!!editClinicianModalVisible && (
          <Animated.View
            style={{
              position: "absolute",
              top: 0,
              zIndex: 100,
              width: "100%",
              opacity: editClinicianOpacity,
              height: Math.max(dimensions.height, 826),
            }}>
            <EditClinician
              userStore={props.userStore}
              clinician={props?.clinician}
              onFinish={fadeOutEditClinician}
              visible={editClinicianModalVisible}
              removeView={fadeOutEditClinician}></EditClinician>
          </Animated.View>
        )}
      </Modal>
      <Modal
        buggy={props.userStore.screenupdate}
        onBackdropPress={() => {
          setInvitemodalvisible(false);
        }}
        animationType="fade"
        transparent={true}
        visible={invitemodalvisible}
        onDismiss={props.fetchData}>
        {!!invitemodalvisible && (
          <Animated.View
            style={{
              position: "absolute",
              top: 0,
              zIndex: 100,
              width: "100%",
              opacity,
              height: Math.max(dimensions.height, 826),
            }}>
            <AddTeamToClinician
              userStore={props.userStore}
              clinician={props?.clinician}
              onFinish={fadeOut}
              visible={invitemodalvisible}
              removeView={fadeOut}></AddTeamToClinician>
          </Animated.View>
        )}
      </Modal>

      <MainFrame
        setlastreadmessage={props.setlastreadmessage}
        messages={props.messages}
        admin={props.admin}
        clinicianlist
        userStore={props.userStore}
        style={[{ alignItems: "flex-start", flexDirection: "row", width: "100%" }]}>
        <View style={{ width: 181, alignItems: "center", height: "100%" }}>
          <View style={{ height: 106, alignItems: "center", justifyContent: "center" }}>
            <A href="/hospitaladmin/clinicians">
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Image
                  source={require("../assets/images/backicon.png")}
                  style={{ width: 14.333, height: 10, marginRight: 11.5 }}
                />
                <Text style={styles.title1}>Back to Clinician list </Text>
              </View>
            </A>
          </View>
          <View style={{ width: 181, backgroundColor: "#ffffff", height: 1000, paddingTop: 11 }}>
            <ClinicianMenu clinicianId={props.clinician?.id} selected={props.selected} />
          </View>
          <View style={{ flexDirection: "row" }}>
            <View style={[styles.whitebox]}></View>
          </View>
        </View>
        <View style={{ flexGrow: 1, alignSelf: "stretch" }}>
          <ClinicianHeader
            openmodal={fadeIn}
            openClinicianModal={fadeInEditClinician}
            userStore={props.userStore}
            clinician={props?.clinician}
          />
          <View
            style={[
              {
                paddingLeft: 63,
                paddingRight: 63,
                paddingTop: 20,
                alignSelf: "stretch",
                borderTopColor: "#1F2E99",
                borderTopWidth: 0.3,
                flexDirection: "row",
              },
              props.style,
            ]}>
            {props.children}
          </View>
        </View>
        <View></View>
      </MainFrame>
    </View>
  );
};

const styles = StyleSheet.create({
  whitebox: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    shadowColor: "#818181",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    elevation: 5,
  },
  title1: {
    fontFamily: "Lato",
    fontSize: 13,
    color: "#1F2E99",
  },
});
