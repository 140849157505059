import React, { useCallback } from "react";
import { BarLoader } from "react-spinners";

import { css } from "@emotion/css";

import { Avatar } from "../../components/Avatar";
import Clickable from "../../components/Clickable";
import { HospitalMeta } from "../../components/HospitalMeta";
import PostopAdminLayout from "../../components/layouts/PostopAdminLayout";
import { toastError } from "../../components/Notifications";
import type { RenderCellCallback } from "../../components/ui/DataTable";
import { DataTable } from "../../components/ui/DataTable";
import Input from "../../components/ui/Input";
import { OneOf } from "../../components/ui/Logicals";
import useAsyncResource from "../../hooks/useAsyncResource";
import { useNav } from "../../hooks/useNav";
import useParams from "../../hooks/useParams";
import { requests } from "../../misc";
import { getImageUrl, theme } from "../../misc/constants";
import type { IHospitalAdmin } from "../../misc/types";
import * as Users from "../../misc/user-utils";

const { disableHospitalAdmin } = Users;

const HospitalDetailsScreen = () => {
  const nav = useNav<"hospitalAdmin">();
  const params = useParams("postopAdmin", "HospitalDetailsScreen");
  const { hospitalId } = params;
  const [filter, setFilter] = React.useState("");

  const hospital = useAsyncResource(
    async () => await requests.get(`/v1/hospitals/${hospitalId}`).then(({ data }) => data),
    [hospitalId],
  );

  const admins = useAsyncResource<IHospitalAdmin[]>(
    async () => await requests.get(`/v1/hospitals/${hospitalId}/admins`).then(({ admins }) => admins),
    [hospitalId],
  );

  const impersonate = useCallback(async (id: number) => {
    if (await Users.impersonate({ hospitaladmin_id: id })) {
      nav.navigate("AdminDashboardScreen");
    } else {
      toastError("Failed to impersonate. Please see console for details.");
    }
  }, []);

  const renderCell = useCallback<RenderCellCallback<IHospitalAdmin>>((key, item) => {
    switch (key) {
      case "Avatar":
        return (
          <Avatar
            imageUrl={item.thumburl ? getImageUrl(item.thumburl) : undefined}
            size={46}
            firstName={item.firstname}
            lastName={item.lastname}
            className={css`
              margin: 0 18px 0 24px;
              border-width: 1px;
            `}
          />
        );
      case "Name":
        return item.firstname + " " + item.lastname;
      case "Phone number":
        return `(+${item.gsmcountry}) ${item.gsm}`;
    }
  }, []);

  return (
    <PostopAdminLayout className={`POAHospitalDetails-root ${styles.root}`}>
      <OneOf>
        {hospital.status === "loading" && (
          <div className={styles.metabox}>
            <BarLoader color={theme.primary} />
          </div>
        )}
        {hospital.status === "error" && (
          <div className={`${styles.metabox} metabox-error`}>
            Failed to load hospital details. Please see console for details.
          </div>
        )}
        {hospital.status === "loaded" && <HospitalMeta hospital={hospital.resource} />}
      </OneOf>

      <section className="POAHospitalDetails-box">
        <OneOf>
          {admins.status === "loading" && (
            <div className={styles.metabox}>
              <BarLoader color={theme.primary} />
            </div>
          )}
          {admins.status === "loaded" && (
            <>
              <header
                className={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 8px;
                `}>
                <h1>List of admins ({admins.resource?.length ?? "?"})</h1>
                <Input
                  type="email"
                  placeholder="Search"
                  value={filter}
                  maxLength={50}
                  adornBack={
                    <img src={require("../../assets/images/searchicon.png")} style={{ width: 14, height: 15 }} />
                  }
                  onChangeText={setFilter}
                />
              </header>
              <main className={`POAHospitalDetails-admins ${styles.admins}`}>
                <DataTable<IHospitalAdmin>
                  heads={[["Avatar", ""], "Name", ["email", "Email address"], "Phone number"]}
                  records={admins.resource ?? []}
                  extractItemKey={(item) => item.id}
                  renderCell={renderCell}
                  renderActions={(item) => (
                    <ul className={styles.popover}>
                      <li>
                        <Clickable
                          onClick={async () => {
                            await impersonate(item.id);
                          }}>
                          Login as this Hospital Admin
                        </Clickable>
                      </li>
                      <li>
                        <Clickable
                          onClick={async () => {
                            await disableHospitalAdmin(item.id);
                            await hospital.refresh();
                          }}>
                          De-assign Hospital Admin
                        </Clickable>
                      </li>
                    </ul>
                  )}
                />
              </main>
            </>
          )}
        </OneOf>
      </section>
    </PostopAdminLayout>
  );
};

export default HospitalDetailsScreen;

const styles = Object.freeze({
  root: css`
    .LayoutContent {
      display: flex;
      flex-direction: column;
      gap: 40px;
    }

    .POAHospitalDetails-box {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding: 24px;
      background: white;
      border-radius: 8px;
      gap: 12px;
    }
  `,
  admins: css`
    align-self: stretch;

    table {
      width: 100%;
    }
  `,
  popover: css`
    li:hover {
      color: ${theme.primary} !important;
    }
  `,
  metabox: css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 150px;

    &.metabox-error {
      color: red;
    }
  `,
});
