import * as React from "react";
import { StyleSheet } from "react-native";
import {
  VictoryLabel,
  VictoryPie,
  VictoryVoronoiContainer,
  VictoryGroup,
  VictoryBar,
  VictoryTooltip,
  VictoryLine,
  VictoryChart,
  VictoryAxis,
  VictoryScatter,
} from "victory-native";

export const WoundAIPie = (props) => {
  let colors = [];
  const values = [];

  if (props?.labels) {
    colors = Object.values(props?.labels.colors);
    console.log("XX1");
    console.log(colors);

    console.log(props?.labels?.percs);

    Object.keys(props?.labels.percs).forEach((key) => {
      values.push({ y: props?.labels.percs[key] });
    });
  }

  return (
    <VictoryPie
      width={300}
      height={300}
      padding={{ top: 0, bottom: 0 }}
      colorScale={colors}
      labels={({ datum }) => ""}
      data={values}
    />
  );
};

export const RecoveryStatusChart = (props) => {
  return (
    <VictoryChart
      domainPadding={{ x: 0, y: 20 }}
      padding={{ top: 18, bottom: 40, left: 24, right: 40 }}
      scale={{ x: "time", y: "linear" }}
      width={445}
      height={226}
      style={{}}>
      <VictoryAxis
        tickValues={[3.5, 10.5, 17.5, 24.5]}
        tickFormat={["wk 1", "wk 2", "wk 3", "wk 4"]}
        style={{
          axis: { stroke: "#dddddd", strokeWidth: 1, strokeDasharray: [3, 3] },
          tickLabels: {
            fontSize: 11,
            fontFamily: "Lato",
            fontWeight: "regular",
            fontStyle: "normal",
            stroke: "rgba(255,255,255,0.5)",
          },
        }}
      />
      <VictoryAxis
        dependentAxis
        // tickFormat specifies how ticks should be displayed
        tickFormat={(x) => `${x}`}
        style={{
          grid: { stroke: "#eeeeee", strokeWidth: 1, strokeDasharray: [3, 3] },

          axis: { stroke: "#dddddd", strokeWidth: 1, strokeDasharray: [3, 3] },
          tickLabels: {
            fontSize: 11,
            fontFamily: "Lato",
            fontWeight: "regular",
            fontStyle: "normal",
            stroke: "rgba(255,255,255,0.5)",
          },
        }}
      />

      {props?.data?.dashchart1a && (
        <VictoryLine
          style={{
            data: { stroke: "#19CE37", strokeWidth: 1 },
            parent: { border: "1px solid #111" },
          }}
          interpolation="catmullRom"
          x={(datum) => datum.x}
          data={props?.data?.dashchart1a}
        />
      )}

      {props?.data?.dashchart1a && props?.data?.dashchart1a.length < 2 && (
        <VictoryScatter
          style={{
            data: { fill: "#19CE37", stroke: "#19CE37", strokeWidth: 1 },
          }}
          data={props?.data?.dashchart1a}
        />
      )}

      {props?.data?.dashchart1b && (
        <VictoryLine
          style={{
            data: { stroke: "#F9CE37", strokeWidth: 1 },
            parent: { border: "3px solid #111" },
          }}
          interpolation="catmullRom"
          x={(datum) => datum.x}
          data={props?.data?.dashchart1b}
        />
      )}
      {props?.data?.dashchart1b && props?.data?.dashchart1b.length < 2 && (
        <VictoryScatter
          style={{
            data: { fill: "#F9CE37", stroke: "#F9CE37", strokeWidth: 1 },
          }}
          data={props?.data?.dashchart1b}
        />
      )}

      {props?.data?.dashchart1c && (
        <VictoryLine
          style={{
            data: { stroke: "#FF6D6D", strokeWidth: 1 },
            parent: { border: "3px solid #111" },
          }}
          interpolation="catmullRom"
          x={(datum) => datum.x}
          data={props?.data?.dashchart1c}
        />
      )}

      {props?.data?.dashchart1c && props?.data?.dashchart1c.length < 2 && (
        <VictoryScatter
          style={{
            data: { fill: "#FF6D6D", stroke: "#FF6D6D", strokeWidth: 1 },
          }}
          data={props?.data?.dashchart1c}
        />
      )}
    </VictoryChart>
  );
};

export const OutComeScoresChart33 = (props) => {
  const data = [
    { x: 1, y: 1 },
    { x: 2, y: 2 },
    { x: 3, y: 4 },
    { x: 4, y: 7 },
    { x: 5, y: 11 },
  ];

  return (
    <VictoryChart
      width={600}
      height={500}
      containerComponent={<VictoryVoronoiContainer labels={(d) => "(x=" + d.x + ";y=" + d.y + ")"} />}>
      <VictoryLine data={data} />
    </VictoryChart>
  );
};

export const OutComeScoresChart = (props) => {
  return (
    <VictoryChart
      domainPadding={{ x: 0, y: 40 }}
      padding={{ top: 18, bottom: 30, left: 35, right: 25 }}
      width={445}
      height={226}
      containerComponent={<VictoryVoronoiContainer mouseFollowTooltips />}
      style={{}}
      /**
       * the material theme uses the Roboto font, and react-native-svg isn't
       * compatible with expo-font, so we can't use this theme:
       * theme={VictoryTheme.material}
       **/
    >
      <VictoryAxis
        // tickValues specifies both the number of ticks and where
        // they are placed on the axis
        tickValues={[0, 8, 16, 24, 32, 40, 48, 56]}
        tickFormat={["", "wk 8", "wk 16", "wk 24", "wk 32", "wk 40", "wk 48", "wk 56"]}
        style={{
          axis: { stroke: "#dddddd", strokeWidth: 1, strokeDasharray: [3, 3] },
          tickLabels: {
            fontSize: 11,
            fontFamily: "Lato",
            fontWeight: "regular",
            fontStyle: "normal",
            stroke: "rgba(255,255,255,0.5)",
          },
        }}
      />
      <VictoryAxis
        dependentAxis
        tickValues={props.yaxis}
        style={{
          grid: { stroke: "#eeeeee", strokeWidth: 1, strokeDasharray: [3, 3] },

          axis: { stroke: "#dddddd", strokeWidth: 1, strokeDasharray: [3, 3] },
          tickLabels: {
            fontSize: 11,
            fontFamily: "Lato",
            fontWeight: "regular",
            fontStyle: "normal",
            stroke: "rgba(255,255,255,0.5)",
          },
        }}
        // tickFormat specifies how ticks should be displayed
        tickFormat={(x) => `${x}`}
      />

      {props?.data && props?.data.length > 1 && (
        <VictoryLine
          style={{
            data: { stroke: "#0077FF", strokeWidth: 1 },

            parent: { border: "1px solid #ccc" },
          }}
          interpolation="catmullRom"
          data={props?.data}
        />
      )}

      {props?.data && props?.data.length < 32 && (
        <VictoryScatter
          labelComponent={
            <VictoryTooltip
              events={{
                onMouseOver: (evt) => {
                  alert("x: ");
                },
              }}
              activateData={true}
            />
          }
          labels={({ datum }) => `wk: ${datum.x}, score: ${datum.y}`}
          style={{
            data: { fill: "#0077FF", stroke: "#0077FF", strokeWidth: 1 },

            parent: { border: "1px solid #ccc" },
          }}
          data={props?.data}
        />
      )}
    </VictoryChart>
  );
};

export const OutComeScoresChartWoundDetailsAI = (props) => {
  return (
    <VictoryChart
      domainPadding={{ x: 0, y: 40 }}
      padding={{ top: 18, bottom: 30, left: 35, right: 25 }}
      width={445}
      height={226}
      containerComponent={<VictoryVoronoiContainer mouseFollowTooltips />}
      style={{}}
      /**
       * the material theme uses the Roboto font, and react-native-svg isn't
       * compatible with expo-font, so we can't use this theme:
       * theme={VictoryTheme.material}
       **/
    >
      <VictoryAxis
        // tickValues specifies both the number of ticks and where
        // they are placed on the axis
        tickValues={[0, 8 * 7, 16 * 7, 24 * 7, 32 * 7, 40 * 7, 48 * 7, 56 * 7]}
        tickFormat={["", "wk 8", "wk 16", "wk 24", "wk 32", "wk 40", "wk 48", "wk 56"]}
        style={{
          axis: { stroke: "#dddddd", strokeWidth: 1, strokeDasharray: [3, 3] },
          tickLabels: {
            fontSize: 11,
            fontFamily: "Lato",
            fontWeight: "regular",
            fontStyle: "normal",
            stroke: "rgba(255,255,255,0.5)",
          },
        }}
      />
      <VictoryAxis
        dependentAxis
        tickValues={props.yaxis}
        style={{
          grid: { stroke: "#eeeeee", strokeWidth: 1, strokeDasharray: [3, 3] },

          axis: { stroke: "#dddddd", strokeWidth: 1, strokeDasharray: [3, 3] },
          tickLabels: {
            fontSize: 11,
            fontFamily: "Lato",
            fontWeight: "regular",
            fontStyle: "normal",
            stroke: "rgba(255,255,255,0.5)",
          },
        }}
        // tickFormat specifies how ticks should be displayed
        tickFormat={(x) => `${x}`}
      />

      <VictoryAxis
        dependentAxis
        width={1}
        height={400}
        domain={[1]}
        offsetX={35 + (props.day * 385) / (56 * 7)}
        style={{
          tickLabels: { fill: "none" },
          axis: { stroke: "red" },
        }}
        axisLabelComponent={<VictoryLabel dy={20} />}
        standalone={false}
      />
      {props?.data && props?.data.length > 1 && (
        <VictoryLine
          style={{
            data: { stroke: "#0077FF", strokeWidth: 1 },

            parent: { border: "1px solid #ccc" },
          }}
          interpolation="catmullRom"
          data={props?.data}
        />
      )}

      {props?.data && props?.data.length < 32 && (
        <VictoryScatter
          labelComponent={
            <VictoryTooltip
              events={{
                onMouseOver: (evt) => {
                  alert("x: ");
                },
              }}
              activateData={true}
            />
          }
          labels={({ datum }) => `day: ${datum.x}, score: ${datum.y}`}
          style={{
            data: { fill: "#0077FF", stroke: "#0077FF", strokeWidth: 1 },

            parent: { border: "1px solid #ccc" },
          }}
          data={props?.data}
        />
      )}
    </VictoryChart>
  );
};
function patientLegendTickFromDay(dayno) {
  if (dayno < 30) return [0, 1, 2, 3, 4, 5];
  if (dayno < 90) return [0, 2, 4, 6, 8, 10, 12, 14, 16];
  if (dayno < 180) return [0, 4, 8, 12, 16, 20, 24, 28, 32];

  return [0, 8, 16, 24, 32, 40, 48, 56];
}
function patientLegendTickFormatFromDay(dayno) {
  if (dayno < 30) return ["", "wk 1", "wk 2", "wk 3", "wk 4", "wk 5"];
  if (dayno < 90) return ["", "wk 2", "wk 4", "wk 6", "wk 8", "wk 10", "wk 12", "wk 14", "wk 16"];
  if (dayno < 180) return ["", "wk 4", "wk 8", "wk 12", "wk 16", "wk 20", "wk 24", "wk 28", "wk 32"];

  return ["", "wk 8", "wk 16", "wk 24", "wk 32", "wk 40", "wk 48", "wk 56"];
}

export const PREMSScoreClientChart = (props) => {
  return (
    <VictoryChart
      domainPadding={{ x: 0, y: 10 }}
      padding={{ top: 18, bottom: 40, left: 40, right: 40 }}
      width={540}
      height={240}
      style={{}}
      /**
       * the material theme uses the Roboto font, and react-native-svg isn't
       * compatible with expo-font, so we can't use this theme:
       * theme={VictoryTheme.material}
       **/
    >
      <VictoryAxis
        // tickValues specifies both the number of ticks and where
        // they are placed on the axis
        tickValues={patientLegendTickFromDay(props.patient?.dayno)}
        tickFormat={patientLegendTickFormatFromDay(props.patient?.dayno)}
        style={{
          axis: { stroke: "#dddddd", strokeWidth: 1, strokeDasharray: [3, 3] },
          tickLabels: {
            fontSize: 11,
            fontFamily: "Lato",
            fontWeight: "regular",
            fontStyle: "normal",
            stroke: "rgba(255,255,255,0.5)",
          },
        }}
      />
      <VictoryAxis
        dependentAxis
        tickValues={props.yaxis}
        style={{
          grid: { stroke: "#eeeeee", strokeWidth: 1, strokeDasharray: [3, 3] },

          axis: { stroke: "#dddddd", strokeWidth: 1, strokeDasharray: [3, 3] },
          tickLabels: {
            fontSize: 11,
            fontFamily: "Lato",
            fontWeight: "regular",
            fontStyle: "normal",
            stroke: "rgba(255,255,255,0.5)",
          },
        }}
        // tickFormat specifies how ticks should be displayed
        tickFormat={(x) => `${x}`}
      />

      <VictoryLine
        style={{
          data: { stroke: "#0077FF", strokeWidth: 1 },

          parent: { border: "1px solid #ccc" },
        }}
        interpolation="catmullRom"
        data={props?.data}
      />

      {props?.data && props?.data?.length < 2 && (
        <VictoryScatter
          style={{
            data: { fill: "#0077FF", stroke: "#0077FF", strokeWidth: 1 },
          }}
          data={props?.data}
        />
      )}
    </VictoryChart>
  );
};

export const EQ5D5LClientChart = (props) => {
  return (
    <VictoryChart
      domainPadding={{ x: 30 }}
      padding={{ top: 18, bottom: 40, left: 45, right: 40 }}
      width={540}
      height={240}
      style={{}}
      /**
       * the material theme uses the Roboto font, and react-native-svg isn't
       * compatible with expo-font, so we can't use this theme:
       * theme={VictoryTheme.material}
       **/
    >
      <VictoryAxis
        // tickValues specifies both the number of ticks and where
        // they are placed on the axis

        tickFormat={["Mobility", "Self-care", "Usual activity", "Discomfort", "Anxiety"]}
        style={{
          axis: { stroke: "#dddddd", strokeWidth: 1, strokeDasharray: [3, 3] },
          tickLabels: {
            fontSize: 11,
            fontFamily: "Lato",
            fontWeight: "regular",
            fontStyle: "normal",
            stroke: "rgba(255,255,255,0.5)",
          },
        }}
      />
      <VictoryAxis
        dependentAxis
        tickValues={[0, 1, 2, 3, 4, 5]}
        style={{
          grid: { stroke: "#eeeeee", strokeWidth: 1, strokeDasharray: [3, 3] },

          axis: { stroke: "#dddddd", strokeWidth: 1, strokeDasharray: [3, 3] },
          tickLabels: {
            fontSize: 11,
            fontFamily: "Lato",
            fontWeight: "regular",
            fontStyle: "normal",
            stroke: "rgba(255,255,255,0.5)",
          },
        }}
        // tickFormat specifies how ticks should be displayed
        tickFormat={(x) => `${x}`}
      />

      <VictoryBar
        cornerRadius={5}
        barWidth={10}
        categories={{
          x: ["Mobility", "Self-care", "Usual activity", "Discomfort", "Anxiety"],
        }}
        style={{ data: { fill: "#31BACC" } }}
        data={[
          { x: "Mobility", y: parseInt(props.data.eq5d5lresult.charAt(0)) },
          { x: "Self-care", y: parseInt(props.data.eq5d5lresult.charAt(1)) },
          { x: "Usual activity", y: parseInt(props.data.eq5d5lresult.charAt(2)) },
          { x: "Discomfort", y: parseInt(props.data.eq5d5lresult.charAt(3)) },
          { x: "Anxiety", y: parseInt(props.data.eq5d5lresult.charAt(4)) },
        ]}
      />
    </VictoryChart>
  );
};

export const HospitalAdminPatientTypeChart = (props) => {
  return (
    <VictoryChart
      domainPadding={{ x: 30 }}
      padding={{ top: 18, bottom: 30, left: 40, right: 40 }}
      width={372}
      height={217}
      style={{}}
      /**
       * the material theme uses the Roboto font, and react-native-svg isn't
       * compatible with expo-font, so we can't use this theme:
       * theme={VictoryTheme.material}
       **/
    >
      <VictoryAxis
        // tickValues specifies both the number of ticks and where
        // they are placed on the axis
        tickFormat={props.months}
        style={{
          axis: { stroke: "#dddddd", strokeWidth: 1, strokeDasharray: [3, 3] },
          tickLabels: {
            fontSize: 11,
            fontFamily: "Lato",
            fontWeight: "regular",
            fontStyle: "normal",
            stroke: "rgba(255,255,255,0.5)",
          },
        }}
      />
      <VictoryAxis
        dependentAxis
        tickValues={[0, 20, 40, 60, 80, 100]}
        style={{
          grid: { stroke: "#eeeeee", strokeWidth: 1, strokeDasharray: [3, 3] },

          axis: { stroke: "#dddddd", strokeWidth: 1, strokeDasharray: [3, 3] },
          tickLabels: {
            fontSize: 11,
            fontFamily: "Lato",
            fontWeight: "regular",
            fontStyle: "normal",
            stroke: "rgba(255,255,255,0.5)",
          },
        }}
        tickFormat={(x) => `${x}`}
      />

      <VictoryGroup offset={10} style={{ data: { width: 10 } }} colorScale={["#0077FF", "#6DD803"]}>
        <VictoryBar cornerRadius={5} data={props.proms} />
        <VictoryBar cornerRadius={5} data={props.prems} />
      </VictoryGroup>
    </VictoryChart>
  );
};

export const HospitalAdminOverallComplienceTypeChart = (props) => {
  return (
    <VictoryChart
      width={372}
      height={217 + 18}
      style={{}}
      padding={{ top: 16, bottom: 30, left: 40, right: 40 }}
      scale={{ x: "time", y: "linear" }}

      /**
       * the material theme uses the Roboto font, and react-native-svg isn't
       * compatible with expo-font, so we can't use this theme:
       * theme={VictoryTheme.material}
       **/
    >
      <VictoryAxis
        // tickValues specifies both the number of ticks and where
        // they are placed on the axis
        tickValues={[3.5, 10.5, 17.5, 24.5]}
        tickFormat={["wk 1", "wk 2", "wk 3", "wk 4"]}
        style={{
          axis: { stroke: "#dddddd", strokeWidth: 1, strokeDasharray: [3, 3] },
          tickLabels: {
            fontSize: 11,
            fontFamily: "Lato",
            fontWeight: "regular",
            fontStyle: "normal",
            stroke: "rgba(255,255,255,0.5)",
          },
        }}
      />
      <VictoryAxis
        dependentAxis
        tickValues={[0, 20, 40, 60, 80, 100]}
        style={{
          grid: { stroke: "#eeeeee", strokeWidth: 1, strokeDasharray: [3, 3] },

          axis: { stroke: "#dddddd", strokeWidth: 1, strokeDasharray: [3, 3] },
          tickLabels: {
            fontSize: 11,
            fontFamily: "Lato",
            fontWeight: "regular",
            fontStyle: "normal",
            stroke: "rgba(255,255,255,0.5)",
          },
        }}
        // tickFormat specifies how ticks should be displayed
      />

      <VictoryLine
        style={{
          data: { stroke: "#FE5935", strokeWidth: 2 },
          parent: { border: "2px solid #ccc" },
        }}
        interpolation="catmullRom"
        labelComponent={<VictoryTooltip />}
        data={props?.data}
        xdata={[
          { x: 0, y: 14, label: "1,2" },
          { x: 1, y: 45, label: "1,2" },
          { x: 2, y: 53, label: "1,2" },
          { x: 3, y: 73, label: "1,2" },
          { x: 4, y: 44, label: "1,2" },
          { x: 5, y: 54, label: "1,2" },
        ]}
      />
    </VictoryChart>
  );
};

export const OverallComplianceClientChart = (props) => {
  return (
    <VictoryChart
      width={269}
      height={208}
      style={{}}
      domainPadding={{ x: 12, y: 20 }}
      padding={{ top: 18, bottom: 40, left: 30, right: 40 }}
      scale={{ x: "time", y: "linear" }}

      /**
       * the material theme uses the Roboto font, and react-native-svg isn't
       * compatible with expo-font, so we can't use this theme:
       * theme={VictoryTheme.material}
       **/
    >
      <VictoryAxis
        // tickValues specifies both the number of ticks and where
        // they are placed on the axis
        // tickValues={[3.5, 10.5, 17.5, 24.5]}
        tickValues={[3.5 + props?.week * 7, 10.5 + props?.week * 7, 17.5 + props?.week * 7, 24.5 + props?.week * 7]}
        tickFormat={[
          "wk " + (props?.week + 1),
          "wk " + (props?.week + 2),
          "wk " + (props?.week + 3),
          "wk " + (props?.week + 4),
        ]}
        style={{
          axis: { stroke: "#dddddd", strokeWidth: 1, strokeDasharray: [3, 3] },
          tickLabels: {
            fontSize: 11,
            fontFamily: "Lato",
            fontWeight: "regular",
            fontStyle: "normal",
            stroke: "rgba(255,255,255,0.5)",
          },
        }}
      />
      <VictoryAxis
        dependentAxis
        tickValues={[0, 50, 100]}
        style={{
          grid: { stroke: "#eeeeee", strokeWidth: 1, strokeDasharray: [3, 3] },

          axis: { stroke: "#dddddd", strokeWidth: 1, strokeDasharray: [3, 3] },
          tickLabels: {
            fontSize: 11,
            fontFamily: "Lato",
            fontWeight: "regular",
            fontStyle: "normal",
            stroke: "rgba(255,255,255,0.5)",
          },
        }}
        // tickFormat specifies how ticks should be displayed
      />

      <VictoryLine
        style={{
          data: { stroke: "#0077FF", strokeWidth: 2 },
          parent: { border: "2px solid #0077FF" },
        }}
        interpolation="catmullRom"
        labelComponent={<VictoryTooltip />}
        data={props?.data}
        xdata={[
          { x: 0, y: 14, label: "1,2" },
          { x: 1, y: 45, label: "1,2" },
          { x: 2, y: 53, label: "1,2" },
          { x: 3, y: 73, label: "1,2" },
          { x: 4, y: 44, label: "1,2" },
          { x: 5, y: 54, label: "1,2" },
        ]}
      />
    </VictoryChart>
  );
};

const styles = StyleSheet.create({
  title1: {
    color: "#1F2E99",
    fontFamily: "Lato",
    fontSize: 15,
  },
});
