import React from "react";
import Eye from "../../components/icons/EyeIcon";

export interface EyePasswordProps {
  error?: string;
  onChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  id: string;
}

export const EyePassword: React.FC<EyePasswordProps> = ({ error, onChange, id }) => {
  const [secure, setSecure] = React.useState(true);
  return (
    <div className="password-wrapper">
      <div className={`password standard-shadow ${error ? "input-error" : ""}`}>
        <input type={secure ? "password" : "text"} name={id} id={id} onChange={onChange} />
        <span
          onClick={() => {
            setSecure(!secure);
          }}>
          <Eye size={22} color="#1F2E99" className="eye" closed={!secure} />
        </span>
      </div>
      <div className={error ? "error" : "no-error"}>{error}</div>
    </div>
  );
};
