import React from "react";
import validate from "../../screens/validate_wrapper";
import { requests } from "../../misc";
import { toastError } from "../Notifications";
import { Modal } from "../Modals";
import UnifiedLogin2FA from "./UnifiedLogin2FA";
import userStore from "../../mobx/store";
import { RequestError } from "../../misc/requests";
import UserLayout from "./UserLayout";
import { EyePassword } from "./EyePassword";

export interface LoginProps {
  admin?: boolean;
  postopadmin?: boolean;
}

export default function UnifiedLogin(props: LoginProps) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [passwordError, setPasswordError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [loginVerified, setLoginVerified] = React.useState(false);

  const apigateway = props?.admin ? "hospitaladmins" : props?.postopadmin ? "postopadmins" : "clinicians";

  async function doLogin(e: React.FormEvent<Element>) {
    e.preventDefault();
    if (isLoading) return false;

    setPasswordError("");
    setEmailError("");

    const _passwordError = validate("passwordlogin", password);
    const _emailError = validate("email", email);

    if (_passwordError || _emailError) {
      setPasswordError(_passwordError);
      setEmailError(_emailError);
    } else {
      try {
        setIsLoading(true);
        await requests.post(`/${apigateway}/checklogin`, {
          email,
          password,
        });
        setLoginVerified(true);
      } catch (err: any) {
        if (err instanceof RequestError && err.response.status === 401) {
          toastError("Invalid credentials. Please check your email and password and try again.");
          return;
        }
        console.error("Failed to check login:", err);
        toastError("Unable to verify your credentials. Please try later.");
      } finally {
        setIsLoading(false);
      }
    }
  }

  return (
    <>
      <UserLayout title="Enter your Post Op account" body=" ">
        <>
          <div style={{ height: 30 }}>&nbsp;</div>
          <form
            onSubmit={(e: React.FormEvent<Element>) => {
              doLogin(e);
            }}>
            <div className="input-block">
              <label htmlFor="email">Email address</label>
              <input
                className={`standard-shadow ${emailError ? "input-error" : ""}`}
                type="email"
                name="email"
                id="email"
                onChange={(ev) => {
                  setEmail(ev.target.value.trim());
                }}
              />
              <div className={emailError ? "error" : "no-error"}>{emailError}</div>
            </div>
            <div className="input-block">
              <label htmlFor="password">Password</label>
              <EyePassword
                id="password"
                error={passwordError}
                onChange={(ev) => {
                  setPassword(ev.target.value.trim());
                }}
              />
            </div>
            <p>
              <a
                href={
                  props?.admin
                    ? "/hospitaladmin/resetpassword"
                    : props?.postopadmin
                    ? "/postopadmin/resetpassword"
                    : "/resetpassword"
                }>
                Forgot password?
              </a>
            </p>
            <p>
              <button type="submit" id="enter" disabled={isLoading || email.length == 0 || password.length == 0}>
                Enter
              </button>
            </p>
          </form>
          <p className="instructions">
            Haven't created your account yet?{" "}
            <a
              href={
                props?.admin ? "/hospitaladmin/register" : props?.postopadmin ? "/postopadmin/register" : "/register"
              }>
              Create your account
            </a>
          </p>
        </>
      </UserLayout>
      <Modal
        show={loginVerified}
        onDismiss={() => {
          setLoginVerified(false);
        }}
        className="unified-login-backdrop">
        <UnifiedLogin2FA
          userStore={userStore}
          email={email}
          password={password}
          closeAction={() => {
            setLoginVerified(false);
          }}
          {...props}
        />
      </Modal>
    </>
  );
}
