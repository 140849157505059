import React, { useCallback, useState } from "react";

import { useNav } from "../hooks/useNav";
import * as Users from "../misc/user-utils";
import { withStore } from "../misc/utils";
import { Avatar } from "./Avatar";
import Clickable from "./Clickable";
import { toastError } from "./Notifications";
import PopoverMenu from "./ui/PopoverMenu";
import { EllipsisIcon } from "./icons/EllipsisIcon";

function AdvancedMenu({ onClick }: { onClick: () => void }) {
  const [showPopover, setShowPopover] = useState(false);

  const open = useCallback(() => {
    setShowPopover(true);
  }, []);

  const close = useCallback(() => {
    setShowPopover(false);
  }, [showPopover]);

  return (
    <PopoverMenu
      label={
        <Clickable onClick={open}>
          <EllipsisIcon size={18} color="grey" />
        </Clickable>
      }
      show={showPopover}
      onClickOutside={close}
      className="standard-shadow">
      <ul>
        <li>
          <Clickable
            onClick={() => {
              onClick();
              close();
            }}>
            Login as this Clinician
          </Clickable>
        </li>
      </ul>
    </PopoverMenu>
  );
}

export interface Clinician {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  country_code: string;
  phone: string;
  created_at: Date;
  disabled: boolean;
  invite_accepted: boolean;
  invitation_code: string;
  role?: number;
}

const ClinicianList = withStore<{ clinicians: Clinician[] }>(({ clinicians, userStore }) => {
  const nav = useNav<"clinician">();

  const impersonate = useCallback(async (id: number) => {
    if (await Users.impersonate({ clinician_id: id })) {
      nav.navigate("DashboardScreen");
    } else {
      toastError("Failed to impersonate user. Please see console for details.");
    }
  }, []);

  return (
    <div className="POAdminLayout-root">
      {clinicians && clinicians.length > 0 ? (
        <table className="POAdminResults">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {clinicians.map(
              (c: Clinician): JSX.Element => (
                <tr key={c.id}>
                  <td
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <Avatar
                      url={c?.photourl && global.imagepathurl + encodeURI(c?.photourl)}
                      size={46}
                      radius={23}
                      firstname={c.first_name}
                      lastname={c.last_name}
                      style={{ marginLeft: 24, borderWidth: 1, marginRight: 18 }}
                    />
                    {`${c.first_name} ${c.last_name}`}
                  </td>
                  <td>{c.email}</td>

                  <td>{c.role}</td>
                  <td>
                    <AdvancedMenu
                      onClick={async () => {
                        await impersonate(c.id);
                      }}
                    />
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </table>
      ) : (
        <div className="no-results">No clinicians found</div>
      )}
    </div>
  );
});

export default ClinicianList;
