import * as React from "react";
import type { StyleProp, ViewStyle } from "react-native";
import { Text, View, StyleSheet, Image, TouchableOpacity } from "react-native";
import { useFeatureFlag } from "../hooks/useFeatureFlag";
import { useEffect } from "react";
import { OperationType } from "../misc/constants";

export interface CarePlanWeekSelectorProps {
  week: number;
  withLabel?: boolean;
  style?: StyleProp<ViewStyle>;
  onPrev?: () => void;
  onNext?: () => void;
}
export const CarePlanWeekSelector = ({ week, withLabel, style, onPrev, onNext }: CarePlanWeekSelectorProps) => {
  return (
    <View style={[{ flexDirection: "row", position: "absolute", right: 32 }, style]}>
      {withLabel && (
        <Text style={{ color: "#1F2E99", fontFamily: "Lato", fontSize: 15 }}>
          Week {week} - {week + 1}
        </Text>
      )}
      <TouchableOpacity style={{ marginLeft: 12 }} onPress={onPrev}>
        <Image style={{ width: 20, height: 20 }} source={require("../assets/images/careplanprevbutton.png")} />
      </TouchableOpacity>
      <TouchableOpacity style={{ marginLeft: 14 }} onPress={onNext}>
        <Image style={{ width: 20, height: 20 }} source={require("../assets/images/careplannextbutton.png")} />
      </TouchableOpacity>
    </View>
  );
};

export enum CarePointState {
  Pending = 0,
  Completed = 1,
  Missing = 2,
}

export interface CarePointProps {
  active?: boolean;
  state: CarePointState | null;
  dayno: number;
  /** Corresponds to the type/database column of the associated activity. */
  type: string;
  editmode?: boolean;
  /** Called when pressed in edit mode. */
  onToggle?: (type: string, dayno: number) => void;
  /** Called when pressed when not in edit mode. */
  onPress?: (type: string, dayno: number) => void;
}

export function CarePoint({ active, state, dayno, type, editmode, ...props }: CarePointProps) {
  let bgcolor: string;
  switch (state) {
    case null:
    case CarePointState.Pending:
      bgcolor = "#007EF4"; // blue
      break;
    case CarePointState.Completed:
      bgcolor = "#14E310"; // green
      break;
    case CarePointState.Missing:
      bgcolor = "#DD4545"; // red
      break;
  }

  const onPress = React.useCallback(() => {
    if (editmode) {
      props.onToggle?.(type, dayno);
    } else {
      if (state === CarePointState.Completed) props.onPress?.(type, dayno);
    }
  }, [state, props.onPress, props.onToggle, type, dayno, editmode]);

  return (
    <TouchableOpacity onPress={onPress}>
      <View style={{ width: 30, alignItems: "center" }}>
        {(active || editmode) && (
          <View
            style={{
              backgroundColor: bgcolor,
              width: 10,
              height: 10,
              borderRadius: 5,
              opacity: active ? 1 : 0.2,
            }}></View>
        )}
      </View>
    </TouchableOpacity>
  );
}

// Helper function to find an activity object by dayno in the provided activities array
const findActivityByDay = (activities: any, dayno: number, type: string) => {
  return activities?.find((activity: any) => activity.dayno === dayno && activity[type]);
};

// Helper function to check if an activity is completed for a given day
const isActivityCompleted = (activity: any, row: string) => {
  return activity && (activity[row + "completed"] || activity[row + "_completed"]);
};

function getCarePointState(dayno: number, currDay: number, isCompleted: boolean) {
  if (currDay < dayno) return isCompleted ? CarePointState.Completed : CarePointState.Missing;
  if (currDay === dayno) return isCompleted ? CarePointState.Completed : CarePointState.Pending;
  return CarePointState.Pending;
}

export interface CarePointsProps {
  today: number;
  fromDay: number;
  toDay: number;
  /** Corresponds to the type/database column of the associated activity. */
  type: string;
  patientActivities?: any[];
  editmode?: boolean;
  onPressPoint?: (type: string, dayno: number) => void;
  onTogglePoint?: (type: string, dayno: number) => void;
}

/** `CarePoints` Component responsible for rendering a series of CarePoint components */
export function CarePoints({
  today,
  fromDay,
  toDay,
  type,
  patientActivities = [],
  editmode = false,
  onPressPoint,
  onTogglePoint,
}: CarePointsProps) {
  const output = [];

  // Iterate from 1 to 14, representing 14 days
  for (let i = fromDay; i < toDay; i++) {
    // Find the activity for the current activeday
    const activity = findActivityByDay(patientActivities, i, type);

    // If there's no activity data for the activeday, create a CarePoint with dotstate set to false
    const isCompleted = isActivityCompleted(activity, type);

    output.push(
      <CarePoint
        key={i}
        state={getCarePointState(today, i, isCompleted)}
        active={!!activity?.[type]}
        dayno={i}
        type={type}
        editmode={editmode}
        onPress={onPressPoint}
        onToggle={onTogglePoint}
      />,
    );
  }

  // Return the output array containing the series of CarePoint components wrapped in a JSX fragment
  return <>{output}</>;
}

interface CarePlanProps {
  operationtype: number;
  today: number;
  fromDay: number;
  toDay: number;
  clinicalTeamId: number;
  patientActivities?: any[];
  editmode?: boolean;
  style?: StyleProp<ViewStyle>;
  onTogglePoint?: (type: string, dayno: number) => void;
  onPressPoint?: (type: string, dayno: number) => void;
}
export const CarePlan = ({ style, ...props }: CarePlanProps) => {
  const { today, fromDay, toDay, clinicalTeamId, patientActivities, operationtype: operationType } = props;

  const [careplan, setCareplan] = React.useState<any>({ templates: [], schedule: {} });

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(
        global.apiurl + `/v1/clinicians/getcareplan?clinicalteam_id=${clinicalTeamId}&operation_type=${operationType}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: global.tokenparam || "",
          },
        },
      );
      const json_response = await response.json();
      const careplan = json_response.careplan;
      setCareplan(careplan);
    }
    fetchData();
  }, []);

  const renderDay = React.useCallback(
    (day: number) => {
      if (day == today) {
        return (
          <View style={styles.cellDay}>
            <View
              style={{
                height: 28,
                width: 28,
                borderRadius: 14,
                backgroundColor: "#1F2E99",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <Text style={[styles.title1, { color: "#FFFFFF" }]}>{day}</Text>
            </View>
          </View>
        );
      } else {
        return (
          <View style={styles.cellDay}>
            <Text style={styles.title1}>{day}</Text>
          </View>
        );
      }
    },
    [today],
  );

  const renderDays = React.useCallback(() => {
    const output = [];
    for (let i = fromDay; i < toDay; i++) {
      output.push(renderDay(i));
    }
    return output;
  }, [fromDay, toDay, renderDay]);

  const rows = React.useMemo(() => {
    const result: Array<[string, string]> = [];
    const addItems = (...items: Array<[string, string, boolean] | [string, string]>) => {
      for (const [label, type, condition] of items) {
        if (condition !== undefined && !condition) continue;
        result.push([label, type]);
      }
    };

    const isOKS = operationType === OperationType.Knee;
    const isOHS = operationType === OperationType.Hip;
    const isENT = operationType === OperationType.ENT;
    const isOther = !isOKS && !isOHS && !isENT;

    if (careplan?.templates?.length > 0) {
      addItems(...careplan?.templates);
    } else {
      addItems(
        ["Wound Image", "photo"],
        ["Wound Video", "video"],
        ["Voice Recording", "voice", isENT],
        ["VHI-10", "vhi10", isENT],
        ["ESS", "ess6", isENT],
        ["SNOT-22", "snot", isENT],
        ["SSIS", "ssis"],
        ["EQ5D", "eq5d5l"],
        ["PREMS", "prems"],
        ["PHQ9", "phq9"],
        ["WQoL", "wqol", isOther],
        ["FHSQ", "fhs", isOther],
        ["OKS", "ohsoks", isOKS],
        ["Physio - Knee", "physio_knee", isOKS],
        ["OHS", "ohsoks", isOHS],
        ["Physio - Hip", "physio_hip", isOHS],
      );
    }

    return result;
  }, [operationType, careplan]);

  return (
    <View style={[{ backgroundColor: "#ffffff", borderColor: "#E8E9ED", borderWidth: 1 }, style]}>
      <CarePlanRow label="Recovery Day" odd>
        {renderDays()}
      </CarePlanRow>
      {/* TODO: when we finally move away from expo/rn-web, we can solve this with plain old CSS */}
      {rows.map((row, i) => (
        <>
          <CarePlanRow />
          <CarePlanRow label={row[0]} even={i % 2 === 0} odd={i % 2 === 1}>
            <CarePoints type={row[1]} {...props} />
          </CarePlanRow>
        </>
      ))}
    </View>
  );
};

type CarePlanRowProps = React.PropsWithChildren<{
  label?: React.ReactNode;
  odd?: boolean;
  even?: boolean;
}>;
function CarePlanRow({ children, label: _label = "", ...props }: CarePlanRowProps) {
  const variant = props.odd ? "Odd" : props.even ? "Even" : "Empty";
  const label = typeof _label === "string" ? <Text style={styles.title1}>{_label}</Text> : _label;
  return (
    <View style={[styles.row, styles[`row${variant}`]]}>
      <View style={styles.cell1}>{label}</View>
      <View style={styles.cell2}>{children}</View>{" "}
    </View>
  );
}

const styles = StyleSheet.create({
  title1: {
    color: "#1F2E99",
    fontFamily: "Lato",
    fontSize: 15,
  },
  row: {
    flexDirection: "row",
    width: 866,
    borderColor: "#E8E9ED",
    borderBottomWidth: 1,
  },
  rowOdd: {
    backgroundColor: "#F9F9F9",
  },
  rowEven: {
    backgroundColor: "#FCF5EF",
  },
  rowEmpty: {
    height: 3,
  },
  cell1: {
    width: 141,
    height: 57,
    borderColor: "#E8E9ED",
    borderRightWidth: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  cell2: {
    flexDirection: "row",
    width: 724,
    height: 57,
    borderColor: "#E8E9ED",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  cellDay: {
    width: 30,
    alignItems: "center",
  },
});
