/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from "react";
import type { Clinician } from "../../components/ClinicianList";
import ClinicianList from "../../components/ClinicianList";
import { useRoute } from "@react-navigation/native";
import { withStore } from "../../misc/utils";
import type { IHospital } from "../../misc/types";
import { css, cx } from "@emotion/css";
import PostopAdminLayout from "../../components/layouts/PostopAdminLayout";
import { HospitalHeader } from "../../components/HospitalHeader";
const useGetHospitalDetails = (hospitalid: string) => {
  const [data, setData] = React.useState<{ hospital: IHospital }>();

  useEffect(() => {
    function fetchData() {
      fetch(global.apiurl + "/postopadmins/gethospitaldetails", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: global.tokenparam || "",
        },

        body: JSON.stringify({
          hospitalid,
        }),
      })
        .then(async (response) => await response.json())
        .then((resData) => {
          console.log(resData);
          if (resData.result) {
            setData(resData);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, []);

  return data;
};
const HospitalClinicianList = withStore(({ userStore }) => {
  const {
    params: { hospitalId },
  } = useRoute<any>();
  const [clinicians, setClinicians] = useState<Clinician[]>([]);
  const hospitalData = useGetHospitalDetails(hospitalId);
  const performSearch = async (query: string = ""): Promise<void> => {
    if (query && query.length < 3) return;
    const response = await fetch(
      `${global.apiurl}/postopadmins/search-clinicians?q=${encodeURIComponent(query)}&hospital_id=${encodeURIComponent(
        hospitalId,
      )}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: global.tokenparam || "",
        },
      },
    );
    const resData = await response.json();
    setClinicians(resData.data);
  };
  useEffect(() => {
    performSearch();
  }, []);
  if (!hospitalData?.hospital) return null;
  return (
    <div style={{ background: "white" }}>
      <PostopAdminLayout
        extraHeader={
          <div className={cx(styles.headerContainer)}>
            <div style={{ margin: "auto", width: "fit-content" }}>
              <HospitalHeader hospital={hospitalData.hospital || {}} userStore={userStore} />
            </div>
          </div>
        }>
        <div className={cx(styles.container)}>
          <div className={cx(styles.searchHeader)}>
            <h3 className="primary-color">List of Clinicians</h3>
            <div className={cx(styles.search)}>
              <input
                className={cx(styles.searchInput)}
                placeholder="Search"
                onChange={async (e) => {
                  await performSearch(e.target.value);
                }}
              />
              <img src={require("../../assets/images/searchicon.png")} style={{ width: 12 }} />
            </div>
          </div>
          <ClinicianList clinicians={clinicians} />
        </div>
      </PostopAdminLayout>
    </div>
  );
});
const styles = Object.freeze({
  searchHeader: css`
    display: flex;
    align-items: middle;
    margin-bottom: 20px;
  `,
  search: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 200px;
    padding: 10px 15px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    margin: auto 30px;
  `,
  searchInput: css`
    border: none;
    outline: none;
    font-size: 15px;
  `,
  container: css`
    width: 100%;
    height: 100%;
    padding: 10px;
  `,
  headerContainer: css`
    border-bottom: 0.3px solid;
    margin: 0 auto;
  `,
});
export default HospitalClinicianList;
