import * as React from "react";
import { TextInput, Text, View, StyleSheet, Dimensions, Image, TouchableOpacity } from "react-native";

import { OrangeButton } from "./OrangeButton";
import { Hairline } from "./Hairline";
import validate from "../screens/validate_wrapper";
import CountrySelector from "../components/CountrySelector";

export const CreateClinicalTeam = (props) => {
  const [name, setName] = React.useState("");
  const [gsm, setGsm] = React.useState("");
  const [gsmcountrycode, setGsmcountrycode] = React.useState("44");
  const [countrycode, setCountrycode] = React.useState("GB");

  const [gsmnotification, setGsmnotification] = React.useState("");
  const [gsmcountrycodenotification, setGsmcountrycodenotification] = React.useState("44");
  const [countrycodenotification, setCountrycodenotification] = React.useState("GB");

  const [email, setEmail] = React.useState("");
  const [errorText, setErrorText] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  function validateForm() {
    setIsLoading(true);

    setErrorText("");
    const nameError = validate("genericname", name);
    const gsmError = validate("gsm2", gsm);
    const gsmnotificationError = validate("gsmnotification", gsmnotification);
    const emailError = validate("email", email);

    if (!nameError && !gsmError && !emailError) {
      // updateprofile();
      sendform();
    } else {
      setIsLoading(false);

      // alert("\n"+(nameError?"\n"+nameError+"\n":"")+(gsmError?"\n"+gsmError+"\n":"")+(emailError?"\n"+emailError+"\n":"")+"\n");
      setErrorText(
        "\n" +
          (nameError ? "\n" + nameError + "\n" : "") +
          (gsmError ? "\n" + gsmError + "\n" : "") +
          (gsmnotificationError ? "\n" + gsmnotificationError + "\n" : "") +
          (emailError ? "\n" + emailError + "\n" : "") +
          "\n",
      );
    }
  }

  function sendform() {
    console.log("creating clinical team");

    fetch(global.apiurl + "/hospitaladmins/createclinicalteam", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
      body: JSON.stringify({
        name,
        gsmcountrycode,
        gsm,
        countrycode,
        gsmcountrycodenotification,
        gsmnotification,
        countrycodenotification,
        email,
      }),
    })
      .then(async (res) => {
        if (res.status === 422) {
          throw new Error("Request failed.");
        }
        if (res.status !== 200 && res.status !== 201) {
          console.log("Error!");
          throw new Error("Could not authenticate you!");
        }
        return await res.json();
      })
      .then((resData) => {
        console.log(resData);
        if (resData.result) {
          console.log("DONE");
          props.onFinish();
          // todo close ve listede clientı gorsun
        } else {
          alert("Unable to add, please try later");
        }
      })
      .catch((err) => {
        alert("Unable to add, please try later");
        console.log(err);
      });
  }

  function removeView() {
    props.removeView();
  }

  const dimensions = Dimensions.get("window");
  const modalmargin = (dimensions.height - 625) / 2;

  return (
    <View style={[{ backgroundColor: "rgba(52, 52, 52, 0.8)", paddingTop: modalmargin, paddingBottom: modalmargin }]}>
      <View
        style={{
          borderRadius: 8,
          backgroundColor: "#ffffff",
          width: 364,
          height: 700,
          marginBottom: 1000,
          alignSelf: "center",
        }}>
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            borderBottomColor: "#E8E9ED",
            borderBottomWidth: 1,
            height: 70,
          }}>
          <Text style={styles.title1}>Create new clinical team</Text>
          <TouchableOpacity
            style={{ position: "absolute", right: 0 }}
            onPress={() => {
              removeView();
            }}>
            <View
              style={{
                backgroundColor: "#FCF5EF",
                width: 93,
                height: 70,
                flexDirection: "row",
                borderColor: "#E8E9ED",
                borderWidth: 1,
                borderTopRightRadius: 8,
                alignItems: "center",
              }}>
              <Image
                source={require("../assets/images/closecrossicon.png")}
                style={{ width: 10, height: 10, marginRight: 8, marginLeft: 23 }}
              />
              <Text style={{ fontFamily: "Lato", fontSize: 13, color: "#1F2E99" }}>Close</Text>
            </View>
          </TouchableOpacity>
        </View>

        <View style={{ flexDirection: "row" }}>
          <View style={{ width: 863, paddingLeft: 20 }}>
            <Text style={styles.title2}>Team details</Text>

            <Text style={styles.title3}>Clinical team name</Text>
            <View style={[styles.shadowinput, { width: 250 }]}>
              <TextInput
                style={[styles.postopinput, { textAlign: "left", width: 250 }]}
                keyboardType="name-phone-pad"
                maxLength={50}
                numberOfLines={1}
                onChangeText={(text) => {
                  setName(text);
                }}
                placeholder={"NHS T&O"}
                placeholderTextColor={"#A8B1C1"}
                value={name}
                returnKeyType="done"
              />
            </View>

            <View style={{ width: 250 }}>
              <Text style={[styles.title3, { marginTop: 22 }]}>Phone number</Text>
              <View style={[styles.shadowinput]}>
                <CountrySelector
                  countrycode={"GB"}
                  setGsmcountrycode={(value) => {
                    setGsmcountrycode(value);
                  }}
                  setCountrycode={(value) => {
                    setCountrycode(value);
                  }}
                />

                <TextInput
                  placeholder={"7911123456"}
                  placeholderTextColor={"#A8B1C1"}
                  keyboardType={"number-pad"}
                  onChangeText={(text) => {
                    setGsm(text);
                  }}
                  value={gsm}
                  maxLength={15}
                  style={{ color: "#1F2E99", fontFamily: "Lato", fontSize: 15, marginRight: 10 }}
                />
              </View>
            </View>

            <View style={{ width: 250 }}>
              <Text style={[styles.title3]}>Notification mobile phone number{"\n"}(to receive notification SMS's)</Text>
              <View style={[styles.shadowinput]}>
                <CountrySelector
                  countrycode={"GB"}
                  setGsmcountrycode={(value) => {
                    setGsmcountrycodenotification(value);
                  }}
                  setCountrycode={(value) => {
                    setCountrycodenotification(value);
                  }}
                />

                <TextInput
                  placeholder={"7911123456"}
                  placeholderTextColor={"#A8B1C1"}
                  keyboardType={"number-pad"}
                  onChangeText={(text) => {
                    setGsmnotification(text);
                  }}
                  value={gsmnotification}
                  maxLength={15}
                  style={{ color: "#1F2E99", fontFamily: "Lato", fontSize: 15, marginRight: 10 }}
                />
              </View>
            </View>

            <View style={{ width: 250 }}>
              <Text style={[styles.title3]}>Email address</Text>

              <View style={[styles.shadowinput, { width: 250 }]}>
                <TextInput
                  placeholder={"nhs.orth@nhs.net"}
                  placeholderTextColor={"#A8B1C1"}
                  style={[styles.postopinput, { textAlign: "left", width: 250 }]}
                  keyboardType="email-address"
                  maxLength={50}
                  numberOfLines={1}
                  onChangeText={(text) => {
                    setEmail(text || null);
                  }}
                  value={email}
                  returnKeyType="done"
                />
              </View>
            </View>
            <Text
              style={{
                color: "#FF0000",
                flex: 1,
                textAlign: "left",
                fontFamily: "LatoBold",
                fontSize: 14,
                marginTop: -18,
                lineHeight: 14,
              }}>
              {errorText}
            </Text>
          </View>
        </View>

        <View style={{ position: "absolute", bottom: 16, alignItems: "center", width: 364 }}>
          <Hairline />

          <OrangeButton
            onPress={() => {
              validateForm();
            }}
            title="Create clinical team"
            style={{ width: 250, marginTop: 16 }}
          />
        </View>
      </View>{" "}
    </View>
  );
};

const styles = StyleSheet.create({
  select: {
    marginLeft: 20,
    //  "text-align":"center","text-align-last":"center",
    "-moz-appearance": "none",
    "-webkit-appearance": "none",
    appearance: "none",
    backgroundImage:
      "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAVCAYAAAAuJkyQAAAAmUlEQVR42r3OwQmAQAxE0S3JTrUDvairl6ANBG1MySFeFrz8kIGBZRnIK55ZtLOWnPzf3c67r8f1WO2dhannPTZ3TeefXhtmYrxm+UAARTAtyLLKNTEUxyyig28gimNW0akZcxTHAFQCBqAwJgEFMACFMRkojuEojolHcUw8CmLiUQCTgAIYhAKYJBTH8FTRAWLis4t21hKQF5HBtPSQyO2PAAAAAElFTkSuQmCC)",
    backgroundPosition: "87%",
    backgroundSize: "12px 7px",
    "background-repeat": "no-repeat",
    borderWidth: 0,
    width: 163,
    height: 24,
    borderRadius: 20,
    fontFamily: "Lato",
    fontSize: 15,
    color: "#1F2E99",
    textAlign: "center",
  },

  menuitemwait: {
    fontFamily: "Lato",
    fontSize: 15,
    color: "#959DAD",
    marginTop: 27,
    height: 18,
  },
  menuitemdone: {
    fontFamily: "Lato",
    fontSize: 15,
    color: "#1F2E99",
    marginTop: 27,
    height: 18,
  },
  menuitemactive: {
    fontFamily: "LatoBold",
    fontSize: 15,
    color: "#1F2E99",
    marginTop: 27,
    height: 18,
  },

  postopinput: {
    flex: 1,
    outlineWidth: 0,
    color: "#1F2E99",
    fontFamily: "Lato",
    fontSize: 15,
    marginRight: 10,
    marginLeft: 20,
  },
  countryflag: {
    width: 26,
    height: 18,
    marginHorizontal: 16,
    marginVertical: 19,
  },
  shadowinput: {
    marginTop: 8,
    height: 48,
    flexDirection: "row",
    borderRadius: 15,
    shadowColor: "#818181",
    backgroundColor: "#FFFFFF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
  title1: {
    fontSize: 18,
    fontFamily: "LatoBold",
    color: "#1F2E99",
    marginTop: 24,
    marginLeft: 24,
  },

  title2: {
    fontSize: 15,
    fontFamily: "LatoBold",
    color: "#1F2E99",
    marginTop: 24,
  },

  title3: {
    marginTop: 22,
    fontSize: 13,
    fontFamily: "Lato",
    color: "#798599",
  },
});
