import Moment from "moment";
import * as React from "react";
import { Dimensions, Image, StyleSheet, Text, TextInput, TouchableOpacity, View, CheckBox } from "react-native";

import { CarePlan, CarePlanWeekSelector } from "../components/CarePlan";
import CountrySelector from "../components/CountrySelector";
import DatePicker from "../components/ui/DatePicker";
import { useAsyncEffect } from "../hooks/useAsyncEffect";
import { useFeatureFlag } from "../hooks/useFeatureFlag";
import { requests } from "../misc";
import validate from "../screens/validate_wrapper";
import { toastError } from "./Notifications";
import { Button } from "./Button";
import PORadioGroup from "./PORadioGroup";
import { POText } from "./POText";
import { css } from "@emotion/css";
import { theme } from "../misc/constants";
import Select from "./ui/Select";
import OperationAndDiagnosisInput from "./OperationAndDiagnosisInput";
import SpecialtyInput from "./SpecialtyInput";

enum OperationType {
  Knee = 1,
  Hip = 2,
  ENT = 3,
  FootAndAnkle = 4,
  GS = 5,
  Other = 0,
}
enum Specialty {
  ENT = 3,
  GS = 5,
  TO = 6,
  Other = 0,
}
export interface InvitePatientProps {
  onFinish?: () => void;
  removeView: () => void;
}

export const InvitePatient = ({ onFinish, removeView }: InvitePatientProps) => {
  const isDay0Enabled = useFeatureFlag("showNewHomeScreen");

  const dayOffset = isDay0Enabled ? 0 : 1;

  const [formState, setFormState] = React.useState<1 | 2 | 3 | 4>(1);
  const [isLoading, setLoading] = React.useState(false);

  const [dateOfBirth, setDateOfBirth] = React.useState(new Date());

  const [firstname, setFirstname] = React.useState("");
  const [lastname, setLastname] = React.useState("");

  const [diagnosistypeSNOMED, setDiagnosistypeSNOMED] = React.useState({ id: "", name: "" });
  const [operationtypeSNOMED, setOperationtypeSNOMED] = React.useState({ id: "", name: "" });

  const [email, setEmail] = React.useState(null);
  const [gsm, setGsm] = React.useState("");

  const [gsmcountrycode, setGsmcountrycode] = React.useState("44");
  const [countrycode, setCountrycode] = React.useState("GB");

  const [caretakergsmcountrycode, setCaretakergsmcountrycode] = React.useState("44");
  const [caretakercountrycode, setCaretakerCountrycode] = React.useState("GB");

  const [gender, setGender] = React.useState(0);
  const [operationtype, setOperationType] = React.useState(-1);
  const [operationtypeOpt, setOperationTypeOpt] = React.useState<{ value: any; label: string }[]>([]);
  const [specialty, setSpecialty] = React.useState(-1);
  const [height, setHeight] = React.useState("");
  const [weight, setWeight] = React.useState("");
  const [nhsnumber, setNhsnumber] = React.useState("");
  const [clinicalTeamId, setClinicalTeamId] = React.useState<number>();
  const [clinicalTeams, setClinicalTeams] = React.useState<any[]>([]);
  const [operationDate, setOperationDate] = React.useState(new Date());

  const [caretakerfirstname, setCaretakerfirstname] = React.useState("");
  const [caretakerlastname, setCaretakerlasttname] = React.useState("");
  const [caretakergsm, setCaretakergsm] = React.useState("");
  const [caretakeremail, setCaretakeremail] = React.useState(null);
  const [errorText, setErrorText] = React.useState("");
  const [patientActivities, setPatientActivities] = React.useState<any[] | null>(null);
  const [restoreActivitesToggle, setRestoreActivitesToggle] = React.useState(false);
  const [clearActivities, setClearActivities] = React.useState<string[]>([]);
  const [smsConsent, setSmsConsent] = React.useState<boolean | null>(true);
  const [week, setWeek] = React.useState(1);
  const updateSpecialty = React.useCallback(
    (specialty: Specialty) => {
      const OTOptions = {
        default: { value: "", label: "" },
        1: { value: 1, label: "Knee" },
        2: { value: 2, label: "Hip" },
        3: { value: 3, label: "ENT" },
        4: { value: 4, label: "Foot&Ankle" },
        5: { value: 5, label: "General Surgery" },
        0: { value: 0, label: "Other" },
      };
      switch (specialty) {
        case Specialty.ENT:
          setSpecialty(specialty);
          setOperationType(OperationType.ENT);
          setOperationTypeOpt([OTOptions.default, OTOptions[OperationType.ENT]]);
          break;
        case Specialty.TO:
          setSpecialty(specialty);
          setOperationType("");
          setOperationTypeOpt([
            OTOptions.default,
            OTOptions[OperationType.Knee],
            OTOptions[OperationType.Hip],
            OTOptions[OperationType.FootAndAnkle],
          ]);
          break;
        case Specialty.GS:
          setSpecialty(specialty);
          setOperationType(OperationType.GS);
          setOperationTypeOpt([OTOptions.default, OTOptions[OperationType.GS]]);
          break;
        case Specialty.Other:
          setSpecialty(specialty);
          setOperationType(OperationType.Other);
          setOperationTypeOpt([OTOptions.default, OTOptions[OperationType.Other]]);
          break;
        default:
          break;
      }
    },
    [setSpecialty, setOperationType],
  );
  const allActivities = [
    "photo",
    "video",
    "ssis",
    "eq5d5l",
    "wqol",
    "fhs",
    "ohsoks",
    "physio_knee",
    "physio_hip",
    "vhi10",
    "ess6",
    "snot",
    "voice",
    "painscore",
    "prems",
    "ohsoks",
    "phq9",
  ];

  function onPrev() {
    setWeek(Math.max(1, week - 1));
  }
  function onNext() {
    setWeek(Math.min(52, week + 1));
  }

  const inputs = document.querySelectorAll("input");

  // get clinical teams
  useAsyncEffect(async () => {
    try {
      const res = await requests.get("/clinicians/getcliniciansteams");
      setClinicalTeams(res.data);
    } catch (err: any) {
      console.error("Failed to retrieve clinical teams:");
      console.error(err);
      toastError("Failed to retrieve clinical teams. Please check your internet connection or try again later.");
    }
  }, []);

  // get patient activities template
  useAsyncEffect(async () => {
    if (clinicalTeamId && operationtype >= 0)
      try {
        const careplanRes = await requests.get(
          `/v1/clinicians/getcareplan?clinicalteam_id=${clinicalTeamId}&operation_type=${operationtype}`,
        );
        const careplan = careplanRes?.careplan;
        const schedule = careplan?.schedule;
        const emptyPlan365 = new Array(366).fill(0);
        const activities = emptyPlan365.map((_, index) => {
          const row: any = {};
          row.dayno = index;

          for (let i = 0; i < allActivities.length; i++) {
            row[allActivities[i]] = 0;
          }
          if (schedule === undefined) return row;

          for (const key in schedule) {
            if (schedule[key].includes(row.dayno)) {
              row[key.toString()] = 1;
            }
            if (clearActivities.includes(key)) {
              row[key.toString()] = 0;
            }
          }
          return row;
        });
        setPatientActivities(activities);
      } catch (err: any) {
        console.error("Failed to get patient activities template:");
        console.error(err);
        toastError(
          "Failed to get patient activities template. Please check your internet connection or try again later.",
        );
      }
  }, [operationtype, clinicalTeamId, restoreActivitesToggle, clearActivities]);

  const handleRestoreDefaultClick = () => {
    const confirmed = confirm("Are you sure you want to restore the default care plan?");
    if (confirmed) {
      setClearActivities([]);
      setRestoreActivitesToggle(!restoreActivitesToggle);
    }
  };

  const handleClearAllClick = () => {
    const confirmed = confirm("Are you sure you want to clear the care plan?");
    if (confirmed) {
      setClearActivities(allActivities);
    }
  };

  inputs.forEach((input) => {
    input.setAttribute("autocomplete", "off");
    input.setAttribute("autocorrect", "off");
    input.setAttribute("autocapitalize", "none");
    input.setAttribute("spellcheck", "false");
  });

  function validateForm() {
    setErrorText("");
    const firstnameError = validate("firstname", firstname);
    const lastnameError = validate("lastname", lastname);
    const genderError = validate("biggerthanzero", gender);
    const gsmError = validate("gsm2", gsm);
    const emailError = validate("emailoptional", email);
    const smsConsentError = countrycode === "US" ? validate("smsConsent", smsConsent) : null;
    const heightError = validate("heightoptional", height);
    const weightError = validate("weightoptional", weight);
    const operationquestionaretypeError = validate("biggerorequaltozero", operationtype);
    const specialtyError = validate("biggerorequaltozero", specialty);
    const nhsnumberError = validate("nhsnumber", nhsnumber);
    const clinicalteamError = validate("clinicalteam", clinicalTeamId);

    const caretakerfirstnameError =
      caretakerfirstname.length > 0 && validate("genericnameoptional", caretakerfirstname);
    const caretakerlastnameError = caretakerlastname.length > 0 && validate("genericnameoptional", caretakerlastname);

    const caretakergsmError = caretakergsm.length > 0 && validate("caretakergsm", caretakergsm);
    const caretakeremailError = validate("caretakeremail", caretakeremail);

    switch (formState) {
      case 1:
        if (
          !nhsnumberError &&
          !firstnameError &&
          !lastnameError &&
          !genderError &&
          !gsmError &&
          !emailError &&
          !smsConsentError
        ) {
          setFormState(2);
        } else {
          setErrorText(
            "Please check fields: " +
              (nhsnumberError ? "NHS number - " : "") +
              (firstnameError ? " first name" + " - " : "") +
              (lastnameError ? "last name - " : "") +
              (genderError ? " gender  - " : "") +
              (gsmError ? " phone number - " : "") +
              (emailError ? " email -" : "") +
              (smsConsentError ? " sms consent - " + smsConsentError : ""),
          );
        }
        break;
      case 2:
        if (
          !heightError &&
          !weightError &&
          !operationquestionaretypeError &&
          !specialtyError &&
          !clinicalteamError &&
          diagnosistypeSNOMED.id &&
          operationtypeSNOMED.id
        ) {
          setFormState(3);
        } else {
          setErrorText(
            "Please check fields: " +
              (heightError ? " height - " : "") +
              (weightError ? " weight -" : "") +
              (!diagnosistypeSNOMED.id ? " diagnosis" + " - " : "") +
              (!operationtypeSNOMED.id ? " operation type" + " - " : "") +
              (operationquestionaretypeError ? " operation questionnaire" + " - " : "") +
              (specialtyError ? " specialty" + " - " : "") +
              (clinicalteamError ? " clinical team  - " : ""),
          );
        }
        break;
      case 3:
        if (!caretakerfirstnameError && !caretakerlastnameError && !caretakergsmError && !caretakeremailError) {
          setFormState(4);
        } else {
          setErrorText(
            "Please check fields: " +
              (caretakerfirstnameError ? " first name - " : "") +
              (caretakerlastnameError ? " last  name -" : "") +
              (caretakergsmError ? " phone number" + " - " : "") +
              (caretakeremailError ? "  email" + " - " : ""),
          );
        }
        break;
    }
  }

  async function invitePatient() {
    setLoading(true);

    try {
      await requests.post("/clinicians/invitepatient", {
        firstname,
        lastname,
        gsmcountrycode,
        gsm,
        countrycode,
        email,
        gender,
        dateofbirth: Moment(dateOfBirth).format("DD/MM/YYYY"),
        height,
        weight,
        operationdate: Moment(operationDate).format("DD/MM/YYYY"),
        operationtype,
        nhsnumber,
        clinicalteamid: clinicalTeamId,
        caretakerfirstname,
        caretakerlastname,
        caretakeremail,
        caretakergsmcountrycode,
        caretakercountrycode,
        diagnosistypesnomed: diagnosistypeSNOMED.id,
        operationtypesnomed: operationtypeSNOMED.id,
        diagnosistypesnomedtext: diagnosistypeSNOMED.name,
        operationtypesnomedtext: operationtypeSNOMED.name,
        caretakergsm,
        patientactivities: patientActivities,
        smsConsent,
      });
      onFinish?.();
    } catch (err: any) {
      if (err.response?.status === 409) {
        alert("Invalid data: Patient with the phone number already exists.");
      } else if (err.response?.status === 422) {
        alert("Invalid data, please check the details inserted.");
      } else {
        alert("Unable to invite, please try later");
      }
    } finally {
      setLoading(false);
    }
  }

  const dimensions = Dimensions.get("window");

  function onToggleActivity(activity: string, day: number) {
    setPatientActivities((curr) => {
      const idx = curr?.findIndex((activity) => activity.dayno === day) ?? -1;

      const copy = curr!.slice();
      if (idx !== -1) {
        copy[idx][activity] = !copy[idx][activity];
      } else {
        copy.push({
          dayno: day,
          [activity]: true,
        });
      }
      return copy;
    });
  }

  return (
    <View style={styles.root}>
      <View style={styles.container}>
        <View style={styles.header}>
          <View style={styles.titleBar}>
            <POText fontFamily="LatoBold" fontSize={18} color="#1F2E99">
              Invite a new patient
            </POText>
            <POText flex={1} fontFamily="LatoBold" fontSize={14} textAlign="center" color="#FF0000">
              {errorText}
            </POText>
          </View>
          <Button
            left={<Image source={require("../assets/images/closecrossicon.png")} style={{ width: 10, height: 10 }} />}
            onClick={() => {
              removeView();
            }}
            className={css`
              width: 93px;
              border: 0;
              border-left: 1px solid #e8e9ed;
              border-radius: 0 8px 0 0;
              font-family: Lato;
              font-size: 13px;
            `}
            style={{
              background: "#FCF5EF",
              color: theme.primary,
            }}>
            Close
          </Button>
        </View>

        <View style={styles.bodyContainer}>
          <Stepper
            step={formState}
            handleRestoreDefaultClick={handleRestoreDefaultClick}
            handleClearAllClick={handleClearAllClick}
          />
          <View style={styles.body}>
            {formState === 1 && (
              <View>
                <Text style={styles.title2}>Insert personal details of patient</Text>

                <View style={{ flexDirection: "row", marginTop: 32 }}>
                  <View style={{ width: 250 }}>
                    <Text style={styles.title3}>NHS number</Text>
                    <View style={[styles.shadowinput, { width: 250 }]}>
                      <TextInput
                        style={styles.postopinput}
                        keyboardType="number-pad"
                        maxLength={10}
                        onChangeText={setNhsnumber}
                        value={nhsnumber}
                        returnKeyType="done"
                      />
                    </View>
                  </View>
                  <View style={{ width: 250, marginLeft: 18 }}>
                    <Text style={styles.title3}>First name</Text>
                    <View style={[styles.shadowinput, { width: 250 }]}>
                      <TextInput
                        style={[styles.postopinput, { textAlign: "left", width: 250 }]}
                        keyboardType="name-phone-pad"
                        maxLength={50}
                        numberOfLines={1}
                        onChangeText={(text) => {
                          setFirstname(text);
                        }}
                        value={firstname}
                        returnKeyType="done"
                      />
                    </View>
                  </View>
                  <View style={{ width: 250, marginLeft: 18 }}>
                    <Text style={styles.title3}>Last name</Text>
                    <View style={[styles.shadowinput, { width: 250 }]}>
                      <TextInput
                        style={[styles.postopinput, { textAlign: "left", width: 250 }]}
                        keyboardType="name-phone-pad"
                        maxLength={50}
                        numberOfLines={1}
                        onChangeText={(text) => {
                          setLastname(text);
                        }}
                        value={lastname}
                        returnKeyType="done"
                      />
                    </View>
                  </View>
                </View>

                <Text style={[styles.title3, { marginTop: 32 }]}>Gender</Text>
                <View style={{ flexDirection: "row", marginTop: 8 }}>
                  <PORadioGroup
                    selected={gender}
                    choices={[
                      { value: 1, label: "Male" },
                      { value: 2, label: "Female" },
                      { value: 3, label: "Other" },
                    ]}
                    onValueChange={setGender}
                    style={{ marginTop: 8 }}
                  />
                </View>

                <Text style={[styles.title3, { marginTop: 32, marginBottom: 8 }]}>Date of birth</Text>
                <DatePicker
                  selected={dateOfBirth}
                  onChange={(date) => {
                    setDateOfBirth(date!);
                  }}
                  dateFormat="dd/MM/yyyy"
                  todayButton="Today"
                />

                <View>
                  <Text style={[styles.title3, { marginTop: 32 }]}>Phone number</Text>
                  <View style={[styles.shadowinput, { width: 200 }]}>
                    <View style={{ width: 1, height: 48, backgroundColor: "#DFE1E6" }}></View>

                    <CountrySelector
                      countrycode={countrycode}
                      gsmcountrycode={gsmcountrycode}
                      setGsmcountrycode={(value) => {
                        setGsmcountrycode(value);
                      }}
                      setCountrycode={(value) => {
                        setCountrycode(value);
                      }}
                    />
                    <TextInput
                      keyboardType={"number-pad"}
                      onChangeText={(text) => {
                        setGsm(parseInt(text) ? parseInt(text).toString() : "");
                      }}
                      value={gsm}
                      maxLength={15}
                      style={{ color: "#1F2E99", fontFamily: "Lato", fontSize: 15, marginRight: 10 }}
                    />
                  </View>
                  {countrycode === "US" && (
                    <View style={styles.checkboxContainer}>
                      <CheckBox value={smsConsent} onValueChange={setSmsConsent} style={styles.checkbox} />
                      <Text style={styles.label}>Patient provided consent to receive SMS messages from Post Op.</Text>
                    </View>
                  )}
                </View>
                <View style={{ width: 250 }}>
                  <Text style={[styles.title3, { marginTop: 32 }]}>Email address (optional)</Text>

                  <View style={[styles.shadowinput, { width: 250 }]}>
                    <TextInput
                      style={[styles.postopinput, { textAlign: "left", width: 250 }]}
                      keyboardType="email-address"
                      maxLength={50}
                      numberOfLines={1}
                      onChangeText={(text) => {
                        setEmail(text || null);
                      }}
                      value={email}
                      returnKeyType="done"
                    />
                  </View>
                </View>
              </View>
            )}

            {formState === 2 && (
              <View>
                <Text style={styles.title2}>Add patient’s clinical details</Text>

                <View style={{ flexDirection: "row" }}>
                  <View style={{ width: 85 }}>
                    <Text style={[styles.title3, { marginTop: 24 }]}>Height (optional)</Text>

                    <View style={{ flexDirection: "row" }}>
                      <View style={[styles.shadowinput, { width: 60 }]}>
                        <TextInput
                          style={[styles.postopinput, { textAlign: "center", marginLeft: 0, width: 60 }]}
                          keyboardType="number-pad"
                          maxLength={3}
                          onChangeText={(text) => {
                            setHeight(parseInt(text) ? parseInt(text).toString() : "");
                          }}
                          value={height}
                          returnKeyType="done"
                        />
                      </View>
                      <Text style={[styles.title3, { marginTop: 25, marginLeft: 8 }]}>cm</Text>
                    </View>
                  </View>
                  <View style={{ width: 85, marginLeft: 42 }}>
                    <Text style={[styles.title3, { marginTop: 29 }]}>Weight (optional)</Text>

                    <View style={{ flexDirection: "row" }}>
                      <View style={[styles.shadowinput, { width: 60 }]}>
                        <TextInput
                          style={[styles.postopinput, { textAlign: "center", marginLeft: 0, width: 60 }]}
                          keyboardType="number-pad"
                          maxLength={3}
                          onChangeText={(text) => {
                            setWeight(parseInt(text) ? parseInt(text).toString() : "");
                          }}
                          value={weight}
                          returnKeyType="done"
                        />
                      </View>
                      <Text style={[styles.title3, { marginTop: 25, marginLeft: 8 }]}>kg</Text>
                    </View>
                  </View>
                </View>
                <OperationAndDiagnosisInput
                  {...{ setDiagnosistypeSNOMED, setOperationtypeSNOMED, operationtypeSNOMED, diagnosistypeSNOMED }}
                />
                <View style={{ flexDirection: "row" }}>
                  <View>
                    <Text style={[styles.title3, { marginTop: 28, marginBottom: 8 }]}>Specialty</Text>
                    <SpecialtyInput onChange={updateSpecialty} value={specialty} />
                  </View>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <View>
                    <Text style={[styles.title3, { marginTop: 28, marginBottom: 16 }]}>Operation Date</Text>

                    <DatePicker
                      selected={operationDate}
                      onChange={(date) => {
                        setOperationDate(date!);
                      }}
                      dateFormat="dd/MM/yyyy"
                      todayButton="Today"
                    />
                  </View>
                  <View style={{ marginLeft: 40 }}>
                    <Text style={[styles.title3, { marginTop: 28, marginBottom: 8 }]}>Care Plan</Text>
                    <Select
                      value={String(operationtype)}
                      onValueChange={(itemValue) => {
                        setOperationType(parseInt(itemValue));
                      }}
                      style={{ marginTop: 5 }}
                      options={operationtypeOpt}
                    />
                  </View>
                </View>

                <Text style={[styles.title3, { marginTop: 28, marginBottom: 8 }]}>Clinical Team</Text>

                <Select
                  value={String(clinicalTeamId)}
                  onValueChange={(itemValue) => {
                    setClinicalTeamId(parseInt(itemValue));
                  }}
                  style={{ marginTop: 5 }}
                  options={[{ value: "", label: "" }, ...clinicalTeams.map((c) => ({ value: c.id, label: c.name }))]}
                />
              </View>
            )}

            {formState === 3 && (
              <View>
                <Text style={styles.title2}>Insert details about the Caregiver of the patient (optional)</Text>
                <Text style={[styles.title4, { marginTop: 7 }]}>
                  A <strong>Caregiver</strong> is a trusted person for the Patient, someone who can help them complete
                  the tasks whenever they might need it.
                </Text>

                <View style={{ flexDirection: "row", gap: 18 }}>
                  <View>
                    <Text style={[styles.title3, { marginTop: 32 }]}>First name</Text>
                    <View style={[styles.shadowinput, { width: 250 }]}>
                      <TextInput
                        style={[styles.postopinput, { textAlign: "left", width: 250 }]}
                        keyboardType="name-phone-pad"
                        maxLength={50}
                        numberOfLines={1}
                        onChangeText={(text) => {
                          setCaretakerfirstname(text);
                        }}
                        value={caretakerfirstname}
                        returnKeyType="done"
                      />
                    </View>
                  </View>
                  <View>
                    <Text style={[styles.title3, { marginTop: 29 }]}>Last name</Text>
                    <View style={[styles.shadowinput, { width: 250 }]}>
                      <TextInput
                        style={[styles.postopinput, { textAlign: "left", width: 300 }]}
                        keyboardType="name-phone-pad"
                        maxLength={50}
                        numberOfLines={1}
                        onChangeText={(text) => {
                          setCaretakerlasttname(text);
                        }}
                        value={caretakerlastname}
                        returnKeyType="done"
                      />
                    </View>
                  </View>
                </View>

                <View style={{ flexDirection: "row", gap: 18 }}>
                  <View>
                    <Text style={[styles.title3, { marginTop: 32 }]}>Phone number</Text>
                    <View style={[styles.shadowinput, { width: 250 }]}>
                      <CountrySelector
                        countrycode={"GB"}
                        setGsmcountrycode={(value) => {
                          setCaretakergsmcountrycode(value);
                        }}
                        setCountrycode={(value) => {
                          setCaretakerCountrycode(value);
                        }}
                      />
                      <TextInput
                        onChangeText={(text) => {
                          setCaretakergsm(text);
                        }}
                        value={caretakergsm}
                        maxLength={15}
                        style={{ color: "#1F2E99", fontFamily: "Lato", fontSize: 15, marginRight: 10 }}
                      />
                    </View>
                  </View>

                  <View>
                    <Text style={[styles.title3, { marginTop: 32 }]}>Email address</Text>
                    <View style={[styles.shadowinput, { width: 250 }]}>
                      <TextInput
                        style={[styles.postopinput, { textAlign: "left", width: dimensions.width - 85 }]}
                        keyboardType="email-address"
                        maxLength={50}
                        numberOfLines={1}
                        onChangeText={(text) => {
                          setCaretakeremail(text || null);
                        }}
                        value={caretakeremail}
                        returnKeyType="done"
                      />
                    </View>
                  </View>
                </View>
                <Text style={[styles.title4, { marginTop: 40 }]}>
                  You can choose to skip this section and complete the onboarding!
                </Text>
              </View>
            )}

            {formState === 4 && (
              <View>
                <Text style={[styles.title2, { marginTop: 24 }]}>Review care plan of the patient</Text>
                <CarePlanWeekSelector withLabel week={week} onPrev={onPrev} onNext={onNext} style={{ top: 23 }} />
                <CarePlan
                  editmode
                  operationtype={operationtype}
                  patientActivities={patientActivities ?? []}
                  today={-1}
                  fromDay={dayOffset + (week - 1) * 7}
                  toDay={dayOffset + (week + 1) * 7 + 1}
                  onTogglePoint={onToggleActivity}
                  clinicalTeamId={clinicalTeamId}
                  style={{ marginLeft: -21, marginTop: 25, overflow: "hidden", width: 862 }}
                />
              </View>
            )}
          </View>
        </View>

        <View style={styles.controlBar}>
          {formState !== 1 && (
            <Button
              variant="outlined"
              disabled={isLoading}
              onClick={() => {
                setFormState((curr) => (curr as any) - 1);
              }}
              style={{ width: 250 }}>
              Back
            </Button>
          )}
          <Button
            variant="outlined"
            loading={isLoading}
            onClick={() => {
              if (formState < 4) {
                validateForm();
              } else {
                invitePatient();
              }
            }}
            style={{ width: 250 }}>
            {getNextButtonText(formState)}
          </Button>
        </View>
      </View>
    </View>
  );
};

function Stepper({
  step,
  handleRestoreDefaultClick,
  handleClearAllClick,
}: {
  step: number;
  handleRestoreDefaultClick: () => void;
  handleClearAllClick: () => void;
}) {
  function getLabelStyle(buttonId: number) {
    if (buttonId === step) return styles.stepActive;
    if (buttonId < step) return styles.stepDone;
    return styles.stepPending;
  }

  return (
    <View style={styles.stepper}>
      <StepperImage step={step} />
      <View style={{ marginTop: 11, marginLeft: 15 }}>
        <POText style={getLabelStyle(1)}>Personal details</POText>
        <POText style={getLabelStyle(2)}>Clinical details</POText>
        <POText style={getLabelStyle(3)}>Caregiver details</POText>
        <POText style={getLabelStyle(4)}>Patient care plan</POText>
        <TouchableOpacity onPress={handleClearAllClick} style={{ flexDirection: "row", alignItems: "center" }}>
          <Text style={getLabelStyle(4)}>• </Text>
          <POText style={[styles.underline, getLabelStyle(4)]}>Clear All</POText>
        </TouchableOpacity>
        <TouchableOpacity onPress={handleRestoreDefaultClick} style={{ flexDirection: "row", alignItems: "center" }}>
          <Text style={getLabelStyle(4)}>• </Text>
          <POText style={[styles.underline, getLabelStyle(4)]}>Restore Default</POText>
        </TouchableOpacity>
      </View>
    </View>
  );
}

function StepperImage({ step }: { step: number }) {
  let source: number;
  switch (step) {
    case 1:
      source = require("../assets/images/invitepatientprogess1.png");
      break;
    case 2:
      source = require("../assets/images/invitepatientprogess2.png");
      break;
    case 3:
      source = require("../assets/images/invitepatientprogess3.png");
      break;
    case 4:
      source = require("../assets/images/invitepatientprogess4.png");
      break;
    default:
      console.error(`Invalid step: ${step}`);
      source = require("../assets/images/invitepatientprogess1.png");
  }

  return (
    <Image
      source={source}
      style={{
        width: 32,
        height: 164,
        marginTop: 33,
        marginLeft: 24,
      }}
    />
  );
}

function getNextButtonText(formState: 1 | 2 | 3 | 4) {
  switch (formState) {
    case 1:
      return "Next, insert clinical details";
    case 2:
      return "Next, insert caregiver details";
    case 3:
      return "Next, review care plan";
    case 4:
      return "Send SMS invitation";
  }
}

const styles = StyleSheet.create({
  root: {
    display: "flex",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(52, 52, 52, 0.8)",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: 1130,
    height: 726,
    backgroundColor: "#ffffff",
    borderRadius: 8,
  },

  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    width: "100%",
    height: 70,
    borderBottomColor: "#E8E9ED",
    borderBottomWidth: 1,
  },
  titleBar: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    gap: 24,
    alignSelf: "center",
    alignItems: "baseline",
    paddingHorizontal: 24,
  },
  bodyContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "stretch",
  },
  body: {
    flex: 1,
    paddingHorizontal: 20,
    paddingBottom: 20,
    overflowY: "scroll",
  },
  controlBar: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: 74,
    alignItems: "center",
    justifyContent: "flex-end",
    gap: 24,
    paddingHorizontal: 24,
    paddingVertical: 8,
    borderTopWidth: 1,
    borderColor: "#E8E9ED",
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },

  stepper: {
    display: "flex",
    flexDirection: "row",
    minWidth: 250,
    borderRightWidth: 1,
    borderRightColor: "#E8E9ED",
  },

  select: {
    marginLeft: 20,
    //  "text-align":"center","text-align-last":"center",
    "-moz-appearance": "none",
    "-webkit-appearance": "none",
    appearance: "none",
    backgroundImage:
      "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAVCAYAAAAuJkyQAAAAmUlEQVR42r3OwQmAQAxE0S3JTrUDvairl6ANBG1MySFeFrz8kIGBZRnIK55ZtLOWnPzf3c67r8f1WO2dhannPTZ3TeefXhtmYrxm+UAARTAtyLLKNTEUxyyig28gimNW0akZcxTHAFQCBqAwJgEFMACFMRkojuEojolHcUw8CmLiUQCTgAIYhAKYJBTH8FTRAWLis4t21hKQF5HBtPSQyO2PAAAAAElFTkSuQmCC)",
    backgroundPosition: "87%",
    backgroundSize: "12px 7px",
    "background-repeat": "no-repeat",
    borderWidth: 0,
    width: 163,
    height: 24,
    borderRadius: 20,
    fontFamily: "Lato",
    fontSize: 15,
    color: "#1F2E99",
    textAlign: "center",
  },

  stepPending: {
    fontFamily: "Lato",
    fontSize: 15,
    color: "#959DAD",
    marginTop: 27,
    height: 18,
  },
  stepDone: {
    fontFamily: "Lato",
    fontSize: 15,
    color: "#1F2E99",
    marginTop: 27,
    height: 18,
  },
  stepActive: {
    fontFamily: "LatoBold",
    fontSize: 15,
    color: "#1F2E99",
    marginTop: 27,
    height: 18,
  },

  underline: {
    textDecorationLine: "underline",
  },

  postopinput: {
    flex: 1,
    outlineWidth: 0,
    color: "#1F2E99",
    fontFamily: "Lato",
    fontSize: 15,
    marginRight: 10,
    marginLeft: 20,
  },
  countryflag: {
    width: 26,
    height: 18,
    marginHorizontal: 16,
    marginVertical: 19,
  },
  shadowinput: {
    marginTop: 8,
    height: 48,
    flexDirection: "row",
    borderRadius: 15,
    shadowColor: "#818181",
    backgroundColor: "#FFFFFF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
  title2: {
    fontSize: 15,
    fontFamily: "LatoBold",
    color: "#1F2E99",
    marginTop: 32,
  },

  calendaricon: {
    width: 18,
    height: 18,
  },
  title3: {
    fontSize: 13,
    fontFamily: "Lato",
    color: "#798599",
  },
  title4: {
    fontSize: 13,
    fontFamily: "Lato",
    color: "#1F2E99",
  },
  filtercloseicon: {
    width: 8,
    height: 8,
  },
  checkboxContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 20,
    marginTop: 20,
  },
  checkbox: {
    alignSelf: "center",
  },
  label: {
    margin: 8,
  },
  colContainer: {
    display: "flex",
    flexDirection: "column",
  },
});
