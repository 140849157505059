/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */

export interface RequestOptions {
  method: "GET" | "POST" | "PUT" | "DELETE" | "OPTIONS";
  url: string;
  query?: Record<string, string | number | boolean>;
  headers?: Record<string, string>;
  /** Whether to use authentication. By default uses authentication when logged in. */
  auth?: boolean;
  body?: any;
  contentType?: "json" | "text";
  /**
   * How the response is automatically processed.
   *
   * - If `json`, the response is parsed as JSON and the result is returned.
   * - If `text`, the response is returned as raw text.
   * - If `response`, the response object itself is returned without further processing. This includes any status code errors.
   */
  responseType?: "json" | "text" | "response";
  credentials?: "omit" | "same-origin" | "include";
}

export type GetRequestOptions = Omit<RequestOptions, "url" | "method" | "body">;
export type PostRequestOptions = Omit<RequestOptions, "url" | "method" | "body">;

export type ProcessedResponseResult<T extends ResponseType> = T extends "text" ? string : any;

export async function request(options: RequestOptions) {
  const token: string | undefined = (global as any).token;
  const baseurl: string = (global as any).apiurl;
  const headers: any = {};

  const { method, url, query = {}, auth = !!token, contentType = "json", responseType = "json" } = options;

  let body;
  if (contentType === "json") {
    headers["Content-Type"] = "application/json";
    body = JSON.stringify(options.body);
  } else if (contentType === "text") {
    headers["Content-Type"] = "text/plain";
    body = "" + options.body;
  } else throw Error(`Unsupported content type: ${contentType as string}`);

  if (auth) {
    headers.Authorization = `Bearer ${token}`;
  }

  const response = await fetch(`${baseurl}${url}${getQueryString(query)}`, {
    method,
    headers: {
      ...headers,
      ...options.headers,
    },
    body,
    credentials: options.credentials,
  });

  if (responseType === "response") return response;

  // default error handling simply logs the response and throws an error.
  if (!response.ok) {
    throw await RequestError.fromResponse(response);
  }

  if (responseType === "json") {
    const data = await response.json();
    if ("result" in data && !data.result) throw Object.assign(Error(data.message ?? "Unknown error"), { data });
    return data;
  } else {
    return await response.text();
  }
}

export async function get(url: string, options: GetRequestOptions = {}) {
  return await request({
    ...options,
    url,
    method: "GET",
  });
}

export async function post(url: string, body: any = {}, options: PostRequestOptions = {}) {
  return await request({
    ...options,
    url,
    method: "POST",
    body,
  });
}

export async function put(url: string, body: any = {}, options: PostRequestOptions = {}) {
  return await request({
    ...options,
    url,
    method: "PUT",
    body,
  });
}

export async function del(url: string, options: PostRequestOptions = {}) {
  return await request({
    ...options,
    url,
    method: "DELETE",
  });
}

function getQueryString(query: Record<string, string | number | boolean>) {
  const parts = Object.entries(query).map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
  if (!parts.length) return "";
  return "?" + parts.join("&");
}

export class RequestError extends Error {
  constructor(public readonly response: Response, message?: string) {
    super(message || response.statusText);
    this.name = "RequestError";
  }

  static async fromResponse(response: Response) {
    let message = "";
    const contentType = response.headers.get("Content-Type") ?? "text/plain";
    if (contentType.startsWith("application/json")) {
      message = (await response.json()).message;
    } else {
      message = await response.text();
    }

    message = message || response.statusText;
    return new RequestError(response, message);
  }

  get status() {
    return this.response.status;
  }
}
