import { css, cx } from "@emotion/css";
import type { CSSProperties, ComponentType } from "react";
import React, { forwardRef, useCallback } from "react";

export interface ClickableProps {
  children?: React.ReactNode;
  as?: string | ComponentType;
  disabled?: boolean;
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
}

const Clickable = forwardRef<HTMLElement, ClickableProps>(
  ({ children, as: _Component = "button", disabled, className, style, onClick }, ref) => {
    const Component: any = _Component;

    const handleClick = useCallback(() => {
      if (disabled) return;
      onClick?.();
    }, [onClick]);

    return (
      <Component
        ref={ref}
        className={cx(
          {
            [styles.root]: true,
            "POClickable-root": true,
            "POClickable-disabled": disabled,
          },
          className,
        )}
        style={style}
        onClick={handleClick}>
        {children}
      </Component>
    );
  },
);
export default Clickable;

const styles = Object.freeze({
  root: css`
    font-size: inherit;
    color: inherit;
    padding: 0;
    background: transparent;
    border: 0;
    cursor: pointer;
    &.POClickable-disabled {
      cursor: default;
    }
  `,
});
