import * as React from "react";
import { Text, View, StyleSheet, Image } from "react-native";

export interface ValueChangeProps {
  deltavalue?: number;
  margintop?: number;
  marginleft?: number;
  oldValue?: number;
  newValue?: number;
}

export const ValueChange = (props: ValueChangeProps): JSX.Element => {
  if (props.deltavalue) {
    return (
      <View
        style={[
          { flexDirection: "row", alignItems: "center" },
          props.margintop && { marginTop: props.margintop },
          props.marginleft && { marginLeft: props.marginleft },
        ]}>
        {props.deltavalue > 0 && (
          <Image
            source={require("../assets/images/dashboardarrowup.png")}
            style={{ width: 5, height: 10, marginRight: 5, marginTop: 2 }}
          />
        )}

        {props.deltavalue < 0 && (
          <Image
            source={require("../assets/images/dashboardarrowdown.png")}
            style={{ width: 5.3, height: 12, marginRight: 5, marginTop: 4 }}
          />
        )}

        {!props.deltavalue && props.deltavalue !== 0 && <Text style={styles.titlegrey}>-</Text>}
        {props.deltavalue > 0 && <Text style={styles.titlegreen}>+{props.deltavalue}% </Text>}
        {props.deltavalue < 0 && <Text style={styles.titlered}>{props.deltavalue}% </Text>}
        {props.deltavalue === 0 && <Text style={styles.titlegrey}> {props.deltavalue}% </Text>}
      </View>
    );
  }
  const difference = (props.newValue ?? 0) - (props.oldValue ?? 0);
  const percentage =
    (props.oldValue ?? 0) !== 0
      ? Math.round(((props.newValue ?? 0) - (props.oldValue ?? 0)) / (props.oldValue ?? 0))
      : (props.newValue ?? 0) > 0
      ? "100"
      : "0";
  return (
    <div
      style={{
        flexDirection: "row",
        alignItems: "center",
        marginTop: props.margintop ?? 0,
        marginLeft: props.marginleft ?? 0,
      }}>
      {difference > 0 && (
        <img
          src={require("../assets/images/dashboardarrowup.png")}
          alt="growth"
          style={{ width: 5, height: 10, marginRight: 5, marginTop: 2 }}
        />
      )}
      {difference < 0 && (
        <img
          src={require("../assets/images/dashboardarrowdown.png")}
          alt="decrease"
          style={{ width: 5, height: 10, marginRight: 5, marginTop: 2 }}
        />
      )}
      {difference > 0 && <Text style={styles.titlegreen}>+{percentage}%</Text>}
      {difference < 0 && <Text style={styles.titlered}>{percentage}%</Text>}
      {difference === 0 && <Text style={styles.titlegrey}>No change</Text>}
    </div>
  );
};

const styles = StyleSheet.create({
  titlegrey: {
    fontFamily: "LatoBold",
    fontSize: 13,
    color: "#aaaaaa",
  },
  titlegreen: {
    fontFamily: "LatoBold",
    fontSize: 13,
    color: "#6DD803",
  },

  titlered: {
    fontFamily: "LatoBold",
    fontSize: 13,
    color: "#FD3E3E",
    marginTop: 5,
  },
});
