import React, { useState, useEffect } from "react";
import {
  Animated,
  Text,
  TextInput,
  TextProps,
  View,
  StyleSheet,
  ImageBackground,
  Dimensions,
  Image,
  TouchableOpacity,
} from "react-native";
import { OrangeButton } from "./OrangeButton";
import { Hairline } from "./Hairline";
import { OrangeBorderButton } from "./OrangeBorderButton";
import { A } from "@expo/html-elements";
import validate from "../screens/validate_wrapper";
import { useNavigation } from "@react-navigation/native";
import { inject, observer } from "mobx-react";
import * as ImagePicker from "expo-image-picker";
import CountrySelector from "../components/CountrySelector";
import Hoverable from "../navigation/Hoverable";

const UpdatePostopAdmin = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState(null);

  const [errorText, setErrorText] = React.useState("");
  const [firstname, setFirstname] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [email, setEmail] = React.useState(null);
  //    const [gsm, setGsm] = React.useState('');
  //   const [gsmcountrycode, setGsmcountrycode] = React.useState('');
  //   const [countryCode, setCountryCode] = useState("UK");
  const [photourl, setPhotourl] = React.useState("");
  const [image, setImage] = useState();

  const navigation = useNavigation();

  const titleCase = (str) => {
    return str.replace(/\w\S*/g, (t) => {
      return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase();
    });
  };
  const pickImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      base64: false,
      aspect: [4, 4],
      quality: 0.85,
    });

    console.log(result);

    if (!result.cancelled) {
      console.log(result);
      setImage(result?.assets && result.assets[0]?.uri);
    }
  };

  function base64ToBlob(base64, mime) {
    mime = mime || "";
    const sliceSize = 1024;
    const byteChars = window.atob(base64);
    const byteArrays = [];

    for (let offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
      const slice = byteChars.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: mime });
  }

  function validateForm() {
    setIsLoading(true);

    setErrorText("");
    const firstnameError = validate("firstname", firstname);
    const lastnameError = validate("lastname", lastname);
    //     const gsmError = validate('gsm2', gsm);

    if (!firstnameError && !lastnameError) {
      // updateprofile();
      sendform();
    } else {
      setIsLoading(false);

      alert(
        "\n" +
          (firstnameError ? "\n" + firstnameError + "\n" : "") +
          (lastnameError ? "\n" + lastnameError + "\n" : "") +
          "\n",
      );
      //   setErrorText((emailError?"\n"+emailError+"\n":"")+(passwordError?"\n"+passwordError+"\n":""));
    }
  }

  function sendform() {
    console.log("updating profile");

    let fileType;

    const formData = new FormData();
    formData.append("firstname", firstname);
    formData.append("lastname", lastname);
    //  formData.append('gsm', gsm);
    //   formData.append('countrycode', countryCode);
    //    formData.append('gsmcountrycode', gsmcountrycode);

    if (image) {
      const base64ImageContent = image.replace(/^data:image\/(png|jpg|jpeg);base64,/, "");

      const blob = base64ToBlob(base64ImageContent, "image/jpg");

      const lowerCase = base64ImageContent.toLowerCase();
      if (lowerCase.substring(0, 20).indexOf("png") !== -1) fileType = "png";
      else if (lowerCase.indexOf("jpg") !== -1 || lowerCase.indexOf("jpeg") !== -1) {
        fileType = "jpg";
      }

      formData.append("image", blob, "test." + fileType);
    }
    console.log(formData);
    fetch(global.apiurl + "/postopadmins/updateprofile", {
      method: "POST",
      headers: {
        Authorization: global.tokenparam || "",
      },
      body: formData,
    })
      .then(async (res) => {
        setIsLoading(false);

        if (res.status === 422) {
          throw new Error("Request failed.");
        }
        if (res.status !== 200 && res.status !== 201) {
          console.log("Error!");
          throw new Error("Could not authenticate you!");
        }
        return await res.json();
      })
      .then((resData) => {
        console.log(resData);
        if (resData.result) {
          console.log("DONE");

          if (image) {
            // props.userstore.setUserPhotoThumbnailURL(resData.photourl);
            props.userstore.setFullLoggedinIsAdmin(
              props.userstore.sessiontoken,
              resData.photourl,
              firstname,
              lastname,
              3,
              props.userstore.readonly
            );
          } else {
            props.userstore.setFullLoggedinIsAdmin(
              props.userstore.sessiontoken,
              null,
              firstname,
              lastname,
              3,
              props.userstore.readonly
            );
          }

          if (props.afterregister) {
          } else {
            setTimeout(function () {
              location.href = "/postopadmin/profile";
            }, 800);
          }
        } else {
          alert("xxUnable update, please check the form");
        }
      })
      .catch((err) => {
        alert("Unable update, please check the form");
        console.log(err);
      });
  }
  function updateprofile() {
    console.log("updating profile");

    fetch(global.apiurl + "/postopadmins/updateprofile", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
      body: JSON.stringify({
        email,
        firstname,
        lastname,
      }),
    })
      .then(async (res) => {
        if (res.status === 422) {
          throw new Error("Request failed.");
        }
        if (res.status !== 200 && res.status !== 201) {
          console.log("Error!");
          throw new Error("Could not authenticate you!");
        }
        return await res.json();
      })
      .then((resData) => {
        console.log(resData);
        if (resData.result) {
          console.log("DONE");
          if (props.afterregister) {
            // props.userstore.setLoggedin(props.userstore.sessiontoken,resData,photourl&&"");
            props.userstore.setFullLoggedin(props.userstore.sessiontoken, resData, photourl, firstname, lastname);
          } else {
            props.userstore.setFullLoggedin(props.userstore.sessiontoken, resData, photourl, firstname, lastname);

            // navigation.push('ProfileScreen');
          }
        } else {
          alert("Unable change password\n\nPlease check your password");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    async function fetchData() {
      fetch(global.apiurl + "/postopadmins/getprofile", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: global.tokenparam || "",
        },
      })
        .then(async (response) => await response.json())
        .then((resData) => {
          console.log(resData);
          if (resData.result) {
            /// setData(resData.user);

            setEmail(resData.user.email);
            setFirstname(resData.user.firstname);
            setLastname(resData.user.lastname);

            setPhotourl(resData.user.photourl);
            setData(resData.user);
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, [props]);

  return (
    <View>
      {props.afterregister && <Text style={styles.title1}>Complete setting up your profile</Text>}
      {!props.afterregister && <Text style={styles.title1a}>Your profile</Text>}
      <View style={{ flexDirection: "row", marginTop: 32 }}>
        <View style={[styles.whitebox, { width: 268, height: 298, marginBottom: 100, alignItems: "center" }]}>
          <View style={{ flexDirection: "row", marginTop: 32 }}>
            <Text style={styles.title2}>Profile picture</Text>
            <Hoverable>
              {(isHovered) => (
                <View>
                  <Image
                    style={{ marginLeft: 7, width: 16, height: 16 }}
                    source={require("../assets/images/infobutton.png")}
                  />
                  {isHovered && (
                    <Text
                      style={[
                        { color: "#FFFFFF", position: "absolute", top: -63, width: 100, left: 18, textAlign: "center" },
                        isHovered && { backgroundColor: "#aaaaaa", borderRadius: 10, padding: 5 },
                      ]}>
                      {" "}
                      {"- JPG file\n- Max filesize: 10MB"}
                    </Text>
                  )}
                </View>
              )}
            </Hoverable>
          </View>

          <View style={styles.bgimage}>
            {!(photourl || image) && (
              <Text style={{ fontFamily: "LatoBold", fontSize: 36, color: "#FFFFFF" }}>
                {(" " + firstname).charAt(1).toUpperCase()}
                {(" " + lastname).charAt(1).toUpperCase()}
              </Text>
            )}

            {photourl && !image && (
              <Image
                source={{ uri: global.imagepathurl + photourl }}
                style={{ width: 132, height: 132, borderRadius: 66 }}
              />
            )}
            {image && <Image source={{ uri: image }} style={{ width: 132, height: 132, borderRadius: 66 }} />}
          </View>
          {!(photourl || image) && (
            <OrangeBorderButton
              onPress={async () => {
                await pickImage();
              }}
              title="Upload a picture"
              width={144}
              style={{ marginTop: 26 }}
            />
          )}
          {(photourl || image) && (
            <OrangeBorderButton
              onPress={async () => {
                await pickImage();
              }}
              title="Change picture"
              width={144}
              style={{ marginTop: 26 }}
            />
          )}
        </View>

        <View style={[styles.whitebox, { width: 652, marginLeft: 20, marginBottom: 32, alignItems: "flex-start" }]}>
          <Text style={styles.title3}>Profile details</Text>

          <Hairline marginTop={24} />

          {props.afterregister && <Text style={styles.title4}>Personal details</Text>}
          {!props.afterregister && <Text style={styles.title4}>Edit personal details</Text>}

          {!props.afterregister && (
            <TouchableOpacity
              style={{ alignItems: "center", flexDirection: "row", position: "absolute", right: 23, top: 28 }}>
              <A href="/postopadmin/profile">
                <Image source={require("../assets/images/undoicon.png")} style={styles.undoicon} />
                <Text style={styles.title5}>Undo changes</Text>
              </A>
            </TouchableOpacity>
          )}
          <View style={{ flexDirection: "row", marginTop: 24, marginLeft: 24 }}>
            <View style={{ width: 250 }}>
              <Text style={styles.title2}>First name</Text>
              <View style={[styles.shadowinput, { width: 250 }]}>
                <TextInput
                  style={[styles.postopinput, { textAlign: "left", width: 250 }]}
                  keyboardType="name-phone-pad"
                  maxLength={50}
                  numberOfLines={1}
                  onChangeText={(text) => {
                    setFirstname(titleCase(text));
                  }}
                  value={firstname}
                  returnKeyType="done"
                />
              </View>
            </View>
            <View style={{ width: 250, marginLeft: 32 }}>
              <Text style={styles.title2}>Last name</Text>
              <View style={[styles.shadowinput, { width: 250 }]}>
                <TextInput
                  style={[styles.postopinput, { textAlign: "left", width: 250 }]}
                  keyboardType="name-phone-pad"
                  maxLength={50}
                  numberOfLines={1}
                  onChangeText={(text) => {
                    setLastname(titleCase(text));
                  }}
                  value={lastname}
                  returnKeyType="done"
                />
              </View>
            </View>
          </View>

          <View style={{ flexDirection: "row", marginTop: 24, marginLeft: 24 }}>
            <View style={{ width: 250 }}>
              <Text style={styles.title2}>Email address</Text>
              <View style={[styles.shadowinput, { width: 250 }]}>
                <TextInput
                  style={[styles.postopinput, { textAlign: "left", width: 250, maxWidth: 250, color: "#868686" }]}
                  value={email}
                />
              </View>
            </View>
          </View>

          <View
            style={{
              marginTop: 24,
              height: StyleSheet.hairlineWidth,
              backgroundColor: "#E8E9ED",
              borderBottomColor: "#E8E9ED",
              borderBottomWidth: StyleSheet.hairlineWidth,
              alignSelf: "stretch",
              width: "100%",
            }}></View>

          <OrangeButton
            disabled={isLoading}
            onPress={validateForm}
            title="Save profile details"
            width={250}
            style={{ marginTop: 16, marginBottom: 16, alignSelf: "center" }}
          />
        </View>
      </View>
    </View>
  );
};

export default UpdatePostopAdmin;

const styles = StyleSheet.create({
  postopinput: {
    flex: 1,
    outlineWidth: 0,
    color: "#1F2E99",
    fontFamily: "Lato",
    fontSize: 15,
    marginRight: 10,
    marginLeft: 20,
  },
  shadowinput: {
    marginTop: 15,
    height: 48,
    flexDirection: "row",
    borderRadius: 15,
    shadowColor: "#818181",
    backgroundColor: "#FFFFFF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },

  countryflag: {
    width: 26,
    height: 18,
    marginHorizontal: 16,
    marginVertical: 17,
  },
  undoicon: {
    width: 15.55,
    height: 14,
    marginRight: 8.45,
  },
  whitebox: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    shadowColor: "#818181",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
  title1: {
    fontSize: 32,
    fontFamily: "LatoBold",
    color: "#1F2E99",
    marginTop: 40,
  },
  title1a: {
    fontSize: 24,
    fontFamily: "LatoBold",
    color: "#1F2E99",
    marginTop: 32,
  },
  title2: {
    fontSize: 13,
    fontFamily: "Lato",
    color: "#798599",
  },
  title3: {
    fontSize: 18,
    fontFamily: "LatoBold",
    color: "#1F2E99",
    marginTop: 24,
    marginLeft: 24,
  },
  title4: {
    fontSize: 15,
    fontFamily: "LatoBold",
    color: "#1F2E99",
    marginTop: 24,
    marginLeft: 24,
  },
  title5: {
    fontSize: 15,
    fontFamily: "Lato",
    color: "#1F2E99",
  },

  bgimage: {
    backgroundColor: "#36C2D5",
    borderColor: "#F2F3F7",
    borderWidth: 1,
    borderRadius: 66,
    width: 132,
    height: 132,
    aspectRatio: 1,
    marginTop: 18,
    alignItems: "center",
    justifyContent: "center",
  },
});
