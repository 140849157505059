import moment from "moment";
import React, { useCallback, useState } from "react";

import PostopAdminLayout from "../../components/layouts/PostopAdminLayout";
import { toastError } from "../../components/Notifications";
import Input from "../../components/ui/Input";
import { useNav } from "../../hooks/useNav";
import { requests } from "../../misc";
import * as Users from "../../misc/user-utils";
import { withStore } from "../../misc/utils";

interface ClinicianList {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  country_code: string;
  phone: string;
  created_at: Date;
  disabled: boolean;
  invite_accepted: boolean;
  invitation_code: string;
}

const PostopAdminClinicianListScreen = withStore(({ userStore }) => {
  const nav = useNav<"clinician">();
  const [clinicians, setClinicians] = useState<ClinicianList[]>();

  const performSearch = useCallback(async (query: string) => {
    if (!query || query.length < 3) return;
    const resData = await requests.get("/postopadmins/search-clinicians", {
      query: {
        q: query,
      },
    });
    setClinicians(resData.data);
  }, []);

  const impersonate = useCallback(async (id: number): Promise<void> => {
    if (await Users.impersonate({ clinician_id: id })) {
      nav.navigate("DashboardScreen");
    } else {
      toastError("Failed to impersonate. Please see console for details.");
    }
  }, []);

  const bool = function (value: boolean): JSX.Element {
    return <span style={{ fontSize: 20, fontWeight: "bold" }}>{value ? "☒" : "☐"}</span>;
  };

  return (
    <PostopAdminLayout>
      <h1>Clinician Search</h1>
      <Input placeholder="Name, email or phone number" onChangeText={performSearch} className="search-box" />
      {clinicians && clinicians.length > 0 ? (
        <table className="POAdminResults">
          <thead>
            <tr>
              <th>Id</th>
              {global.environment === "LOCAL" && <th>Action</th>}
              <th>Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Invite Accepted</th>
              <th>Disabled</th>
              <th>Invitiation Code</th>
              <th>Created</th>
            </tr>
          </thead>
          <tbody>
            {clinicians.map((c) => (
              <tr key={c.id}>
                <td>{c.id}</td>
                {global.environment === "LOCAL" && (
                  <td>
                    <a
                      onClick={() => {
                        impersonate(c.id);
                      }}
                      href="#">
                      Impersonate
                    </a>
                  </td>
                )}
                <td>{`${c.first_name} ${c.last_name}`}</td>
                <td>{`+${c.country_code} ${c.phone}`}</td>
                <td>{c.email}</td>
                <td style={{ textAlign: "center" }}>{bool(c.invite_accepted)}</td>
                <td style={{ textAlign: "center" }}>{bool(c.disabled)}</td>
                <td>{c.invitation_code}</td>
                <td>
                  <span title={c.created_at.toString()}>{moment(c.created_at).fromNow()}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="no-results">Please enter a few letters or numbers from name, email or phone of clinician.</div>
      )}
    </PostopAdminLayout>
  );
});

export default PostopAdminClinicianListScreen;
