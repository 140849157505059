import React, { useCallback, useEffect } from "react";
import { FlatList, Image, View, Text, StyleSheet, TouchableOpacity, TextInput } from "react-native";
import { Table, TR, TD, TBody } from "@expo/html-elements";
import { MainFramePatient } from "../components/MainFramePatient";
import { Avatar } from "../components/Avatar";
import { OrangeBorderButton } from "../components/OrangeBorderButton";
import { UpdatePatient } from "../components/UpdatePatient";
import Hoverable from "../navigation/Hoverable";
import Moment from "moment";
import DatePicker from "../components/ui/DatePicker";
import { requests } from "../misc";
import { toastError, toastSuccess } from "../components/Notifications";
import { useRoute } from "@react-navigation/native";
import { withStore } from "../misc/utils";
import SpecialtyInput from "../components/SpecialtyInput";
import OperationAndDiagnosisInput from "../components/OperationAndDiagnosisInput";

const PatientOverviewScreen = withStore(({ userStore }) => {
  const params = useRoute()?.params ?? {};
  const { patientid } = params as any;

  const textInputRef = React.useRef();

  const [data, setData] = React.useState<any>();
  const [updatePatientVisible, setUpdatePatientVisible] = React.useState(false);
  const [clinicalteams, setClinicalteams] = React.useState<any>(null);
  const [clinician, setClinician] = React.useState(null);
  const [editPersonalDetails, setEditPersonalDetails] = React.useState(false);
  const [editClinicalDetails, setEditClinicalDetails] = React.useState(false);
  const [weight, setWeight] = React.useState(0);
  const [specialty, setSpecialty] = React.useState(-1);
  const [operationDate, setOperationDate] = React.useState(new Date());
  const [clinicalTeam, setClinicalTeam] = React.useState({});
  const [diagnosistypeSNOMED, setDiagnosistypeSNOMED] = React.useState({ id: "", name: "" });
  const [operationtypeSNOMED, setOperationtypeSNOMED] = React.useState({ id: "", name: "" });

  function updateweight() {
    if (!(weight > 0)) return;

    fetch(global.apiurl + "/clinicians/setpatientweight", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
      body: JSON.stringify({
        patientid,
        weight,
      }),
    })
      .then(async (res) => {
        if (res.status === 422) {
          throw new Error("Request failed.");
        }
        if (res.status !== 200 && res.status !== 201) {
          console.error("Error!");
          throw new Error("Could not authenticate you!");
        }
        return await res.json();
      })
      .then((resData) => {
        if (resData.result) {
          fetchData();
        } else {
          alert("Unable change password\n\nPlease check your password");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async function updateClinicalDetails(): Promise<void> {
    try {
      await requests.request({
        method: "PUT",
        url: `/v1/patients/${patientid | 0}/clinical-details`,
        auth: true,
        body: {
          operationDate: Date.UTC(operationDate.getFullYear(), operationDate.getMonth(), operationDate.getDate()),
          clinicalTeamId: clinicalTeam.id,
          diagnosistypesnomed: diagnosistypeSNOMED.id,
          operationtypesnomed: operationtypeSNOMED.id,
          diagnosistypesnomedtext: diagnosistypeSNOMED.name,
          operationtypesnomedtext: operationtypeSNOMED.name,
          specialty,
        },
      });
      toastSuccess("Clinical details updated.");
      setEditClinicalDetails(false);
      await fetchData();
    } catch (err: unknown) {
      toastError("Unable to update, please try later", err);
      console.error(err);
    }
  }
  const cancelEdit = useCallback(() => {
    setWeight(data?.weight);
    setEditClinicalDetails(false);
    setOperationDate(Moment(data.operationdate).toDate());
    setClinicalTeam(data.clinicalteam);
    setOperationtypeSNOMED({ name: data.operationtypesnomedtext, id: data.operationtypesnomed });
    setDiagnosistypeSNOMED({ name: data.diagnosistypesnomedtext, id: data.diagnosistypesnomed });
  }, [data]);
  async function fetchData() {
    fetch(global.apiurl + "/clinicians/getpatient", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
      body: JSON.stringify({
        patientid,
      }),
    })
      .then(async (response) => await response.json())
      .then((resData) => {
        if (resData.result) {
          setData(resData.data);
          setClinicalteams(resData.clinician.clinicalteams);
          setClinician(resData.clinician);
          setEditPersonalDetails(false);
          setEditClinicalDetails(false);
          setWeight(resData.data.weight);
          setSpecialty(resData.data.specialty);
          setOperationDate(Moment(resData.data.operationdate).toDate());
          setClinicalTeam(resData.data.clinicalteam);
          setOperationtypeSNOMED({ name: resData.data.operationtypesnomedtext, id: resData.data.operationtypesnomed });
          setDiagnosistypeSNOMED({ name: resData.data.diagnosistypesnomedtext, id: resData.data.diagnosistypesnomed });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  function calculageage(birthday: string): string {
    // birthday is a date
    const ageDifMs = Date.now() - Date.parse(birthday);
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970) <= 0 ? "-" : Math.abs(ageDate.getUTCFullYear() - 1970).toString();
  }
  function calculateBMI(height: number, weight: number): string {
    if (height <= 0 || weight <= 0) return "-";
    const bmi = Math.round((weight / height / height) * 100 * 100 * 10);
    return bmi > 0 ? (bmi / 10).toString() : "-";
  }

  return (
    <View style={styles.container}>
      {data && (
        <MainFramePatient
          userStore={userStore}
          selected={1}
          patient={data}
          clinician={clinician}
          patientlist
          style={{
            alignItems: "flex-start",
            flexDirection: "row",
            width: "100%",
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
          }}>
          <View
            style={{ paddingLeft: 63, paddingRight: 63, paddingTop: 20, alignSelf: "stretch", flexDirection: "row" }}>
            {updatePatientVisible && (
              <UpdatePatient
                patient={data}
                userStore={userStore}
                visible={updatePatientVisible}
                removeView={() => {
                  setUpdatePatientVisible(false);
                }}
                onFinish={() => {
                  fetchData();
                  setUpdatePatientVisible(false);
                }}
              />
            )}

            <View style={[styles.whitebox, { flex: 1, alignSelf: "stretch", height: 674, width: 556 }]}>
              <Text style={[styles.title3, { marginTop: 24, marginLeft: 24 }]}>Personal details</Text>

              {!editPersonalDetails && userStore?.readonly == 0 && (
                <TouchableOpacity
                  style={{ position: "absolute", right: 24, top: 28 }}
                  onPress={(x) => {
                    setEditPersonalDetails(true);
                    setTimeout(() => textInputRef.current.focus(), 200);
                  }}>
                  <Image style={{ width: 14.87, height: 14.87 }} source={require("../assets/images/editpenicon.png")} />
                </TouchableOpacity>
              )}

              {editPersonalDetails && userStore?.readonly == 0 && (
                <TouchableOpacity
                  style={{ position: "absolute", right: 24, top: 28 }}
                  onPress={(x) => {
                    setWeight(data?.weight);
                    setEditPersonalDetails(false);
                  }}>
                  <Text style={styles.title8}>Cancel</Text>
                </TouchableOpacity>
              )}
              {editPersonalDetails && (
                <TouchableOpacity
                  style={{ position: "absolute", right: 94, top: 28 }}
                  onPress={(x) => {
                    updateweight();
                  }}>
                  <Text style={styles.title8}>Save</Text>
                </TouchableOpacity>
              )}

              <View
                style={{
                  marginTop: 24,
                  height: StyleSheet.hairlineWidth,
                  backgroundColor: "#00C3FF",
                  borderBottomColor: "#00C3FF",
                  borderBottomWidth: StyleSheet.hairlineWidth,
                  alignSelf: "stretch",
                  width: "100%",
                }}></View>
              <Table style={{ marginLeft: 26, width: 390, marginTop: 24, marginRight: 26 }}>
                <TBody>
                  <TR style={styles.title7}>
                    <TD>Gender</TD>
                    <TD>Age</TD>
                    <TD></TD>
                  </TR>
                  <TR style={styles.title6}>
                    <TD>
                      <Text>
                        {data?.gender == 1 && "Male"}
                        {data?.gender == 2 && "Female"}
                        {data?.gender == 3 && "Other"}
                      </Text>
                    </TD>
                    <TD>{calculageage(data?.dateofbirth)}</TD>
                    <TD></TD>
                  </TR>
                  <TR style={styles.title7}>
                    <TD>Height</TD>
                    <TD>
                      <View style={{ flexDirection: "row" }}>
                        <Text>Weight</Text>
                        <Hoverable>
                          {(isHovered) => (
                            <View>
                              <Image
                                style={{ marginLeft: 8, width: 16, height: 16 }}
                                source={require("../assets/images/infobutton.png")}
                              />
                              <Text
                                style={[
                                  { color: "#FFFFFF", position: "absolute", top: -63, left: 18, textAlign: "center" },
                                  isHovered && { backgroundColor: "#aaaaaa", borderRadius: 10, padding: 5 },
                                ]}>
                                {" "}
                                {"last updated:\n" +
                                  Moment(data?.lastweightdate ? data?.lastweightdate : data?.createdAt).format(
                                    "DD/MM/YYYY",
                                  )}
                              </Text>
                            </View>
                          )}
                        </Hoverable>
                      </View>
                    </TD>
                    <TD>BMI</TD>
                  </TR>
                  <TR style={styles.title6}>
                    <TD>{data?.height}cm</TD>
                    <TD>
                      {!editPersonalDetails && <Text>{data?.weight} </Text>}
                      {editPersonalDetails && (
                        <TextInput
                          ref={textInputRef}
                          maxLength={3}
                          style={[styles.shadowinput, { width: 40 }]}
                          onChangeText={(text) => {
                            setWeight(text);
                          }}
                          value={String(weight)}></TextInput>
                      )}
                      kg
                    </TD>
                    <TD>{calculateBMI(data?.height, data?.weight)}</TD>
                  </TR>
                  <TR style={styles.title7}>
                    <TD>Phone number</TD>
                    <TD>Email address</TD>
                    <TD></TD>
                  </TR>
                  <TR style={styles.title6}>
                    <TD>
                      +{data?.gsmcountrycode} {data?.gsm}
                    </TD>
                    <TD>{data?.email}</TD>
                    <TD></TD>
                  </TR>
                  <TR style={styles.title7}>
                    <TD>NHS number</TD>
                    <TD>Hospital number</TD>
                    <TD></TD>
                  </TR>
                  <TR style={styles.title6}>
                    <TD>{data?.nhsnumber}</TD>
                    <TD>{data?.hospital?.number ? data?.hospital?.number : "-"}</TD>
                    <TD></TD>
                  </TR>
                </TBody>
              </Table>
              <OrangeBorderButton
                onPress={() => {
                  setUpdatePatientVisible(true);
                }}
                title="Edit patient"
                style={{ width: 200, marginLeft: 26 }}
              />

              <View
                style={{
                  marginTop: 20,
                  height: StyleSheet.hairlineWidth,
                  backgroundColor: "#00C3FF",
                  borderBottomColor: "#00C3FF",
                  borderBottomWidth: StyleSheet.hairlineWidth,
                  alignSelf: "stretch",
                  width: "100%",
                }}></View>

              <Table style={styles.tablesLayout}>
                <TBody>
                  <TR style={styles.title7}>
                    <TD>Caregiver fullname</TD>
                    <TD></TD>
                    <TD></TD>
                  </TR>
                  <TR style={styles.title6}>
                    <TD>
                      {data?.caretakerfirstname} {data?.caretakerlastname}
                    </TD>
                    <TD></TD>
                    <TD></TD>
                  </TR>
                  <TR style={styles.title7}>
                    <TD>Caregiver phone number</TD>
                    <TD>Caregiver email address</TD>
                    <TD></TD>
                  </TR>
                  <TR style={styles.title6}>
                    <TD>
                      +{data?.caretakergsmcountrycode ? data?.caretakergsmcountrycode : "-"}{" "}
                      {data?.caretakergsm ? data?.caretakergsm : "-"}
                    </TD>
                    <TD>{data?.caretakeremail ? data?.caretakeremail : "-"}</TD>
                    <TD></TD>
                  </TR>
                </TBody>
              </Table>
            </View>

            <View style={[styles.whitebox, { flex: 1, alignSelf: "stretch", height: 674, marginLeft: 20, width: 556 }]}>
              <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                <Text style={[styles.title3, { marginTop: 24, marginLeft: 24 }]}>Clinical details</Text>

                {!editClinicalDetails && userStore?.readonly == 0 && (
                  <TouchableOpacity
                    style={{ position: "absolute", right: 24, top: 28 }}
                    onPress={(x) => {
                      setEditClinicalDetails(true);
                    }}>
                    <Image
                      style={{ width: 14.87, height: 14.87 }}
                      source={require("../assets/images/editpenicon.png")}
                    />
                  </TouchableOpacity>
                )}

                {editClinicalDetails && userStore?.readonly == 0 && (
                  <>
                    <TouchableOpacity
                      style={{ position: "absolute", right: 24, top: 28 }}
                      onPress={(x) => {
                        cancelEdit();
                      }}>
                      <Text style={styles.title8}>Cancel</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{ position: "absolute", right: 94, top: 28 }}
                      onPress={(x) => {
                        updateClinicalDetails();
                      }}>
                      <Text style={styles.title8}>Save</Text>
                    </TouchableOpacity>
                  </>
                )}
              </View>

              <View
                style={{
                  marginTop: 24,
                  height: StyleSheet.hairlineWidth,
                  backgroundColor: "#00C3FF",
                  borderBottomColor: "#00C3FF",
                  borderBottomWidth: StyleSheet.hairlineWidth,
                  alignSelf: "stretch",
                  width: "100%",
                }}></View>
              <div style={{ padding: 26, paddingBottom: 0, marginBlock: -10 }}>
                <OperationAndDiagnosisInput
                  disableChange={!editClinicalDetails}
                  {...{ setDiagnosistypeSNOMED, setOperationtypeSNOMED, operationtypeSNOMED, diagnosistypeSNOMED }}
                  dropDownStyle={{ width: 500 }}
                />
              </div>

              <Table style={styles.tablesLayout}>
                <TBody>
                  <TR style={styles.title7}>
                    <TD>Operation date</TD>
                    <TD>Clinical team</TD>
                  </TR>
                  <TR>
                    <TD style={{ width: "50%" }}>
                      {!editClinicalDetails && <Text>{Moment(operationDate).format("DD/MM/YYYY")}</Text>}
                      {editClinicalDetails && (
                        <DatePicker
                          selected={operationDate}
                          onChange={(date) => {
                            setOperationDate(date!);
                          }}
                          dateFormat="dd/MM/yyyy"
                          todayButton="Today"
                        />
                      )}
                    </TD>
                    <TD style={{ width: "50%" }}>
                      {!editClinicalDetails && <Text>{clinicalTeam.name}</Text>}
                      {editClinicalDetails && (
                        <select
                          style={{
                            boxShadow: "rgba(129, 129, 129, 0.5) 0px 0px 5px",
                            height: "25px",
                            borderWidth: "0px",
                            padding: "1px 10px 2px 10px",
                            width: "200px",
                            MozAppearance: "none",
                            WebkitAppearance: "none",
                            appearance: "none",
                            backgroundImage:
                              "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAVCAYAAAAuJkyQAAAAmUlEQVR42r3OwQmAQAxE0S3JTrUDvairl6ANBG1MySFeFrz8kIGBZRnIK55ZtLOWnPzf3c67r8f1WO2dhannPTZ3TeefXhtmYrxm+UAARTAtyLLKNTEUxyyig28gimNW0akZcxTHAFQCBqAwJgEFMACFMRkojuEojolHcUw8CmLiUQCTgAIYhAKYJBTH8FTRAWLis4t21hKQF5HBtPSQyO2PAAAAAElFTkSuQmCC)",
                            backgroundPosition: "center right 10px",
                            backgroundSize: "12px 7px",
                            backgroundRepeat: "no-repeat",
                            borderRadius: 20,
                            fontFamily: "Lato",
                            fontSize: 15,
                            color: "#1F2E99",
                          }}
                          value={clinicalTeam.id}
                          onChange={(e: Event) => {
                            setClinicalTeam(clinicalteams.filter((x) => x.id === (e.target?.value | 0))[0]);
                          }}>
                          {clinicalteams.map((t) => (
                            <option key={t.id} value={t.id}>
                              {t.name}
                            </option>
                          ))}
                        </select>
                      )}
                    </TD>
                  </TR>
                </TBody>
              </Table>
              <Table style={styles.tablesLayout}>
                <TBody>
                  <TR style={styles.title7}>
                    <TD>Specialty</TD>
                  </TR>
                  <TR>
                    <TD style={styles.title6}>
                      <SpecialtyInput onChange={setSpecialty} value={specialty} disabled={!editClinicalDetails} />
                    </TD>
                  </TR>
                </TBody>
              </Table>

              <Text style={[styles.title8, { marginTop: 24, marginLeft: 24 }]}>Clinical team members</Text>

              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                  marginTop: 22,
                  alignItems: "flex-start",
                  alignContent: "flex-start",
                  borderBottomWidth: 1,
                  borderBottomColor: "#E6E6E6",
                }}>
                <View style={{ width: 42.8, marginLeft: 24 }}>
                  <Text style={[styles.title11]}>Name</Text>
                </View>
                <View style={{ flex: 1 }}> </View>
                <View style={{ flex: 1 }}>
                  <Text style={[styles.title11]}>Role</Text>{" "}
                </View>
              </View>
              <FlatList
                style={{ width: "100%" }}
                data={data.clinicalteam.clinicians}
                renderItem={({ item, index, separators }) => (
                  <View
                    style={{
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                      height: 64,
                      alignItems: "center",
                      alignContent: "center",
                      borderBottomWidth: 1,
                      borderBottomColor: "#E6E6E6",
                    }}>
                    <Avatar
                      url={item?.photourl && global.imagepathurl + encodeURI(item?.thumburl)}
                      size={32}
                      radius={16}
                      firstname={item.firstname}
                      lastname={item.lastname}
                      style={{ marginLeft: 24, marginRight: 12.8 }}
                    />
                    <View style={{ flex: 1 }}>
                      <Text style={[styles.title10]}>
                        {item.firstname} {item.lastname}
                      </Text>{" "}
                    </View>
                    <View style={{ flex: 1 }}>
                      <Text style={[styles.title10]}>{item?.cliniciantype?.title}</Text>{" "}
                    </View>
                  </View>
                )}
              />
            </View>
          </View>
        </MainFramePatient>
      )}
    </View>
  );
});
export default PatientOverviewScreen;

const styles = StyleSheet.create({
  tablesLayout: { paddingHorizontal: 26, width: "100%", marginTop: 24 },
  shadowinput: {
    marginRight: 8,
    height: 23,
    flexDirection: "row",
    borderRadius: 15,
    shadowColor: "#818181",
    backgroundColor: "#FFFFFF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.5,
    shadowRadius: 5,
    textAlign: "center",
    elevation: 5,
  },
  whitebox: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    shadowColor: "#818181",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
  title1: {
    fontFamily: "Lato",
    fontSize: 13,
    color: "#1F2E99",
  },
  title2: {
    fontFamily: "Lato",
    fontSize: 15,
    color: "#1F2E99",
  },
  title3: {
    fontFamily: "LatoBold",
    fontSize: 18,
    color: "#1F2E99",
  },
  title4: {
    fontFamily: "Lato",
    fontSize: 13,
    color: "#868686",
  },

  title5: {
    fontFamily: "Lato",
    fontSize: 13,
    color: "#1F2E99",
  },
  title6: {
    fontFamily: "Lato",
    fontSize: 15,
    color: "#000000",
    height: 50,
    alignItems: "flex-start",
    "vertical-align": "top",
  },

  title7: {
    fontFamily: "Lato",
    fontSize: 13,
    color: "#868686",
    height: 28,
  },
  title8: {
    fontFamily: "LatoBold",
    fontSize: 15,
    color: "#1F2E99",
  },
  title9: {
    fontFamily: "Lato",
    fontSize: 13,
    color: "#868686",
    height: 64,
  },
  title10: {
    fontFamily: "Lato",
    fontSize: 15,

    color: "#000000",
  },
  title11: {
    fontFamily: "Lato",
    fontSize: 13,
    color: "#868686",
    height: 28,
  },
  container: {
    flex: 1,
  },
  threedots: {
    width: 17,
    height: 3.66667,
  },
});
