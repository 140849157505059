import * as React from "react";
import SplitFrame from "../../components/SplitFrame";
import Register from "../../components/user/Register";
import UnifiedEnrollment from "../../components/user/UnifiedEnrollment";
import { useNullableFeatureFlag } from "../../hooks/useFeatureFlag";

export const ClinicianRegister = () => {
  const useUnifiedLogin = useNullableFeatureFlag("unifiedlogin");

  if (useUnifiedLogin === null) return <></>;
  if (useUnifiedLogin) return <UnifiedEnrollment />;
  return (
    <SplitFrame bgcolor={"#E8F0FF"}>
      <Register bgcolor={"#E8F0FF"} />
    </SplitFrame>
  );
};

export const HospitalAdminRegister = () => {
  return (
    <SplitFrame>
      <Register admin />
    </SplitFrame>
  );
};

export const PostopAdminRegister = () => {
  return (
    <SplitFrame>
      <Register postopadmin />
    </SplitFrame>
  );
};
