import React, { useState, useEffect } from "react";
import {
  TouchableOpacity,
  SectionList,
  View,
  Text,
  StyleSheet,
  Dimensions,
  Image,
  Modal,
  TouchableHighlight,
} from "react-native";

import { Hairline } from "../components/Hairline";
import { ValueChange } from "../components/ValueChange";
import { MessageSummary } from "../components/MessageSummary";
import { MessageSummaryHeader } from "../components/MessageSummaryHeader";
import { MainFrame } from "../components/MainFrame";
import { RecoveryStatusChart, OutComeScoresChart } from "../components/Charts";
import { inject, observer } from "mobx-react";
import Moment from "moment";

import Menu, { MenuOptions, MenuOption, MenuTrigger, renderers } from "react-native-popup-menu";

const DashboardScreen = inject("userStore")(
  observer(({ userStore, navigation }) => {
    const { ContextMenu, SlideInMenu, Popover } = renderers;

    const [data, setData] = React.useState(null);
    const [datachart1, setDatachart1] = React.useState(null);
    const [datachart2, setDatachart2] = React.useState(null);

    const [deltaMonth, setDeltaMonth] = React.useState(0);
    const [deltaMonth2, setDeltaMonth2] = React.useState(0);
    const [chartType, setChartType] = React.useState(1);
    const [selectedTab, setSelectedTab] = React.useState(1);
    const [unreadmessages, setUnreadmessages] = React.useState();
    const [filteredmessages, setFilteredmessages] = React.useState<any[]>([]);
    const yaxisoutcomescores = [
      [0, 12, 24, 36, 48],
      [0, 12, 24, 36, 48],
      [0, 8, 16, 24, 32, 41],
      [0, 9, 18, 27],
    ];

    function getchartdata(whichchart, ctype, dmonth) {
      let chartapiurl = "";
      if (whichchart == 1) {
        setDeltaMonth(dmonth);
        chartapiurl = "getdashboardrecoverychartdata";
      }

      if (whichchart == 2) {
        setChartType(ctype);
        setDeltaMonth2(dmonth);

        chartapiurl = "getdashboardchartdata";
      }

      fetch(global.apiurl + "/clinicians/" + chartapiurl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: global.tokenparam || "",
        },
        body: JSON.stringify({
          deltamonth: dmonth,
          charttype: ctype,
        }),
      })
        .then(async (response) => await response.json())
        .then((resData) => {
          console.log(resData);
          if (resData.result) {
            if (whichchart == 1) setDatachart1(resData.data);
            if (whichchart == 2) setDatachart2(resData.data);
          }
        });
    }

    useEffect(() => {
      async function fetchData() {
        fetch(global.apiurl + "/clinicians/getdashboard", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: global.tokenparam || "",
          },
        })
          .then(async (response) => await response.json())
          .then((resData) => {
            console.log(resData);
            if (resData.result) {
              setData(resData.data);

              setDatachart1(resData.data);
              setDatachart2(resData.data.outcomescores);

              const groups = resData.data.unreadmessages.reduce((groups, xx) => {
                const title = xx.created_at.split("T")[0];
                if (!groups[title]) {
                  groups[title] = [];
                }
                groups[title].push(xx);
                return groups;
              }, {});

              // Edit: to add it in the array format instead
              const groupArrays = Object.keys(groups).map((title) => {
                return {
                  title,
                  data: groups[title],
                };
              });

              setUnreadmessages(groupArrays);
              setFilteredmessages(groupArrays);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      fetchData();
    }, []);

    const dimensions = Dimensions.get("window");
    const miniboxwidth = Math.min(230.5, (Math.max(1200, dimensions.width) - 328 - 32 * 6) / 4);
    const maxiboxwidth = Math.min(493, (Math.max(1200, dimensions.width) - 328 - 32 * 4) / 2);

    const renderItem = ({ item }) => (
      <MessageSummary
        patientid={item.patientid}
        firstname={item.firstname}
        lastname={item.lastname}
        message={item.message}
        created_at={item.created_at}
        messagesreplied={item.messagesreplied}
        watchclosely={item.watchclosely}
        checked={item?.message_read_at?.length ?? 0 > 0}
        recoverystatus={item.recoverystatus}
        thumburl={item.thumburl}
      />
    );

    const renderHeader = ({ title }) => <MessageSummaryHeader title={title} />;

    console.log(userStore.screenupdate);
    console.log("redraw");
    return (
      <View style={styles.container}>
        <MainFrame dashboard userStore={userStore}>
          <View style={{ flexDirection: "row", marginTop: 37, marginLeft: 32, marginRight: 32 }}>
            <View>
              <View style={{ flexDirection: "row" }}>
                <View style={[styles.whitebox, { width: miniboxwidth, height: 222, alignItems: "center" }]}>
                  <Image
                    source={require("../assets/images/dashboardiconperson.png")}
                    style={{ width: 42, height: 42, marginTop: 24 }}
                  />
                  <Text style={styles.title1}>{data?.patientsInRecovery}</Text>

                  <ValueChange
                    margintop={5}
                    oldValue={data?.patientsInRecoveryYesterday}
                    newValue={data?.patientsInRecovery}
                  />

                  <Text style={styles.title2}>Patients less than 90 days into recovery</Text>
                </View>

                <View
                  style={[styles.whitebox, { width: miniboxwidth, height: 222, marginLeft: 32, alignItems: "center" }]}>
                  <Image
                    source={require("../assets/images/dashboardiconeye.png")}
                    style={{ width: 42, height: 42, marginTop: 24 }}
                  />
                  <Text style={styles.title1}>{data?.dashboard2}</Text>

                  <ValueChange margintop={5} deltavalue={data?.dashboard2change} />

                  <Text style={styles.title2}>
                    Patients to be
                    <br />
                    watched closely
                  </Text>
                </View>

                <View
                  style={[styles.whitebox, { width: miniboxwidth, height: 222, marginLeft: 32, alignItems: "center" }]}>
                  <Image
                    source={require("../assets/images/dashboardiconchart.png")}
                    style={{ width: 42, height: 42, marginTop: 24 }}
                  />
                  <Text style={[styles.title1, { fontSize: 20 }]}>
                    {data?.dashboard3 ? `${data?.dashboard3}%` : "-"}
                  </Text>

                  <ValueChange margintop={5} deltavalue={data?.dashboard3change} />

                  <Text style={styles.title2}>Avg. compliance rate this month</Text>
                </View>

                <View
                  style={[styles.whitebox, { width: miniboxwidth, height: 222, marginLeft: 32, alignItems: "center" }]}>
                  <Image
                    source={require("../assets/images/dashboardiconchat.png")}
                    style={{ width: 42, height: 42, marginTop: 24 }}
                  />
                  <Text style={[styles.title1, { fontSize: 20 }]}>
                    {data?.dashboard4 ? `${data?.dashboard4} hrs` : "-"}
                  </Text>
                  <ValueChange margintop={5} deltavalue={data?.dashboard4change} />

                  <Text style={styles.title2}>Avg. response time this month</Text>
                </View>
              </View>

              <View style={{ flexDirection: "row", marginTop: 32 }}>
                <View style={[styles.whitebox, { width: maxiboxwidth, height: 420, paddingTop: 17 }]}>
                  <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                    {" "}
                    <Text style={[styles.title3, { marginLeft: 17 }]}>Recovery status</Text>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                      <Text style={[styles.title11, { marginRight: 15.51 }]}>Monthly</Text>

                      <TouchableOpacity
                        style={{ marginRight: 16.12 }}
                        onPress={() => {
                          getchartdata(1, 0, deltaMonth - 1);
                        }}>
                        <Image
                          style={{ width: 20, height: 20 }}
                          source={require("../assets/images/careplanprevbutton.png")}
                        />
                      </TouchableOpacity>
                      <Text style={[styles.title11, { marginRight: 16.12, width: 40 }]}>
                        {Moment().add(deltaMonth, "months").format("MMM YY")}
                      </Text>

                      <TouchableOpacity
                        style={{ marginRight: 20 }}
                        onPress={() => {
                          getchartdata(1, 0, deltaMonth + 1);
                        }}>
                        <Image
                          style={{ width: 20, height: 20 }}
                          source={require("../assets/images/careplannextbutton.png")}
                        />
                      </TouchableOpacity>
                    </View>
                  </View>

                  <Hairline marginTop={26} />
                  <Text style={[styles.title9, { marginTop: 20, marginLeft: 27 }]}>Avg. no. of patients</Text>
                  {datachart1 && (
                    <View
                      style={{
                        flexDirection: "row",
                        marginTop: 14,
                        marginLeft: 27,
                        marginRight: 43,
                        justifyContent: "space-between",
                      }}>
                      <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <View
                          style={{
                            width: 20,
                            height: 20,
                            borderRadius: 7,
                            backgroundColor: "#6DD803",
                            alignItems: "center",
                          }}
                        />
                        <Text style={{ fontFamily: "Lato", fontSize: 13, color: "#B4B7CC", marginLeft: 10.47 }}>
                          {" "}
                          Good
                        </Text>
                        <Text style={styles.title10}>
                          {datachart1?.dashboard5 == "-" ? "-" : Math.floor(datachart1?.dashboard5 * 100) / 100}
                        </Text>{" "}
                      </View>
                      <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <View
                          style={{
                            width: 20,
                            height: 20,
                            borderRadius: 7,
                            backgroundColor: "#F9CE37",
                            alignItems: "center",
                          }}
                        />
                        <Text style={{ fontFamily: "Lato", fontSize: 13, color: "#B4B7CC", marginLeft: 10.47 }}>
                          {" "}
                          Fair
                        </Text>
                        <Text style={styles.title10}>
                          {datachart1?.dashboard6 == "-" ? "-" : Math.floor(datachart1?.dashboard6 * 100) / 100}
                        </Text>{" "}
                      </View>
                      <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <View
                          style={{
                            width: 20,
                            height: 20,
                            borderRadius: 7,
                            backgroundColor: "#FF6D6D",
                            alignItems: "center",
                          }}
                        />
                        <Text style={{ fontFamily: "Lato", fontSize: 13, color: "#B4B7CC", marginLeft: 10.47 }}>
                          {" "}
                          Poor
                        </Text>
                        <Text style={styles.title10}>
                          {datachart1?.dashboard7 == "-" ? "-" : Math.floor(datachart1?.dashboard7 * 100) / 100}
                        </Text>{" "}
                      </View>
                    </View>
                  )}
                  <Text style={[styles.title9, { marginTop: 32, marginLeft: 27 }]}>No. of Patients</Text>

                  <View style={{ marginLeft: 27 }}>{data && <RecoveryStatusChart data={datachart1} />}</View>
                </View>

                <View style={[styles.whitebox, { width: maxiboxwidth, height: 420, paddingTop: 17, marginLeft: 32 }]}>
                  <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                    {" "}
                    <Text style={[styles.title3, { marginLeft: 17 }]}>Outcome scores</Text>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                      <TouchableOpacity
                        style={{ marginRight: 16.12 }}
                        onPress={() => {
                          setSelectedTab(1);
                          getchartdata(2, chartType, deltaMonth2 - 1);
                        }}>
                        <Image
                          style={{ width: 20, height: 20 }}
                          source={require("../assets/images/careplanprevbutton.png")}
                        />
                      </TouchableOpacity>
                      <Text style={[styles.title11, { marginRight: 16.12, width: 40 }]}>
                        {Moment().add(deltaMonth2, "months").format("MMM YY")}
                      </Text>

                      <TouchableOpacity
                        style={{ marginRight: 20 }}
                        onPress={() => {
                          setSelectedTab(1);
                          getchartdata(2, chartType, deltaMonth2 + 1);
                        }}>
                        <Image
                          style={{ width: 20, height: 20 }}
                          source={require("../assets/images/careplannextbutton.png")}
                        />
                      </TouchableOpacity>
                    </View>
                  </View>
                  <Hairline marginTop={26} />
                  <View
                    style={{ backgroundColor: "#FBFBFB", height: 42, flexDirection: "row", alignContent: "center" }}>
                    <TouchableOpacity
                      onPress={() => {
                        setSelectedTab(1);
                        getchartdata(2, 1, deltaMonth2);
                      }}
                      style={[
                        { marginLeft: 26, width: 27, borderBottomColor: "#0009EC" },
                        selectedTab == 1 && { borderBottomWidth: 2 },
                      ]}>
                      <Text style={[styles.title8]}>OKS</Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                      onPress={() => {
                        setSelectedTab(2);
                        getchartdata(2, 2, deltaMonth2);
                      }}
                      style={[
                        { marginLeft: 42, width: 28, borderBottomColor: "#0009EC" },
                        selectedTab == 2 && { borderBottomWidth: 2 },
                      ]}>
                      <Text style={[styles.title8]}>OHS</Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                      onPress={() => {
                        setSelectedTab(3);
                        getchartdata(2, 3, deltaMonth2);
                      }}
                      style={[
                        { marginLeft: 42, width: 25, borderBottomColor: "#0009EC" },
                        selectedTab == 3 && { borderBottomWidth: 2 },
                      ]}>
                      <Text style={[styles.title8]}>SSIS</Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                      onPress={() => {
                        setSelectedTab(4);
                        getchartdata(2, 4, deltaMonth2);
                      }}
                      style={[
                        { marginLeft: 42, width: 36, borderBottomColor: "#0009EC" },
                        selectedTab == 4 && { borderBottomWidth: 2 },
                      ]}>
                      <Text style={[styles.title8]}>PHQ9</Text>
                    </TouchableOpacity>
                  </View>
                  <Hairline marginTop={0} />
                  <Text style={[styles.title9, { marginTop: 30, marginLeft: 27 }]}>Score (avg.)</Text>

                  {datachart2 && (
                    <OutComeScoresChart
                      yaxis={yaxisoutcomescores[selectedTab - 1]}
                      data={datachart2}></OutComeScoresChart>
                  )}

                  <Text style={[styles.title9, { textAlign: "center" }]}>Patient recovery period</Text>
                </View>
              </View>
            </View>
            <View style={[styles.whitebox, { width: 328, marginLeft: 32, padding: 16, height: 674, marginBottom: 32 }]}>
              <Text style={styles.title3}>Recent messages</Text>

              <SectionList
                sections={filteredmessages}
                keyExtractor={(item, index) => item + index}
                renderItem={renderItem}
                renderSectionHeader={({ section: { title } }) => renderHeader({ title })}
              />
            </View>
          </View>
        </MainFrame>
      </View>
    );
  }),
);
export default DashboardScreen;

const styles = StyleSheet.create({
  titlegreen: {
    fontFamily: "LatoBold",
    fontSize: 13,
    color: "#6DD803",
  },

  titlered: {
    fontFamily: "LatoBold",
    fontSize: 13,
    color: "#6DD803",
    marginTop: 5,
  },
  title1: {
    fontFamily: "LatoBold",
    fontSize: 42,
    height: 50,
    color: "#1F2E99",
    marginTop: 17,
  },
  whitebox: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    shadowColor: "#818181",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",

    height: "100%",
  },

  title2: {
    fontSize: 13,
    fontFamily: "Lato",
    color: "#616368",
    top: 11,
    textAlign: "center",
    marginTop: 11,
    maxWidth: 147,
  },
  title3: {
    fontSize: 18,
    fontFamily: "LatoBold",
    color: "#1F2E99",
  },

  title4: {
    fontSize: 11,
    fontFamily: "Lato",
    color: "#1F2E99",
  },
  title5: {
    fontSize: 11,
    fontFamily: "Lato",
    color: "#798599",
  },
  title6: {
    fontSize: 13,
    fontFamily: "Lato",
    color: "#B4B7CC",
  },
  title7: {
    fontSize: 15,
    fontFamily: "LatoBold",
    color: "#000000",
  },
  title8: {
    fontSize: 13,
    marginTop: 13,
    fontFamily: "Lato",
    color: "#000000",
  },

  title9: {
    fontFamily: "Lato",
    fontSize: 11,
    color: "#9A9A9A",
  },
  title10: {
    fontSize: 15,
    fontFamily: "LatoBold",
    color: "#000000",
    marginLeft: 9.03,
  },
  title11: {
    fontSize: 11,
    fontFamily: "Lato",
    color: "#1F2E99",
  },
});
