import * as React from "react";
import Login from "../../components/user/Login";
import SplitFrame from "../../components/SplitFrame";
import { inject, observer } from "mobx-react";
import { useNullableFeatureFlag } from "../../hooks/useFeatureFlag";
import UnifiedLogin from "../../components/user/UnifiedLogin";

export const ClinicianLogin = inject("userStore")(
  observer(({ userStore }): JSX.Element => {
    const useUnifiedLogin = useNullableFeatureFlag("unifiedlogin");

    if (useUnifiedLogin === null) return <></>;
    if (useUnifiedLogin) return <UnifiedLogin userStore={userStore} />;
    return (
      <SplitFrame bgcolor={"#E8F0FF"}>
        <Login bgcolor={"#E8F0FF"} userStore={userStore} />
      </SplitFrame>
    );
  }),
);

export const HospitalAdminLogin = inject("userStore")(
  observer(
    ({ userStore }): JSX.Element => (
      <SplitFrame>
        <Login admin userStore={userStore} />
      </SplitFrame>
    ),
  ),
);

export const PostopAdminLogin = inject("userStore")(
  observer(
    ({ userStore }): JSX.Element => (
      <SplitFrame>
        <Login postopadmin userStore={userStore} />
      </SplitFrame>
    ),
  ),
);
