import React, { useEffect } from "react";
import { View, Text, StyleSheet } from "react-native";
import { MainFramePatient } from "../components/MainFramePatient";
import { Hairline } from "../components/Hairline";
import { PREMSScoreClientChart, EQ5D5LClientChart } from "../components/Charts";
import Moment from "moment";
import { withStore } from "../misc/utils";
import { useRoute } from "@react-navigation/native";
interface PatientPROMSScreenProps {
  userStore: any;
  patientid: string;
  patient: any;
  clinician: any;
  patientlist: boolean;
}
const PatientPROMSScreen = withStore((props: PatientPROMSScreenProps) => {
  const params = useRoute()?.params ?? {};
  const { patientid } = params as any;
  const [data, setData] = React.useState<any>();

  useEffect(() => {
    async function fetchData() {
      fetch(global.apiurl + "/clinicians/getpatientpromsdashboard", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: global.tokenparam || "",
        },
        body: JSON.stringify({
          patientid,
        }),
      })
        .then(async (response) => await response.json())
        .then((resData) => {
          console.log(resData);
          if (resData.result) {
            setData(resData);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, []);

  return (
    <View style={styles.container}>
      {data && (
        <MainFramePatient
          userStore={props.userStore}
          selected={3}
          patient={data?.patient}
          clinician={data?.clinician}
          patientlist
          style={{ alignItems: "flex-start", flexDirection: "column", width: "100%" }}>
          <View style={{ flexDirection: "row", alignItems: "flex-end" }}>
            {" "}
            <Text style={[styles.title3, {}]}>PROMS questionnaire data</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              maxWidth: 1200,
              flexWrap: "wrap",
              minWidth: 600,
              paddingTop: 3,
              paddingLeft: 20,
              marginBottom: 32,
            }}>
            <View style={styles.whitebox}>
              <View style={{ justifyContent: "space-between", flexDirection: "row" }}>
                <Text style={[styles.title3, { marginLeft: 27, marginTop: 18 }]}>SSIS</Text>
                <View style={{ flexDirection: "row" }}>
                  <Text style={[styles.title5, { marginRight: 10, marginTop: 20 }]}>compliance rate</Text>
                  <Text style={[styles.title4, { marginRight: 27, marginTop: 21 }]}>
                    {data?.data.dashinfo.ssiscomplience}%
                  </Text>
                </View>
              </View>
              <Hairline marginTop={16} />
              <Text style={[styles.title9, { marginTop: 30, marginLeft: 27 }]}>Pain Score</Text>
              <PREMSScoreClientChart
                patient={data?.patient}
                yaxis={[0, 8, 16, 24, 32, 41]}
                data={data?.data.dashchart1}></PREMSScoreClientChart>
              <Text style={[styles.title9, { textAlign: "center" }]}>Patient recovery period</Text>
            </View>

            {data?.patient?.operationtype == 1 && (
              <View style={styles.whitebox}>
                <View style={{ justifyContent: "space-between", flexDirection: "row" }}>
                  <Text style={[styles.title3, { marginLeft: 27, marginTop: 18 }]}>Oxford knee score</Text>
                  <View style={{ flexDirection: "row" }}>
                    <Text style={[styles.title5, { marginRight: 10, marginTop: 20 }]}>compliance rate</Text>
                    <Text style={[styles.title4, { marginRight: 27, marginTop: 21 }]}>
                      {data?.data.dashinfo.ohsokscomplience}%
                    </Text>
                  </View>
                </View>
                <Hairline marginTop={16} />
                <Text style={[styles.title9, { marginTop: 30, marginLeft: 27 }]}>Knee Score</Text>
                <PREMSScoreClientChart
                  patient={data?.patient}
                  yaxis={[0, 12, 24, 36, 48]}
                  data={data?.data.dashchart2}></PREMSScoreClientChart>
                <Text style={[styles.title9, { textAlign: "center" }]}>Patient recovery period</Text>
              </View>
            )}

            {data?.patient?.operationtype == 2 && (
              <View style={styles.whitebox}>
                <View style={{ justifyContent: "space-between", flexDirection: "row" }}>
                  <Text style={[styles.title3, { marginLeft: 27, marginTop: 18 }]}>Oxford hip score</Text>
                  <View style={{ flexDirection: "row" }}>
                    <Text style={[styles.title5, { marginRight: 10, marginTop: 20 }]}>compliance rate</Text>
                    <Text style={[styles.title4, { marginRight: 27, marginTop: 21 }]}>
                      {data?.data.dashinfo.ohsokscomplience}%
                    </Text>
                  </View>
                </View>
                <Hairline marginTop={16} />
                <Text style={[styles.title9, { marginTop: 30, marginLeft: 27 }]}>Hip Score</Text>
                <PREMSScoreClientChart
                  patient={data?.patient}
                  yaxis={[0, 12, 24, 36, 48]}
                  data={data?.data.dashchart2}></PREMSScoreClientChart>
                <Text style={[styles.title9, { textAlign: "center" }]}>Patient recovery period</Text>
              </View>
            )}

            <View style={styles.whitebox}>
              <View style={{ justifyContent: "space-between", flexDirection: "row" }}>
                <Text style={[styles.title3, { marginLeft: 27, marginTop: 18 }]}>EQ5D - 5L</Text>
                <View style={{ flexDirection: "row" }}>
                  <Text style={[styles.title5, { marginRight: 10, marginTop: 20 }]}>compliance rate</Text>
                  <Text style={[styles.title4, { marginRight: 27, marginTop: 21 }]}>
                    {data?.data.dashinfo.eq5d5lcomplience}%
                  </Text>
                </View>
              </View>
              <Hairline marginTop={16} />

              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 13,
                }}>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Text style={[styles.title9, { marginLeft: 27 }]}>Score Profile</Text>
                  <Text style={[styles.title4, { marginLeft: 4 }]}>{data?.data?.dashchart3?.eq5d5lresult}</Text>
                </View>
                <Text style={[styles.title9, { marginRight: 24 }]}>
                  Latest result: {Moment(data?.data?.dashchart3?.created_at).format("DD MMM YYYY")} (Day{" "}
                  {data?.data?.dashchart3?.dayno} / Week {data?.data?.dashchart3?.week})
                </Text>
              </View>
              <Text style={[styles.title9, { marginTop: 18, marginLeft: 27 }]}>Score</Text>
              {data?.data.dashchart3 && <EQ5D5LClientChart data={data?.data?.dashchart3}></EQ5D5LClientChart>}
            </View>

            <View style={styles.whitebox}>
              <View style={{ justifyContent: "space-between", flexDirection: "row" }}>
                <Text style={[styles.title3, { marginLeft: 27, marginTop: 18 }]}>PHQ9</Text>
                <View style={{ flexDirection: "row" }}>
                  <Text style={[styles.title5, { marginRight: 10, marginTop: 20 }]}>compliance rate</Text>
                  <Text style={[styles.title4, { marginRight: 27, marginTop: 21 }]}>
                    {data?.data.dashinfo.phq9complience}%
                  </Text>
                </View>
              </View>
              <Hairline marginTop={16} />
              <Text style={[styles.title9, { marginTop: 30, marginLeft: 27 }]}>Score</Text>

              <PREMSScoreClientChart
                patient={data?.patient}
                yaxis={[0, 9, 18, 27]}
                data={data?.data.dashchart4}></PREMSScoreClientChart>
              <Text style={[styles.title9, { textAlign: "center" }]}>Patient recovery period</Text>
            </View>

            <View style={styles.whitebox}>
              <View style={{ justifyContent: "space-between", flexDirection: "row" }}>
                <Text style={[styles.title3, { marginLeft: 27, marginTop: 18 }]}>VAS Pain Score </Text>
                <View style={{ flexDirection: "row" }}>
                  <Text style={[styles.title5, { marginRight: 10, marginTop: 20 }]}>compliance rate</Text>
                  <Text style={[styles.title4, { marginRight: 27, marginTop: 21 }]}>
                    {data?.data.dashinfo.painscorecomplience}%
                  </Text>
                </View>
              </View>
              <Hairline marginTop={16} />
              <Text style={[styles.title9, { marginTop: 30, marginLeft: 27 }]}>Score</Text>

              <PREMSScoreClientChart
                patient={data?.patient}
                yaxis={[0, 5, 10]}
                data={data?.data.dashchart5}></PREMSScoreClientChart>
              <Text style={[styles.title9, { textAlign: "center" }]}>Patient recovery period</Text>
            </View>
          </View>
        </MainFramePatient>
      )}
    </View>
  );
});
export default PatientPROMSScreen;
const styles = StyleSheet.create({
  whitebox: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    shadowColor: "#818181",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    marginTop: 20,
    marginRight: 20,
    width: 556,
    height: 370,

    elevation: 5,
  },
  title1: {
    fontFamily: "Lato",
    fontSize: 13,
    color: "#1F2E99",
  },
  title2: {
    fontFamily: "Lato",
    fontSize: 13,
    color: "#1F2E99",
  },
  title3: {
    fontFamily: "LatoBold",
    fontSize: 18,
    color: "#1F2E99",
  },
  title4: {
    fontFamily: "LatoBold",
    fontSize: 13,
    color: "#1F2E99",
  },
  title5: {
    fontFamily: "Lato",
    fontSize: 13,
    color: "#9A9A9A",
  },
  title9: {
    fontFamily: "Lato",
    fontSize: 11,
    color: "#9A9A9A",
  },
  container: {
    flex: 1,
  },
});
