import {
  Animated,
  Dimensions,
  TouchableOpacity,
  View,
  Text,
  StyleSheet,
  TextInput,
  Image,
  FlatList,
} from "react-native";
import React, { useEffect } from "react";

import { CreateClinicalTeam } from "../../components/CreateClinicalTeam";
import { BlueButton } from "../../components/BlueButton";
import { Hairline } from "../../components/Hairline";
import { Avatar } from "../../components/Avatar";
import { MainFrame } from "../../components/MainFrame";

import { A } from "@expo/html-elements";
import Modal from "modal-react-native-web";

import { withStore } from "../../misc/utils";

const TempScreen = withStore(({ userStore }) => {
  const [searchtext, setSearchtext] = React.useState("");
  //      const [filterHip,setFilterHip]= React.useState(false);
  //    const [filterKnee,setFilterKnee]= React.useState(false);

  const [invitemodalvisible, setInvitemodalvisible] = React.useState(false);
  const dimensions = Dimensions.get("window");
  const [data, setData] = React.useState<any[] | null>(null);
  const [tempData, setTempData] = React.useState<any[] | null>(null);

  const opacity = new Animated.Value(1);

  function fadeIn() {
    setInvitemodalvisible(true);
  }

  function fadeOut() {
    Animated.timing(opacity, {
      toValue: 0,
      duration: 175,
      useNativeDriver: false,
    }).start(() => {
      setInvitemodalvisible(false);
    });
    fetchData();
  }

  function updateSearch(text: string) {
    setSearchtext(text);
    // if (('' == text)&&(!filterKnee)&&(!filterHip)) {
    if (text == "") {
      console.log("updatesearch:null");
      setData(tempData);
      return;
    }

    setData(
      tempData?.filter(function (item) {
        return item.name.toLowerCase().includes(text.toLowerCase());
      }) ?? [],
    );
  }

  async function fetchData() {
    fetch(global.apiurl + "/hospitaladmins/getclinicalteams", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
    })
      .then(async (response) => await response.json())
      .then((resData) => {
        console.log(resData);
        if (resData.result) {
          setData(resData.data);
          setTempData(resData.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  /**
   *
  [{ title: 'Title Text', key: 'item1' },{ title: 'Title Text', key: 'item2' },{ title: 'Title Text', key: 'item13' }]
   */
  return (
    <View style={styles.container}>
      <Modal
        buggy={userStore.screenupdate}
        onBackdropPress={() => {
          setInvitemodalvisible(false);
        }}
        animationType="fade"
        transparent={true}
        visible={invitemodalvisible}
        onDismiss={() => {
          fetchData();
          //  alert('Modal has been closed.');
        }}>
        {!!invitemodalvisible && (
          <Animated.View
            style={{
              position: "absolute",
              top: 0,
              zIndex: 100,
              width: "100%",
              opacity,
              height: Math.max(dimensions.height, 826),
            }}>
            <CreateClinicalTeam
              userStore={userStore}
              onFinish={fadeOut}
              visible={invitemodalvisible}
              removeView={fadeOut}></CreateClinicalTeam>
          </Animated.View>
        )}
      </Modal>

      <MainFrame admin userStore={userStore} clinicalteamlist style={{}}>
        <View style={{ alignItems: "flex-start", width: 1035 }}>
          <Text style={[styles.title1]}>List of clinical teams</Text>
          <Text style={[styles.title2]}>{data?.length} teams</Text>
          {userStore?.readonly == 0 && (
            <BlueButton
              onPress={() => {
                fadeIn();
              }}
              invitenewuser
              title="Create new team"
              style={{
                position: "absolute",
                right: 0,
                top: 32,
                width: 193,
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          )}
          <View style={{ flexDirection: "row", alignItems: "center", marginTop: 24 }}>
            <View style={[styles.shadowinput, { width: 267 }]}>
              <TextInput
                style={[styles.postopinput, { textAlign: "left", width: 220 }]}
                keyboardType="email-address"
                maxLength={50}
                numberOfLines={1}
                onChangeText={(text) => {
                  updateSearch(text);
                }}
                placeholder={"Search"}
                placeholderTextColor={"#1F2E99"}
                value={searchtext}
              />
              <TouchableOpacity style={{ position: "absolute", right: 10, top: 13 }}>
                <Image source={require("../../assets/images/searchicon.png")} style={styles.searchicon} />
              </TouchableOpacity>
            </View>
          </View>
          <View style={{ flexDirection: "row", width: "100%", justifyContent: "space-between", marginTop: 24.5 }}>
            <TouchableOpacity style={{ flex: 0.2 }}>
              <Text style={[styles.title3]}>Name</Text>
            </TouchableOpacity>
            <TouchableOpacity style={{ flex: 0.5 }}>
              <Text style={[styles.title3]}>Team members</Text>
            </TouchableOpacity>
            <TouchableOpacity style={{ flex: 0.22 }}>
              {" "}
              <Text style={[styles.title3]}>Performance indicators</Text>
            </TouchableOpacity>
            <TouchableOpacity style={{ flex: 0.08 }}></TouchableOpacity>
          </View>
          <Hairline marginTop={15.5} />
          <FlatList
            scrollEnabled={false}
            xextraData={data}
            style={{ width: "100%", marginBottom: 94 }}
            data={data}
            keyExtractor={(item) => item.id}
            renderItem={({ item, index, separators }) => (
              <A href={"clinicalteam?clinicalteamid=" + item.id} key={item.id} style={{ textDecorationLine: "none" }}>
                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                    marginBottom: 24.5,
                    backgroundColor: "#ffffff",
                    height: 132,
                    alignItems: "center",
                    borderRadius: 6,
                  }}>
                  <View style={{ flex: 0.2, paddingLeft: 22 }}>
                    <Text style={[styles.title6]}>{item.name} </Text>
                    <Text style={[styles.title7]}>In charge of {item?.patients?.length} patients </Text>
                  </View>

                  <Text style={[styles.title4, { flex: 0.5 }]}>
                    <A
                      href={"clinicalteamclinicians?clinicalteamid=" + item.id}
                      key={item.id}
                      style={{ textDecorationLine: "none" }}>
                      {item.clinicians.slice(0, 3).map((item) => (
                        <View style={{ width: 220, marginTop: 12, marginBottom: 12, flexDirection: "row" }}>
                          <Avatar
                            url={item?.thumburl && global.imagepathurl + encodeURI(item?.thumburl)}
                            size={36}
                            radius={18}
                            firstname={item.firstname}
                            lastname={item.lastname}
                            style={{ backgroundColor: "#E0E3F7", borderWidth: 1, marginRight: 12 }}
                            fontcolor={"#000000"}
                          />
                          <View>
                            <Text style={styles.title8}>
                              {item.firstname} {item.lastname}{" "}
                            </Text>
                            <Text style={styles.title9}>{item?.cliniciantype?.title} </Text>
                          </View>
                        </View>
                      ))}

                      {item.clinicians.length === 4 &&
                        item.clinicians.slice(3, 4).map((item) => (
                          <View style={{ width: 220, marginTop: 12, marginBottom: 12, flexDirection: "row" }}>
                            <Avatar
                              url={item?.thumburl && global.imagepathurl + encodeURI(item?.thumburl)}
                              size={36}
                              radius={18}
                              firstname={item.firstname}
                              lastname={item.lastname}
                              style={{ backgroundColor: "#E0E3F7", borderWidth: 1, marginRight: 12 }}
                              fontcolor={"#000000"}
                            />
                            <View>
                              <Text style={styles.title8}>
                                {item.firstname} {item.lastname}{" "}
                              </Text>
                              <Text style={styles.title9}>{item?.cliniciantype?.title}</Text>
                            </View>
                          </View>
                        ))}

                      {item.clinicians.length > 4 && (
                        <View
                          style={{
                            width: 220,
                            marginTop: 12,
                            marginBottom: 12,
                            flexDirection: "row",
                            alignItems: "center",
                          }}>
                          <Avatar
                            size={36}
                            radius={18}
                            firstname={"+"}
                            lastname={String(item.clinicians.length - 3)}
                            style={{ backgroundColor: "#E0E3F7", borderWidth: 1, marginRight: 12 }}
                            fontcolor={"#000000"}
                          />
                          <Text style={styles.title8}>+{item.clinicians.length - 3} more members </Text>
                        </View>
                      )}
                    </A>
                  </Text>

                  <View style={{ flex: 0.22 }}>
                    <Text style={[styles.title4, { fontWeight: "400" }]}>
                      {item.ssisinfectioncount} Infection{item.ssisinfectioncount != 1 ? "s" : ""}
                    </Text>
                    <Text style={[styles.title4, { fontWeight: "400", marginTop: 16 }]}>
                      {Math.round(item.promspremscompletion)} % PROMS/PREMS completion
                    </Text>
                  </View>
                  <Text style={[styles.title4, { flex: 0.08 }]}></Text>
                </View>
              </A>
            )}
          />
        </View>
      </MainFrame>
    </View>
  );
});

export default TempScreen;

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  openButton: {
    backgroundColor: "#F194FF",
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },

  searchicon: {
    marginTop: 2,
    width: 12.33,
    height: 13.33,
  },
  watchcloselycircleicon: {
    width: 15,
    height: 15,
  },
  unreadmsgicon: {
    width: 8,
    height: 8,
  },
  flagicon: {
    width: 15,
    height: 18.333,
  },

  postopinput: {
    flex: 1,
    outlineWidth: 0,
    color: "#1F2E99",
    fontFamily: "Lato",
    fontSize: 15,
    marginRight: 10,
    marginLeft: 20,
  },
  shadowinput: {
    marginTop: 15,
    height: 44,
    flexDirection: "row",
    borderRadius: 15,
    shadowColor: "#818181",
    backgroundColor: "#FFFFFF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",

    height: "100%",
  },
  title1: {
    fontSize: 24,
    fontFamily: "LatoBold",
    color: "#1F2E99",

    marginTop: 28,
  },
  title2: {
    marginTop: 12,

    fontSize: 15,
    fontFamily: "Lato",
    color: "#1F2E99",
  },
  title3: {
    fontSize: 13,
    fontFamily: "Lato",
    color: "#1F2E99",
  },
  titlecenter: { width: "100%", textAlign: "center" },
  title4: {
    fontSize: 15,
    fontFamily: "Lato",
    color: "#000000",
  },
  title5: {
    color: "#0009EC",
    fontFamily: "Lato",
    fontSize: 13,
    marginLeft: 6,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  title6: {
    fontSize: 18,
    fontFamily: "LatoBold",
    color: "#000000",
  },
  title7: {
    fontSize: 13,
    marginTop: 8,
    fontFamily: "Lato",
    color: "#000000",
  },
  title8: {
    fontSize: 13,
    fontFamily: "Lato",
    color: "#000000",
  },
  title9: {
    fontSize: 13,
    fontFamily: "Lato",
    color: "#868686",
    marginTop: 4,
  },
});
