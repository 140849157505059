import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { BarLoader, ClipLoader } from "react-spinners";

import { css, cx } from "@emotion/css";

import Clickable from "../components/Clickable";
import { Comment } from "../components/Comment";
import CommentInput from "../components/CommentInput";
import Container from "../components/Container";
import PinIcon from "../components/icons/PinIcon";
import { toastError } from "../components/Notifications";
import { POTextNu } from "../components/POText";
import ScreenBase from "../components/ScreenBase";
import MediaHeadBar from "../components/ui/MediaHeadBar";
import WoundVisualizerPro from "../components/wounds/WoundVisualizerPro";
import { useAsyncEffect } from "../hooks/useAsyncEffect";
import { useNav } from "../hooks/useNav";
import useParams from "../hooks/useParams";
import { requests } from "../misc";
import { getMediaTypeLabel, MediaType, theme } from "../misc/constants";
import { RequestError } from "../misc/requests";
import type { IPatientMedia, IPatientMediaComment } from "../misc/types";
import { getRecoveryWeek } from "../misc/user-utils";
import { withStore } from "../misc/utils";

const SIDEBAR_SIZE = 490;

export default function PatientMediaDetailsScreen() {
  const { patientId, mediaId } = useParams("clinician", "PatientMediaDetailsScreen");
  const nav = useNav<"clinician">();

  const [data, setData] = useState<any>(null);
  const [pinned, setPinned] = useState(false);

  const strDayno = data?.dayno ?? "?";
  const strWeek = data?.dayno !== undefined && data?.dayno !== null ? getRecoveryWeek(data.dayno) : "?";
  const strMediaType = getMediaTypeLabel(data?.phototypeid) || "Unknown type";

  const handleClose = useCallback(() => {
    if (nav.canGoBack()) nav.goBack();
    else
      nav.navigate("PatientMediaScreen", {
        patientid: patientId,
      });
  }, [patientId]);

  const pin = useCallback(async () => {
    // TODO: add & migrate to v1 endpoint
    const oldPinned = pinned;
    setPinned(!pinned);
    try {
      await requests.post(`/clinicians/pinpatientmedia`, {
        patientmediaid: data.id,
        ispinned: Number(!pinned),
      });
    } catch (err) {
      console.error("Pinning patient media failed:", err);
      toastError("(Un)pinning this patient media was unsuccessful");
      setPinned(oldPinned);
    }
  }, [data, pinned]);

  useAsyncEffect(async () => {
    const res = await requests.get(`/v1/patients/${patientId}/media/${mediaId}`, { responseType: "response" });
    if (res.ok) {
      const { media } = await res.json();
      setData(media);
      setPinned(!!media.ispinned);
    } else {
      switch (res.status) {
        case 401:
        case 404:
          nav.navigate("DashboardScreen");
          break;
        default:
          alert("Failed to fetch media");
          console.error(RequestError.fromResponse(res));
          setData({});
      }
    }
  }, []);

  return (
    <ScreenBase className={cx("POMediaDetailsScreen-root", styles.root)}>
      <MediaHeadBar onClose={handleClose}>
        <div className="POMediaDetailsScreen-title">
          Day {strDayno} / Week {strWeek} / {strMediaType}
          {data && (
            <span className="POMediaDetailsScreen-timestamp">{moment(data.created_at).format("DD.MM.YY hh:mm")}</span>
          )}
        </div>
        {!!data && (
          <div className="POMediaDetailsScreen-pinner">
            <Clickable
              onClick={async () => {
                await pin();
              }}>
              <PinIcon filled={pinned} color={theme.primary} />
            </Clickable>
          </div>
        )}
      </MediaHeadBar>
      <div className="POMediaDetailsScreen-content">
        {data ? (
          <div className="POMediaDetailsScreen-contentInner">
            <MediaPresenter media={data} />
          </div>
        ) : (
          <div className="POSpinnerContainer">
            <BarLoader color={theme.primary} />
          </div>
        )}
      </div>
    </ScreenBase>
  );
}

interface MediaPresenterProps {
  media: IPatientMedia;
}

function MediaPresenter({ media }: MediaPresenterProps) {
  return (
    <div className="POMediaDetailsScreen-presenter">
      <MediaPresenterInner media={media} />
      <div className="POMediaDetailsScreen-sidebar POMediaDetailsScreen-comments">
        <Comments media={media} />
      </div>
    </div>
  );
}

function MediaPresenterInner({ media }: MediaPresenterProps) {
  switch (media?.phototypeid) {
    case MediaType.WoundPhoto:
    case MediaType.DEPRECATD_WoundTop:
    case MediaType.DEPRECATD_WoundBottom:
      return <WoundVisualizerPro media={media} />;
    case MediaType.WoundVideo:
    case MediaType.VoiceRecording:
      return (
        <div className="POMediaDetailsScreen-presenterVideo">
          <video controls>
            <source src={media.video_url!} />
          </video>
        </div>
      );
    default:
      if (media) {
        console.error(`Unknown media type: ${media?.phototypeid}`);
      }
      return null;
  }
}

interface CommentsProps {
  media: IPatientMedia;
  className?: string;
}

const Comments = withStore<CommentsProps>(({ userStore, media, className }) => {
  const [loading, setLoading] = useState(true);
  const [sendingComment, setSendingComment] = useState(false);
  const [comments, setComments] = useState<IPatientMediaComment[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);

  const sendComment = useCallback(
    async (comment: string) => {
      setSendingComment(true);
      try {
        await requests.post(`/v1/patient-media/${media.id}/comments`, {
          patientmediaid: media.id,
          message: comment.trim(),
        });
        loadComments();
      } catch (err) {
        console.error("Comment submission failed:", err);
        toastError("Comment submission unsuccessful");
      } finally {
        setSendingComment(false);
      }
    },
    [media],
  );

  const loadComments = useCallback(async () => {
    try {
      const { comments } = await requests.get(`/v1/patient-media/${media.id}/comments`);
      setComments(comments);
      setTimeout(() => {
        containerRef.current?.scrollTo(0, containerRef.current.scrollHeight);
      }, 100);
    } catch (err) {
      console.error("Failed to load comments:", err);
      toastError("Retrieving comments from server unsuccessful");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadComments();
  }, [media]);

  return (
    <div className={cx("POMediaDetailsScreen-commentsContainer", styles.comments, className)}>
      <div className="POMediaDetailsScreen-commentsHeader">
        <POTextNu fontFamily="Lato" fontWeight={700} fontSize={18} lineHeight={1.2} color={theme.primary}>
          Comments
        </POTextNu>
      </div>

      <Container className="POMediaDetailsScreen-commentsContent">
        {loading ? (
          <div className="POSpinnerContainer">
            <ClipLoader color={theme.primary} />
          </div>
        ) : (
          <div ref={containerRef} style={{ width: "100%", height: "100%", overflow: "auto" }}>
            {comments.map((comment, i) => (
              <Comment
                key={i}
                photourl={comment.avatar_url}
                firstname={comment.commenter_firstname}
                lastname={comment.commenter_lastname}
                date={comment.created_at}
                msg={comment.message}
              />
            ))}
          </div>
        )}
      </Container>

      {userStore.readonly != 1 && <CommentInput disabled={sendingComment} onSendComment={sendComment} />}
    </div>
  );
});

const styles = Object.freeze({
  root: css`
    width: 100vw;
    height: 100vh;

    .POMediaDetailsScreen-title {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-self: center;
      align-items: baseline;
      gap: 25px;
      padding: 0 25px;
      font-family: Lato;
      font-size: 15px;

      .POMediaDetailsScreen-timestamp {
        font-size: 13px;
        color: #868686;
      }
    }

    .POMediaDetailsScreen-pinner {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
    }

    .POMediaDetailsScreen-content {
      position: relative;
      display: flex;
      flex: 1;
    }

    .POMediaDetailsScreen-contentInner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
    }

    .POMediaDetailsScreen-presenter {
      display: flex;
      flex: 1;

      .POMediaDetailsScreen-presenterVideo {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        max-height: 100%;

        video {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    .POMediaDetailsScreen-sidebar {
      width: ${SIDEBAR_SIZE}px;
    }

    .POMediaDetailsScreen-comments {
      padding: 40px 15px 80px 15px;
    }

    .POSpinnerContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  `,
  comments: css`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 8px;
    overflow: hidden;

    .POMediaDetailsScreen-commentsHeader {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 68px;
      padding-left: 24px;
      border-bottom: 1px solid #e8e9ed;
    }

    .POMediaDetailsScreen-commentsContent {
      flex-grow: 1;
      border-bottom: 1px solid #e8e9ed;
      overflow: auto;
    }

    .POCommentInput-root {
      .POInput-root {
        flex: 1;
      }
    }
  `,
});
