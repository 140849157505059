import type { CSSProperties, MouseEvent, ReactNode } from "react";
import React, { useCallback, useEffect, useState } from "react";

import { css, cx } from "@emotion/css";

import { theme } from "../../misc/constants";
import { UserAvatar } from "../Avatar";
import Logo from "../icons/Logo";
import Link from "../ui/Link";
import PopoverMenu from "../ui/PopoverMenu";
import CirclePlusIcon from "../icons/CirclePlusIcon";
import ShieldUserIcon from "../icons/ShieldUserIcon";
import GearIcon from "../icons/GearIcon";

export interface PostopAdminLayoutProps {
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
  extraHeader?: ReactNode;
}

/** The base layout */
export default function PostopAdminLayout({
  children,
  className,
  style,
  extraHeader,
}: Readonly<PostopAdminLayoutProps>) {
  // no need for zendesk in our admin pages
  useEffect(() => {
    document.getElementById("zendesk")?.remove();
  }, []);

  return (
    <div className={cx("POAdminLayout-root", styles, className)} style={style}>
      <nav className="LayoutHeader">
        <div className="LayoutHeader-left">
          <Link url="/postopadmin" title="Return to homepage">
            <Logo />
          </Link>
        </div>
        <ul>
          <li>
            <Link url="/postopadmin/hospitals">
              <CirclePlusIcon size={30} />
              Hospitals
            </Link>
          </li>
          <li>
            <Link url="/postopadmin/hospital-admins">
              <ShieldUserIcon size={30} />
              Hospital Admins
            </Link>
          </li>
          <li>
            <AdvancedMenu />
          </li>
        </ul>
        <div className="LayoutHeader-right">
          <Link url="/postopadmin/profile" title="to your Profile">
            <UserAvatar
              size={40}
              className={css`
                border-color: ${theme.primaryLight};
              `}
            />
          </Link>
        </div>
      </nav>
      {extraHeader}
      <section className="LayoutContent">{children}</section>
    </div>
  );
}

function AdvancedMenu() {
  const [showPopover, setShowPopover] = useState(false);

  const open = useCallback((e: MouseEvent) => {
    e.preventDefault();
    setShowPopover(true);
  }, []);

  const close = useCallback(() => {
    setShowPopover(false);
  }, [showPopover]);

  return (
    <PopoverMenu
      label={
        <Link url="#" onClick={open}>
          <GearIcon size={30} />
          Advanced
        </Link>
      }
      show={showPopover}
      onClickOutside={close}
      className="standard-shadow">
      <ul>
        <li>
          <Link url="/postopadmin/patientsearch" onClick={close}>
            Patients
          </Link>
        </li>
        <li>
          <Link url="/postopadmin/cliniciansearch" onClick={close}>
            Clinicians
          </Link>
        </li>
        <li>
          <Link url="/postopadmin/wound-tags" onClick={close}>
            Wound Tags
          </Link>
        </li>
        <li>
          <Link url="/postopadmin/media" onClick={close}>
            Media
          </Link>
        </li>
      </ul>
    </PopoverMenu>
  );
}

const styles = css`
  width: 100vw;
  min-height: 100vh;

  .LayoutHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    padding: 1.5em;
    background: white;

    .LayoutHeader-left,
    .LayoutHeader-right {
      width: 200px;
    }
    .LayoutHeader-left {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
    .LayoutHeader-right {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    h1 {
      font-family: Lato, Arial, Helvetica, sans-serif;
      font-size: 24px;
      line-height: 1.2em;
      font-weight: 700;
      color: #0077ff;
    }

    ul {
      list-style-type: none;
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      margin: 0;
      padding: 0;
      gap: 4em;
      text-align: center;
      overflow: hidden;
    }

    li {
      flex: 1;
      padding: 0;
    }

    li a {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      gap: 1em;
      font-family: "Lato", Arial, Helvetica, sans-serif;
      font-size: 16px;
      line-height: 1.2em;
      font-weight: 400;
      text-decoration: none;
      color: #c8c8c8;

      &:hover,
      &:active,
      &.active {
        color: ${theme.primaryLight};
      }
    }
  }

  section.LayoutContent {
    /* background-color: #fcf5ef; */
    height: 100%;
    max-width: 1200px;
    padding: 1.5em;
    margin: 0 auto;
  }

  .navIcon {
    width: 107px;
    height: 25px;
  }
`;
