import type { MouseEvent, WheelEvent } from "react";
import React, { useCallback, useEffect } from "react";
import BarLoader from "react-spinners/BarLoader";

import { css, cx } from "@emotion/css";

import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import { useEmbedding, useSAM } from "../../hooks/useSam";
import useWindowResize from "../../hooks/useWindowResize";
import { requests } from "../../misc";
import { getImageUrl, theme } from "../../misc/constants";
import { withReducerContext } from "../../misc/reducer-context";
import { isChildOf, onImageLoaded } from "../../misc/utils";
import Container from "../Container";
// import AnnotationTutorial from "./AnnotationTutorial";
import { DataMask, ImageMask } from "./Masks";
import { OverlayTools } from "./OverlayTools";
import useSegmentAPI from "./useSegmentAPI";
import useTransformAPI from "./useTransformAPI";
import { WoundAnnotations } from "./WoundAnnotation";
import type { Annotation } from "./WoundVizContext";
import { Context, useWoundVizContext } from "./WoundVizContext";

import type { IPatientMedia } from "../../misc/types";
import { MagicCut } from "./MagicCut";

export interface WoundVisualizerProProps {
  media: IPatientMedia;
  withAnnotations?: boolean;
}

const WoundVisualizerPro = withReducerContext(
  ({ media, withAnnotations }: Readonly<WoundVisualizerProProps>): JSX.Element => {
    // state & update are both stable. calling update mutates state & triggers a re-render
    const [state, update] = useWoundVizContext();
    useWindowResize();

    useEffect(() => {
      update({
        media,
        enabled: withAnnotations,
      });
    }, [media, withAnnotations]);

    const isAnnotationsEnabled = state.enabled && state.isAnnotationsEnabled;

    const sam = useSAM();
    const embedding = useEmbedding(media);
    useEffect(() => {
      Object.assign(state, { sam, embedding });
    }, [sam, embedding]);

    const transformer = useTransformAPI();
    const segmenter = useSegmentAPI();

    const handleMouseDown = useCallback((e: MouseEvent) => {
      e.preventDefault();
      const [x, y] = [e.clientX, e.clientY];

      // LMB
      if (e.button === 0) {
        update({ isLmbDown: true });
        if (state.isPanMode) {
          transformer.startPan(x, y);
        } else {
          transformer.startBox(...transformer.toImageCoords(x, y));
          update({ previewMask: null });
        }
      }
      // MWHL
      else if (e.button === 1) {
        transformer.startPan(x, y);
      }
    }, []);

    const handleMouseMove = useCallback(async (e: MouseEvent) => {
      if (state.isPanning) {
        transformer.updatePan(e.clientX, e.clientY);
      } else if (state.isLmbDown) {
        const [x, y] = transformer.toImageCoords(e.clientX, e.clientY);
        transformer.updateBox(x, y);
        segmenter.updatePreviewMask();
      }
    }, []);

    const handleMouseUp = useCallback(async (e: MouseEvent) => {
      update({ isLmbDown: false });

      // create segment on left click (unless panning)
      if (e.button === 0) {
        if (state.isPanMode) {
          if (!state.isPanning) return;
          transformer.stopPan(e.clientX, e.clientY);
        }
      }
      // release mouse wheel to stop panning
      else if (e.button === 1) {
        if (state.isPanning) {
          transformer.stopPan(e.clientX, e.clientY);
        } else {
          transformer.stopBox(e.clientX, e.clientY);
        }
      }
      // custom right click context menu
      else if (e.button === 2) {
        // for future use
      }
    }, []);

    const handleMouseOut = useCallback((e: MouseEvent) => {
      if (state.panStart && !isChildOf(e.relatedTarget as any, state.containerRef.current!))
        transformer.stopPan(e.clientX, e.clientY);
    }, []);

    const handleMouseWheel = useCallback((e: WheelEvent) => {
      transformer.zoom(-Math.sign(e.deltaY));
    }, []);

    useAsyncEffect(async () => {
      if (!media) return;

      // center image on initial load
      if (media.photourl) {
        onImageLoaded(state.imageRef.current!, () => {
          transformer.setZoom(2);
        });
      }

      const res = await requests.get(`/v1/patient-media/${media.id}/wound-annotations`);
      update({
        annotations: res.data.map((x: Annotation) => Object.assign({ visible: true }, x)),
      });
    }, [media]);

    return (
      <div className={cx("POWoundVizPro-root", styles.root)}>
        {media ? (
          <>
            <div className={cx("POWoundVizPro-mediaContainer", styles.mediaContainer)}>
              <div
                ref={state.containerRef}
                className="POWoundVizPro-mediaContainerInner"
                onMouseMove={handleMouseMove}
                onMouseOut={handleMouseOut}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onWheel={handleMouseWheel}>
                <div ref={state.panTargetRef} className="POWoundVizPro-mediaWrapper">
                  <img
                    ref={state.imageRef}
                    src={getImageUrl(media.photourl || "")}
                    className="POWoundVizPro-media"
                    onResize={() => {
                      update({});
                    }}
                  />
                  <div className="POWoundVizPro-mediaOverlay">
                    {isAnnotationsEnabled &&
                      state.annotations
                        .filter((a) => a.visible && !!a.mask)
                        .map((a) => <ImageMask key={a.id} mask={a.mask} color={a.color} />)}
                    <div
                      ref={state.boxRef}
                      className="POWoundVizPro-previewMaskBox"
                      style={{ display: state.box ? "block" : "none" }}
                    />
                    {isAnnotationsEnabled && !!state.previewMask && (
                      <DataMask
                        mask={state.previewMask}
                        color="rgba(100, 191, 244, 0.75)"
                        className="POWoundVizPro-previewMask"
                      />
                    )}
                  </div>
                </div>
                <OverlayTools />
              </div>
            </div>
            {withAnnotations && (
              <div className={cx("POWoundVizPro-tools", styles.tools)}>
                {/* <AnnotationTutorial /> */}
                <MagicCut hasEmbedding={!!media.sam_embedding} />
                {!!media.sam_embedding && (
                  <Container style={{ flex: 1 }}>
                    <WoundAnnotations />
                  </Container>
                )}
              </div>
            )}
          </>
        ) : (
          <div
            className={css`
              display: flex;
              flex: 1;
              align-items: center;
              justify-content: center;
            `}>
            <BarLoader color={theme.primary} />
          </div>
        )}
      </div>
    );
  },
  Context,
);

export default WoundVisualizerPro;

const styles = Object.freeze({
  root: css`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: stretch;
  `,
  mediaContainer: css`
    position: relative;
    flex: 1;
    background: #fafafa;

    .POWoundVizPro-mediaContainerInner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    .POWoundVizPro-mediaWrapper {
      position: absolute;
      width: fit-content;
      transform-origin: top left;
    }

    img.POWoundVizPro-media {
      display: block;
    }

    .POWoundVizPro-mediaOverlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }

    .POWoundVizPro-previewMaskBox {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border: 2.5px solid white;
    }
  `,
  tools: css`
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    gap: 24px;
    padding: 40px 15px;
    width: 490px;
  `,
});
