import * as React from "react";
import { TextInput, Text, View, StyleSheet, Dimensions, Image, TouchableOpacity } from "react-native";
import { OrangeButton } from "./OrangeButton";
import { Hairline } from "./Hairline";
import RadioButton from "../components/RadioButton";
import Moment from "moment";
import validate from "../screens/validate_wrapper";
import Modal from "modal-react-native-web";
import DatePicker from "./ui/DatePicker";
import CountrySelector from "./CountrySelector";

export const UpdatePatient = (props) => {
  const [firstname, setFirstname] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [gender, setGender] = React.useState(0);
  const [email, setEmail] = React.useState(null);
  const [dateOfBirth, setDateOfBirth] = React.useState(new Date());
  const [gsm, setGsm] = React.useState("");
  const [gsmcountrycode, setGsmcountrycode] = React.useState("");
  const [countrycode, setCountrycode] = React.useState("");
  const [height, setHeight] = React.useState(null);
  const [weight, setWeight] = React.useState(null);
  const [nhsnumber, setNhsnumber] = React.useState("");

  const [errorText, setErrorText] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const titleCase = (str) => {
    return str.replace(/\w\S*/g, (t) => {
      return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase();
    });
  };

  React.useEffect(() => {
    setNhsnumber(props.patient.nhsnumber.toString());
    setFirstname(props.patient.firstname);
    setLastname(props.patient.lastname);
    setGender(props.patient.gender);
    setEmail(props.patient.email);
    setGsm(props.patient.gsm);
    setGsmcountrycode(props.patient.gsmcountrycode);
    setCountrycode(props.patient.countrycode);
    setDateOfBirth(Moment(props.patient.dateofbirth).toDate());
    setWeight(props.patient.weight !== null ? props.patient.weight.toString() : "");
    setHeight(props.patient.height !== null ? props.patient.height.toString() : "");
  }, []);

  function removeView() {
    console.log("removeView");
    props.removeView();
  }

  function isValidDate(dateObject) {
    return Moment(dateObject, "DD/MM/YYYY").isValid();
  }

  function validateForm() {
    setIsLoading(true);
    setErrorText("");
    const firstnameError = validate("firstname", firstname);
    const lastnameError = validate("lastname", lastname);
    const emailError = validate("emailoptional", email);
    const gsmError = validate("gsm", gsm);
    const heightError = validate("heightoptional", height);
    const weightError = validate("weightoptional", weight);
    const genderError = validate("biggerthanzero", gender);
    const nhsnumberError = validate("nhsnumber", nhsnumber);

    if (
      !nhsnumberError &&
      !firstnameError &&
      !lastnameError &&
      !genderError &&
      !weightError &&
      !heightError &&
      !emailError &&
      !gsmError
    ) {
      sendform();
    } else {
      setErrorText(
        "Please check fields: " +
          (nhsnumberError ? "NHS number - " : "") +
          (firstnameError ? " first name" + " - " : "") +
          (lastnameError ? "last name - " : "") +
          (genderError ? " gender  - " : "") +
          (emailError ? " email -" : "") +
          (gsmError ? " phone number - " : "") +
          (weightError ? " weight -" : "") +
          (heightError ? " height -" : ""),
      );
      setIsLoading(false);
    }
  }

  function sendform() {
    console.log("update patient");

    fetch(global.apiurl + "/clinicians/updatepatient", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
      body: JSON.stringify({
        patientid: props.patient.id,
        nhsnumber,
        firstname,
        lastname,
        email,
        gsm,
        gsmcountrycode,
        countrycode,
        dateofbirth: Moment(dateOfBirth).format("YYYY-MM-DD"),
        gender,
        weight: weight !== "" ? weight : undefined,
        initialweight: props.patient.weight,
        height: height !== "" ? height : undefined,
      }),
    })
      .then(async (res) => {
        if (res.status === 422) {
          setIsLoading(false);
          throw new Error("Request failed.");
        }
        if (res.status !== 200 && res.status !== 201) {
          setIsLoading(false);
          console.log("Error!");
          throw new Error("Could not authenticate you!");
        }
        return await res.json();
      })
      .then((resData) => {
        console.log(resData);
        if (resData.result) {
          console.log("DONE");
          props.onFinish();
        } else {
          setIsLoading(false);
          alert("Unable to update, please try later");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        alert("Unable to update, please try later");
        console.log(err);
      });
  }

  const dimensions = Dimensions.get("window");
  const modalmargin = (dimensions.height - 702) / 2;

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={props.visible}
      buggy={props.userStore.screenupdate}
      onBackdropPress={() => props.removeView()}>
      <View style={[{ backgroundColor: "rgba(52, 52, 52, 0.8)", paddingTop: modalmargin, paddingBottom: modalmargin }]}>
        <View
          style={{
            borderRadius: 8,
            backgroundColor: "#ffffff",
            width: 850,
            height: 702,
            marginBottom: 1000,
            alignSelf: "center",
          }}>
          <View
            style={{
              flexDirection: "row",
              width: "100%",
              borderBottomColor: "#E8E9ED",
              borderBottomWidth: 1,
              height: 70,
            }}>
            <Text style={styles.title1}>Edit patient</Text>
            <Text
              style={{
                color: "#FF0000",
                flex: 1,
                textAlign: "center",
                fontFamily: "LatoBold",
                fontSize: 14,
                marginTop: 28,
              }}>
              {errorText}
            </Text>

            <TouchableOpacity
              style={{ position: "absolute", right: 0 }}
              onPress={() => {
                removeView();
              }}>
              <View
                style={{
                  backgroundColor: "#FCF5EF",
                  width: 93,
                  height: 70,
                  flexDirection: "row",
                  borderColor: "#E8E9ED",
                  borderWidth: 1,
                  borderTopRightRadius: 8,
                  alignItems: "center",
                }}>
                <Image
                  source={require("../assets/images/closecrossicon.png")}
                  style={{ width: 10, height: 10, marginRight: 8, marginLeft: 23 }}
                />
                <Text style={{ fontFamily: "Lato", fontSize: 13, color: "#1F2E99" }}>Close</Text>
              </View>
            </TouchableOpacity>
          </View>

          <View style={{ flexDirection: "row", paddingLeft: 24 }}>
            <View style={{ width: 652 }}>
              <View style={{ flexDirection: "row", marginTop: 32 }}>
                <View style={{ width: 250 }}>
                  <Text style={styles.title3}>NHS number</Text>
                  <View style={[styles.shadowinput, { width: 250 }]}>
                    <TextInput
                      style={styles.postopinput}
                      keyboardType="number-pad"
                      maxLength={10}
                      onChangeText={(text) => {
                        setNhsnumber(parseInt(text) ? parseInt(text).toString() : "");
                      }}
                      value={nhsnumber}
                      returnKeyType="done"
                    />
                  </View>
                </View>
                <View style={{ width: 250, marginLeft: 18 }}>
                  <Text style={styles.title3}>First name</Text>
                  <View style={[styles.shadowinput, { width: 250 }]}>
                    <TextInput
                      style={[styles.postopinput, { textAlign: "left", width: 250 }]}
                      keyboardType="name-phone-pad"
                      maxLength={50}
                      numberOfLines={1}
                      onChangeText={(text) => {
                        setFirstname(titleCase(text));
                      }}
                      value={firstname}
                      returnKeyType="done"
                    />
                  </View>
                </View>
                <View style={{ width: 250, marginLeft: 18 }}>
                  <Text style={styles.title3}>Last name</Text>
                  <View style={[styles.shadowinput, { width: 250 }]}>
                    <TextInput
                      style={[styles.postopinput, { textAlign: "left", width: 250 }]}
                      keyboardType="name-phone-pad"
                      maxLength={50}
                      numberOfLines={1}
                      onChangeText={(text) => {
                        setLastname(titleCase(text));
                      }}
                      value={lastname}
                      returnKeyType="done"
                    />
                  </View>
                </View>
              </View>
              <Text style={[styles.title3, { marginTop: 32 }]}>Gender</Text>

              <View style={{ flexDirection: "row", marginTop: 8 }}>
                <RadioButton
                  selected={gender === 1}
                  onSelected={() => {
                    setGender(1);
                  }}
                  title="Male"
                />
                <RadioButton
                  selected={gender === 2}
                  onSelected={() => {
                    setGender(2);
                  }}
                  title="Female"
                />
                <RadioButton
                  selected={gender === 3}
                  onSelected={() => {
                    setGender(3);
                  }}
                  title="Other"
                />
              </View>
              <Text style={[styles.title3, { marginTop: 32, marginBottom: 8 }]}>Date of birth</Text>

              <DatePicker
                selected={dateOfBirth}
                onChange={(date) => {
                  setDateOfBirth(date!);
                }}
                dateFormat="dd/MM/yyyy"
                todayButton="Today"
              />

              <View style={{ flexDirection: "row", marginTop: 32 }}>
                <View style={{ width: 250 }}>
                  <Text style={styles.title3}>Email address (optional)</Text>

                  <View style={[styles.shadowinput, { width: 250 }]}>
                    <TextInput
                      style={styles.postopinput}
                      keyboardType="email-address"
                      maxLength={50}
                      numberOfLines={1}
                      onChangeText={(text) => {
                        setEmail(text || null);
                      }}
                      value={email}
                      returnKeyType="done"
                    />
                  </View>
                </View>
                {props.patient.inviteaccepted !== 1 && (
                  <View style={{ width: 250, marginLeft: 18 }}>
                    <Text style={styles.title3}>Phone number</Text>

                    <View style={[styles.shadowinput, { width: 250 }]}>
                      <CountrySelector
                        countrycode={countrycode}
                        gsmcountrycode={gsmcountrycode}
                        setGsmcountrycode={(value) => {
                          setGsmcountrycode(value);
                        }}
                        setCountrycode={(value) => {
                          setCountrycode(value);
                        }}
                      />
                      <TextInput
                        style={[styles.postopinput, { textAlign: "left", width: 250 }]}
                        keyboardType="number-pad"
                        maxLength={15}
                        onChangeText={(text) => {
                          setGsm(text);
                        }}
                        value={gsm}
                        returnKeyType="done"
                      />
                    </View>
                  </View>
                )}
              </View>

              <View style={{ width: 250 }}>
                <View style={{ flexDirection: "row" }}>
                  <View style={{ width: 85 }}>
                    <Text style={[styles.title3, { marginTop: 24 }]}>Height (optional)</Text>
                    <View style={{ flexDirection: "row" }}>
                      <View style={[styles.shadowinput, { width: 60 }]}>
                        <TextInput
                          style={[styles.postopinput, { textAlign: "center", marginLeft: 0, width: 60 }]}
                          keyboardType="number-pad"
                          maxLength={3}
                          onChangeText={(text) => {
                            setHeight(parseInt(text) ? parseInt(text).toString() : "");
                          }}
                          value={height}
                          returnKeyType="done"
                        />
                      </View>
                      <Text style={[styles.title3, { marginTop: 25, marginLeft: 8 }]}>cm</Text>
                    </View>
                  </View>
                  <View style={{ width: 85, marginLeft: 42 }}>
                    <Text style={[styles.title3, { marginTop: 24 }]}>Weight (optional)</Text>
                    <View style={{ flexDirection: "row" }}>
                      <View style={[styles.shadowinput, { width: 60 }]}>
                        <TextInput
                          style={[styles.postopinput, { textAlign: "center", marginLeft: 0, width: 60 }]}
                          keyboardType="number-pad"
                          maxLength={3}
                          onChangeText={(text) => {
                            setWeight(parseInt(text) ? parseInt(text).toString() : "");
                          }}
                          value={weight}
                          returnKeyType="done"
                        />
                      </View>
                      <Text style={[styles.title3, { marginTop: 25, marginLeft: 8 }]}>kg</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>

          <View style={{ position: "absolute", bottom: 16, alignItems: "center", width: 850 }}>
            <Hairline />
            <OrangeButton
              disabled={isLoading}
              onPress={() => {
                validateForm();
              }}
              title="Update patient"
              style={{ width: 250, marginTop: 16 }}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  postopinput: {
    flex: 1,
    outlineWidth: 0,
    color: "#1F2E99",
    fontFamily: "Lato",
    fontSize: 15,
    marginRight: 10,
    marginLeft: 20,
  },
  shadowinput: {
    marginTop: 8,
    height: 48,
    flexDirection: "row",
    borderRadius: 15,
    shadowColor: "#818181",
    backgroundColor: "#FFFFFF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
  title1: {
    fontSize: 18,
    fontFamily: "LatoBold",
    color: "#1F2E99",
    marginTop: 26,
    marginLeft: 24,
  },

  title3: {
    fontSize: 13,
    fontFamily: "Lato",
    color: "#798599",
  },
});
