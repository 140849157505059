import React, { useState, useEffect } from "react";
import { Text, View, StyleSheet, Dimensions, Image, TouchableOpacity, Modal } from "react-native";

import { A } from "@expo/html-elements";
import { PatientMenu } from "./LeftMenu";
import { PatientHeader } from "../components/PatientHeader";
import { MainFrame } from "../components/MainFrame";

export const MainFramePatient = (props) => {
  const [patientReadLogs, setPatientReadlogs] = useState({});

  async function fetchPatientReadLogs() {
    try {
      const response = await fetch(`${global.apiurl}/v1/patients/${props.patient.id}/latest_read_log`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: global.tokenparam || "",
        },
      });

      const resData = await response.json();

      if (resData.result) {
        setPatientReadlogs(resData.lastReadLogs);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchPatientReadLogs();
  }, []);

  const dimensions = Dimensions.get("window");

  // readlogs do not have an order, so we need to sort them
  // by createdAt
  // TODO: this should be done in the backend
  const readlogs = props?.patient?.readlogs ?? [];
  if (readlogs && readlogs.length > 0) {
    readlogs.sort((a, b) => {
      if (a?.createdAt > b?.createdAt) {
        return -1;
      }
      if (a?.createdAt < b?.createdAt) {
        return 1;
      }
      return 0;
    });
  }

  const patient_id = props?.patient?.id;
  const unreadcount = patientReadLogs?.[patient_id]?.unreadcount ?? 0;

  return (
    <MainFrame
      setlastreadmessage={props.setlastreadmessage}
      messages={props.messages}
      patient={props.patient}
      patientlist
      userStore={props.userStore}
      style={[{ alignItems: "flex-start", flexDirection: "row", width: "100%" }]}>
      <View style={{ width: 181, alignItems: "center", height: dimensions.height - 183 + 109 }}>
        {props.selected == 22 && (
          <View style={{ height: 106, alignItems: "center", justifyContent: "center" }}>
            <A href={"/patientmediaai?patientid=" + patient_id}>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Image
                  source={require("../assets/images/backicon.png")}
                  style={{ width: 14.333, height: 10, marginRight: 11.5 }}
                />
                <Text style={styles.title1}>Back to Patient Media</Text>
              </View>
            </A>
          </View>
        )}
        {props.selected != 22 && (
          <View style={{ height: 106, alignItems: "center", justifyContent: "center" }}>
            <A href="/patients">
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Image
                  source={require("../assets/images/backicon.png")}
                  style={{ width: 14.333, height: 10, marginRight: 11.5 }}
                />
                <Text style={styles.title1}>Back to patient list </Text>
              </View>
            </A>
          </View>
        )}

        <View style={{ width: 181, backgroundColor: "#ffffff", paddingTop: 11, flex: 1 }}>
          <PatientMenu patientId={patient_id} messageCount={unreadcount} selected={props.selected} />
        </View>
        <View style={{ flexDirection: "row" }}>
          <View style={[styles.whitebox]}></View>
        </View>
      </View>
      <View style={{ flexGrow: 1, alignSelf: "stretch" }}>
        <PatientHeader userStore={props.userStore} patient={props?.patient} clinician={props?.clinician} />
        <View
          style={[
            {
              paddingLeft: 63,
              paddingRight: 63,
              paddingTop: 20,
              alignSelf: "stretch",
              borderTopColor: "#1F2E99",
              borderTopWidth: 0.3,
              flexDirection: "row",
            },
            props.style,
          ]}>
          {props.children}
        </View>
      </View>
      <View></View>
    </MainFrame>
  );
};

const styles = StyleSheet.create({
  whitebox: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    shadowColor: "#818181",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    elevation: 5,
  },
  title1: {
    fontFamily: "Lato",
    fontSize: 13,
    color: "#1F2E99",
  },
});
