import { inject, observer } from "mobx-react";
import Modal from "modal-react-native-web";
import Moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Animated,
  Dimensions,
  FlatList,
  Image,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";

import { A } from "@expo/html-elements";

import { Avatar } from "../components/Avatar";
import { BlueButton } from "../components/BlueButton";
import { Hairline } from "../components/Hairline";
import { InvitePatient } from "../components/InvitePatient";
import { MainFrame } from "../components/MainFrame";
import { toastError } from "../components/Notifications";
import { PatientFilter } from "../components/PatientFilter";
import { PatientRecoveryStatus, PatientRecoveryStatusLeftBar } from "../components/PatientRecoveryStatus";
import { SortIcon } from "../components/SortIcon";
import { requests } from "../misc";

const TempScreen = inject("userStore")(
  observer(({ userStore }) => {
    const [searchtext, setSearchtext] = React.useState("");

    const [invitemodalvisible, setInvitemodalvisible] = React.useState(false);
    const dimensions = Dimensions.get("window");
    const [data, setData] = React.useState(null);
    const [tempData, setTempData] = React.useState(null);
    const [sortKey, setSortKey] = React.useState("namedesc");
    const [patientReadLogs, setPatientReadlogs] = useState({});
    const [specialtiesFilter, setSpecialtiesFilter] = useState<number[]>([]);
    const opacity = new Animated.Value(1);
    function calculateRecoveryTime(operationDate: string) {
      if (!operationDate) return "-";

      const diffDays = recoveryDay(operationDate);

      if (diffDays < 0) {
        return `${Math.abs(diffDays)} days until operation`;
      } else if (diffDays === 0) {
        return "Day 0";
      } else {
        return `${diffDays} days / week ${1 + Math.floor((diffDays - 1) / 7)}`;
      }
    }

    function recoveryDay(operationDate: string) {
      const given = Moment(operationDate.replace("'", ""));
      const current = Moment().startOf("day");
      return Math.floor(Moment.duration(current.diff(given)).asDays());
    }

    function fadeIn() {
      setInvitemodalvisible(true);
    }

    function fadeOut() {
      Animated.timing(opacity, {
        toValue: 0,
        duration: 175,
      }).start(() => {
        setInvitemodalvisible(false);
      });
      fetchData();
    }

    function sortResults(newsortkey) {
      if (newsortkey == sortKey) {
        newsortkey = newsortkey + "desc";
      }
      updateSearch(searchtext, newsortkey);
    }
    function updateSearch(text, sortKey) {
      setSortKey(sortKey);
      console.log("list with sortkey:" + sortKey);
      function compare(a, b) {
        if (sortKey == "operationdatedesc") {
          if (a.operationdate > b.operationdate) return 1;
          if (a.operationdate < b.operationdate) return -1;
        }

        if (sortKey == "operationdate") {
          if (a.operationdate < b.operationdate) return 1;
          if (a.operationdate > b.operationdate) return -1;
        }

        if (sortKey == "name") {
          if (a.firstname.toLowerCase() > b.firstname.toLowerCase()) return 1;
          if (a.firstname.toLowerCase() < b.firstname.toLowerCase()) return -1;
          if (a.lastname.toLowerCase() > b.lastname.toLowerCase()) return 1;
          if (a.lastname.toLowerCase() < b.lastname.toLowerCase()) return -1;
        }

        if (sortKey == "namedesc") {
          if (a.firstname.toLowerCase() < b.firstname.toLowerCase()) return 1;
          if (a.firstname.toLowerCase() > b.firstname.toLowerCase()) return -1;
          if (a.lastname.toLowerCase() < b.lastname.toLowerCase()) return 1;
          if (a.lastname.toLowerCase() > b.lastname.toLowerCase()) return -1;
        }

        if (sortKey == "clinicalteam") {
          if (a.clinicalteam.name.toLowerCase() > b.clinicalteam.name.toLowerCase()) return 1;
          if (a.clinicalteam.name.toLowerCase() < b.clinicalteam.name.toLowerCase()) return -1;
        }

        if (sortKey == "clinicalteamdesc") {
          if (a.clinicalteam.name.toLowerCase() < b.clinicalteam.name.toLowerCase()) return 1;
          if (a.clinicalteam.name.toLowerCase() > b.clinicalteam.name.toLowerCase()) return -1;
        }

        if (sortKey == "compliance") {
          if ((a.compliance ?? -1) < (b.compliance ?? -1)) return 1;
          if ((a.compliance ?? -1) > (b.compliance ?? -1)) return -1;
        }

        if (sortKey == "compliancedesc") {
          if ((a.compliance ?? -1) > (b.compliance ?? -1)) return 1;
          if ((a.compliance ?? -1) < (b.compliance ?? -1)) return -1;
        }

        if (sortKey == "watchclosely") {
          if ((a.watchclosely ?? -1) < (b.watchclosely ?? -1)) return 1;
          if ((a.watchclosely ?? -1) > (b.watchclosely ?? -1)) return -1;
        }

        if (sortKey == "watchcloselydesc") {
          if ((a.watchclosely ?? -1) > (b.watchclosely ?? -1)) return 1;
          if ((a.watchclosely ?? -1) < (b.watchclosely ?? -1)) return -1;
        }

        if (sortKey == "recoverystatus") {
          if ((a.recoverystatus ?? -1) < (b.recoverystatus ?? -1)) return 1;
          if ((a.recoverystatus ?? -1) > (b.recoverystatus ?? -1)) return -1;
        }

        if (sortKey == "recoverystatusdesc") {
          if ((a.recoverystatus ?? 5) > (b.recoverystatus ?? 5)) return 1;
          if ((a.recoverystatus ?? 5) < (b.recoverystatus ?? 5)) return -1;
        }

        if (a.firstname.toLowerCase() > b.firstname.toLowerCase()) return 1;
        if (a.firstname.toLowerCase() < b.firstname.toLowerCase()) return -1;
        if (a.lastname.toLowerCase() > b.lastname.toLowerCase()) return 1;
        if (a.lastname.toLowerCase() < b.lastname.toLowerCase()) return -1;

        return 0;
      }

      setSearchtext(text);
    }

    useEffect(() => {
      const memoizedCallback = (specialties: number[]) => {
        console.log("searchtext:" + searchtext);
        if (searchtext == "" && specialties.length === 0) {
          setData(tempData);
          return;
        }

        setData(
          tempData.filter(function (item) {
            if (specialties.length !== 0) {
              if (!specialties.includes(item.specialty)) return false;
            }

            return (item.firstname + " " + item.lastname + " " + item.clinicalteam.name)
              .toLowerCase()
              .includes(searchtext.toLowerCase());
          }),
        );

        userStore.updatescreen();
      };
      memoizedCallback(specialtiesFilter);
    }, [specialtiesFilter, searchtext, tempData]);
    async function fetchData() {
      try {
        const resData = await requests.get("/clinicians/getpatients");
        setData(resData.data);
        setTempData(resData.data);
        const ids = resData.data.map((patient: any) => patient.id);
        fetchPatientReadLogs(ids);
      } catch (err) {
        console.error("Failed to load patient list:");
        console.error(err);
        toastError("Failed to load patient list. Please try again later.");
      }
    }

    async function fetchPatientReadLogs(ids: number[]) {
      try {
        const resData = await requests.get(`/v1/patients/${ids.join(";")}/latest_read_log`);
        setPatientReadlogs(resData.lastReadLogs);
      } catch (err) {
        console.error("Failed to fetch patient read logs:");
        console.error(err);
      }
    }

    useEffect(() => {
      fetchData();
    }, []);

    return (
      <View style={styles.container}>
        <Modal
          buggy={userStore.screenupdate}
          onBackdropPress={() => {
            setInvitemodalvisible(false);
          }}
          animationType="fade"
          transparent={true}
          visible={invitemodalvisible}
          onDismiss={() => {
            fetchData();
          }}>
          {!!invitemodalvisible && (
            <Animated.View
              style={{
                position: "absolute",
                top: 0,
                zIndex: 100,
                width: "100%",
                opacity,
                height: Math.max(dimensions.height, 826),
              }}>
              <InvitePatient onFinish={fadeOut} removeView={fadeOut} />
            </Animated.View>
          )}
        </Modal>

        <MainFrame userStore={userStore} patientlist style={{}}>
          <View style={{ alignItems: "flex-start", width: 1000 }}>
            <Text style={[styles.title1]}>List of patients</Text>
            <Text style={[styles.title2]}>{data?.length} Patients</Text>
            {userStore?.readonly == 0 && (
              <BlueButton
                onPress={() => {
                  fadeIn();
                }}
                invitenewuser
                title="Invite new patient"
                style={{ position: "absolute", right: 0, top: 32, width: 180 }}
              />
            )}
            <View style={{ flexDirection: "row", alignItems: "center", marginTop: 24 }}>
              <View style={[styles.shadowinput, { width: 267 }]}>
                <TextInput
                  style={[styles.postopinput, { textAlign: "left", width: 220 }]}
                  keyboardType="email-address"
                  maxLength={50}
                  numberOfLines={1}
                  onChangeText={(text) => {
                    updateSearch(text, sortKey);
                  }}
                  placeholder={"Search"}
                  placeholderTextColor={"#1F2E99"}
                  value={searchtext}
                />
                <TouchableOpacity style={{ position: "absolute", right: 10, top: 13 }}>
                  <Image source={require("../assets/images/searchicon.png")} style={styles.searchicon} />
                </TouchableOpacity>
              </View>
              <PatientFilter specialties={specialtiesFilter} setSpecialtiesFilter={setSpecialtiesFilter} />
            </View>
            <View style={{ flexDirection: "row", width: "100%", justifyContent: "space-between", marginTop: 24.5 }}>
              <TouchableOpacity
                onPress={() => {
                  sortResults("name");
                }}
                style={{ flex: 0.3, flexDirection: "row" }}>
                <Text style={[styles.title3]}>Name</Text>
                <SortIcon titlekey={"name"} sortKey={sortKey} />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  sortResults("clinicalteam");
                }}
                style={{ flex: 0.177, flexDirection: "row" }}>
                <Text style={[styles.title3]}>Clinical team</Text>
                <SortIcon titlekey={"clinicalteam"} sortKey={sortKey} />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  sortResults("operationdate");
                }}
                style={{ flex: 0.177, flexDirection: "row" }}>
                {" "}
                <Text style={[styles.title3]}>Recovery time</Text>
                <SortIcon titlekey={"operationdate"} sortKey={sortKey} />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  sortResults("compliance");
                }}
                style={{ flex: 0.13, flexDirection: "row" }}>
                <Text style={[styles.title3, styles.titlecenter2]}>Compliance</Text>
                <SortIcon titlekey={"compliance"} sortKey={sortKey} />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  sortResults("watchclosely");
                }}
                style={{ flex: 0.177, flexDirection: "row" }}>
                <Text style={[styles.title3, styles.titlecenter2]}>Watch closely</Text>
                <SortIcon titlekey={"watchclosely"} sortKey={sortKey} />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  sortResults("recoverystatus");
                }}
                style={{ flex: 0.13, flexDirection: "row" }}>
                <Text style={[styles.title3]}>Recovery status</Text>
                <SortIcon titlekey={"recoverystatus"} sortKey={sortKey} />
              </TouchableOpacity>
              <TouchableOpacity style={{ flex: 0.08 }}></TouchableOpacity>
            </View>
            <Hairline marginTop={15.5} />
            <FlatList
              scrollEnabled={false}
              extraData={sortKey}
              style={{ width: "100%", marginBottom: 94, overflow: "visible" }}
              data={data}
              keyExtractor={(item) => item.id.toString()}
              renderItem={({ item, index, separators }) => (
                <A href={"patient?patientid=" + item.id} key={item.id} style={{ textDecorationLine: "none" }}>
                  <View
                    style={{
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                      marginTop: 24.5,
                      backgroundColor: "#ffffff",
                      height: 94,
                      alignItems: "center",
                      borderRadius: 6,
                    }}>
                    {recoveryDay(item.operationdate) < 31 && (
                      <Image
                        source={require("../assets/images/30dayscycle.png")}
                        style={styles.thirtydayscyclebanner}
                      />
                    )}
                    <View style={{ flex: 0.1 }}>
                      <PatientRecoveryStatusLeftBar status={item.recoverystatus} />

                      <Avatar
                        url={item?.thumburl && global.imagepathurl + encodeURI(item?.thumburl)}
                        size={46}
                        radius={23}
                        firstname={item.firstname}
                        lastname={item.lastname}
                        style={{ marginLeft: 24, borderWidth: 1, marginRight: 18 }}
                      />
                    </View>
                    <View style={{ flex: 0.2 }}>
                      <Text style={[styles.title4]}>
                        {item.firstname} {item.lastname}
                      </Text>

                      {item.inviteaccepted != 1 && (
                        <View style={{ flexDirection: "row", alignItems: "center", marginTop: 8 }}>
                          <Image source={require("../assets/images/unreadmsgicon.png")} style={styles.unreadmsgicon} />
                          <Text style={styles.title5}>Invitation sent</Text>
                        </View>
                      )}
                      {patientReadLogs?.[item.id]?.unreadcount > 0 && (
                        <View style={{ flexDirection: "row", alignItems: "center", marginTop: 8 }}>
                          <Image source={require("../assets/images/unreadmsgicon.png")} style={styles.unreadmsgicon} />
                          <Text style={styles.title5}>{patientReadLogs?.[item.id]?.unreadcount} unread message</Text>
                        </View>
                      )}
                    </View>
                    <TouchableOpacity style={{ flex: 0.177 }}>
                      <Text style={[styles.title4]}>{item?.clinicalteam?.name}</Text>
                    </TouchableOpacity>
                    <View style={{ flex: 0.177 }}>
                      <Text style={[styles.title4]}>
                        {recoveryDay(item.operationdate) < 31 && (
                          <div className="gradient-text">{calculateRecoveryTime(item.operationdate)}</div>
                        )}
                        {recoveryDay(item.operationdate) > 30 && calculateRecoveryTime(item.operationdate)}
                      </Text>
                    </View>
                    <View style={{ flex: 0.13 }}>
                      <Text style={[styles.title4, styles.titlecenter, 83 > 75 && { color: "#ED0000" }]}>
                        {item.compliance ? Math.round(item.compliance) + " %" : ""}{" "}
                      </Text>
                    </View>
                    <View style={{ flex: 0.177, alignItems: "center" }}>
                      {" "}
                      {item.watchclosely == 1 && (
                        <Image
                          source={require("../assets/images/watchcloselycircleicon.png")}
                          style={styles.watchcloselycircleicon}
                        />
                      )}
                    </View>
                    <View style={{ flex: 0.13 }}>
                      <PatientRecoveryStatus status={item.recoverystatus} />
                    </View>
                    <View style={{ flex: 0.08, alignItems: "center" }}>
                      {item.ispinned == 1 && (
                        <Image source={require("../assets/images/flagicon.png")} style={styles.flagicon} />
                      )}
                      {!(item.ispinned == 1) && (
                        <Image source={require("../assets/images/flagemptyicon.png")} style={styles.flagicon} />
                      )}
                    </View>
                  </View>
                </A>
              )}
            />
          </View>
        </MainFrame>
      </View>
    );
  }),
);

export default TempScreen;

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  openButton: {
    backgroundColor: "#F194FF",
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },

  searchicon: {
    marginTop: 2,
    width: 12.33,
    height: 13.33,
  },
  watchcloselycircleicon: {
    width: 15,
    height: 15,
  },
  unreadmsgicon: {
    width: 8,
    height: 8,
  },
  thirtydayscyclebanner: {
    width: 76,
    height: 25,
    position: "absolute",
    top: -8,
    left: -12,
    zIndex: 100,
  },

  flagicon: {
    width: 15,
    height: 18.333,
  },

  postopinput: {
    flex: 1,
    outlineWidth: 0,
    color: "#1F2E99",
    fontFamily: "Lato",
    fontSize: 15,
    marginRight: 10,
    marginLeft: 20,
  },
  shadowinput: {
    marginTop: 15,
    height: 44,
    flexDirection: "row",
    borderRadius: 15,
    shadowColor: "#818181",
    backgroundColor: "#FFFFFF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",

    height: "100%",
  },
  title1: {
    fontSize: 24,
    fontFamily: "LatoBold",
    color: "#1F2E99",

    marginTop: 32,
  },
  title2: {
    marginTop: 12,

    fontSize: 15,
    fontFamily: "Lato",
    color: "#1F2E99",
  },
  title3: {
    fontSize: 13,
    fontFamily: "Lato",
    color: "#1F2E99",
  },
  titlecenter: { width: "100%", textAlign: "center" },

  title4: {
    fontSize: 15,
    fontFamily: "Lato",
    color: "#000000",
  },
  title5: {
    color: "#FE5935",
    fontFamily: "Lato",
    fontSize: 13,
    marginLeft: 6,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
});
