import { useRoute } from "@react-navigation/native";
import type { WelcomeFlowParams, ClinicianParams, HospitalAdminParams, PostopAdminParams } from "../navigation/params";

export type ParamFamily = "welcome" | "clinician" | "hospitalAdmin" | "postopAdmin";
type RouteMap = {
  welcome: WelcomeFlowParams;
  clinician: ClinicianParams;
  hospitalAdmin: HospitalAdminParams;
  postopAdmin: PostopAdminParams;
};

export default function useParams(): any;
export default function useParams<F extends ParamFamily, R extends keyof RouteMap[F]>(f: F, r: R): RouteMap[F][R];
export default function useParams(): any {
  return useRoute().params ?? {};
}
