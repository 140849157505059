import fuzzy from "fuzzy";
import type { CSSProperties, KeyboardEvent } from "react";
import { useCallback, useEffect, useState } from "react";

import { css, cx } from "@emotion/css";

import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import { requests } from "../../misc";
import { theme } from "../../misc/constants";
import Clickable from "../Clickable";
import EyeIcon from "../icons/EyeIcon";
import { TrashAllIcon, TrashIcon } from "../icons/TrashIcon";
import { POTextNu } from "../POText";
import Input from "../ui/Input";
import type { Annotation, Tag } from "./WoundVizContext";
import { useWoundVizContext } from "./WoundVizContext";
import { toastError } from "../Notifications";

export function WoundAnnotations(): JSX.Element {
  const [state, update] = useWoundVizContext();
  const [tags, setTags] = useState<Tag[]>([]);

  const handleDeleteAll = useCallback(async () => {
    try {
      await Promise.all(state.annotations.map(async (a) => await requests.del(`/v1/wound-annotations/${a.id}`)));
      update({ annotations: [] });
    } catch (err) {
      toastError("Failed to delete some or all annotations. Please refresh the page.");
      console.error(err);
    }
  }, []);

  useAsyncEffect(async () => {
    setTags((await requests.get(`/v1/wound-tags`)).data);
  }, []);

  return (
    <div className={cx("POWoundViz-annotations", styles.woundAnnotations)}>
      <div className="POWoundViz-annotationsHeader">
        <POTextNu
          as="h2"
          flex={1}
          fontFamily="Lato"
          fontSize={18}
          fontWeight={700}
          lineHeight={1.2}
          color={theme.primary}>
          Wound annotations
        </POTextNu>
        <Clickable onClick={handleDeleteAll}>
          <TrashAllIcon color={theme.primary} />
        </Clickable>
      </div>
      <ul className="POWoundViz-annotations">
        {state.annotations.map((a, i) => (
          <li key={a.id}>
            <WoundAnnotation key={a.id} annotation={a} tagSource={tags} />
          </li>
        ))}
      </ul>
    </div>
  );
}

interface WoundAnnotationProps {
  annotation: Annotation;
  tagSource: Tag[];
}

function WoundAnnotation({ annotation, tagSource }: WoundAnnotationProps): JSX.Element {
  const [state, update] = useWoundVizContext();
  const [isEditing, setEditing] = useState(false);
  const [tagFilter, setTagFilter] = useState("");
  const [filteredTags, setFilteredTags] = useState<Tag[]>([]);
  const [selectedTag, setSelectedTag] = useState(0);

  const resetUI = () => {
    setTagFilter("");
    setEditing(false);
  };

  const saveAnnotation = useCallback(async (annotation: Annotation) => {
    await requests.put(`/v1/wound-annotations/${annotation.id}`, annotation);
  }, []);

  const selectTag = useCallback(
    (tag: Tag) => {
      annotation.woundTagId = tag.id;
      annotation.color = tag.color;
      annotation.tag = tag.label;
      saveAnnotation(annotation);
      resetUI();
      update({});
    },
    [annotation],
  );

  const handleDelete = useCallback(async () => {
    const idx = state.annotations.indexOf(annotation);
    if (idx === -1) return;
    state.annotations.splice(idx, 1);
    update({});
    await requests.del(`/v1/wound-annotations/${annotation.id}`);
  }, [state, annotation]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      const { key } = e;
      switch (key) {
        case "ArrowUp":
        case "ArrowDown": {
          e.preventDefault();
          let selectedIndex = selectedTag;
          if (key === "ArrowUp") selectedIndex--;
          if (key === "ArrowDown") selectedIndex++;
          selectedIndex = Math.max(0, Math.min(selectedIndex, filteredTags.length - 1));
          setSelectedTag(selectedIndex);
          update({});
          break;
        }
        case "Enter": {
          e.preventDefault();
          const tag = filteredTags[selectedTag];
          if (!tag) break;
          selectTag(tag);
          // @ts-expect-error
          e.target.blur();
          break;
        }
        default: {
          setSelectedTag(0);
        }
      }
    },
    [selectTag, selectedTag, filteredTags],
  );

  useEffect(() => {
    if (!tagFilter.trim()) {
      setFilteredTags(tagSource || []);
      return;
    }

    const filtered = fuzzy.filter(tagFilter, tagSource || [], {
      extract: (x) => x.label,
    });
    setFilteredTags(filtered.map((x) => x.original));
  }, [tagSource, tagFilter]);

  return (
    <div className="POWoundViz-annotation">
      <div className="POWoundViz-annotationInfo">
        <ColorBox color={annotation.color} size={30} style={{ marginRight: 10 }} />
        <Input
          value={tagFilter || (!isEditing && annotation.tag) || ""}
          placeholder="Type or select a label"
          onChangeText={setTagFilter}
          onKeyDown={handleKeyDown}
          onFocus={() => {
            setTagFilter(tagFilter || annotation.tag || "");
            setEditing(true);
          }}
          onBlur={(e) => {
            // defer, otherwise the click event on the tag list won't fire
            setTimeout(() => {
              resetUI();
            }, 200);
          }}
          className="POWoundViz-annotationInput"
        />
        <Clickable
          onClick={() => {
            annotation.visible = !annotation.visible;
            update({});
          }}>
          <EyeIcon closed={!annotation.visible} color={theme.primary} />
        </Clickable>
        <Clickable onClick={handleDelete}>
          <TrashIcon color={theme.primary} />
        </Clickable>
      </div>
      {isEditing &&
        (filteredTags.length ? (
          <ul className="POWoundViz-annotationTagList">
            {filteredTags.map((t, i) => (
              <li
                key={t.id}
                className={cx("POWoundViz-annotationTag", { selected: selectedTag === i })}
                onMouseMove={() => {
                  setSelectedTag(i);
                }}
                onClick={() => {
                  selectTag(t);
                }}>
                <POTextNu fontSize={15} lineHeight={1.2} color={theme.primary}>
                  {t.label}
                </POTextNu>
              </li>
            ))}
          </ul>
        ) : (
          <div
            className={css`
              margin: 6px 0;
              text-align: center;
            `}>
            <POTextNu fontSize={15} lineHeight={1.2} color={theme.primary}>
              No tags found
            </POTextNu>
          </div>
        ))}
    </div>
  );
}

function ColorBox({
  color,
  size,
  opacity = 0.4,
  style,
}: {
  color: string;
  size: number;
  opacity?: number;
  style: CSSProperties;
}) {
  return (
    <div
      style={{
        width: size,
        height: size,
        backgroundColor: color,
        borderRadius: 4,
        opacity,
        ...style,
      }}
    />
  );
}

const styles = Object.freeze({
  woundAnnotations: css`
    .POWoundViz-annotationsHeader {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 81px;
      padding-right: 16px;

      h2 {
        margin: 0;
        padding: 0;
      }
    }

    .POWoundViz-annotations {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    .POWoundViz-annotation {
      margin: 0;
      padding: 0;
    }

    .POWoundViz-annotationInfo {
      display: flex;
      flex-direction: row;
      gap: 20px;
      align-items: center;
      padding: 24px 20px 20px;
      border-bottom: 1px solid #d3dbeb;
    }

    .POWoundViz-annotationInput {
      flex: 1;
      input {
        border: 0;
        padding: 0;
      }
    }

    .POWoundViz-annotationTagList {
      list-style-type: none;
      margin: 6px 0 0 0;
      padding: 0 0 6px;
      border-bottom: 1px solid #d3dbeb;
    }

    .POWoundViz-annotationTag {
      padding: 8px 30px 8px 80px;
      &.selected {
        background: #dde7fa;
      }
    }
  `,
});
