import { useMemo } from "react";
import { InlineVector } from "../../misc/linal";
import type { WoundVizState } from "./WoundVizContext";
import { useWoundVizContext } from "./WoundVizContext";

export default function useTransformAPI() {
  const [state, update] = useWoundVizContext();

  return useMemo(() => {
    const getDelta = (x: number, y: number) => [state.panStart![0] - x, state.panStart![1] - y] as [number, number];
    const getFullImageScale=()=>{
      const sx = state.containerRef.current!.clientWidth / state.imageRef.current!.naturalWidth;
      const sy = state.containerRef.current!.clientHeight / state.imageRef.current!.naturalHeight;
      return Math.min(sx, sy);
    };
    const getZoomScale=(level = state.imageZoomLevel)=> {
      return 0.2 * level + getFullImageScale();
    };
    const getZoomLevel = (scale: number) => (scale - 1) * 5;

    // relative to image center at image scale
    const scrollTo = (x: number, y: number, scale?: number) => {
      scale = scale ?? getZoomScale();
      const vImg = new InlineVector(state.imageRef.current!.naturalWidth, state.imageRef.current!.naturalHeight);
      const vCnt = new InlineVector(state.containerRef.current!.clientWidth, state.containerRef.current!.clientHeight);

      // center point rel to image origin (scaled)
      // note: this is the point we want to scroll to
      // this calculation assumes image's scale origin is (0,0), hence the CSS transform-origin
      const c = new InlineVector(x, y).add(vImg.scaled(0.5)).scale(scale);

      // target point rel to container origin
      const p = new InlineVector().add(vCnt.scaled(0.5)).sub(c);

      Object.assign(state.panTargetRef.current!.style, {
        top: `${p.y}px`,
        left: `${p.x}px`,
      });
    };

    const scrollDelta = (dx: number, dy: number, persist = false) => {
      const [offX, offY] = state.imageOffset;
      const x = offX + dx / getZoomScale();
      const y = offY + dy / getZoomScale();

      scrollTo(x, y);

      if (persist) {
        update({
          imageOffset: [x, y],
        });
      }
    };

    const updateBox = (box: WoundVizState["box"]) => {
      if (!box) return;
      const [_x1, _y1] = box.start;
      const [_x2, _y2] = box.stop;

      const x1 = Math.max(0, Math.min(Math.min(_x1, _x2), state.imageRef.current!.naturalWidth));
      const y1 = Math.max(0, Math.min(Math.min(_y1, _y2), state.imageRef.current!.naturalHeight));
      const x2 = Math.max(0, Math.min(Math.max(_x1, _x2), state.imageRef.current!.naturalWidth));
      const y2 = Math.max(0, Math.min(Math.max(_y1, _y2), state.imageRef.current!.naturalHeight));

      state.boxRef.current!.style.left = `${Math.min(x1, x2)}px`;
      state.boxRef.current!.style.top = `${Math.min(y1, y2)}px`;
      state.boxRef.current!.style.width = `${Math.abs(x2 - x1)}px`;
      state.boxRef.current!.style.height = `${Math.abs(y2 - y1)}px`;
    };

    const self = {
      toImageCoords(x: number, y: number): [number, number] {
        const img = state.imageRef.current;
        if (!img) return [-1, -1];

        // image refers to the image element, not the image contents
        // rect is the bounding box of the image relative to the page origin
        const rect = img.getBoundingClientRect();
        // center of the rectangle in page coordinates
        const rcx = rect.left + rect.width / 2;
        const rcy = rect.top + rect.height / 2;
        // the image is scaled + cropped, so we need to find the correct ratio
        const ratioX = img.naturalWidth / rect.width;
        const ratioY = img.naturalHeight / rect.height;
        const ratio = Math.min(ratioX, ratioY);
        // finally, find the coordinates of the click in image content coordinates
        return [(x - rcx) * ratio + img.naturalWidth / 2, (y - rcy) * ratio + img.naturalHeight / 2];
      },
      startPan(x: number, y: number) {
        update({
          panStart: [x, y],
          isPanning: true,
        });
      },
      updatePan(x: number, y: number) {
        const [dx, dy] = getDelta(x, y);
        if (state.isPanning) scrollDelta(dx, dy);
      },
      stopPan(x: number, y: number) {
        const [dx, dy] = getDelta(x, y);
        scrollDelta(dx, dy, true);
        update({
          panStart: null,
          isPanning: false,
        });
      },
      startBox(x: number, y: number) {
        x = Math.max(0, Math.min(x, state.imageRef.current!.naturalWidth));
        y = Math.max(0, Math.min(y, state.imageRef.current!.naturalHeight));

        state.box = {
          start: [x, y],
          stop: [x, y],
        };
        updateBox(state.box);
        update({});
      },
      updateBox(x: number, y: number) {
        if (!state.box) return;
        state.box.stop = [x, y];
        updateBox(state.box);
      },
      stopBox(x: number, y: number) {
        if (!state.box) return;
        state.box.stop = [x, y];
        updateBox(state.box);
        update({});
      },
      centerImage() {
        scrollTo(0, 0);
        update({ imageOffset: [0, 0] });
      },
      fullImage() {
        const scale = getFullImageScale();
        state.panTargetRef.current!.style.transform = `scale(${scale})`;
        update({
          imageZoomLevel: getZoomLevel(scale),
          imageOffset: [0, 0],
        });
        scrollTo(0, 0, scale);
      },
      zoom(dir: number) {
        if (dir === 0) return;
        return self.setZoom(state.imageZoomLevel + dir);
      },
      setZoom(level: number) {
        level = Math.max(0, Math.min(Math.round(level), 10));
        const scale = getZoomScale(level);
        update({
          imageZoomLevel: level,
        });
        scrollTo(...state.imageOffset, scale);
        state.panTargetRef.current!.style.transform = `scale(${scale})`;
      },
      setScale(scale: number) {
        update({
          imageZoomLevel: getZoomLevel(scale),
        });
      },
    };
    return self;
  }, [state, update]);
}
