const validation = {
  email: {
    presence: {
      message: "^Please enter an email address.",
    },
    email: {
      message: "^Please enter a valid email address.",
    },
  },

  gender: {
    presence: {
      message: "^Please select a gender.",
    },
    numericality: {
      greaterThan: 0,

      flags: "i",
      message: "^Please enter your gender",
    },
  },
  biggerthanzero: {
    presence: {
      message: "^Please select a value.",
    },
    numericality: {
      greaterThan: 0,

      flags: "i",
      message: "^Please enter",
    },
  },

  biggerorequaltozero: {
    presence: {
      message: "^Please select a value.",
    },
    numericality: {
      greaterThan: -1,

      flags: "i",
      message: "^Please enter",
    },
  },

  dateofbirth: {
    presence: {
      message: "^Please enter date of birth.",
    },
    date: {
      message: "^Please enter a valid date of birth.",
    },
  },
  operationtype: {
    presence: {
      message: "^Please select an operation type.",
    },
  },
  registrationnumber: {
    presence: {
      message: "^Please enter registration number.",
    },
  },
  clinicalteam: {
    presence: {
      message: "^Please enter a clinical team.",
    },
    numericality: {
      greaterThan: 0,
      flags: "i",
      message: "^Please select a clinical team.",
    },
  },
  cliniciantype: {
    numericality: {
      greaterThan: 0,

      flags: "i",
      message: "^Please select a role.",
    },
  },

  emailoptional: {
    email: {
      message: "^Please enter a valid email address.",
    },
  },
  firstname: {
    presence: {
      message: "^Please check first name.",
    },
    length: {
      minimum: 1,
      message: "^Please check first name.",
    },
  },

  genericname: {
    presence: {
      message: "^Please check the name field.",
    },
    length: {
      minimum: 2,
      message: "^Please check the name field.",
    },
  },

  genericnameoptional: {
    length: {
      minimum: 2,
      message: "^Please check the name field.",
    },
  },

  lastname: {
    presence: {
      message: "^Please check last name.",
    },
    length: {
      minimum: 2,
      message: "^Please check last name.",
    },
  },
  caretakerfirstname: {},
  caretakerlastname: {},
  caretakergsm: {
    length: {
      minimum: 5,
      message: "^Password should have minimum 5 characters.",
    },
    format: {
      pattern: "[0-9]+",
      flags: "i",
      message: "^Please enter next of kin's phone number.",
    },
  },
  caretakeremail: {
    email: {
      message: "^Please enter a valid next of kin's email address.",
    },
  },

  password: {
    presence: {
      message: "^Please enter a password.",
    },
    format: {
      pattern: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{1,200}$/,
      message: "^Password must contain a capital, lowercase, number and a special character.",
    },
    length: {
      minimum: 12,
      message: "^Password should have minimum 12 characters.",
    },
  },

  passwordmixed: {
    presence: {
      message: "^Please enter a password.",
    },
    format: {
      pattern: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{1,200}$/,
      message: "^Password must contain a capital, lowercase, number and a special character.",
    },
  },

  passwordlogin: {
    presence: {
      message: "^Please enter a password.",
    },
    length: {
      minimum: 6,
      message: "^Password should have minimum 6 characters.",
    },
  },

  gsmcode: {
    presence: {
      message: "^Lütfen onay kodunu girin.",
    },
    length: {
      exact: 4,
      message: "^Onay kodunuz 4 karakterden oluşmalıdır.",
    },
  },

  height: {
    presence: {
      message: "^Please enter your height",
    },
    length: {
      minimum: 1,
      message: "^Please enter your height",
    },
    numericality: {
      greaterThan: 0,
      lessThanOrEqualTo: 400,
      flags: "i",
      message: "^Please check your height",
    },
  },
  heightoptional: {
    format: {
      pattern: /^(?:$|[1-9]\d?|[1-3]\d{2}|400)$/,
      flags: "i",
      message: "Please check your height",
    },
  },
  weight: {
    presence: {
      message: "^Please enter your weight",
    },
    length: {
      minimum: 1,
      message: "^Please enter your weight",
    },
    numericality: {
      greaterThan: 0,
      lessThanOrEqualTo: 400,
      flags: "i",
      message: "^Please check your weight",
    },
  },
  weightoptional: {
    format: {
      pattern: /^(?:$|[1-9]\d?|[1-3]\d{2}|400)$/,
      flags: "i",
      message: "Please check your weight",
    },
  },
  nhsnumber: {
    presence: {
      message: "^Please enter your NHS number",
    },
    format: {
      pattern: /^\d{10}$/,
      message: "^Please check your NHS number",
    },
    nhsnumber: {
      message: "^Please check your NHS number",
    },
  },
  gsm1: {
    presence: {
      message: "^Telefon operatör kodunu giriniz.",
    },
    length: {
      is: 3,
      message: "^Telefon alan kodunuz 3 rakamdan oluşmalıdır.",
    },
    format: {
      pattern: "[0-9]+",
      flags: "i",
      message: "^Telefon numarası sadece rakamlardan oluşmalıdır.",
    },
  },
  smsConsent: {
    presence: {
      message: "^SMS consent confirmation is required.",
    },
    checkboxChecked: {
      message: "^SMS consent confirmation is required.",
    },
  },
  invitationcode: {
    length: {
      is: 4,
      message: "^Invitation code should have 4 digits.",
    },
  },
  logincode: {
    length: {
      is: 6,
      message: "^Authentication code should have 6 characters.",
    },
  },
  resetpasswordcode: {
    length: {
      is: 6,
      message: "^Reset code is not valid.",
    },
  },
  gsm: {
    presence: {
      message: "^Please enter your phone number.",
    },
  },

  gsm2: {
    presence: {
      message: "^Please enter your phone.",
    },
    length: {
      minimum: 6,
      message: "^Please check your phone number.",
    },
    format: {
      pattern: "[0-9]+",
      flags: "i",
      message: "^Please check your phone number.",
    },
  },
  gsmnotification: {
    presence: {
      message: "^Please enter notification phone number.",
    },
    length: {
      minimum: 6,
      message: "^Please check notification phone number.",
    },
    format: {
      pattern: "[0-9]+",
      flags: "i",
      message: "^Please check notification phone number.",
    },
  },
  title: {
    presence: {
      message: "^Lütfen başlık  girin.",
    },
    length: {
      minimum: 5,
      message: "^Başlık en az 5 karakterden oluşmalıdır.",
    },
  },
};

export default validation;
