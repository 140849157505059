import React, { useState, useEffect } from "react";
import { TextInput, FlatList, Image, View, Text, StyleSheet, TouchableOpacity } from "react-native";

import { MainFramePatient } from "../components/MainFramePatient";
import { Comment } from "../components/Comment";
import { Hairline } from "../components/Hairline";
import { useRoute } from "@react-navigation/native";
import { withStore } from "../misc/utils";

const TempScreen = withStore((props) => {
  const params = useRoute()?.params;
  const { patientid } = params as any;
  const [data, setData] = React.useState<any>();
  const [commenttext, setCommenttext] = React.useState("");
  const [sendingData, setSendingData] = useState(false);

  const textInputRef = React.useRef();

  async function sendComment() {
    if (commenttext.length < 3) return;
    if (sendingData) return;
    setSendingData(true);
    console.log("sendComment");

    fetch(global.apiurl + "/clinicians/addpatientcomment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
      body: JSON.stringify({
        patientid,
        message: commenttext,
      }),
    })
      .then(async (response) => await response.json())
      .then((resData) => {
        setSendingData(false);

        console.log(resData);
        if (resData.result) {
          fetchData();
          setCommenttext("");
          if (props.userStore?.readonly == 0) {
            setTimeout(() => textInputRef.current.focus(), 200);
          }
        }
      })
      .catch((err) => {
        setSendingData(false);
        console.log(err);
      });
  }
  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    fetch(global.apiurl + "/clinicians/getpatientdiary", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
      body: JSON.stringify({
        patientid,
      }),
    })
      .then(async (response) => await response.json())
      .then((resData) => {
        console.log(resData);
        if (resData.result) {
          setData(resData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    fetchData();
    if (props.userStore?.readonly == 0) {
      if (textInputRef) setTimeout(() => textInputRef.current.focus(), 1200);
    }
  }, []);

  let flatList;

  return (
    <View style={styles.container}>
      {data && (
        <MainFramePatient
          userStore={props.userStore}
          selected={7}
          patient={data?.patient}
          clinician={data?.clinician}
          patientlist
          style={{ alignItems: "flex-start", flexDirection: "row", width: "100%" }}>
          <View style={[styles.whitebox, { width: "100%" }]}>
            <Text style={styles.title1}>Timeline of notes & updates</Text>

            <Hairline marginTop={24} />

            <FlatList
              ref={(ref) => (flatList = ref)}
              initialNumToRender={1}
              onLayout={() => flatList.scrollToEnd({ animated: false })}
              onContentSizeChange={() => flatList.scrollToEnd({ animated: false })}
              style={{
                width: "100%",
                height: 514,
                overflow: "scroll",
                borderBottomColor: "#E8E9ED",
                borderBottomWidth: 1,
                marginBottom: 68,
              }}
              data={data.patientcomments}
              renderItem={({ item, index, separators }) => (
                <Comment
                  photourl={item?.clinician?.photourl}
                  firstname={item?.clinician?.firstname}
                  lastname={item?.clinician?.lastname}
                  date={item?.createdAt}
                  msg={item.message}
                />
              )}
            />

            {props.userStore?.readonly == 0 && (
              <View
                style={{
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  height: 68,
                  alignItems: "center",
                  backgroundColor: "#ffffff",
                  width: "100%",
                  borderRadius: 8,
                }}>
                {commenttext == "" && (
                  <Text
                    style={{
                      fontFamily: "Lato",
                      fontSize: 15,
                      color: "#959DAD",
                      position: "absolute",
                      left: 24,
                      marginTop: 25,
                    }}>
                    Write a note about the patient
                  </Text>
                )}

                <TextInput
                  editable={!sendingData}
                  ref={textInputRef}
                  onSubmitEditing={sendComment}
                  style={[
                    {
                      fontFamily: "Lato",
                      fontSize: 15,
                      color: "#959DAD",
                      textAlign: "left",
                      position: "absolute",
                      left: 24,
                      marginRight: 24,
                      width: 850,
                      marginTop: 25,
                    },
                  ]}
                  keyboardType="email-address"
                  maxLength={1000}
                  numberOfLines={1}
                  onChangeText={(text) => {
                    setCommenttext(text);
                  }}
                  value={commenttext}
                />

                <TouchableOpacity style={{ position: "absolute", right: 13, top: 13 }} onPress={sendComment}>
                  <Image source={require("../assets/images/sendcommentbutton.png")} style={{ width: 42, height: 42 }} />
                </TouchableOpacity>
              </View>
            )}
          </View>
        </MainFramePatient>
      )}
    </View>
  );
});
export default TempScreen;

const styles = StyleSheet.create({
  whitebox: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    shadowColor: "#818181",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
  title1: {
    fontFamily: "LatoBold",
    fontSize: 18,
    color: "#1F2E99",
    marginLeft: 24,
    marginTop: 24,
  },
  title2: {
    fontFamily: "Lato",
    fontSize: 15,
    color: "#1F2E99",
  },
  title3: {
    fontFamily: "LatoBold",
    fontSize: 18,
    color: "#1F2E99",
  },

  container: {
    flex: 1,
  },
});
