import * as React from "react";
import { Image, StyleSheet, Text, TextInput, TouchableOpacity, View } from "react-native";

import { css, cx } from "@emotion/css";
import { Picker } from "@react-native-picker/picker";

import CountrySelector from "../components/CountrySelector";
import { theme } from "../misc/constants";
import validate from "../screens/validate_wrapper";
import { Button } from "./Button";
import { Modal } from "./Modals";

export interface InviteHospitalAdminModalProps {
  show: boolean;
  className?: string;
  onDismiss: () => void;
}

export const InviteHospitalAdminModal = ({ show, className, onDismiss }: InviteHospitalAdminModalProps) => {
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState<string | null>(null);
  const [gsm, setGsm] = React.useState("");

  const [gsmcountrycode, setGsmcountrycode] = React.useState("44");
  const [countrycode, setCountrycode] = React.useState("GB");

  const [registrationnumber, setRegistrationnumber] = React.useState<string | null>(null);
  const [hospitalid, setHospitalId] = React.useState(0);
  const [hospitals, setHospitals] = React.useState<any>();
  const [errorText, setErrorText] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    async function fetchData() {
      fetch(global.apiurl + "/postopadmins/gethospitals", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: global.tokenparam || "",
        },
      })
        .then(async (response) => await response.json())
        .then((resData) => {
          console.log(resData);
          if (resData.result) {
            setHospitals(resData.data);
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Please check your connection.");
        });
    }

    fetchData();
  }, []);

  function validateForm() {
    setIsLoading(true);

    setErrorText("");
    const firstnameError = validate("firstname", firstName);
    const lastnameError = validate("lastname", lastName);
    const gsmError = validate("gsm2", gsm);
    const emailError = validate("email", email);

    // hospitalcheck

    if (!firstnameError && !lastnameError && !emailError && !gsmError) {
      // updateprofile();
      sendform();
    } else {
      setIsLoading(false);

      // alert("\n"+(nameError?"\n"+nameError+"\n":"")+(gsmError?"\n"+gsmError+"\n":"")+(emailError?"\n"+emailError+"\n":"")+"\n");
      setErrorText(
        "Please check - " +
          (firstnameError ? "first name" + " - " : "") +
          (lastnameError ? "" + "last name" + " - " : "") +
          (emailError ? "" + "email" + " - " : "") +
          (gsmError ? "" + "phone number" + " - " : "") +
          (hospitalid == 0 ? "hospital" + " - " : ""),
      );
    }
  }

  function sendform() {
    console.log("inviting clinician");

    fetch(global.apiurl + "/postopadmins/invitehospitaladmin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
      body: JSON.stringify({
        firstname: firstName,
        lastname: lastName,
        gsmcountrycode,
        countrycode,

        gsm,
        email,
        role: 1,
        registrationnumber,
        hospitalid,
      }),
    })
      .then(async (res) => {
        if (res.status === 422) {
          setIsLoading(false);

          throw new Error("Request failed.");
        }
        if (res.status !== 200 && res.status !== 201) {
          console.log("Error!");
          setIsLoading(false);

          throw new Error("Could not authenticate you!");
        }
        return await res.json();
      })
      .then((resData) => {
        console.log(resData);
        if (resData.result) {
          console.log("DONE");
          //   alert("pp");
          onDismiss();
          //
          // todo close ve listede clientı gorsun
        } else {
          setIsLoading(false);

          alert("Unable to invite, please try later");
        }
      })
      .catch((err) => {
        alert("Unable to invite, please try later");

        console.log(err);
      });
  }

  return (
    <Modal
      show={show}
      onDismiss={onDismiss}
      className={cx("POAModal-InviteHospitalAdmin-root", styles.root, className)}>
      <View
        style={{
          flexDirection: "row",
          width: "100%",
          borderBottomColor: "#E8E9ED",
          borderBottomWidth: 1,
          height: 70,
        }}>
        <Text style={legacyStyles.title1}>Invite new hospital admin</Text>
        <TouchableOpacity
          style={{ position: "absolute", right: 0 }}
          onPress={() => {
            onDismiss();
          }}>
          <View
            style={{
              backgroundColor: "#FCF5EF",
              width: 93,
              height: 70,
              flexDirection: "row",
              borderColor: "#E8E9ED",
              borderWidth: 1,
              borderTopRightRadius: 8,
              alignItems: "center",
            }}>
            <Image
              source={require("../assets/images/closecrossicon.png")}
              style={{ width: 10, height: 10, marginRight: 8, marginLeft: 23 }}
            />
            <Text style={{ fontFamily: "Lato", fontSize: 13, color: "#1F2E99" }}>Close</Text>
          </View>
        </TouchableOpacity>
      </View>

      <View style={{ flexDirection: "row" }}>
        <View style={{ width: 652 }}>
          <View style={{}}>
            <Text style={legacyStyles.title2}>Hospital admin details</Text>

            <View style={{ flexDirection: "row", marginTop: 24, paddingLeft: 24 }}>
              <View style={{ width: 150 }}>
                <Text style={legacyStyles.title3}>First name</Text>
                <View style={[legacyStyles.shadowinput, { width: 150 }]}>
                  <TextInput
                    style={[legacyStyles.postopinput, { textAlign: "left", width: 250 }]}
                    keyboardType="name-phone-pad"
                    maxLength={50}
                    numberOfLines={1}
                    onChangeText={setFirstName}
                    value={firstName}
                    returnKeyType="done"
                  />
                </View>
              </View>
              <View style={{ width: 150, marginLeft: 32 }}>
                <Text style={legacyStyles.title3}>Last name</Text>
                <View style={[legacyStyles.shadowinput, { width: 150 }]}>
                  <TextInput
                    style={[legacyStyles.postopinput, { textAlign: "left", width: 150 }]}
                    keyboardType="name-phone-pad"
                    maxLength={50}
                    numberOfLines={1}
                    onChangeText={setLastName}
                    value={lastName}
                    returnKeyType="done"
                  />
                </View>
              </View>
            </View>

            <View style={{ width: 250, marginTop: 24, paddingLeft: 24 }}>
              <Text style={[legacyStyles.title3]}>Phone number</Text>
              <View style={[legacyStyles.shadowinput]}>
                <CountrySelector
                  countrycode={countrycode}
                  gsmcountrycode={gsmcountrycode}
                  setGsmcountrycode={(value) => {
                    setGsmcountrycode(value);
                  }}
                  setCountrycode={(value) => {
                    setCountrycode(value);
                  }}
                />

                <TextInput
                  keyboardType={"number-pad"}
                  onChangeText={(text) => {
                    setGsm(text);
                  }}
                  value={gsm}
                  maxLength={15}
                  style={{
                    color: "#1F2E99",
                    fontFamily: "Lato",
                    fontSize: 15,
                    marginRight: 10,
                  }}
                />
              </View>
            </View>
            <View style={{ width: 250, marginTop: 24, paddingLeft: 24 }}>
              <Text style={[legacyStyles.title3]}>Email address</Text>

              <View style={[legacyStyles.shadowinput, { width: 250 }]}>
                <TextInput
                  style={[legacyStyles.postopinput, { textAlign: "left", width: 250 }]}
                  keyboardType="email-address"
                  maxLength={50}
                  numberOfLines={1}
                  onChangeText={(text) => {
                    setEmail(text || null);
                  }}
                  value={email}
                  returnKeyType="done"
                />
              </View>
            </View>

            <View style={{ flexDirection: "row", marginTop: 32, paddingLeft: 24 }}>
              <View style={{ width: 250 }}>
                <Text style={[legacyStyles.title3]}>Assigned hospital</Text>

                <View style={[legacyStyles.shadowinput, { zIndex: 100, width: 250, alignItems: "center" }]}>
                  <Picker
                    // style={{"-webkit-appearance":"none", "text-align":"center","text-align-last":"center","-moz-text-align-last":"center",width:76,height:24,borderRadius:20,fontFamily:"LatoBold",fontSize:13,color:"#ffffff",backgroundColor:"#6DD803",alignItems:"center",borderWidth:0}}
                    style={[legacyStyles.select]}
                    selectedValue={hospitalid}
                    onValueChange={(itemValue, itemIndex) => {
                      setHospitalId(itemValue);
                    }}>
                    {hospitalid == 0 && <Picker.Item key={0} label="" value={0} />}

                    {hospitals?.map((hospital) => (
                      <Picker.Item key={hospital.id} label={hospital.name} value={hospital.id} />
                    ))}
                  </Picker>
                </View>
                <Text
                  style={{
                    color: "#FF0000",
                    flex: 1,
                    textAlign: "center",
                    fontFamily: "LatoBold",
                    fontSize: 14,
                    marginTop: 8,
                  }}>
                  {errorText}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>

      <div className="SubmitButton">
        <Button
          loading={isLoading}
          right={
            <img
              role="presentation"
              src={require("../assets/images/sendsmsicon.png")}
              style={{ marginLeft: 15, width: 16, height: 16 }}
            />
          }
          onClick={() => {
            validateForm();
          }}
          style={{ width: 250, height: 40 }}>
          Send email invitation
        </Button>
      </div>
    </Modal>
  );
};

const styles = Object.freeze({
  root: css`
    .POModal-modal {
      border-radius: 8px;
      background: white;
      width: 460px;
      max-height: 628px;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .SubmitButton {
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid ${theme.line};
      padding: 16px 0;
      margin-top: 16px;
    }
  `,
});

const legacyStyles = StyleSheet.create({
  select: {
    marginLeft: 20,
    //  "text-align":"center","text-align-last":"center",
    "-moz-appearance": "none",
    "-webkit-appearance": "none",
    appearance: "none",
    backgroundImage:
      "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAVCAYAAAAuJkyQAAAAmUlEQVR42r3OwQmAQAxE0S3JTrUDvairl6ANBG1MySFeFrz8kIGBZRnIK55ZtLOWnPzf3c67r8f1WO2dhannPTZ3TeefXhtmYrxm+UAARTAtyLLKNTEUxyyig28gimNW0akZcxTHAFQCBqAwJgEFMACFMRkojuEojolHcUw8CmLiUQCTgAIYhAKYJBTH8FTRAWLis4t21hKQF5HBtPSQyO2PAAAAAElFTkSuQmCC)",
    backgroundPosition: "87%",
    backgroundSize: "12px 7px",
    "background-repeat": "no-repeat",
    borderWidth: 0,
    width: 250,
    height: 24,
    borderRadius: 20,
    fontFamily: "Lato",
    fontSize: 15,
    color: "#1F2E99",
    textAlign: "center",
  },

  postopinput: {
    flex: 1,
    outlineWidth: 0,
    color: "#1F2E99",
    fontFamily: "Lato",
    fontSize: 15,
    marginRight: 10,
    marginLeft: 20,
  },
  shadowinput: {
    alignItems: "center",
    marginTop: 8,
    height: 48,
    flexDirection: "row",
    borderRadius: 15,
    shadowColor: "#818181",
    backgroundColor: "#FFFFFF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
  title1: {
    fontSize: 18,
    fontFamily: "LatoBold",
    color: "#1F2E99",
    marginTop: 26,
    marginLeft: 24,
  },

  title2: {
    paddingLeft: 24,
    fontSize: 15,
    fontFamily: "LatoBold",
    color: "#1F2E99",
    marginTop: 24,
  },

  title3: {
    fontSize: 13,
    fontFamily: "Lato",
    color: "#798599",
  },
});
