import { useMemo } from "react";

import { Box, getMaskByBounds, getMaskDataUrl } from "../../hooks/useSam";
import { requests } from "../../misc";
import { limiter } from "../../misc/utils";
import { useWoundVizContext } from "./WoundVizContext";

export default function useSegmentAPI() {
  const [state, update] = useWoundVizContext();

  return useMemo(() => {
    const isEnabled = () =>
      Boolean(state.enabled && state.isAnnotationsEnabled && state.sam && state.embedding);

    return {
      updatePreviewMask: limiter(
        async () => {
          if (!isEnabled() || !state.box) return;

          const [x1, y1] = state.box.start;
          const [x2, y2] = state.box.stop;
          const box: Box = {
            x1: Math.min(x1, x2),
            y1: Math.min(y1, y2),
            x2: Math.max(x1, x2),
            y2: Math.max(y1, y2),
          };

          console.time('Segment Inferrence');
          const mask = await getMaskByBounds(state.sam!, box, state.embedding!);
          if (mask) {
            update({ previewMask: mask });
          };
          console.timeEnd('Segment Inferrence');
        }
      ),
      async createSegment() {
        if (!isEnabled() || !state.box) return;

        const [x1, y1] = state.box.start;
        const [x2, y2] = state.box.stop;
        const box: Box = {
          x1: Math.min(x1, x2),
          y1: Math.min(y1, y2),
          x2: Math.max(x1, x2),
          y2: Math.max(y1, y2),
        };

        const mask = await getMaskByBounds(state.sam!, box, state.embedding!);
        const color = `hsl(${Math.random() * 360}, ${Math.random() * 100}%, ${Math.random() * 50 + 25}%)`;
        const dataUrl = getMaskDataUrl(mask);

        const res = await requests.post(`/v1/patient-media/${state.media.id}/wound-annotations`, {
          mask: dataUrl,
          color,
        });

        state.annotations.push({
          id: res.id,
          color,
          mask: dataUrl,
          visible: true,
        });
        update({
          previewMask: null,
          box: null,
        });
      },
    }
  }, [state, update]);
}
