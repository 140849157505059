import Constants from "expo-constants";

const IMAGES_ROOT = Constants.expoConfig!.extra!.imagepathurl;
const EMBEDDINGS_ROOT = Constants.expoConfig!.extra!.samEmbeddingsUrl;

export const theme = Object.freeze({
  primary: "#1F2E99",
  primaryLight: "#0077FF",
  secondary: "#FE7235",
  secondaryLight: "#FFAA85",
  secondaryLightest: "#FFE5DA",
  secondaryDark: "#D7573B",
  line: "#E8E9ED",
  background: "#E8F0FF",
  surface: "#FCF5EF",
  surfaceDark: "#F8F8F8",
  shadow: "rgba(0, 0, 0, 0.1)",
  boxShadow: "0 0 5px rgba(129, 129, 129, 0.2)",
});

export enum MediaType {
  WoundPhoto = 1,
  DEPRECATD_WoundTop = 2,
  DEPRECATD_WoundBottom = 3,
  WoundVideo = 4,
  VoiceRecording = 5,
}

// TODO: i18n
export const mediaTypeLabels: Record<MediaType, string> = Object.freeze({
  [MediaType.WoundPhoto]: "Full",
  [MediaType.DEPRECATD_WoundTop]: "Top",
  [MediaType.DEPRECATD_WoundBottom]: "Bottom",
  [MediaType.WoundVideo]: "Video",
  [MediaType.VoiceRecording]: "Voice",
});

export const getMediaTypeLabel = (mediaType: MediaType | undefined): string => mediaTypeLabels[mediaType!] || "";

export const getImageUrl = (path: string) => new URL(path, IMAGES_ROOT).toString();
export const getEmbeddingUrl = (path: string) => new URL(path, EMBEDDINGS_ROOT).toString();

export const ENV = Constants.expoConfig!.extra!.environment;
export const APIURL = Constants.expoConfig!.extra!.apiurl;
export const IMAGEPATHURL = Constants.expoConfig!.extra!.imagepathurl;
export const DEFAULT_COUNTRYCODE = Constants.expoConfig!.extra!.countrycode;
export const LINKFORM_ENABLED = Constants.expoConfig!.extra!.linkformenabled;
export const LINKFORM_URL = Constants.expoConfig!.extra!.linkformurl;

export enum OperationType {
  Knee = 1,
  Hip = 2,
  ENT = 3,
  FootAnkle = 4,
  GeneralSurgery = 5,
  Other = 0,
}

// @ts-expect-error
global.apiurl = APIURL;
// @ts-expect-error
global.imagepathurl = IMAGES_ROOT;
// @ts-expect-error
global.countrycode = DEFAULT_COUNTRYCODE;
// @ts-expect-error
global.environment = ENV;
// @ts-expect-error
global.linkformenabled = LINKFORM_ENABLED;
// @ts-expect-error
global.linkformurl = LINKFORM_URL;
