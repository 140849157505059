import React, { useState, useEffect } from "react";
import { Text, View, StyleSheet, Image, TouchableOpacity } from "react-native";

import { BlueButton } from "./BlueButton";

import Menu, { MenuOptions, MenuOption, MenuTrigger, renderers } from "react-native-popup-menu";

export const ClinicalTeamHeader = (props) => {
  const { ContextMenu, SlideInMenu, Popover } = renderers;

  async function deleteClinicalteam() {
    fetch(global.apiurl + "/hospitaladmins/deleteclinicalteam", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
      body: JSON.stringify({
        clinicalteamid: props.clinicalteam.id,
      }),
    })
      .then(async (response) => await response.json())
      .then((resData) => {
        console.log(resData);
        if (resData.result) {
          location.href = "/hospitaladmin/clinicalteams";
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <View style={{ height: 106, paddingLeft: 63, alignSelf: "stretch" }}>
      <View style={{ flexDirection: "row", marginTop: 22, width: 1131 }}>
        <View style={{ marginTop: 20, width: 225 }}>
          <Text style={styles.title3}>{props?.clinicalteam?.name} </Text>
        </View>

        <View style={{ marginTop: 8, marginLeft: 10, width: 250 }}>
          {" "}
          <Text style={styles.title4}>Email address</Text>
          <Text style={styles.title5}>{props?.clinicalteam?.email}</Text>
        </View>

        <View style={{ marginTop: 8, marginLeft: 10, width: 250 }}>
          {" "}
          <Text style={styles.title4}>Phone number</Text>
          <Text style={styles.title5}>
            +{props?.clinicalteam?.gsmcountrycode} {props?.clinicalteam?.gsm}
          </Text>
        </View>
        <Menu style={{ marginLeft: 150, marginTop: 10 }} renderer={Popover} rendererProps={{ placement: "bottom" }}>
          <MenuTrigger style={{}}>
            <Image source={require("../assets/images/morebutton.png")} style={{ width: 42, height: 42 }} />
          </MenuTrigger>
          <MenuOptions customStyles={{ optionsContainer: { borderRadius: 8, padding: 15, marginLeft: "-80%" } }}>
            <MenuOption
              text={"Delete Clinical Team"}
              onSelect={() => {
                if (confirm("All clinical data will be permenantly DELETED. Are you sure?")) {
                  deleteClinicalteam();
                }
              }}
            />
          </MenuOptions>
        </Menu>

        {!props.nobuttons && (
          <BlueButton
            onPress={() => {
              props.openmodal();
            }}
            calendar
            title="Add new clinician"
            style={{ marginTop: 10, width: 178, marginLeft: 20 }}
          />
        )}
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  title3: {
    fontFamily: "LatoBold",
    fontSize: 18,
    color: "#1F2E99",
  },
  title4: {
    fontFamily: "Lato",
    fontSize: 13,
    color: "#868686",
  },

  title5: {
    fontFamily: "Lato",
    fontSize: 15,
    color: "#1F2E99",
    marginTop: 12,
  },
});
