import type { CommonIconProps } from "./icon-types";

export default function DragArrows({ size = 32, color = "currentColor" }: CommonIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(1 1)">
        <path
          d="M0 15L30 15"
          fill="none"
          fillRule="evenodd"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 30L15 0"
          fill="none"
          fillRule="evenodd"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24 21L30 15L24 9"
          fill="none"
          fillRule="evenodd"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 9L0 15L6 21"
          fill="none"
          fillRule="evenodd"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 24L15 30L21 24"
          fill="none"
          fillRule="evenodd"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21 6L15 0L9 6"
          fill="none"
          fillRule="evenodd"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
