import { css, cx } from "@emotion/css";
import type { CSSProperties, ReactNode, RefObject } from "react";
import React from "react";

export interface ContainerProps {
  children?: ReactNode;
  outerRef?: RefObject<HTMLDivElement>;
  innerRef?: RefObject<HTMLDivElement>;
  className?: string;
  style?: CSSProperties;
}

/**
 * Container creates a screen-space limited container for its contents. It works well in combination
 * with flexbox or absolute sizing.
 */
export default function Container({
  children,
  outerRef,
  innerRef,
  className,
  ...props
}: Readonly<ContainerProps>): JSX.Element {
  return (
    <div
      ref={outerRef}
      className={cx(
        "POContainer-root",
        css`
          position: relative;
          overflow: hidden;
        `,
        className,
      )}
      {...props}>
      <div
        ref={innerRef}
        className={cx(
          "POContainer-inner",
          css`
            position: absolute;
            box-sizing: content-box;
            width: 100%;
            height: 100%;
            overflow: auto;
          `,
        )}>
        {children}
      </div>
    </div>
  );
}

export function CenterContainer({ className, ...props }: Readonly<ContainerProps>) {
  return (
    <Container
      className={cx(
        "POContainer-center",
        css`
          .POContainer-inner {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        `,
        className,
      )}
      {...props}
    />
  );
}
