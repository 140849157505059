import React, { useEffect } from "react";
import { View, Text, StyleSheet } from "react-native";

import { MainFramePatient } from "../components/MainFramePatient";
import Messages from "../components/Messages";
import { withStore } from "../misc/utils";
import { useRoute } from "@react-navigation/native";

const TempScreen = withStore(({ userStore }) => {
  const params = useRoute()?.params ?? {};
  const { patientid } = params as any;

  const [data, setData] = React.useState<any>();

  useEffect(() => {
    async function fetchData() {
      fetch(global.apiurl + "/clinicians/getpatientpromsdashboard", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: global.tokenparam || "",
        },
        body: JSON.stringify({
          patientid,
        }),
      })
        .then(async (response) => await response.json())
        .then((resData) => {
          console.log(resData);
          if (resData.result) {
            setData(resData);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, []);

  function setlastreadmessage(lastreadmessage) {
    console.log("setlastreadmessage");
    // if (lastsent==lastestnotification) return;

    fetch(global.apiurl + "/clinicians/setlastreadmessage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
      body: JSON.stringify({
        patientid,
        messageid: lastreadmessage,
      }),
    })
      .then(async (response) => await response.json())
      .then((resData) => {
        //  setLastsent(lastestnotification);
        // console.log(resData);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // <Messages userStore={props.userStore} ></Messages>

  return (
    <View style={styles.container}>
      {data && (
        <MainFramePatient
          setlastreadmessage={setlastreadmessage}
          messages={true}
          userStore={userStore}
          selected={6}
          patient={data.patient}
          clinician={data.clinician}
          patientlist
          style={{ alignItems: "flex-start", flexDirection: "row", width: "100%" }}>
          <View style={{ flex: 1 }}>
            <View
              style={{
                paddingLeft: 63,
                paddingRight: 63,
                paddingTop: 20,
                alignSelf: "stretch",
                flexDirection: "row",
              }}>
              <View style={[styles.whitebox, { flex: 1, alignSelf: "stretch", height: "calc(100vh - 250px)" }]}>
                <Text style={[styles.title3, { marginTop: 24, marginLeft: 24 }]}>
                  Patient & Clinical team (RD Team)
                </Text>

                <View
                  style={{
                    marginTop: 24,
                    height: StyleSheet.hairlineWidth,
                    backgroundColor: "#00C3FF",
                    borderBottomColor: "#00C3FF",
                    borderBottomWidth: StyleSheet.hairlineWidth,
                    alignSelf: "stretch",
                    width: "100%",
                  }}></View>

                <Messages
                  setlastreadmessage={setlastreadmessage}
                  length={userStore?.messages.length}
                  patient={data.patient}
                  userStore={userStore}
                  messages={userStore?.messages}
                  patientid={patientid}
                />
              </View>
            </View>
          </View>
        </MainFramePatient>
      )}
    </View>
  );
});
export default TempScreen;

const styles = StyleSheet.create({
  whitebox: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    shadowColor: "#818181",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
  title1: {
    fontFamily: "Lato",
    fontSize: 13,
    color: "#1F2E99",
  },
  title2: {
    fontFamily: "Lato",
    fontSize: 15,
    color: "#1F2E99",
  },
  title3: {
    fontFamily: "LatoBold",
    fontSize: 18,
    color: "#1F2E99",
  },

  container: {
    flex: 1,
  },
});
