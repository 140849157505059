import React, { useCallback, useEffect, useState } from "react";
import { Dimensions, Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";

import { BlueButton } from "../components/BlueButton";
import { MainFramePatient } from "../components/MainFramePatient";
import { SendLink } from "../components/SendLink";
import type { PatientMediaProps } from "../components/PatientMedia";
import { MediaDetailsModal, PatientMediaHeader, PatientMedia } from "../components/PatientMedia";
import { getRecoveryWeek } from "../misc/user-utils";
import { Modal } from "../components/Modals";
import { useNav } from "../hooks/useNav";
import { useFeatureFlag } from "../hooks/useFeatureFlag";
import { withStore } from "../misc/utils";
import useParams from "../hooks/useParams";

const PatientWoundsScreen = withStore(({ userStore }) => {
  const nav = useNav<"clinician">();
  const showWoundVizPro = useFeatureFlag("showWoundVizPro");

  const { day, patientid } = useParams("clinician", "PatientMediaScreen");
  const [data, setData] = useState<any>();
  const [pinFilter, setPinFilter] = useState(false);

  const [showSendLinkModal, setShowSendLinkModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState<any>(null);

  const dimensions = Dimensions.get("window");
  const listwidth = dimensions.width - 226;

  const handleClickPatientMedia = useCallback<PatientMediaProps["onClick"] & Function>(
    (item) => {
      if (showWoundVizPro) {
        nav.push("PatientMediaDetailsScreen", {
          patientId: patientid,
          mediaId: item.id,
        });
      } else {
        setShowDetailsModal(item);
      }
    },
    [patientid, showWoundVizPro],
  );

  async function fetchData() {
    fetch(global.apiurl + "/clinicians/getpatientmedia", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
      body: JSON.stringify({
        patientid,
      }),
    })
      .then(async (response) => await response.json())
      .then((resData) => {
        console.log(resData);
        if (resData.result) {
          setData(resData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    const scroll = () => {
      const elem = document.getElementById("day" + day);
      if (elem != null) {
        elem.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    };
    if (day) {
      setTimeout(scroll, 600);
      setTimeout(scroll, 400);
      setTimeout(scroll, 1200);
    }

    fetchData();
  }, []);

  const renderItem = ({ item }) => (
    <PatientMedia item={item} patient={data?.patient} onClick={handleClickPatientMedia} key={item.id} />
  );

  const renderHeader = ({ title, style }) => <PatientMediaHeader style={style}>{title}</PatientMediaHeader>;

  let allphotocount = 0;
  let pinnedphotocount = 0;
  function ispinned(item) {
    return item.ispinned == 1;
  }

  function renderList(pinFilter: boolean, fullFilter: boolean, topFilter: boolean, bottomFilter: boolean) {
    const output = [];
    let first = true;
    let showitem = true;
    let showitem2 = true;

    let currentweek = -1;
    data.patientmedia.forEach((item) => {
      showitem = !pinFilter || item.ispinned;
      showitem2 = !(topFilter || bottomFilter || fullFilter);

      if (fullFilter && item.phototypeid == 1) showitem2 = true;
      if (topFilter && item.phototypeid == 2) showitem2 = true;
      if (bottomFilter && item.phototypeid == 3) showitem2 = true;
      if (showitem && showitem2) {
        if (currentweek != getRecoveryWeek(item.dayno)) {
          currentweek = getRecoveryWeek(item.dayno);
          if (!first) {
            output.push(
              renderHeader({
                title: "Week " + currentweek + " of recovery",
                style: { width: listwidth, marginTop: 60 },
              }),
            );
          } else {
            output.push(
              renderHeader({
                title: "Week " + currentweek + " of recovery",
                style: { width: listwidth, marginTop: 24 },
              }),
            );
          }
          first = false;
        }
        allphotocount++;
        if (item.ispinned) pinnedphotocount++;
        output.push(renderItem({ item }));
      }
    });

    return output;
  }

  return (
    <View style={styles.container}>
      <Modal
        show={showSendLinkModal}
        onDismiss={() => {
          fetchData();
        }}>
        <SendLink
          userStore={userStore}
          visible={showSendLinkModal}
          patient={data?.patient}
          removeView={() => {
            setShowSendLinkModal(false);
          }}
        />
      </Modal>

      <MediaDetailsModal
        show={!!showDetailsModal}
        patientMediaId={showDetailsModal?.id}
        onDismiss={() => {
          setShowDetailsModal(null);
        }}
      />

      {data && (
        <MainFramePatient
          userStore={userStore}
          selected={2}
          patient={data?.patient}
          clinician={data?.clinician}
          style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0, flexDirection: "column" }}>
          <View
            style={{
              paddingLeft: 65,
              paddingRight: 79,
              height: 50,
              width: "100%",
              backgroundColor: "#ffffff",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <TouchableOpacity
                onPress={(x) => {
                  setPinFilter(false);
                }}>
                <Text style={[styles.title1, pinFilter && styles.title2]}>
                  All Photos({data?.patientmedia?.length})
                </Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={{ marginLeft: 33, marginTop: 0 }}
                onPress={(x) => {
                  setPinFilter(true);
                }}>
                <View style={{ flexDirection: "row", alignItems: "center", height: 44 }}>
                  {!pinFilter ? (
                    <Image source={require("../assets/images/pinblackicon.png")} style={styles.pinicon} />
                  ) : (
                    <Image source={require("../assets/images/pinselectedorangeicon.png")} style={styles.pinicon} />
                  )}

                  <Text style={[styles.title2, { marginLeft: 6.67 }, pinFilter && styles.title1]}>
                    Pinned Photos({data?.patientmedia?.filter(ispinned).length})
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>

          <View
            style={{
              width: listwidth,
              marginBottom: 64,
              marginLeft: 43,
              paddingRight: 63,
              flexDirection: "row",
              flexWrap: "wrap",
            }}>
            {global.linkformenabled && (
              <BlueButton
                onPress={() => {
                  setShowSendLinkModal(true);
                }}
                sendlink
                title="Send link"
                style={{ position: "absolute", right: 64, top: 14, width: 126, zIndex: 100 }}
              />
            )}

            {renderList(pinFilter, false, false, false)}
          </View>
        </MainFramePatient>
      )}
    </View>
  );
});

export default PatientWoundsScreen;

const styles = StyleSheet.create({
  whitebox: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    shadowColor: "#818181",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    elevation: 5,
  },
  title1: {
    fontFamily: "LatoBold",
    fontSize: 15,
    color: "#FE7235",
  },
  title2: {
    fontFamily: "Lato",
    fontSize: 15,
    color: "#000000",
  },
  title3: {
    fontFamily: "Lato",
    fontSize: 15,
    color: "#1F2E99",
  },
  pinicon: {
    width: 12.666,
    height: 16,
  },
  photoicon: {
    width: 19,
    height: 19,
  },

  container: {
    flex: 1,
  },
});
