import React from "react";
import { PasswordStrengthMeter } from "../PasswordStrengthMeter";
import { ValidCheckmark } from "../ValidCheckmark";
import UserLayout from "./UserLayout";
import { EyePassword } from "./EyePassword";
import useParams from "../../hooks/useParams";
import { withStore } from "../../misc/utils";
import { Checkmark } from "./Checkmark";
import { toastError } from "../Notifications";
import { requests } from "../../misc";

interface UnifiedSetPasswordProps {
  admin?: boolean;
  postopadmin?: boolean;
  userStore: any;
}

const UnifiedSetPassword = withStore((props: UnifiedSetPasswordProps) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [passwordCheck, setPasswordCheck] = React.useState("");
  const [passwordCheckError, setPasswordCheckError] = React.useState("");
  const { email, tempPassword } = useParams();

  const apigateway = props.admin ? "hospitaladmins" : props.postopadmin ? "postopadmins" : "clinicians";

  async function setFirstPassword(e: React.FormEvent<Element>) {
    e.preventDefault();
    if (isLoading) return false;

    setPasswordCheckError("");

    if (password != passwordCheck) {
      setPasswordCheckError("Passwords don't match");
      return;
    }

    try {
      setIsLoading(true);
      const resData = await requests.post(`/${apigateway}/setfirstpassword`, {
        email,
        temppassword: tempPassword,
        newpassword: password,
      });
      await props.userStore.setFullLoggedinIsAdmin(
        resData.token,
        resData.photourl,
        resData.firstname,
        resData.lastname,
        // usertype in backend has difrent values , fix this !!!
        resData.usertype - 1,
        resData.readonly,
      );
      location.href = props?.admin
        ? "/hospitaladmin/registerprofile"
        : props?.postopadmin
        ? "/postopadmin/registerprofile"
        : "/registerprofile";
    } catch (err: any) {
      console.error("Failed to save password:", err);
      toastError("We could not save your password. Please try later.");
    } finally {
      setIsLoading(false);
    }
  }

  function lengthCheck(password: string): boolean {
    return password?.length > 11;
  }

  function entropyCheck(password: string): boolean {
    return /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{1,200}$/.test(password);
  }

  return (
    <UserLayout title="Set a new password for your account" body=" ">
      <>
        <div style={{ height: 30 }}>&nbsp;</div>
        <form onSubmit={setFirstPassword}>
          <div className="input-block" style={{ paddingTop: 20 }}>
            <label htmlFor="password">Set new password</label>
            <EyePassword
              id="password"
              onChange={(ev: { target: { value: string } }) => {
                setPassword(ev.target.value.trim());
              }}
            />
          </div>
          <PasswordStrengthMeter style={{ marginTop: 11, marginBottom: 11 }} pw={password} />
          <div className="password-check">
            <Checkmark value={lengthCheck(password)} />
            Minimum of 12 characters
          </div>
          <div className="password-check">
            <Checkmark value={entropyCheck(password)} />
            Must include capital, lowercase, number and a special character
          </div>
          <div className="input-block" style={{ marginTop: 20 }}>
            <label htmlFor="password-check">Repeat new password</label>
            <EyePassword
              id="password-check"
              error={passwordCheckError}
              onChange={(ev: { target: { value: string } }) => {
                setPasswordCheck(ev.target.value.trim());
              }}
            />
          </div>
          <p>
            <button type="submit" id="enter" disabled={isLoading || !lengthCheck(password) || !entropyCheck(password)}>
              Set Password
            </button>
          </p>
          <a href="https://postop.ai/password-policy/" target="_blank" className="password-policy">
            <div className={"standard-shadow"}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.6879 1.16792C11.8891 1.09246 12.1109 1.09246 12.3121 1.16792L20.3121 4.16792C20.659 4.29803 20.8889 4.62969 20.8889 5.00022V12.0002C20.8889 15.397 18.6385 18.1227 16.5853 19.9192C15.5401 20.8337 14.4991 21.549 13.7211 22.0352C13.3312 22.2789 13.005 22.4666 12.7743 22.5943C12.6588 22.6582 12.5671 22.7072 12.503 22.7408C12.471 22.7577 12.4459 22.7707 12.4281 22.7797L12.4071 22.7904L12.3988 22.7946C12.3985 22.7948 12.3975 22.7953 12 22.0002C11.6025 22.7953 11.6022 22.7951 11.6019 22.795L11.5929 22.7904L11.5719 22.7797C11.5541 22.7707 11.529 22.7577 11.497 22.7408C11.4329 22.7072 11.3412 22.6582 11.2258 22.5943C10.995 22.4666 10.6688 22.2789 10.2789 22.0352C9.50093 21.549 8.4599 20.8337 7.41467 19.9192C5.36156 18.1227 3.11111 15.397 3.11111 12.0002V5.00022C3.11111 4.62969 3.34096 4.29803 3.68789 4.16792L11.6879 1.16792ZM12 22.0002L11.6019 22.795C11.8522 22.9201 12.1473 22.9204 12.3975 22.7953L12 22.0002ZM12 20.9906C12.2001 20.8781 12.4664 20.723 12.7789 20.5277C13.5009 20.0764 14.4599 19.4167 15.4147 18.5813C17.3616 16.8777 19.1111 14.6034 19.1111 12.0002V5.61622L12 2.94955L4.88889 5.61622V12.0002C4.88889 14.6034 6.63845 16.8777 8.58534 18.5813C9.54011 19.4167 10.4991 20.0764 11.2211 20.5277C11.5336 20.723 11.7999 20.8781 12 20.9906Z"
                  fill="#222E93"
                />
                <path d="M11.9111 11.5556V1.77783L19.9111 5.5027V11.5556H11.9111Z" fill="#222E93" />
                <path d="M12 11.5556L12 21.7778L5.33333 16.4445L4.44444 11.5556L12 11.5556Z" fill="#222E93" />
              </svg>
              Read our password policy
            </div>
          </a>
        </form>
      </>
    </UserLayout>
  );
});

export default UnifiedSetPassword;
