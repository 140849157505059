import { css, cx } from "@emotion/css";
import type { CSSProperties, ReactNode } from "react";
import React from "react";

export interface ScreenBaseProps {
  children?: ReactNode;
  bgcolor?: string;
  className?: string;
  style?: CSSProperties;
}

export default function ScreenBase({ children, bgcolor, className, style }: ScreenBaseProps) {
  return (
    <div
      className={cx("POScreenBase", ScreenStyle, className)}
      style={{
        backgroundColor: bgcolor || "#E8F0FF",
        ...style,
      }}>
      {children}
    </div>
  );
}

const ScreenStyle = css`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-self: stretch;
  align-items: stretch;
  justify-content: flex-start;
`;
