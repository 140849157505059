import type { ReactDatePickerProps } from "react-datepicker";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export interface DatePickerProps extends ReactDatePickerProps {}

export default function DatePicker(props: DatePickerProps): JSX.Element {
  // @ts-expect-error
  return <ReactDatePicker {...props} />;
}
