import * as React from "react";
import Login2FA from "../../components/user/Login2FA";

import SplitFrame from "../../components/SplitFrame";

import { inject, observer } from "mobx-react";
import UnifiedLogin from "../../components/user/UnifiedLogin";
import { useFeatureFlag } from "../../hooks/useFeatureFlag";

// TODO: what is this screen for? the flow uses the Login2FA component directly!

export const ClinicianLogin2FA = inject("userStore")(
  observer(({ userStore }) => {
    if (useFeatureFlag("unifiedlogin")) return <UnifiedLogin userStore={userStore} />;
    return (
      <SplitFrame bgcolor={"#E8F0FF"}>
        <Login2FA bgcolor={"#E8F0FF"} userStore={userStore} />
      </SplitFrame>
    );
  }),
);

export const HospitalAdminLogin2FA = inject("userStore")(
  observer(({ userStore }) => {
    return (
      <SplitFrame>
        <Login2FA admin userStore={userStore} />
      </SplitFrame>
    );
  }),
);

export const PostopAdminLogin2FA = inject("userStore")(
  observer(({ userStore }) => {
    return (
      <SplitFrame>
        <Login2FA postopadmin userStore={userStore} />
      </SplitFrame>
    );
  }),
);
