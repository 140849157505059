import * as React from "react";
import { Dimensions, View, StyleSheet, Image, TouchableOpacity, ImageBackground } from "react-native";

export default function SplitFrame(props) {
  const dimensions = Dimensions.get("window");

  return (
    <View style={[styles.containersplit, { backgroundColor: props.bgcolor ? props.bgcolor : "#ffffff" }]}>
      <View style={{ width: dimensions.width / 2 - 350 - 100, flexShrink: 0.5 }} />

      <View
        style={{
          minWidth: 350,
          height: "100%",
          alignItems: "flex-start",
          backgroundColor: props.bgcolor ? props.bgcolor : "#ffffff",
        }}>
        <View style={{ width: 390, marginLeft: 10, paddingLeft: 5, marginRight: 10, flexShrink: 0.5 }}>
          {props.children}
        </View>
      </View>
      <View style={{ width: 147, flexShrink: 0.5, backgroundColor: props.bgcolor ? props.bgcolor : "#ffffff" }}></View>
      <View
        style={{
          width: 300,
          backgroundColor: props.bgcolor ? props.bgcolor : "#ffffff",
          flexShrink: 1,
          flexGrow: 1,
          overflow: "hidden",
        }}>
        <ImageBackground
          source={require("../assets/images/bglogin.jpg")}
          style={{
            height: "100%",
            resizeMode: "cover",
            flexShrink: 1,
          }}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  containersplit: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: "#fff",
  },
});
