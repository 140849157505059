import { cx } from "@emotion/css";
import type { MouseEvent } from "react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

const MODALTARGET_ID = "POModalTarget";

export interface ModalProps {
  children: React.ReactNode;
  show: boolean;
  onDismiss: () => void;
  className?: string;
}

export function Modal({ children, show, className, onDismiss }: ModalProps): JSX.Element {
  const backdropRef = useRef<HTMLDivElement | null>(null);
  const [modalTarget, setModalTarget] = useState<HTMLElement | null>(document.getElementById(MODALTARGET_ID));

  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (e.target === backdropRef.current) onDismiss();
    },
    [onDismiss],
  );

  useEffect(() => {
    document.body.style.overflow = show ? "hidden" : "";
    return () => {
      document.body.style.overflow = "";
    };
  }, [show]);

  useEffect(() => {
    let interval: any;
    if (!modalTarget) {
      interval = setInterval(() => {
        const target = document.getElementById(MODALTARGET_ID);
        if (target) {
          setModalTarget(target);
          clearInterval(interval);
        }
      }, 100);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, []);

  if (!show || !modalTarget) return <></>;
  return createPortal(
    <div ref={backdropRef} className={cx("POModal-root", "POModal-backdrop", className)} onClick={handleClick}>
      <div className="POModal-modal">{children}</div>
    </div>,
    document.getElementById(MODALTARGET_ID)!,
  );
}

export function ModalTarget() {
  return <div id={MODALTARGET_ID} className="POModalTarget-root" />;
}
