import { css, cx } from "@emotion/css";
import type { CSSProperties, KeyboardEvent } from "react";
import React, { useCallback, useMemo, useState } from "react";
import { Popover } from "react-tiny-popover";
import { theme } from "../../misc/constants";

export type DropdownVariant = "chip" | "form";

export interface DropdownProps<T, R> {
  variant: DropdownVariant;
  values: T[];
  /** Currently selected value. */
  value: T | R;
  /** Size of the input box. Implicitly determines size/width of the dropdown. */
  size?: number;
  className?: string;
  style?: CSSProperties;
  onChange?: (value: T, index: number) => void;
  /** Extract the unique ID of an item. Defaults to its index. */
  placeholder?: string;
  extractId?: (item: T, index: number) => string | number;
  /** Extract label of an item. Defaults to the `label` property if any, otherwise stringification. */
  extractLabel?: (item: T) => string;
  /** Compare equality between an item from `values` to either `value` or `defaultValue`, whichever is applicable. Defaults to strict equality. */
  equality?: <R>(lhs: T, rhs: R) => boolean;
  filter?: (item: T) => boolean;
}

export default function Dropdown<T = string, R = T>({
  variant,
  size,
  className,
  style,
  onChange,
  placeholder,
  extractId = (_, index) => index,
  extractLabel = (item: any) => item?.label ?? String(item),
  equality = (lhs: any, rhs: any) => lhs === rhs,
  filter = () => true,
  ...props
}: DropdownProps<T, R>) {
  const values = useMemo(() => props.values.filter(filter), [props.values, filter]);
  const value = values.find((lhs) => equality(lhs, props.value)) ?? placeholder ?? values[0];

  const [isEditing, setEditing] = useState(false);
  const [hoverIndex, setHoverIndex] = useState(-1);

  const selectItem = useCallback(
    (index: number) => {
      onChange?.(values.filter(filter)[index], index);
      setEditing(false);
    },
    [values, filter],
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      const { key } = e;
      switch (key) {
        case "ArrowUp":
          e.preventDefault();
          setHoverIndex(Math.max(hoverIndex - 1, 0));
          break;
        case "ArrowDown":
          e.preventDefault();
          setHoverIndex(Math.min(hoverIndex + 1, values.length - 1));
          break;
        case "Enter":
          e.preventDefault();
          selectItem(hoverIndex);
          break;
      }
    },
    [hoverIndex],
  );

  return (
    <Popover
      isOpen={isEditing}
      positions={["bottom", "top"]}
      align="end"
      onClickOutside={() => {
        setEditing(false);
      }}
      reposition={false}
      content={
        <ul className={cx("PODropdown-options", popoverStylesheet)}>
          {values.filter(filter).map((item, index) => (
            <li
              key={extractId(item, index)}
              className={cx({
                "PODropdown-option": true,
                hover: hoverIndex === index,
              })}
              onMouseOver={() => {
                setHoverIndex(index);
              }}
              onClick={() => {
                selectItem(index);
              }}>
              {extractLabel(item)}
            </li>
          ))}
        </ul>
      }
      padding={4}>
      <div className={cx("PODropdown-root", `PODropdown-${variant}`, stylesheet, className)} style={style}>
        <input
          className="PODropdown-value"
          value={value ? extractLabel(value) : ""}
          size={size}
          onFocus={() => {
            setEditing(true);
          }}
          onKeyDown={handleKeyDown}
          // noop onChange to prevent React from complaining about uncontrolled input
          onChange={() => {}}
        />
      </div>
    </Popover>
  );
}

const stylesheet = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;

  &.PODropdown-form {
    background: white;
    border: 0;
    border-radius: 15px;
    box-shadow: ${theme.boxShadow};

    input {
      width: 250px;
    }
  }

  &.PODropdown-chip {
    background-color: #dddddd;
    border-radius: 50px;
  }

  .PODropdown-value {
    appearance: none;
    font-family: Lato;
    background: transparent;
    border: 0;
    border-bottom: 1px solid ${theme.line};

    &:focus {
      outline: none;
      border-bottom-color: ${theme.primary};
    }
    &:read-only,
    &:disabled {
      border-bottom: 0;
    }
  }
`;

const popoverStylesheet = css`
  list-style-type: none;
  margin: 0;
  padding: 4px 0;
  background: white;
  border: 1px solid ${theme.line};
  border-radius: 4px;

  li.PODropdown-option {
    padding: 4px 8px;
    &.hover,
    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
`;
