/* eslint-disable @typescript-eslint/no-explicit-any */
import { Buffer } from "buffer";
import { inject, observer } from "mobx-react";
import type { ComponentType } from "react";
import type { UserStore } from "../mobx/store";

export type ArrayElement<T> = T extends (infer U)[] ? U : never;

export type Zipped<Args> = Args extends [infer First, ...infer Rest]
  ? [ArrayElement<First> | undefined, ...Zipped<Rest>]
  : [];

export const withStore = <Props = unknown>(Component: ComponentType<Props & { userStore: UserStore }>) =>
  inject(({ userStore }) => ({ userStore }))(observer(Component)) as any as ComponentType<Props & { userStore?: any }>;

export function zip<Args extends any[][]>(...arrays: Args): Zipped<Args>[] {
  const length = Math.max(...arrays.map((arr) => arr.length));
  return Array.from({ length }, (_, i) => arrays.map((arr) => arr[i])) as any;
}

export const decodeB64 = (b64: string) => Buffer.from(b64, "base64").toString("utf-8");
export const encodeB64 = (str: string) => Buffer.from(str, "utf-8").toString("base64");

export function getJWTPayload(token: string) {
  const [, payload] = token.split(".", 3);
  return JSON.parse(decodeB64(payload));
}

export function limiter<Args extends any[]>(fn: (...args: Args) => Promise<void> | void, delay = 100) {
  let timeout: ReturnType<typeof setTimeout> | undefined;

  return (...args: Args) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(async () => {
      try {
        await fn(...args);
      } catch (err) {
        console.error(err);
      } finally {
        timeout = undefined;
      }
    }, delay);
  };
}

export const isDefined = <T>(value: T | undefined | null): value is T => value !== undefined && value !== null;

export async function getImageSize(url: string) {
  return await new Promise<[number, number]>((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      resolve([img.naturalWidth, img.naturalHeight]);
    };
    img.onerror = reject;
  });
}

export function onImageLoaded(img: HTMLImageElement, cb: () => void) {
  if (img.complete) {
    cb();
  } else {
    const wrapper = () => {
      img.removeEventListener("load", wrapper);
      cb();
    };
    img.addEventListener("load", wrapper);
  }
}

export function isChildOf(child: HTMLElement, parent: HTMLElement) {
  let current = child;
  while (current !== document.body) {
    if (current === parent) return true;
    current = current.parentElement!;
  }
  return false;
}
