import { css, cx } from "@emotion/css";
import type { ReactNode } from "react";
import React from "react";
import { theme } from "../misc/constants";

export interface PatientMenuProps {
  patientId: number;
  selected: number;
  messageCount: number;
}

export function PatientMenu({ patientId, selected, messageCount }: Readonly<PatientMenuProps>) {
  return (
    <div>
      <MenuButton url={`/patient?patientid=${patientId}`} selected={selected === 1}>
        Patient Overview
      </MenuButton>
      <MenuButton url={`/patientmedia?patientid=${patientId}`} selected={selected === 2}>
        Patient Media
      </MenuButton>
      <MenuButton url={`/patientproms?patientid=${patientId}`} selected={selected === 3}>
        PROMS Data
      </MenuButton>
      <MenuButton url={`/patientprems?patientid=${patientId}`} selected={selected === 4}>
        PREMS Data
      </MenuButton>
      <MenuButton url={`/patientcareplan?patientid=${patientId}`} selected={selected === 5}>
        Care Plan
      </MenuButton>
      <MenuButton url={`/patientmessages?patientid=${patientId}`} selected={selected === 6} badge={messageCount}>
        Messages
      </MenuButton>
      <MenuButton url={`/patientdiary?patientid=${patientId}`} selected={selected === 7}>
        Clinician Diary
      </MenuButton>
    </div>
  );
}

export interface ClinicalTeamMenuProps {
  teamId: number;
  selected: number;
}

export function ClinicalTeamMenu({ teamId, selected }: Readonly<ClinicalTeamMenuProps>) {
  return (
    <div>
      <MenuButton url={`/hospitaladmin/clinicalteam?clinicalteamid=${teamId}`} selected={selected === 1}>
        Patients
      </MenuButton>
      <MenuButton url={`/hospitaladmin/clinicalteamclinicians?clinicalteamid=${teamId}`} selected={selected === 2}>
        Clinicians
      </MenuButton>
    </div>
  );
}

export interface ClinicianMenuProps {
  clinicianId: number;
  selected: number;
}

export function ClinicianMenu({ clinicianId, selected }: Readonly<ClinicianMenuProps>) {
  return (
    <div>
      <MenuButton url={`/hospitaladmin/clinician?clinicianid=${clinicianId}`} selected={selected === 1}>
        Patients
      </MenuButton>
      <MenuButton url={`/hospitaladmin/clinicianteams?clinicianid=${clinicianId}`} selected={selected === 2}>
        Clinical Teams
      </MenuButton>
    </div>
  );
}

interface MenuButtonProps {
  children?: ReactNode;
  url: string;
  selected?: boolean;
  badge?: number;
}

function MenuButton({ children, url, selected, badge }: Readonly<MenuButtonProps>) {
  return (
    <div className={cx("POLeftMenu-item", styles.item, selected && "selected")}>
      <a href={url} className={`POLeftMenu-link ${styles.link}`}>
        {children}
        {badge ? <div className="POLeftMenu-badge">{badge}</div> : null}
      </a>
    </div>
  );
}

const styles = Object.freeze({
  item: css`
    font-family: Lato;
    font-size: 15px;
    line-height: 38px;
    color: ${theme.primary};
    padding: 8.5px 0 8.5px 17px;
    padding-right: 0;

    .POLeftMenu-badge {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      margin-left: 6px;
      font-size: 10px;
      font-weight: bold;
      color: white;
      background: #fe5935;
      border-radius: 10px;
      overflow: hidden;
      transform: translateY(-2px);
    }
  `,
  link: css`
    display: block;
    text-decoration: none;
    color: ${theme.primary};
    border-radius: 8px 0 0 8px;
    padding: 4px;
    padding-left: 17px;

    &:visited {
      color: ${theme.primary};
    }

    .selected & {
      font-weight: bold;
      background: #fcf5ef;
    }
  `,
});
