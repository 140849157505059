import Moment from "moment";
import type { ReactNode } from "react";
import React, { useEffect, useState } from "react";
import { Dimensions, Image, ScrollView, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import io from "socket.io-client";

import { css, cx } from "@emotion/css";
import { A } from "@expo/html-elements";

import { Avatar } from "../components/Avatar";
import { withStore } from "../misc/utils";
import Hoverable from "../navigation/Hoverable";
import { Hairline } from "./Hairline";
interface MainFrameProps {
  children: ReactNode;
  style?: any;
  patient?: any;
  messages?: any;
  setlastreadmessage?: any;
  postopadmin?: boolean;
  admin?: boolean;
  dashboard?: boolean;
  patientlist?: boolean;
  clinicalteamlist?: boolean;
  clinicianlist?: boolean;
  hospitallist?: boolean;
  hospitaladmins?: boolean;
}
export const MainFrame = withStore<MainFrameProps>((props) => {
  const [notificationsVisual, setNotificationsVisual] = React.useState([]);
  const [lastreadnotification] = React.useState(0);

  const [lastestnotification, setLastestnotification] = React.useState([]);
  const [lastsent, setLastsent] = React.useState(0);
  const [newnotificationcount, setNewnotificationcount] = React.useState(0);

  const [modalNotificationsVisible, setModalNotificationsVisible] = useState(false);

  const dimensions = Dimensions.get("window");

  useEffect(() => {
    async function connectSocket() {
      if (props.userStore.usertype == 2) return;
      global.socket = io(global.apiurl, {
        extraHeaders: {
          "x-auth-token": props.userStore.sessiontoken,
        },
        transportOptions: {
          polling: {
            extraHeaders: {
              "x-auth-token": props.userStore.sessiontoken,
            },
          },
        },
      });

      global.socket.on("error", function (err) {
        throw new Error(err);
      });

      global.socket.on("success", function (data) {
        if (props.messages) {
          global.socket.emit("joinpatientmessages", props.patient.id);
        }
      });

      global.socket.on("notificationsupdated", function () {
        getNotifications();
      });

      if (props.messages) {
        global.socket.on("chatupdated", function (data) {
          const item = data.data;
          if (!item?.clinician) {
            item.clinician = {
              _id: -10,
              firstname: props.patient.firstname,
              lastname: props.patient.lastname,
              thumburl: props.patient.thumburl,
            };
          } else {
            item.clinician.firstname = "Doctor - " + item.clinician.firstname;
          }

          const newMessage = {
            _id: item.id,
            text: item.message,
            createdAt: item.createdAt,
            user: {
              _id: item?.clinician?.id,
              name: item?.clinician?.firstname + " " + item?.clinician?.lastname,
              avatar: item?.clinician?.thumburl ? global.imagepathurl + item?.clinician?.thumburl : null,
            },
          };

          props.userStore.addmessage2(newMessage);

          props.setlastreadmessage(newMessage._id);
        });
      }
    }
    setTimeout(connectSocket, 500);
  }, []);

  async function getNotifications() {
    fetch(global.apiurl + "/clinicians/getnotifications", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
    })
      .then(async (response) => await response.json())
      .then((resData) => {
        if (resData.result) {
          if (!global.notificationsvisible) {
            global.visuallastreadnotification = resData.lastreadnotification;
            calculatenewNotificationCount(resData.notifications, resData.lastreadnotification);
          } else {
            calculatenewNotificationCount(resData.notifications, resData.visuallastreadnotification);
          }
          renderNotifications(resData.notifications, true, global.visuallastreadnotification);
        }
      })
      .catch((err) => {
        console.error("Failed to get notifications:", err);
      });
  }

  useEffect(() => {
    // setTimeout(getNotifications,1000);
    getNotifications();
  }, []);

  function sendlastreadnotification() {
    if (lastsent == lastestnotification) return;

    fetch(global.apiurl + "/clinicians/setlastreadnotification", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
      body: JSON.stringify({
        notificationid: lastestnotification,
      }),
    })
      .then(async (response) => await response.json())
      .then((resData) => {
        setLastsent(lastestnotification);
      })
      .catch((err) => {
        console.error("Failed to set last read notification:", err);
      });
  }

  function calculatenewNotificationCount(notifications = [], visuallastreadnotification) {
    let anynew = false;
    let newcount = 0;
    if (notifications.length > 0) {
      setLastestnotification(notifications[0].id);
      if (notifications[0].id > visuallastreadnotification) {
        anynew = true;
      }
      notifications.forEach((element) => {
        if (anynew && !(element.id > visuallastreadnotification)) {
          anynew = false;
        }

        if (anynew) {
          newcount++;
        }
      });

      setNewnotificationcount(newcount);
    }
  }

  function renderNotifications(notifications, render, visuallastreadnotification) {
    const output = [];
    let anynew = false;
    let newcount = 0;
    if (notifications[0]) {
      if (notifications[0].id > visuallastreadnotification) {
        anynew = true;
        if (render) {
          output.push(
            <View
              key="headernew"
              style={{
                backgroundColor: "#FCF5EF",
                height: 28,
                justifyContent: "center",
                paddingLeft: 14,
                marginHorizontal: 11,
                marginTop: 8,
                marginBottom: 4,
                borderRadius: 4,
              }}>
              <Text
                style={{
                  color: "#FE5935",
                  fontFamily: "LatoBold",
                  fontSize: 13,
                }}>
                New notifications
              </Text>
            </View>,
          );
        }
      } else {
        if (render) {
          output.push(
            <View
              key="headerold"
              style={{
                backgroundColor: "#FCF5EF",
                height: 28,
                justifyContent: "center",
                paddingLeft: 14,
                marginHorizontal: 11,
                marginTop: 8,
                marginBottom: 4,
                borderRadius: 4,
              }}>
              <Text
                style={{
                  color: "#1F2E99",
                  fontFamily: "LatoBold",
                  fontSize: 13,
                }}>
                Older notifications
              </Text>
            </View>,
          );
        }
      }
      let i = 0;
      notifications.forEach((element) => {
        if (anynew && !(element.id > visuallastreadnotification)) {
          anynew = false;
          if (render) {
            output.push(
              <View
                style={{
                  backgroundColor: "#FCF5EF",
                  height: 28,
                  justifyContent: "center",
                  paddingLeft: 14,
                  marginHorizontal: 11,
                  marginTop: 0,
                  borderRadius: 4,
                }}>
                <Text
                  style={{
                    color: "#1F2E99",
                    fontFamily: "LatoBold",
                    fontSize: 13,
                  }}>
                  Older notifications
                </Text>
              </View>,
            );
          }
        }

        if (render) {
          output.push(
            <A
              href={
                element.notificationtype_id == 1
                  ? "/patient?patientid=" + element.patient_id
                  : "/clinicalteam?clinicalteamid=" + element.clinicalteam_id
              }
              key={element.id}>
              <TouchableOpacity>
                <View style={styles.view2}>
                  <View style={styles.view1}>
                    {element.notificationtype_id == 1 && (
                      <Image source={require("../assets/images/flagicon.png")} style={{ width: 15, height: 18.333 }} />
                    )}
                  </View>
                  <View style={{ marginLeft: 12 }}>
                    <Text style={[styles.title1, { width: 294, overflow: "visible" }]}>{element.message}</Text>
                    <Text style={styles.title3}>{Moment(element.createdAt).fromNow()}</Text>
                  </View>
                </View>
              </TouchableOpacity>
            </A>,
          );
        }

        if (anynew) newcount++;

        i++;
      });

      // setNewnotificationcount(newcount);

      //    return output;

      setNotificationsVisual(output);
    } else {
      //  return <Text style={styles.title1}>Nothing new.</Text>
      setNotificationsVisual(<Text style={[styles.title1, { padding: 10 }]}>No unread messages.</Text>);
    }
  }

  window.onclick = function (event) {
    if (event.target == document.getElementById("myModal")) {
      document.getElementById("myModal").style.display = "none";
      document.getElementById("modaliframe").src = "about:blank";
    }
  };

  return (
    <View
      style={[
        { width: "100%", flex: 1 },
        props.userStore.usertype == 1 && { backgroundColor: "#E8F0FF" },
        props.userStore.usertype == 2 && { backgroundColor: "#FCF5EF" },
        props.userStore.usertype == 2 && { backgroundColor: "#FCF5EF" },
      ]}>
      <View
        style={{
          zIndex: 100,
          backgroundColor: "#FFFFFF",
          height: 72,
          width: "100%",
          flexDirection: "row",
        }}>
        <div
          onClick={() => {
            document.getElementById("myModal").style.display = "none";
            document.getElementById("modaliframe").src = "about:blank";
          }}
          id="myModal"
          className={css`
            display: none;
            position: fixed;
            z-index: 1;
            padding-top: 100px;
            width: 100%;
            height: 100%;
            overflow: auto;
            background-color: rgba(0, 0, 0, 0.4);
          `}>
          <div
            className={css`
              background-color: #fefefe;
              margin: auto;
              padding: 5px;
              padding-top: 0;
              border: 1px solid #888;
              width: 80%;
              height: 80%;
            `}>
            <A
              onPress={() => {
                document.getElementById("myModal").style.display = "none";
                document.getElementById("modaliframe").src = "about:blank";
              }}>
              {" "}
              <span
                className={cx(
                  "close",
                  css`
                    color: #aaaaaa;
                    float: right;
                    font-size: 28px;
                    font-weight: bold;
                  `,
                )}>
                &times;
              </span>
            </A>

            <iframe
              id="modaliframe"
              width="100%"
              height="780"
              className={css`
                top: 66px;
                bottom: 0;
                right: 0;
                /* xwidth: 600px; */
                border: none;
                margin: 0;
                padding: 0;
                overflow: hidden;
                z-index: 3;
                height: 95%;
              `}
            />
          </div>
        </div>
        <View style={{ flex: 1, flexDirection: "row" }}>
          <A href="/dashboard">
            <Image
              source={require("../assets/images/logopostop1.1.png")}
              style={{
                width: 102.1,
                height: 33.48,
                marginTop: 19,
                alignSelf: "flex-start",
                marginLeft: 32,
              }}
            />
          </A>
          <View>
            {props.userStore.usertype == 1 && (
              <Text
                style={{
                  fontSize: 13,
                  fontFamily: "Lato",
                  color: "#0077FF",
                  marginTop: 22,
                  marginLeft: 32,
                }}>
                clinician admin
              </Text>
            )}
            {props.userStore.usertype == 2 && (
              <Text
                style={{
                  fontSize: 13,
                  fontFamily: "Lato",
                  color: "#0077FF",
                  marginTop: 22,
                  marginLeft: 32,
                }}>
                hospital admin
              </Text>
            )}
            {props.userStore.usertype == 3 && (
              <Text
                style={{
                  fontSize: 13,
                  fontFamily: "Lato",
                  color: "#0077FF",
                  marginTop: 22,
                  marginLeft: 32,
                }}>
                Post Op admin
              </Text>
            )}

            {props.userStore?.readonly != 0 && (
              <Text
                style={{
                  fontSize: 13,
                  fontFamily: "Lato",
                  color: "#ff0000",
                  marginLeft: 32,
                }}>
                [READ ONLY MODE]
              </Text>
            )}
          </View>
        </View>

        {!props.postopadmin && !props.admin && (
          <View
            style={{
              flexDirection: "row",
              flex: 1,
              alignSelf: "center",
              justifyContent: "space-around",
            }}>
            <A href="/dashboard">
              {!props.dashboard && (
                <Image source={require("../assets/images/tabdashboard.png")} style={{ width: 132, height: 25 }} />
              )}
              {props.dashboard && (
                <Image
                  source={require("../assets/images/tabdashboardselected.png")}
                  style={{ width: 132, height: 25 }}
                />
              )}
            </A>

            <A href="/patients">
              {!props.patientlist && (
                <Image source={require("../assets/images/tabpatientlist.png")} style={{ width: 129, height: 23 }} />
              )}
              {props.patientlist && (
                <Image
                  source={require("../assets/images/tabpatientlistselected.png")}
                  style={{ width: 129, height: 23 }}
                />
              )}
            </A>
          </View>
        )}

        {props.admin && (
          <View
            style={{
              flexDirection: "row",
              alignSelf: "center",
              justifyContent: "space-evenly",
              width: 661,
            }}>
            <A href="/admindashboard">
              {!props.dashboard && (
                <Image source={require("../assets/images/tabdashboard.png")} style={{ width: 132, height: 25 }} />
              )}
              {props.dashboard && (
                <Image
                  source={require("../assets/images/tabdashboardselected.png")}
                  style={{ width: 132, height: 25 }}
                />
              )}
            </A>

            <A href="/hospitaladmin/clinicalteams">
              {!props.clinicalteamlist && (
                <Image source={require("../assets/images/tabclinicalteam.png")} style={{ width: 129, height: 23 }} />
              )}
              {props.clinicalteamlist && (
                <Image
                  source={require("../assets/images/tabclinicalteamselected.png")}
                  style={{ width: 129, height: 23 }}
                />
              )}
            </A>

            <A href="/hospitaladmin/clinicians">
              {!props.clinicianlist && (
                <Image source={require("../assets/images/tabclinician.png")} style={{ width: 129, height: 23 }} />
              )}
              {props.clinicianlist && (
                <Image
                  source={require("../assets/images/tabclinicianselected.png")}
                  style={{ width: 129, height: 23 }}
                />
              )}
            </A>

            <A href="/hospitaladmin/adminpatients">
              {!props.patientlist && (
                <Image source={require("../assets/images/tabpatientlist.png")} style={{ width: 129, height: 23 }} />
              )}
              {props.patientlist && (
                <Image
                  source={require("../assets/images/tabpatientlistselected.png")}
                  style={{ width: 129, height: 23 }}
                />
              )}
            </A>
          </View>
        )}

        {props.postopadmin && (
          <View
            style={{
              flexDirection: "row",
              flex: 1,
              alignSelf: "center",
              justifyContent: "space-around",
            }}>
            <A href="/postopadmin/hospitallist">
              {!props.hospitallist && (
                <Image source={require("../assets/images/tabhospital.png")} style={{ width: 107, height: 25 }} />
              )}
              {props.hospitallist && (
                <Image
                  source={require("../assets/images/tabhospitalselected.png")}
                  style={{ width: 107, height: 25 }}
                />
              )}
            </A>

            <A href="/postopadmin/hospitaladmins">
              {!props.hospitaladmins && (
                <Image source={require("../assets/images/tabadmin.png")} style={{ width: 90, height: 22 }} />
              )}
              {props.hospitaladmins && (
                <Image source={require("../assets/images/tabadminselected.png")} style={{ width: 90, height: 22 }} />
              )}
            </A>
          </View>
        )}
        <View
          style={{
            flexDirection: "row",
            flex: 1,
            justifyContent: "flex-end",
            alignSelf: "center",
          }}>
          {props.userStore.usertype != 2 && (
            <TouchableOpacity
              style={{ width: 30, marginRight: 52 }}
              onPress={() => {
                setModalNotificationsVisible(true);
                global.notificationsvisible = true;
                sendlastreadnotification();
              }}>
              <Image
                source={require("../assets/images/notificationicon.png")}
                style={{ width: 16, height: 20, marginRight: 52, marginTop: 7 }}
              />

              {newnotificationcount > 0 && !modalNotificationsVisible && (
                <View
                  style={{
                    position: "absolute",
                    left: 16,
                    top: -2,
                    backgroundColor: "#FE5935",
                    borderRadius: 9,
                    minWidth: 18,
                    padding: 3,
                    height: 19,
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 100,
                  }}>
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: "LatoBold",
                      color: "#FFFFFF",
                    }}>
                    {newnotificationcount}
                  </Text>
                </View>
              )}
            </TouchableOpacity>
          )}
          <A
            href={!props.admin ? (!props.postopadmin ? "/profile" : "/postopadmin/profile") : "/hospitaladmin/profile"}>
            <Avatar
              url={
                props?.userStore?.userphotothumbnailurl != null && props?.userStore?.userphotothumbnailurl != "null"
                  ? global.imagepathurl + encodeURI(props?.userStore?.userphotothumbnailurl)
                  : null
              }
              size={30}
              radius={15}
              firstname={props?.userStore ? props?.userStore?.userfirstname : " "}
              lastname={props?.userStore ? props?.userStore?.userlastname : " "}
              style={{
                borderColor: "#0077FF",
                borderWidth: 1,
                marginRight: 32,
              }}
            />
          </A>
          <Hoverable
            onHoverOut={(x) => {
              global.notificationsvisible = false;
              setModalNotificationsVisible(false);
              global.visuallastreadnotification = lastreadnotification;
            }}
            onHoverIn={(z) => {}}>
            {(isHovered) => (
              <View
                style={[
                  styles.whitebox,
                  {
                    position: "absolute",
                    right: 100,
                    minHeight: 300,
                    top: 0,
                    width: 441,
                  },
                  !isHovered && { backgroundColor: "#ffffff" },
                  !modalNotificationsVisible && { top: -1000 },
                ]}>
                <Text
                  style={{
                    fontSize: 18,
                    fontFamily: "LatoBold",
                    color: "#1F2E99",
                    marginTop: 24,
                    marginLeft: 24,
                    zIndex: 100,
                  }}>
                  Notifications{isHovered}
                </Text>

                <Hairline marginTop={24} />
                <ScrollView style={{ maxHeight: Math.min(779, dimensions.height - 100) }}>
                  {notificationsVisual}
                </ScrollView>
              </View>
            )}
          </Hoverable>
        </View>
      </View>
      <View
        style={[
          {
            zIndex: 0,
            width: "100%",
            alignItems: "center",
            overflow: "visible",
          },
          props.style,
        ]}>
        {props.children}
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  view1: {
    backgroundColor: "#F0F0F0",
    width: 36,
    height: 36,
    borderRadius: 18,
    alignItems: "center",
    justifyContent: "center",
  },
  view2: {
    flexDirection: "row",
    marginLeft: 26,
    marginTop: 16,
    marginBottom: 32,
  },
  whitebox: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    shadowColor: "#818181",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },

  title1: {
    color: "#1F2E99",
    fontFamily: "Lato",
    fontSize: 15,
  },
  title2: {
    color: "#1F2E99",
    fontFamily: "LatoBold",
    fontSize: 15,
  },
  title3: {
    color: "#646464",
    marginTop: 8,
    fontFamily: "Lato",
    fontSize: 13,
  },
  container: {
    backgroundColor: "#FCF5EF",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  pinicon: {
    width: 12.666,
    height: 16,
  },
});
