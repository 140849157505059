import React, { useState, useEffect } from "react";
import { Text, View, StyleSheet, Image, TouchableOpacity } from "react-native";
import { Avatar } from "../components/Avatar";
import { BlueButton } from "./BlueButton";
import Menu, { MenuOptions, MenuOption, MenuTrigger, renderers } from "react-native-popup-menu";
import { errorMessage, successMessage } from "./Notifications";

export const ClinicianHeader = (props) => {
  const [lastname, setLastname] = React.useState("");

  const { ContextMenu, SlideInMenu, Popover } = renderers;

  async function sendInvitation() {
    const response = await fetch(global.apiurl + "/v1/clinicians/" + props.clinician.id + "/invite", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
    });

    if (response?.status == 200) {
      successMessage("Invitation sent");
    } else {
      errorMessage("Failed to send invitation", response);
    }
  }

  async function disableClinician(disabled) {
    fetch(global.apiurl + "/hospitaladmins/setcliniciandisabled", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
      body: JSON.stringify({
        clinicianid: props.clinician.id,
        disabled,
      }),
    })
      .then(async (response) => await response.json())
      .then((resData) => {
        console.log(resData);
        if (resData.result) {
          if (disabled == 1) alert("Clinician disabled.");
          if (disabled == 0) alert("Clinician enabled.");
          window.location.reload(true);
        }
      })
      .catch((err) => {
        alert("Please try again later.");
        console.log(err);
      });
  }

  return (
    <View style={{ height: 106, paddingLeft: 63, alignSelf: "stretch" }}>
      <View style={{ flexDirection: "row", width: 1132, alignItems: "flex-start", justifyContent: "flex-start" }}>
        <View style={{ flexDirection: "row", width: 300, alignItems: "center", marginTop: 25 }}>
          <Avatar
            url={props.clinician?.thumburl && global.imagepathurl + encodeURI(props.clinician?.thumburl)}
            size={56}
            radius={28}
            firstname={props?.clinician?.firstname}
            lastname={props?.clinician?.lastname}
          />
          <View style={{ marginLeft: 16 }}>
            <Text style={styles.title3}>
              {props?.clinician?.firstname} {props?.clinician?.lastname}{" "}
            </Text>
            <Text style={styles.title4}>{props?.clinician?.cliniciantype?.title}</Text>
            {props.clinician?.disabled == 1 && (
              <Text style={[styles.title4, { color: "#ff0000" }]}>[ACCESS DISABLED]</Text>
            )}
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            marginTop: 30,
            width: 1132 - 300 - 225 - 73,
            justifyContent: "space-between",
            alignItems: "flex-start",
            paddingRight: 15,
          }}>
          <View style={{ marginTop: 8 }}>
            {" "}
            <Text style={styles.title4}>Email address</Text>
            <Text style={styles.title6}>{props?.clinician?.email}</Text>
          </View>

          <View style={{ marginTop: 8 }}>
            {" "}
            <Text style={styles.title4}>Phone number</Text>
            <Text style={styles.title6}>
              +{props?.clinician?.gsmcountry} {props?.clinician?.gsm}
            </Text>
          </View>

          <View style={{ marginTop: 8 }}>
            {" "}
            <Text style={styles.title4}>Reg. number</Text>
            <Text style={styles.title6}>
              {props?.clinician?.registrationnumber ? props?.clinician?.registrationnumber : "-"}
            </Text>
          </View>

          {props.userStore?.readonly == 0 && (
            <Menu style={{ marginLeft: 34 }} renderer={Popover} rendererProps={{ placement: "bottom" }}>
              <MenuTrigger style={{}}>
                <Image source={require("../assets/images/morebutton.png")} style={{ width: 42, height: 42 }} />
              </MenuTrigger>
              <MenuOptions customStyles={{ borderRadius: 8, padding: 15 }}>
                {props.openClinicianModal && (
                  <MenuOption
                    text={"Edit Clinician"}
                    onSelect={() => {
                      props.openClinicianModal();
                    }}
                  />
                )}

                {props.clinician?.disabled != 1 && (
                  <MenuOption
                    text={"Disable Clinician"}
                    onSelect={() => {
                      if (confirm("Clinician won't be able to login. Are you sure?")) {
                        disableClinician(1);
                      }
                    }}
                  />
                )}

                {props.clinician?.disabled == 1 && (
                  <MenuOption
                    text={"Enable Clinician"}
                    onSelect={() => {
                      if (confirm("Clinician will be able to login. Are you sure?")) {
                        disableClinician(0);
                      }
                    }}
                  />
                )}

                {props.clinician?.inviteaccepted != 1 && (
                  <MenuOption
                    text={"Resend invitation"}
                    onSelect={() => {
                      sendInvitation();
                    }}
                  />
                )}
              </MenuOptions>
            </Menu>
          )}
        </View>
        {props.userStore?.readonly == 0 && (
          <BlueButton
            onPress={() => {
              props.openmodal();
            }}
            calendar
            title="Assign a new clinical team"
            style={{ width: 225, marginTop: 31 }}
            fontsize={15}
          />
        )}
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  title3: {
    fontFamily: "LatoBold",
    fontSize: 18,
    color: "#1F2E99",
  },
  title4: {
    fontFamily: "Lato",
    fontSize: 13,
    color: "#868686",
  },

  title5: {
    fontFamily: "Lato",
    fontSize: 13,
    color: "#1F2E99",
  },
  title6: {
    fontFamily: "Lato",
    fontSize: 15,
    color: "#1F2E99",
  },
});
