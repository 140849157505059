import * as React from "react";
import { FlatList, Image, StyleSheet, Text, TextInput, TouchableOpacity, View } from "react-native";

import { requests } from "../misc";
import { toastError } from "./Notifications";
import type { ViewStyle } from "@expo/html-elements/build/primitives/View";

const titleCase = (str: string) => {
  return str.replace(/\w\S*/g, (t) => {
    return t.charAt(0).toUpperCase() + t.slice(1).toLowerCase();
  });
};
const OperationAndDiagnosisInput: React.FC<{
  diagnosistypeSNOMED: { id: string; name: string };
  setDiagnosistypeSNOMED: (s: { id: string; name: string }) => void;
  operationtypeSNOMED: { id: string; name: string };
  setOperationtypeSNOMED: (s: { id: string; name: string }) => void;
  disableChange?: boolean;
  dropDownStyle?: ViewStyle;
}> = ({
  disableChange,
  setDiagnosistypeSNOMED,
  diagnosistypeSNOMED,
  setOperationtypeSNOMED,
  operationtypeSNOMED,
  dropDownStyle,
}) => {
  const [query, setQuery] = React.useState("");
  const [query2, setQuery2] = React.useState("");
  const [diagnosisResults, setDiagnosisResults] = React.useState<any[]>([]);
  const [operationResults, setOperationResults] = React.useState<any[]>([]);
  const [queryVisible, setQueryVisible] = React.useState(true);
  const [query2Visible, setQuery2Visible] = React.useState(true);

  async function fetchSnomed(type: "diagnosis" | "operation", text: string) {
    let setter: React.Dispatch<React.SetStateAction<any[]>>;
    let typeId: number;
    switch (type) {
      case "diagnosis":
        setter = setDiagnosisResults;
        typeId = 0;
        break;
      case "operation":
        setter = setOperationResults;
        typeId = 1;
        break;
    }

    setter([]);
    if (text.length < 3) return;
    try {
      // TODO: autosuggest based on numeric IDs is a horrible idea...
      const resData = await requests.get("/clinicians/autosuggest?type=" + typeId + "&kw=" + text);
      setter(resData.results);
    } catch (err: any) {
      console.error("Failed to retrieve SNOMED results:");
      console.error(err);
      toastError("Failed to retrieve SNOMED results. Please check your internet connection or try again later.");
    }
  }
  return (
    <>
      <Text style={[styles.title3, { marginTop: 32 }]}>Diagnosis</Text>
      <div style={{ position: "relative" }}>
        {!diagnosistypeSNOMED.id && (
          <View style={[styles.shadowinput, { width: 300, marginBottom: 6 }]}>
            <TextInput
              style={[styles.postopinput, { textAlign: "left", width: 250 }]}
              keyboardType="name-phone-pad"
              maxLength={50}
              numberOfLines={1}
              selectTextOnFocus={true}
              placeholder="Please type a diagnosis"
              onFocus={() => {
                setQueryVisible(true);
                setQuery2Visible(false);
              }}
              onChangeText={(text) => {
                setQuery(text);
                fetchSnomed("diagnosis", text);
              }}
              value={query}
              returnKeyType="done"
            />
          </View>
        )}

        {diagnosistypeSNOMED.id && (
          <View
            style={[{ marginTop: 8, height: 48, width: 810, marginBottom: 8, flexDirection: "row", maxWidth: "100%" }]}>
            <Text style={[styles.postopinput, { marginTop: 10, marginLeft: 10 }]}>
              {diagnosistypeSNOMED.id} {diagnosistypeSNOMED.name}
              {!disableChange && (
                <TouchableOpacity
                  style={{ marginLeft: 10, flexDirection: "row" }}
                  onPress={() => {
                    setDiagnosistypeSNOMED({ id: "", name: "" });
                    setQueryVisible(true);
                  }}>
                  (<Text style={{ textDecorationLine: "underline" }}>change</Text>)
                </TouchableOpacity>
              )}
            </Text>
          </View>
        )}

        {query.length > 2 && queryVisible && (
          <View
            style={[
              styles.shadowinput,
              {
                position: "absolute",
                width: 600,
                height: 250,
                padding: 20,
                top: 54,
                zIndex: 200 + queryVisible * 100,
              },
              dropDownStyle,
            ]}>
            <TouchableOpacity
              onPress={() => {
                setQueryVisible(false);
              }}
              style={{ position: "absolute", right: 13, top: 13 }}>
              <Image source={require("../assets/images/filtercloseicon.png")} style={styles.filtercloseicon} />
            </TouchableOpacity>
            <FlatList
              data={diagnosisResults}
              renderItem={({ item: { term, conceptId } }) => (
                <TouchableOpacity
                  onPress={() => {
                    setDiagnosistypeSNOMED({ id: conceptId, name: term });
                    setQueryVisible(false);
                  }}
                  style={{ zIndex: 200, width: 600, padding: 6 }}>
                  <Text style={styles.title3}>
                    {term} - {conceptId}{" "}
                  </Text>
                </TouchableOpacity>
              )}
              keyExtractor={(item) => item.conceptId}
            />
          </View>
        )}
      </div>

      <Text style={[styles.title3, { marginTop: 16 }]}>Operation type</Text>
      <div style={{ position: "relative" }}>
        {!operationtypeSNOMED.id && (
          <View style={[styles.shadowinput, { width: 300, marginBottom: 6 }]}>
            <TextInput
              placeholder="Please type an operation type"
              style={[styles.postopinput, { textAlign: "left", width: 250 }]}
              keyboardType="name-phone-pad"
              maxLength={50}
              numberOfLines={1}
              selectTextOnFocus={true}
              onFocus={() => {
                setQuery2Visible(true);
                setQueryVisible(false);
              }}
              onChangeText={(text) => {
                setQuery2(titleCase(text));
                fetchSnomed("operation", text);
              }}
              value={query2}
              returnKeyType="done"
            />
          </View>
        )}
        {operationtypeSNOMED.id && (
          <View
            style={[{ marginTop: 8, height: 48, width: 810, marginBottom: 8, flexDirection: "row", maxWidth: "100%" }]}>
            <Text style={[styles.postopinput, { marginTop: 10, marginLeft: 10 }]}>
              {operationtypeSNOMED.id} {operationtypeSNOMED.name}
              {!disableChange && (
                <TouchableOpacity
                  style={{ marginLeft: 10, flexDirection: "row" }}
                  onPress={() => {
                    setOperationtypeSNOMED({ id: "", name: "" });
                    setQuery2Visible(true);
                  }}>
                  (<Text style={{ textDecorationLine: "underline" }}>change</Text>)
                </TouchableOpacity>
              )}
            </Text>
          </View>
        )}
        {query2.length > 2 && query2Visible && (
          <View
            style={[
              styles.shadowinput,
              {
                position: "absolute",
                width: 600,
                height: 250,
                padding: 20,
                top: 54,
                zIndex: 200 + query2Visible * 100,
              },
              dropDownStyle,
            ]}>
            <TouchableOpacity
              onPress={() => {
                setQuery2Visible(false);
              }}
              style={{ position: "absolute", right: 13, top: 13 }}>
              <Image source={require("../assets/images/filtercloseicon.png")} style={styles.filtercloseicon} />
            </TouchableOpacity>
            <FlatList
              data={operationResults}
              renderItem={({ item: { term, conceptId } }) => (
                <TouchableOpacity
                  onPress={() => {
                    setOperationtypeSNOMED({ id: conceptId, name: term });
                    setQuery2Visible(false);
                  }}
                  style={[{ zIndex: 200, width: 600, padding: 6 }]}>
                  <Text style={[styles.title3]}>
                    {term} - {conceptId}{" "}
                  </Text>
                </TouchableOpacity>
              )}
              keyExtractor={(item: any) => item.conceptId}
            />
          </View>
        )}
      </div>
    </>
  );
};
const styles = StyleSheet.create({
  title3: {
    fontSize: 13,
    fontFamily: "Lato",
    color: "#798599",
  },
  shadowinput: {
    marginTop: 8,
    height: 48,
    flexDirection: "row",
    borderRadius: 15,
    shadowColor: "#818181",
    backgroundColor: "#FFFFFF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
  postopinput: {
    flex: 1,
    outlineWidth: 0,
    color: "#1F2E99",
    fontFamily: "Lato",
    fontSize: 15,
    marginRight: 10,
    marginLeft: 20,
  },
  filtercloseicon: {
    width: 8,
    height: 8,
  },
});

export default OperationAndDiagnosisInput;
