import type { ReactNode } from "react";
import { CloseModal } from "./Close";
import { css, cx } from "@emotion/css";

export interface MediaHeadBarProps {
  children?: ReactNode;
  className?: string;
  onClose?: () => void;
}

export default function MediaHeadBar({ children, className, onClose }: Readonly<MediaHeadBarProps>) {
  return (
    <div className={cx("POMediaHeadBar-root", styles.root, className)}>
      <CloseModal onClick={onClose} />
      {children}
    </div>
  );
}

const styles = Object.freeze({
  root: css`
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    align-items: center;
    height: 70px;
    gap: 25px;
    border-bottom: 1px solid #e0e3f7;
    background: #ffffff;

    .POCloseModal-root {
      border-width: 0;
      border-right-width: 1px;
      padding: 0 20px;
      align-self: stretch;
    }
  `,
});
