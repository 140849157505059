import * as ImagePicker from "expo-image-picker";
import * as React from "react";
import { Image, StyleSheet, Text, TextInput, TouchableOpacity, View } from "react-native";

import { css, cx } from "@emotion/css";

import CountrySelector from "../components/CountrySelector";
import { theme } from "../misc/constants";
import validate from "../screens/validate_wrapper";
import { Button } from "./Button";
import { Hairline } from "./Hairline";
import { Modal } from "./Modals";
import { OrangeBorderButton } from "./OrangeBorderButton";

export interface CreateHospitalModalProps {
  show: boolean;
  className?: string;
  onDismiss: () => void;
}

export default function CreateHospitalModal({ show, onDismiss, className }: Readonly<CreateHospitalModalProps>) {
  const [name, setName] = React.useState("");
  const [gsm, setGsm] = React.useState("");
  const [gsmcountrycode, setGsmcountrycode] = React.useState("44");
  const [countrycode, setCountrycode] = React.useState("GB");

  const [email, setEmail] = React.useState<string | null>("");
  const [city, setCity] = React.useState("");
  const [zipcode, setZipcode] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [errorText, setErrorText] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const [photourl, setPhotourl] = React.useState("");
  const [image, setImage] = React.useState<string | undefined>();

  const clearState = React.useCallback(() => {
    setName("");
    setGsm("");
    setGsmcountrycode("44");
    setCountrycode("GB");
    setEmail("");
    setCity("");
    setZipcode("");
    setAddress("");
    setErrorText("");
    setIsLoading(false);
    setPhotourl("");
    setImage(undefined);
  }, []);

  const pickImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      base64: false,
      aspect: [4, 4],
      quality: 0.85,
    });

    console.log(result);

    if (!result.cancelled) {
      console.log(result);
      setImage(result?.assets && result.assets[0]?.uri);
    }
  };

  function base64ToBlob(base64: string, mime: string) {
    mime = mime || "";
    const sliceSize = 1024;
    const byteChars = window.atob(base64);
    const byteArrays = [];

    for (let offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
      const slice = byteChars.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: mime });
  }

  function validateForm() {
    setIsLoading(true);

    setErrorText("");
    const nameError = validate("genericname", name);
    const gsmError = validate("gsm2", gsm);
    const emailError = validate("email", email);

    if (!nameError && !gsmError && !emailError) {
      sendform();
    } else {
      setIsLoading(false);

      setErrorText(
        "\n" +
          (nameError ? "\n" + nameError + "\n" : "") +
          (gsmError ? "\n" + gsmError + "\n" : "") +
          (emailError ? "\n" + emailError + "\n" : "") +
          "\n",
      );
    }
  }

  function sendform() {
    console.log("updating profile");

    let fileType;

    const formData = new FormData();
    formData.append("name", name);
    formData.append("countrycode", countrycode);
    formData.append("gsm", gsm);
    formData.append("gsmcountrycode", gsmcountrycode);
    formData.append("email", email);
    formData.append("address", address);
    formData.append("zipcode", zipcode);
    formData.append("city", city);

    if (image) {
      const base64ImageContent = image.replace(/^data:image\/(png|jpg|jpeg);base64,/, "");

      const blob = base64ToBlob(base64ImageContent, "image/jpg");

      const lowerCase = base64ImageContent.toLowerCase();
      if (lowerCase.substring(0, 20).includes("png")) fileType = "png";
      else if (lowerCase.includes("jpg") || lowerCase.includes("jpeg")) {
        fileType = "jpg";
      }

      formData.append("image", blob, "test." + fileType);
    }
    console.log(formData);
    fetch(global.apiurl + "/postopadmins/createhospital", {
      method: "POST",
      headers: {
        Authorization: global.tokenparam || "",
      },
      body: formData,
    })
      .then(async (res) => {
        setIsLoading(false);

        if (res.status === 422) {
          throw new Error("Request failed.");
        }
        if (res.status !== 200 && res.status !== 201) {
          console.log("Error!");
          throw new Error("Could not authenticate you!");
        }
        return await res.json();
      })
      .then((resData) => {
        console.log(resData);
        if (resData.result) {
          console.log("DONE");
          clearState();
          onDismiss();
          window.location.reload();
        } else {
          alert("Unable update, please check the form");
        }
      })
      .catch((err) => {
        alert("Unable update, please check the form");
        console.log(err);
      });
  }

  return (
    <Modal show={show} onDismiss={onDismiss} className={cx("POAModal-CreateHospital-root", styles.root, className)}>
      <div className="POAModal-CreateHospital-container">
        <div className="POAModal-CreateHospital-left">
          <View
            style={{
              borderRadius: 8,
              backgroundColor: "#ffffff",
              width: 268,
              height: 298,
              marginRight: 10,
              alignSelf: "flex-start",
            }}>
            <View style={{ alignItems: "center" }}>
              <View style={{ flexDirection: "row", marginTop: 32 }}>
                <Text style={legacyStyles.title3}>Profile logo</Text>
                <Image
                  source={require("../assets/images/infobutton.png")}
                  style={{ width: 16, height: 16, marginLeft: 7 }}
                />
              </View>

              <View style={legacyStyles.bgimage}>
                {!(photourl || image) && (
                  <Text
                    style={{
                      fontFamily: "LatoBold",
                      fontSize: 36,
                      color: "#FFFFFF",
                    }}>
                    {(" " + name).charAt(1).toUpperCase()}
                    {"H"}
                  </Text>
                )}

                {photourl && !image && (
                  <Image
                    source={{ uri: global.imagepathurl + photourl }}
                    style={{ width: 132, height: 132, borderRadius: 66 }}
                  />
                )}
                {image && <Image source={{ uri: image }} style={{ width: 132, height: 132, borderRadius: 66 }} />}
              </View>
              {!(photourl || image) && (
                <OrangeBorderButton
                  onPress={async () => {
                    await pickImage();
                  }}
                  title="Upload a picture"
                  width={144}
                  style={{ marginTop: 26 }}
                />
              )}
              {(photourl || image) && (
                <OrangeBorderButton
                  onPress={async () => {
                    await pickImage();
                  }}
                  title="Change picture"
                  width={144}
                  style={{ marginTop: 26 }}
                />
              )}
            </View>
          </View>

          <Text
            style={{
              color: "#FF0000",
              flex: 1,
              textAlign: "left",
              fontFamily: "LatoBold",
              fontSize: 14,
              marginTop: 4,
            }}>
            {errorText}
          </Text>
        </div>

        <div className="POAModal-CreateHospital-right">
          <View
            style={{
              flexDirection: "row",
              width: "100%",
              borderBottomColor: "#E8E9ED",
              borderBottomWidth: 1,
              height: 70,
            }}>
            <Text style={legacyStyles.title1}>Create new hospital</Text>
            <TouchableOpacity
              style={{ position: "absolute", right: 0 }}
              onPress={() => {
                onDismiss();
              }}>
              <View
                style={{
                  backgroundColor: "#FCF5EF",
                  width: 93,
                  height: 70,
                  flexDirection: "row",
                  borderColor: "#E8E9ED",
                  borderWidth: 1,
                  borderTopRightRadius: 8,
                  alignItems: "center",
                }}>
                <Image
                  source={require("../assets/images/closecrossicon.png")}
                  style={{
                    width: 10,
                    height: 10,
                    marginRight: 8,
                    marginLeft: 23,
                  }}
                />
                <Text
                  style={{
                    fontFamily: "Lato",
                    fontSize: 13,
                    color: "#1F2E99",
                  }}>
                  Close
                </Text>
              </View>
            </TouchableOpacity>
          </View>

          <View style={{ flexDirection: "column" }}>
            <View style={{ width: 863, paddingLeft: 24 }}>
              <Text style={legacyStyles.title2}>Organisation details</Text>

              <Text style={legacyStyles.title3}>Hospital name</Text>
              <View style={[legacyStyles.shadowinput, { width: 250 }]}>
                <TextInput
                  style={[legacyStyles.postopinput, { textAlign: "left", width: 250 }]}
                  keyboardType="name-phone-pad"
                  maxLength={50}
                  numberOfLines={1}
                  onChangeText={(text) => {
                    setName(text);
                  }}
                  placeholder={""}
                  placeholderTextColor={"#A8B1C1"}
                  value={name}
                  returnKeyType="done"
                />
              </View>

              <View style={{ flexDirection: "row" }}>
                <View style={{ width: 250 }}>
                  <Text style={[legacyStyles.title3, { marginTop: 32 }]}>Phone number</Text>
                  <View style={[legacyStyles.shadowinput]}>
                    <CountrySelector
                      countrycode={"GB"}
                      setGsmcountrycode={(value) => {
                        setGsmcountrycode(value);
                      }}
                      setCountrycode={(value) => {
                        setCountrycode(value);
                      }}
                    />

                    <TextInput
                      placeholder={""}
                      placeholderTextColor={"#A8B1C1"}
                      keyboardType={"number-pad"}
                      onChangeText={(text) => {
                        setGsm(text);
                      }}
                      value={gsm}
                      maxLength={15}
                      style={{
                        color: "#1F2E99",
                        fontFamily: "Lato",
                        fontSize: 15,
                        marginRight: 10,
                        marginTop: 1,
                      }}
                    />
                  </View>
                </View>
                <View style={{ width: 250, marginLeft: 24 }}>
                  <Text style={[legacyStyles.title3, { marginTop: 32 }]}>Email address</Text>

                  <View style={[legacyStyles.shadowinput, { width: 250 }]}>
                    <TextInput
                      placeholder={""}
                      placeholderTextColor={"#A8B1C1"}
                      style={[legacyStyles.postopinput, { textAlign: "left", width: 250 }]}
                      keyboardType="email-address"
                      maxLength={50}
                      numberOfLines={1}
                      onChangeText={(text) => {
                        setEmail(text || null);
                      }}
                      value={email}
                      returnKeyType="done"
                    />
                  </View>
                </View>
              </View>
            </View>
            <Hairline marginTop={32} />

            <View style={{ width: 863, paddingLeft: 24 }}>
              <Text style={legacyStyles.title2}>Location details</Text>

              <Text style={legacyStyles.title3}>Street name</Text>
              <View style={[legacyStyles.shadowinput, { width: 250 }]}>
                <TextInput
                  style={[legacyStyles.postopinput, { textAlign: "left", width: 250 }]}
                  keyboardType="name-phone-pad"
                  maxLength={50}
                  numberOfLines={1}
                  onChangeText={(text) => {
                    setAddress(text);
                  }}
                  placeholder={""}
                  placeholderTextColor={"#A8B1C1"}
                  value={address}
                  returnKeyType="done"
                />
              </View>

              <View style={{ flexDirection: "row", marginTop: 32 }}>
                <View>
                  <Text style={legacyStyles.title3}>City</Text>
                  <View style={[legacyStyles.shadowinput, { width: 250 }]}>
                    <TextInput
                      style={[legacyStyles.postopinput, { textAlign: "left", width: 250 }]}
                      keyboardType="name-phone-pad"
                      maxLength={50}
                      numberOfLines={1}
                      onChangeText={(text) => {
                        setCity(text);
                      }}
                      placeholder={""}
                      placeholderTextColor={"#A8B1C1"}
                      value={city}
                      returnKeyType="done"
                    />
                  </View>
                </View>
                <View style={{ marginLeft: 24 }}>
                  <Text style={legacyStyles.title3}>Zip code</Text>
                  <View style={[legacyStyles.shadowinput, { width: 250 }]}>
                    <TextInput
                      style={[legacyStyles.postopinput, { textAlign: "left", width: 250 }]}
                      keyboardType="name-phone-pad"
                      maxLength={50}
                      numberOfLines={1}
                      onChangeText={(text) => {
                        setZipcode(text);
                      }}
                      placeholder={""}
                      placeholderTextColor={"#A8B1C1"}
                      value={zipcode}
                      returnKeyType="done"
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>

          <div
            className={css`
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 16px 0;
              margin-top: 16px;
              border-top: 1px solid ${theme.line};
            `}>
            <Button
              onClick={() => {
                validateForm();
              }}
              style={{ width: 250, height: 40 }}>
              Create hospital
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const styles = Object.freeze({
  root: css`
    .POAModal-CreateHospital-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 10px;

      > * {
        background: white;
        border-radius: 8px;
      }
    }

    .POAModal-CreateHospital-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 286px;
    }

    .POAModal-CreateHospital-right {
      width: 650px;
    }
  `,
});

const legacyStyles = StyleSheet.create({
  select: {
    marginLeft: 20,
    //  "text-align":"center","text-align-last":"center",
    "-moz-appearance": "none",
    "-webkit-appearance": "none",
    appearance: "none",
    backgroundImage:
      "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAVCAYAAAAuJkyQAAAAmUlEQVR42r3OwQmAQAxE0S3JTrUDvairl6ANBG1MySFeFrz8kIGBZRnIK55ZtLOWnPzf3c67r8f1WO2dhannPTZ3TeefXhtmYrxm+UAARTAtyLLKNTEUxyyig28gimNW0akZcxTHAFQCBqAwJgEFMACFMRkojuEojolHcUw8CmLiUQCTgAIYhAKYJBTH8FTRAWLis4t21hKQF5HBtPSQyO2PAAAAAElFTkSuQmCC)",
    backgroundPosition: "87%",
    backgroundSize: "12px 7px",
    "background-repeat": "no-repeat",
    borderWidth: 0,
    width: 163,
    height: 24,
    borderRadius: 20,
    fontFamily: "Lato",
    fontSize: 15,
    color: "#1F2E99",
    textAlign: "center",
  },

  menuitemwait: {
    fontFamily: "Lato",
    fontSize: 15,
    color: "#959DAD",
    marginTop: 27,
    height: 18,
  },
  menuitemdone: {
    fontFamily: "Lato",
    fontSize: 15,
    color: "#1F2E99",
    marginTop: 27,
    height: 18,
  },
  menuitemactive: {
    fontFamily: "LatoBold",
    fontSize: 15,
    color: "#1F2E99",
    marginTop: 27,
    height: 18,
  },

  postopinput: {
    flex: 1,
    outlineWidth: 0,
    color: "#1F2E99",
    fontFamily: "Lato",
    fontSize: 15,
    marginRight: 10,
    marginLeft: 20,
  },
  countryflag: {
    width: 26,
    height: 18,
    marginHorizontal: 16,
    marginVertical: 19,
  },
  shadowinput: {
    marginTop: 8,
    height: 48,
    flexDirection: "row",
    borderRadius: 15,
    shadowColor: "#818181",
    backgroundColor: "#FFFFFF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
  title1: {
    fontSize: 18,
    fontFamily: "LatoBold",
    color: "#1F2E99",
    marginTop: 24,
    marginLeft: 24,
  },

  title2: {
    fontSize: 15,
    fontFamily: "LatoBold",
    color: "#1F2E99",
    marginTop: 24,
    marginBottom: 24,
  },

  title3: {
    fontSize: 13,
    fontFamily: "Lato",
    color: "#798599",
  },

  bgimage: {
    backgroundColor: "#36C2D5",
    borderColor: "#F2F3F7",
    borderWidth: 1,
    borderRadius: 66,
    width: 132,
    height: 132,
    aspectRatio: 1,
    marginTop: 18,
    alignItems: "center",
    justifyContent: "center",
  },
});
