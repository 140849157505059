import {
  Animated,
  Dimensions,
  TouchableHighlight,
  TouchableOpacity,
  View,
  Text,
  StyleSheet,
  TextInput,
  Image,
  FlatList,
} from "react-native";
import React, { useCallback, useState, useEffect } from "react";

import { Hairline } from "../../components/Hairline";
import { PatientFilter } from "../../components/PatientFilter";
import { Avatar } from "../../components/Avatar";
import Moment from "moment";

import { StackActions } from "@react-navigation/native";
import { A } from "@expo/html-elements";
import Modal from "modal-react-native-web";
import { MainFrameClinician } from "../../components/MainFrameClinician";
import { inject, observer } from "mobx-react";

const TempScreen = inject("userStore")(
  observer(({ userStore, navigation, route }) => {
    const { clinicianid } = route.params;

    const [searchtext, setSearchtext] = React.useState("");
    const [filterHip, setFilterHip] = React.useState(false);
    const [filterKnee, setFilterKnee] = React.useState(false);

    const [invitemodalvisible, setInvitemodalvisible] = React.useState(false);
    const dimensions = Dimensions.get("window");
    const [data, setData] = React.useState(null);
    const [clinician, setClinician] = React.useState(null);
    const [tempData, setTempData] = React.useState(null);

    const opacity = new Animated.Value(1);

    const theme = {
      datapicker: {
        backgroundColor: "#ff0000",
      },

      Button: {
        small: true,
        auto: true,
        flat: false,
        type: "navy",
        radius: true,
      },
      MainContainer: {
        style: StyleSheet.create({
          mainContainer: {
            justifyContent: "space-between",
          },
        }).mainContainer,
      },
      Link: {
        type: "navy",
      },
      Text: {
        // Instead of being overwritten by the prop style defined on the
        // text itself, the style property is the only property that is
        // prepended to the component prop.
        //
        // Example:
        // <Text style={{ fontSize: 20 }}>
        //   Something!
        // </Text>
        //
        // In this example, Text will have the following style:
        // [
        //   {
        //     lineHeight: 28
        //   },
        //   {
        //     fontSize: 20,
        //   }
        // ]
        type: "gray",
        style: StyleSheet.create({
          text: {
            lineHeight: 28,
          },
        }).text,
      },
      Title: {
        style: StyleSheet.create({
          title: {
            fontSize: 30,
            paddingTop: 20,
            paddingBottom: 20,
          },
        }).title,
      },
      colors: {
        text: "gray",
        primary: "navy",
        gray: StyleSheet.create({
          background: { backgroundColor: "#1B2733" },
          border: { borderColor: "#1B2733" },
          text: { color: "#1B2733" },
        }),
        lightGray: StyleSheet.create({
          background: { backgroundColor: "#637282" },
          border: { borderColor: "#637282" },
          text: { color: "#637282" },
        }),
        navy: StyleSheet.create({
          background: { backgroundColor: "#0404f5" },
          border: { borderColor: "#0404f5" },
          text: { color: "#0404f5" },
        }),
        navySidemenu: StyleSheet.create({
          background: { backgroundColor: "#0404f5" },
          border: { borderColor: "#0404f5" },
          text: { color: "#0000a0" },
        }),
      },
    };

    const history = {
      location: {
        pathname: () => "pp",
      },
      push: (routeName) => navigation.navigate(routeName),
      replace: (routeName) => navigation.dispatch(StackActions.replace(routeName)),
    };

    function calculateRecoveryTime(operationdate) {
      if (!operationdate) return "-";
      // 12 days / week 2
      const given = Moment(operationdate.replace("'", ""));
      const current = Moment().startOf("day");
      const totalday = Math.floor(Moment.duration(current.diff(given)).asDays());

      if (totalday > 0) {
        return totalday + " days / week " + (1 + Math.floor((totalday - 1) / 7));
      }

      return "-";
    }

    function fadeIn() {
      setInvitemodalvisible(true);
    }

    function fadeOut() {
      Animated.timing(opacity, {
        toValue: 0,
        duration: 175,
      }).start(() => {
        setInvitemodalvisible(false);
      });
      fetchData();
    }

    function updateSearch(text) {
      setSearchtext(text);
      if (text == "" && !filterKnee && !filterHip) {
        console.log("updatesearch:null");
        setData(tempData);
        return;
      }
      setData(
        tempData.filter(function (item) {
          if (!(filterKnee && filterHip)) {
            if (filterKnee && item.operationtype != 1) return false;
            if (filterHip && item.operationtype != 2) return false;
          }

          return (item.firstname + " " + item.lastname).toLowerCase().includes(text.toLowerCase());
        }),
      );
    }

    const memoizedCallback = (knee, hip) => {
      console.log("searchtext:" + searchtext);
      console.log("filterHip:" + filterHip);
      console.log("filterKnee:" + filterKnee);

      if (searchtext == "" && !hip && !knee) {
        setData(tempData);
        return;
      }

      setData(
        tempData.filter(function (item) {
          if (!(knee && hip)) {
            if (knee && item.operationtype != 1) return false;
            if (hip && item.operationtype != 2) return false;
          }

          //    return (item.firstname+" "+item.lastname+" "+item.clinicalteam.name).toLowerCase().includes(searchtext.toLowerCase());
          return (item.firstname + " " + item.lastname).toLowerCase().includes(searchtext.toLowerCase());
        }),
      );

      userStore.updatescreen();
    };

    async function fetchData() {
      fetch(global.apiurl + "/hospitaladmins/getclinicianpatients", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: global.tokenparam || "",
        },

        body: JSON.stringify({
          clinicianid,
        }),
      })
        .then(async (response) => await response.json())
        .then((resData) => {
          console.log(resData);
          if (resData.result) {
            setData(resData.data);
            setTempData(resData.data);
            setClinician(resData.clinician);
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    useEffect(() => {
      fetchData();
    }, []);

    /**
   *
  [{ title: 'Title Text', key: 'item1' },{ title: 'Title Text', key: 'item2' },{ title: 'Title Text', key: 'item13' }]
   */
    return (
      <View style={styles.container}>
        {data && !clinician && "Clinician not found"}
        {clinician && (
          <MainFrameClinician clinician={clinician} admin patientlist userStore={userStore} selected={1} style={{}}>
            <View style={[styles.whitebox, { alignItems: "flex-start", width: 1061, marginBottom: 94 }]}>
              <View style={{ flexDirection: "row", alignItems: "center", marginLeft: 25, marginTop: 24 }}>
                <Text style={[styles.title1]}>List of patients </Text>
                <Text style={[styles.title1, { position: "absolute", left: 120 }]}>({data?.length})</Text>

                <View style={[styles.shadowinput, { width: 267, marginLeft: 70 }]}>
                  <TextInput
                    style={[styles.postopinput, { textAlign: "left", width: 220 }]}
                    keyboardType="email-address"
                    maxLength={50}
                    numberOfLines={1}
                    onChangeText={(text) => {
                      updateSearch(text);
                    }}
                    placeholder={"Search"}
                    placeholderTextColor={"#1F2E99"}
                    value={searchtext}
                  />
                  <TouchableOpacity style={{ position: "absolute", right: 10, top: 13 }}>
                    <Image source={require("../../assets/images/searchicon.png")} style={styles.searchicon} />
                  </TouchableOpacity>
                </View>
              </View>
              <Hairline marginTop={13} />
              <View style={{ flex: 1, width: "100%", paddingHorizontal: 24 }}>
                <View style={{ flexDirection: "row", width: "100%", justifyContent: "space-between", marginTop: 24.5 }}>
                  <TouchableOpacity style={{ flex: 0.3 }}>
                    <Text style={[styles.title3, { marginLeft: 24 }]}>Name</Text>
                  </TouchableOpacity>
                  <TouchableOpacity style={{ flex: 0.177 }}>
                    <Text style={[styles.title3]}>Clinical team</Text>
                  </TouchableOpacity>
                  <TouchableOpacity style={{ flex: 0.147 }}>
                    {" "}
                    <Text style={[styles.title3]}>Hospital number</Text>
                  </TouchableOpacity>
                  <TouchableOpacity style={{ flex: 0.277 }}>
                    <Text style={[styles.title3]}>Email address</Text>
                  </TouchableOpacity>
                  <TouchableOpacity style={{ flex: 0.177 }}>
                    <Text style={[styles.title3]}>Phone number</Text>
                  </TouchableOpacity>
                  <TouchableOpacity style={{ flex: 0.03 }}> </TouchableOpacity>
                </View>
                <Hairline marginTop={15.5} />
                <FlatList
                  scrollEnabled={false}
                  xextraData={data}
                  style={{ width: "100%" }}
                  data={data}
                  keyExtractor={(item) => item.id}
                  renderItem={({ item, index, separators }) => (
                    <View
                      style={{
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                        backgroundColor: "#ffffff",
                        height: 78,
                        alignItems: "center",
                        borderRadius: 6,
                        borderBottomColor: "#E8E9ED",
                        borderBottomWidth: StyleSheet.hairlineWidth,
                      }}>
                      <View style={{ flex: 0.1 }}>
                        <Avatar
                          url={item?.thumburl && global.imagepathurl + encodeURI(item?.thumburl)}
                          size={46}
                          radius={23}
                          firstname={item.firstname}
                          lastname={item.lastname}
                          style={{ marginLeft: 24, borderWidth: 1, marginRight: 18 }}
                        />
                      </View>
                      <View style={{ flex: 0.2 }}>
                        <Text style={[styles.title4]}>
                          {item.firstname} {item.lastname}
                        </Text>
                      </View>
                      <Text style={[styles.title4, { flex: 0.177 }]}>{item?.clinicalteam?.name}</Text>
                      <Text style={[styles.title4, { flex: 0.147 }]}>-</Text>
                      <Text style={[styles.title4, { flex: 0.277 }]}>{item?.email}</Text>
                      <Text style={[styles.title4, { flex: 0.177 }]}>
                        +{item?.gsmcountrycode} {item?.gsm}
                      </Text>
                      <Text style={[styles.title4, styles.titlecenter, { flex: 0.03 }]}></Text>
                    </View>
                  )}
                />
              </View>
            </View>
          </MainFrameClinician>
        )}
      </View>
    );
  }),
);

export default TempScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
  },
  title1: {
    fontSize: 18,
    fontFamily: "Lato",
    color: "#1F2E99",
  },
  title2: {
    width: "84%",
    fontSize: 20,
    fontFamily: "Lato",
    color: "#1F2E99",
    position: "absolute",
    top: "55%",
  },
  title3: {
    fontSize: 13,
    fontFamily: "Lato",
    color: "#1F2E99",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },

  searchicon: {
    marginTop: 2,
    width: 12.33,
    height: 13.33,
  },
  postopinput: {
    flex: 1,
    outlineWidth: 0,
    color: "#1F2E99",
    fontFamily: "Lato",
    fontSize: 15,
    marginRight: 10,
    marginLeft: 20,
  },
  shadowinput: {
    height: 44,
    flexDirection: "row",
    borderRadius: 15,
    shadowColor: "#818181",
    backgroundColor: "#FFFFFF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
  whitebox: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    shadowColor: "#818181",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
});
