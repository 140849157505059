import { css, cx } from "@emotion/css";
import { useWoundVizContext } from "./WoundVizContext";
import useTransformAPI from "./useTransformAPI";
import { POTextNu } from "../POText";
import { theme } from "../../misc/constants";
import DragArrows from "../icons/DragArrows";
import ZoomIcon from "../icons/ZoomIcon";
import type { CSSProperties, MouseEvent } from "react";
import { useCallback, useRef } from "react";

export function OverlayTools() {
  const [state, update] = useWoundVizContext();
  const dragger = useTransformAPI();

  return (
    <div className={cx("POWoundVizPro-overlayTools", styles.overlayTools)}>
      <div className="POWoundVizPro-overlayTools-topRight">
        <OverlayButton
          className="POWoundVizPro-overlayButton"
          onClick={() => {
            dragger.fullImage();
          }}>
          <POTextNu fontFamily="Lato" fontWeight={500} fontSize={15} lineHeight={1.2} color={theme.primary}>
            View full image
          </POTextNu>
        </OverlayButton>

        <OverlayButton
          className="POWoundVizPro-overlayButton"
          onClick={(e) => {
            update({ isPanMode: !state.isPanMode });
            dragger.stopPan(e.clientX, e.clientY);
          }}>
          <DragArrows color={state.isPanMode ? theme.primaryLight : theme.primary} size={24} />
        </OverlayButton>

        <div className="POWoundVizPro-overlayButton POWoundVizPro-zoomTools">
          <OverlayButton
            className="POWoundVizPro-zoomTool"
            onClick={() => {
              dragger.zoom(-1);
            }}>
            <ZoomIcon variant="out" />
          </OverlayButton>
          <OverlayButton
            className="POWoundVizPro-zoomTool"
            onClick={() => {
              dragger.zoom(1);
            }}>
            <ZoomIcon variant="in" />
          </OverlayButton>
        </div>
      </div>
    </div>
  );
}

function OverlayButton({
  ...props
}: {
  children: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  onClick: (e: MouseEvent) => void;
}) {
  const isDown = useRef(false);

  const handleMouseDown = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    isDown.current = true;
  }, []);
  const handleMouseUp = useCallback((e: MouseEvent) => {
    if (isDown.current) e.stopPropagation();
    isDown.current = false;
  }, []);

  return <button {...props} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} />;
}

const styles = Object.freeze({
  overlayTools: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    button {
      cursor: pointer;
    }

    .POWoundVizPro-overlayTools-topRight {
      position: absolute;
      display: flex;
      top: 40px;
      right: 30px;
      height: 45px;
      gap: 20px;
      align-items: stretch;
    }

    .POWoundVizPro-overlayButton {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 14px;
      background: rgba(255, 255, 255, 0.75);
      border: 0;
      border-radius: 9px;
      pointer-events: all;
    }

    .POWoundVizPro-zoomTools {
      padding: 8px;

      .POWoundVizPro-zoomTool {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px 12px;
        background: transparent;
        border: 0;
        border-left: 1px solid #bdbdbd;

        &:first-child {
          border-left: 0;
        }
      }
    }
  `,
});
