import type { LinkingOptions } from "@react-navigation/native";
import type { WelcomeFlowParams, ClinicianParams, HospitalAdminParams, PostopAdminParams } from "./params";

export const WelcomeLinking: LinkingOptions<WelcomeFlowParams> = {
  prefixes: ["/"],
  config: {
    screens: {
      ClinicianRegisterCode: "register",
      ClinicianRegisterSetPasswordScreen: "set-password",
      ClinicianLogin: "login",
      ClinicianResetPasswordScreen: "resetpassword",
      HospitalAdminRegisterCode: "hospitaladmin/register",
      HospitalAdminLogin: "hospitaladmin/login",
      HospitalAdminResetPasswordScreen: "hospitaladmin/resetpassword",
      PostopAdminRegisterUpdateDetailsScreen: "postopadmin/registerprofile",
      PostopAdminRegisterCode: "postopadmin/register",
      PostopAdminLogin: "postopadmin/login",
      PostopAdminResetPassword: "postopadmin/resetpassword",
    },
  },
};

export const LoggedInLinking: LinkingOptions<ClinicianParams | HospitalAdminParams | PostopAdminParams> = {
  prefixes: ["/"],
  config: {
    screens: {
      // TODO: confirm and remove
      PatientOverviewScreen: "patient",
      PatientListScreen: "patients",
      DashboardScreen: "dashboard",
      PatientMediaScreen: "patientmedia",
      PatientPROMSScreen: "patientproms",
      PatientPREMSScreen: "patientprems",
      PatientGeneralquestionsScreen: "patientgeneralquestions",
      PatientCarePlanScreen: "patientcareplan",
      PatientMessagesScreen: "patientmessages",
      PatientDiaryScreen: "patientdiary",
      PatientMediaDetailsScreen: "patient/:patientId/media/:mediaId",
      ClinicianProfileScreen: "profile",
      // TODO: confirm and remove
      TeamScreen: "team",
      ClinicianListScreen: "hospitaladmin/clinicians",
      ClinicalTeamListScreen: "hospitaladmin/clinicalteams",
      AdminPatientListScreen: "hospitaladmin/adminpatients",
      AdminDashboardScreen: "hospitaladmin/admindashboard",
      // TODO: confirm and remove
      ClinicianPatients: "hospitaladmin/clinician",
      // TODO: confirm and remove
      ClinicianTeams: "hospitaladmin/clinicianteams",
      // TODO: confirm and remove
      ClinicalTeamPatients: "hospitaladmin/clinicalteam",
      // TODO: confirm and remove
      ClinicalTeamClinicians: "hospitaladmin/clinicalteamclinicians",
      ClinicianRegisterUpdateDetailsScreen: "registerprofile",
      ClinicianRegisterCode: "register",
      ClinicianLogin: "login",
      ClinicianResetPasswordScreen: "resetpassword",
      ClinicianUpdateProfileScreen: "updateprofile",
      ClinicalTeam: "clinicalteam",
      HospitalAdminProfileScreen: "hospitaladmin/profile",
      HospitalAdminRegisterUpdateDetailsScreen: "hospitaladmin/registerprofile",
      HospitalAdminRegisterCode: "hospitaladmin/register",
      HospitalAdminLogin: "hospitaladmin/login",
      HospitalAdminResetPasswordScreen: "hospitaladmin/resetpassword",
      HospitalAdminUpdateProfileScreen: "hospitaladmin/updateprofile",
      PostopAdminProfileScreen: "postopadmin/profile",
      PostopAdminRegisterUpdateDetailsScreen: "postopadmin/registerprofile",
      PostopAdminRegisterCode: "postopadmin/register",
      PostopAdminLogin: "postopadmin/login",
      PostopAdminResetPassword: "postopadmin/resetpassword",
      PostopAdminUpdateProfileScreen: "postopadmin/updateprofile",
      // TODO: confirm and remove
      HospitalAdminDetailsScreen: "postopadmin/hospitaladmindetails",
      PostopAdminPatientList: "postopadmin/patientsearch",
      PostopAdminClinicianList: "postopadmin/cliniciansearch",
      PostopAdminTags: "postopadmin/wound-tags",
      PostopAdminMedia: "postopadmin/media",
      WoundAnnotator: "wound-annotator/:mediaId",
      HospitalListScreen: "postopadmin/hospitals",
      HospitalAdminsScreen: "postopadmin/hospital-admins",
      HospitalDetailsScreen: "postopadmin/hospitals/:hospitalId",
      HospitalClinicianList: "postopadmin/hospitals/:hospitalId/clinicians",
    },
  },
};
