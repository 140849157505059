import React, { useEffect, useRef } from "react";
import { Dimensions } from "react-native";

import { DefaultTheme, NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";

import { withStore } from "../misc/utils";
import ClinicalTeam from "../screens/ClinicalTeamScreen";
import DashboardScreen from "../screens/DashboardScreen";
import AdminDashboardScreen from "../screens/hospitaladmin/AdminDashboardScreen";
import AdminPatientListScreen from "../screens/hospitaladmin/AdminPatientListScreen";
import ClinicalTeamClinicians from "../screens/hospitaladmin/ClinicalTeamClinicians";
import ClinicalTeamListScreen from "../screens/hospitaladmin/ClinicalTeamListScreen";
import ClinicalTeamPatients from "../screens/hospitaladmin/ClinicalTeamPatients";
import ClinicianListScreen from "../screens/hospitaladmin/ClinicianListScreen";
import ClinicianPatients from "../screens/hospitaladmin/ClinicianPatients";
import ClinicianTeams from "../screens/hospitaladmin/ClinicianTeams";
import PatientCarePlanScreen from "../screens/PatientCarePlanScreen";
import PatientDiaryScreen from "../screens/PatientDiaryScreen";
import PatientGeneralquestionsScreen from "../screens/PatientGeneralquestionsScreen";
import PatientListScreen from "../screens/PatientListScreen";
import PatientMediaDetailsScreen from "../screens/PatientMediaDetailsScreen";
import PatientMediaScreen from "../screens/PatientMediaScreen";
import PatientMessagesScreen from "../screens/PatientMessagesScreen";
import PatientOverviewScreen from "../screens/PatientOverviewScreen";
import PatientPREMSScreen from "../screens/PatientPREMSScreen";
import PatientPROMSScreen from "../screens/PatientPROMSScreen";
import PostopAdminClinicianList from "../screens/postopadmin/ClinicianListScreen";
import HospitalAdminDetailsScreen from "../screens/postopadmin/HospitalAdminDetails";
import HospitanAdminsScreen from "../screens/postopadmin/HospitalAdmins";
import HospitalClinicianList from "../screens/postopadmin/HospitalClinicianList";
import HospitalDetailsScreen from "../screens/postopadmin/HospitalDetails";
import HospitalListScreen from "../screens/postopadmin/HospitalListScreen";
import PostopAdminMedia from "../screens/postopadmin/PostopAdminMedia";
import PostopAdminPatientList from "../screens/postopadmin/PostopAdminPatientList";
import PostopAdminWoundTags from "../screens/postopadmin/PostopAdminWoundTags";
import WoundAnnotator from "../screens/postopadmin/WoundAnnotator";
import { ClinicianProfileScreen, HospitalAdminProfileScreen, PostopAdminProfileScreen } from "../screens/ProfileScreen";
import TeamScreen from "../screens/TeamScreen";
import {
  ClinicianUpdateProfileScreen,
  HospitalAdminUpdateProfileScreen,
  PostopAdminUpdateProfileScreen,
} from "../screens/UpdateProfileScreen";
import { ClinicianLogin2FA, HospitalAdminLogin2FA, PostopAdminLogin2FA } from "../screens/user/Login2FAScreen";
import { ClinicianLogin, HospitalAdminLogin, PostopAdminLogin } from "../screens/user/LoginScreen";
import { ClinicianRegister, HospitalAdminRegister, PostopAdminRegister } from "../screens/user/RegisterScreen";
import {
  ClinicianRegisterSetPassword,
  HospitalAdminRegisterSetPassword,
  PostopAdminRegisterSetPassword,
} from "../screens/user/RegisterSetPasswordScreen";
import {
  ClinicianRegisterUpdateDetailsScreen,
  HospitalAdminRegisterUpdateDetailsScreen,
  PostopAdminRegisterUpdateDetailsScreen,
} from "../screens/user/RegisterUpdateDetailsScreen";
import {
  ClinicianResetPassword,
  HospitalAdminResetPassword,
  PostopAdminResetPassword,
} from "../screens/user/ResetPasswordScreen";
import { LoggedInLinking, WelcomeLinking } from "./linkingOptions";

import type { ClinicianParams, HospitalAdminParams, PostopAdminParams, WelcomeFlowParams } from "./params";
const MyTheme = {
  ...DefaultTheme,

  colors: {
    ...DefaultTheme.colors,
    notification: "#F65031",
  },
};

const Navigation = withStore(({ userStore }) => {
  const navigationRef = useRef<any>();
  const routeNameRef = useRef<string>();

  useEffect(() => {
    const onChange = (): void => {
      userStore.updatescreen();
    };

    const sub = Dimensions.addEventListener("change", onChange);
    return () => {
      sub.remove();
    };
  }, []);

  return (
    <NavigationContainer
      ref={navigationRef}
      //
      onReady={async () => {
        const currentRouteName = navigationRef.current.getCurrentRoute().name;
        routeNameRef.current = navigationRef.current.getCurrentRoute().name;
        // TODO: track screen with eg. Segment or Google Analytics
        console.log("Navigation ready:", currentRouteName);
      }}
      onStateChange={async () => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = navigationRef.current.getCurrentRoute().name;

        if (previousRouteName !== currentRouteName) {
          // TODO: track screen with eg. Segment or Google Analytics
          console.log("Navigate to:", currentRouteName);
        }

        // Save the current route name for later comparison
        routeNameRef.current = currentRouteName;
      }}
      linking={userStore.userloggedin ? LoggedInLinking : WelcomeLinking}
      theme={MyTheme}>
      {!userStore.userloggedin && <WelcomeFlow />}
      {userStore.userloggedin && userStore.usertype == 1 && <ClinicianFlow />}
      {userStore.userloggedin && userStore.usertype == 2 && <HospitalAdminFlow />}
      {userStore.userloggedin && userStore.usertype == 3 && <PostopAdminFlow />}
    </NavigationContainer>
  );
});

export default Navigation;

const StackWelcome = createStackNavigator<WelcomeFlowParams>();
const WelcomeFlow = withStore(({ userStore }) => {
  let initialRouteName: keyof WelcomeFlowParams = "ClinicianLogin";
  if (userStore?.usertype == 2) initialRouteName = "HospitalAdminLogin";
  if (userStore?.usertype == 3) initialRouteName = "PostopAdminLogin";

  return (
    <StackWelcome.Navigator
      initialRouteName={initialRouteName}
      screenOptions={{
        headerShown: false,
        gestureEnabled: false,
      }}>
      <StackWelcome.Screen name="ClinicianRegisterCode" component={ClinicianRegister} />
      <StackWelcome.Screen name="HospitalAdminRegisterCode" component={HospitalAdminRegister} />
      <StackWelcome.Screen name="PostopAdminRegisterCode" component={PostopAdminRegister} />

      <StackWelcome.Screen name="ClinicianRegisterSetPasswordScreen" component={ClinicianRegisterSetPassword} />
      <StackWelcome.Screen name="HospitalAdminRegisterSetPasswordScreen" component={HospitalAdminRegisterSetPassword} />
      <StackWelcome.Screen name="PostopAdminRegisterSetPasswordScreen" component={PostopAdminRegisterSetPassword} />

      <StackWelcome.Screen name="ClinicianLogin" component={ClinicianLogin} />
      <StackWelcome.Screen name="HospitalAdminLogin" component={HospitalAdminLogin} />
      <StackWelcome.Screen name="PostopAdminLogin" component={PostopAdminLogin} />

      <StackWelcome.Screen name="Clinician2FALogin" component={ClinicianLogin2FA} />
      <StackWelcome.Screen name="HospitalAdmin2FALogin" component={HospitalAdminLogin2FA} />
      <StackWelcome.Screen name="PostopAdmin2FALogin" component={PostopAdminLogin2FA} />

      <StackWelcome.Screen name="ClinicianResetPasswordScreen" component={ClinicianResetPassword} />
      <StackWelcome.Screen name="HospitalAdminResetPasswordScreen" component={HospitalAdminResetPassword} />
      <StackWelcome.Screen name="PostopAdminResetPassword" component={PostopAdminResetPassword} />

      <StackWelcome.Screen
        name="PostopAdminRegisterUpdateDetailsScreen"
        component={PostopAdminRegisterUpdateDetailsScreen}
      />
    </StackWelcome.Navigator>
  );
});

const StackClinician = createStackNavigator<ClinicianParams>();

function ClinicianFlow(): JSX.Element {
  return (
    <StackClinician.Navigator
      screenOptions={{
        headerShown: false,
        gestureEnabled: false,
      }}>
      <StackClinician.Screen name="DashboardScreen" component={DashboardScreen} />
      <StackClinician.Screen name="PatientListScreen" component={PatientListScreen} />
      <StackClinician.Screen name="PatientOverviewScreen" component={PatientOverviewScreen} />
      <StackClinician.Screen name="PatientMediaScreen" component={PatientMediaScreen} />
      <StackClinician.Screen name="PatientPROMSScreen" component={PatientPROMSScreen} />
      <StackClinician.Screen name="PatientPREMSScreen" component={PatientPREMSScreen} />
      <StackClinician.Screen name="PatientGeneralquestionsScreen" component={PatientGeneralquestionsScreen} />
      <StackClinician.Screen name="PatientCarePlanScreen" component={PatientCarePlanScreen} />
      <StackClinician.Screen name="PatientMessagesScreen" component={PatientMessagesScreen} />
      <StackClinician.Screen name="PatientDiaryScreen" component={PatientDiaryScreen} />
      <StackClinician.Screen name="ClinicianUpdateProfileScreen" component={ClinicianUpdateProfileScreen} />
      <StackClinician.Screen name="ClinicianProfileScreen" component={ClinicianProfileScreen} />
      <StackClinician.Screen name="TeamScreen" component={TeamScreen} />
      <StackClinician.Screen name="ClinicianResetPasswordScreen" component={ClinicianResetPassword} />
      <StackClinician.Screen name="ClinicalTeam" component={ClinicalTeam} />

      <StackClinician.Screen name="ClinicianListScreen" component={ClinicianListScreen} />
      <StackClinician.Screen name="ClinicalTeamListScreen" component={ClinicalTeamListScreen} />
      <StackClinician.Screen name="AdminPatientListScreen" component={AdminPatientListScreen} />

      <StackClinician.Screen name="AdminDashboardScreen" component={AdminDashboardScreen} />
      <StackClinician.Screen name="ClinicianTeams" component={ClinicianTeams} />
      <StackClinician.Screen name="ClinicianPatients" component={ClinicianPatients} />

      <StackClinician.Screen name="ClinicalTeamPatients" component={ClinicalTeamPatients} />
      <StackClinician.Screen name="ClinicalTeamClinicians" component={ClinicalTeamClinicians} />

      <StackClinician.Screen name="PatientMediaDetailsScreen" component={PatientMediaDetailsScreen} />
      <StackClinician.Screen
        name="ClinicianRegisterUpdateDetailsScreen"
        component={ClinicianRegisterUpdateDetailsScreen}
      />
    </StackClinician.Navigator>
  );
}

const StackHospitalAdmin = createStackNavigator<HospitalAdminParams>();

function HospitalAdminFlow(): JSX.Element {
  return (
    <StackClinician.Navigator
      screenOptions={{
        headerShown: false,
        gestureEnabled: false,
      }}>
      <StackHospitalAdmin.Screen name="AdminDashboardScreen" component={AdminDashboardScreen} />
      <StackHospitalAdmin.Screen name="ClinicianListScreen" component={ClinicianListScreen} />
      <StackHospitalAdmin.Screen name="ClinicalTeamListScreen" component={ClinicalTeamListScreen} />
      <StackHospitalAdmin.Screen name="AdminPatientListScreen" component={AdminPatientListScreen} />
      <StackHospitalAdmin.Screen name="HospitalAdminUpdateProfileScreen" component={HospitalAdminUpdateProfileScreen} />
      <StackHospitalAdmin.Screen name="HospitalAdminProfileScreen" component={HospitalAdminProfileScreen} />
      <StackHospitalAdmin.Screen name="HospitalAdminResetPasswordScreen" component={HospitalAdminResetPassword} />
      <StackHospitalAdmin.Screen name="ClinicianTeams" component={ClinicianTeams} />
      <StackHospitalAdmin.Screen name="ClinicianPatients" component={ClinicianPatients} />
      <StackHospitalAdmin.Screen name="ClinicalTeamPatients" component={ClinicalTeamPatients} />
      <StackHospitalAdmin.Screen name="ClinicalTeamClinicians" component={ClinicalTeamClinicians} />
      <StackHospitalAdmin.Screen
        name="HospitalAdminRegisterUpdateDetailsScreen"
        component={HospitalAdminRegisterUpdateDetailsScreen}
      />
    </StackClinician.Navigator>
  );
}

const StackPostopAdmin = createStackNavigator<PostopAdminParams>();

function PostopAdminFlow(): JSX.Element {
  return (
    <StackClinician.Navigator
      screenOptions={{
        headerShown: false,
        gestureEnabled: false,
      }}>
      <StackPostopAdmin.Screen name="HospitalListScreen" component={HospitalListScreen} />
      <StackPostopAdmin.Screen name="HospitalAdminsScreen" component={HospitanAdminsScreen} />
      <StackPostopAdmin.Screen name="HospitalDetailsScreen" component={HospitalDetailsScreen} />
      <StackPostopAdmin.Screen name="HospitalAdminDetailsScreen" component={HospitalAdminDetailsScreen} />
      <StackPostopAdmin.Screen name="PostopAdminProfileScreen" component={PostopAdminProfileScreen} />

      <StackPostopAdmin.Screen name="HospitalAdminUpdateProfileScreen" component={PostopAdminUpdateProfileScreen} />

      <StackPostopAdmin.Screen name="HospitalAdminProfileScreen" component={PostopAdminProfileScreen} />

      <StackPostopAdmin.Screen name="HospitalAdminResetPasswordScreen" component={PostopAdminResetPassword} />

      <StackPostopAdmin.Screen
        name="PostopAdminPatientList"
        component={PostopAdminPatientList}
        options={{ title: "Patient Search" }}
      />
      <StackPostopAdmin.Screen
        name="PostopAdminClinicianList"
        component={PostopAdminClinicianList}
        options={{ title: "Clinician Search" }}
      />
      <StackPostopAdmin.Screen
        name="PostopAdminTags"
        component={PostopAdminWoundTags}
        options={{ title: "Wound Tags" }}
      />
      <StackPostopAdmin.Screen name="PostopAdminMedia" component={PostopAdminMedia} options={{ title: "Media" }} />
      <StackPostopAdmin.Screen
        name="WoundAnnotator"
        component={WoundAnnotator}
        options={{ title: "Wound Annotator" }}
      />
      <StackPostopAdmin.Screen name="HospitalClinicianList" component={HospitalClinicianList} />
    </StackClinician.Navigator>
  );
}
