import type { CommonIconProps } from "./icon-types";

export default function GearIcon({ size = 20, color = "currentColor", className }: Readonly<CommonIconProps>) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.61908 1.12334C7.8427 0.45249 8.4705 0 9.1776 0H10.8224C11.5295 0 12.1573 0.45249 12.3809 1.12334L12.9588 2.8569L14.5932 2.0397C15.2256 1.72346 15.9895 1.84742 16.4895 2.34744L17.6526 3.51045C18.1526 4.01047 18.2765 4.77435 17.9603 5.40683L17.1431 7.04123L18.8767 7.61908C19.5475 7.8427 20 8.4705 20 9.1776V10.8224C20 11.5295 19.5475 12.1573 18.8767 12.3809L17.1432 12.9587L17.9604 14.5932C18.2766 15.2256 18.1527 15.9895 17.6526 16.4895L16.4896 17.6526C15.9896 18.1526 15.2257 18.2765 14.5933 17.9603L12.9588 17.1431L12.3809 18.8767C12.1573 19.5475 11.5295 20 10.8224 20H9.1776C8.4705 20 7.8427 19.5475 7.61908 18.8767L7.04123 17.1431L5.40683 17.9603C4.77435 18.2765 4.01047 18.1526 3.51045 17.6526L2.34744 16.4896C1.84742 15.9895 1.72346 15.2257 2.0397 14.5932L2.8569 12.9588L1.12334 12.3809C0.45249 12.1573 0 11.5295 0 10.8224V9.1776C0 8.4705 0.45249 7.8427 1.12334 7.61908L2.85683 7.04125L2.03962 5.40683C1.72338 4.77435 1.84734 4.01047 2.34736 3.51045L3.51037 2.34744C4.01039 1.84742 4.77427 1.72346 5.40675 2.0397L7.04121 2.85693L7.61908 1.12334ZM7 10C7 8.3431 8.3431 7 10 7C11.6569 7 13 8.3431 13 10C13 11.6569 11.6569 13 10 13C8.3431 13 7 11.6569 7 10Z"
        fill={color}
      />
    </svg>
  );
}
