////////////////////////////////////////////////////////////////////////////////////////////////////
// Wrapper around ConfigCat's useFeatureFlag hook for improved typing and to use a default value
// for the default values.
import { useFeatureFlag as _useFeatureFlag } from "configcat-react";
import { User } from "configcat-common";
import Constants from "expo-constants";
import { getJWTPayload } from "../misc/utils";

type FeatureFlags = {
  showEntFeatures: boolean;
  showCarePlanFromCTeams: boolean;
  showNewHomeScreen: boolean;
  showWoundVizPro: boolean;
  unifiedlogin: boolean;
}

const defaults: Partial<FeatureFlags> = {
  showEntFeatures: false,
  showNewHomeScreen: false,
  showWoundVizPro: false,
  unifiedlogin: false,
};

export function useFeatureFlag<K extends keyof FeatureFlags>(
  name: K,
  defaultValue = defaults[name],
  userExtra: User['custom'] = {},
): FeatureFlags[K] {
  const result = useNullableFeatureFlag<K>(name, defaultValue, userExtra);
  if (result === null) return defaultValue as FeatureFlags[K];
  return result as FeatureFlags[K];
}

export function useNullableFeatureFlag<K extends keyof FeatureFlags>(
  name: K,
  defaultValue = defaults[name],
  userExtra: User['custom'] = {},
): FeatureFlags[K] | null {
  //@ts-ignore
  const token = global.token;
  const decoded = token ? getJWTPayload(token) : null;

  let identifier = token
    ? `${decoded.userType}-${decoded.userId}`
    : 'unknown-user';

  const result = _useFeatureFlag(
    name,
    defaultValue,
    {
      identifier,
      custom: {
        environment: Constants.expoConfig!.extra!.environment,
        ...userExtra,
      },
    }
  );
  if (result.loading) return null;
  return result.value as FeatureFlags[K];
}
