import * as React from "react";
import Logo from "../icons/Logo";
import { requests } from "../../misc";
import { toastError, toastSuccess } from "../Notifications";

interface Login2FAProps {
  userStore: any;
  email: string;
  password: string;
  closeAction: () => void;
  admin?: boolean;
  postopadmin?: boolean;
}

const Login2FA = ({ userStore, email, password, closeAction, admin = false, postopadmin = false }: Login2FAProps) => {
  const [logincode, setLogincode] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const apigateway = admin ? "hospitaladmins" : postopadmin ? "postopadmins" : "clinicians";

  async function sendagain() {
    if (isLoading) return false;

    try {
      setIsLoading(true);
      await requests.post(`/${apigateway}/checklogin`, {
        email,
        password,
      });
      toastSuccess("Authentication code sent");
    } catch (e) {
      toastError("Errpr sending the validation email");
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function login2FA(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (isLoading) return false;
    try {
      setIsLoading(true);
      const resData = await requests.post(
        `/${apigateway}/login2fa`,
        {
          email,
          password,
          logincode,
        },
        { credentials: "include" },
      );
      userStore.setFullLoggedinIsAdmin(
        resData.token,
        resData.photourl,
        resData.firstname,
        resData.lastname,
        admin ? 2 : postopadmin ? 3 : 1,
        resData.readonly,
      );
    } catch (e) {
      toastError("Unable to verify. Please check the authentication code.");
      console.error("Failed 2FA:", e);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="tfa-modal">
      <header>
        <div className="close" onClick={closeAction}>
          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 1L1 9" stroke="#1F2E99" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M1 1L9 9" stroke="#1F2E99" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          Close
        </div>
      </header>
      <form
        onSubmit={(e) => {
          login2FA(e);
        }}>
        <main>
          <Logo />

          <h1>Enhanced account security</h1>
          <p>Protecting your data is our top priority!</p>
          <p>
            We have sent an authentication code to your registered email for confirmation. Please check your email and
            confirm to proceed.
          </p>
          <label htmlFor="authCode">Authentication code</label>
          <input
            type="text"
            name="authCode"
            id="authCode"
            className="standard-shadow"
            minLength={6}
            maxLength={6}
            onChange={(ev) => {
              setLogincode(ev.target.value.trim());
            }}
          />
          <p className="instructions">
            Didn't receive the email?{" "}
            <a
              href="#"
              onClick={() => {
                sendagain();
              }}>
              Send it again
            </a>
            <br />
            Don't forget to check your spam box.
          </p>
        </main>
        <footer>
          <button
            type="submit"
            aria-disabled={!isLoading && logincode.length !== 6}
            disabled={!isLoading && logincode.length !== 6}>
            Verify code
          </button>
        </footer>
      </form>
    </div>
  );
};

export default Login2FA;
