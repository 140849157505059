import React, { useState } from "react";
import { FlatList, Image, StyleSheet, Text, TextInput, TouchableOpacity, View } from "react-native";

import { Avatar } from "../../components/Avatar";
import { Hairline } from "../../components/Hairline";
import { MainFrame } from "../../components/MainFrame";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import { requests } from "../../misc";
import { withStore } from "../../misc/utils";

const TempScreen = withStore(({ userStore }) => {
  const [searchtext, setSearchtext] = useState("");

  // search-filtered data
  const [data, setData] = useState<any[]>([]);
  // cached original data
  const [origData, setOrigData] = useState<any[]>([]);

  function updateSearch(text: string) {
    setSearchtext(text);
    if (text === "") {
      console.log("updatesearch:null");
      setData(origData);
      return;
    }
    setData(
      origData.filter(function (item) {
        return (item.firstname + " " + item.lastname).toLowerCase().includes(text.toLowerCase());
      }),
    );
  }

  useAsyncEffect(async () => {
    const resData = await requests.get("/hospitaladmins/getpatients");
    console.log(resData);
    setData(resData.data);
    setOrigData(resData.data);
  }, []);

  return (
    <View style={styles.container}>
      <MainFrame admin userStore={userStore} patientlist style={{}}>
        <View style={{ alignItems: "flex-start", width: 1000 }}>
          <Text style={[styles.title1]}>List of patients</Text>
          <Text style={[styles.title2]}>{data.length} Patients</Text>
          <View style={{ flexDirection: "row", alignItems: "center", marginTop: 24 }}>
            <View style={[styles.shadowinput, { width: 267 }]}>
              <TextInput
                style={[styles.postopinput, { textAlign: "left", width: 220 }]}
                keyboardType="email-address"
                maxLength={50}
                numberOfLines={1}
                onChangeText={(text) => {
                  updateSearch(text);
                }}
                placeholder={"Search"}
                placeholderTextColor={"#1F2E99"}
                value={searchtext}
              />
              <TouchableOpacity style={{ position: "absolute", right: 10, top: 13 }}>
                <Image source={require("../../assets/images/searchicon.png")} style={styles.searchicon} />
              </TouchableOpacity>
            </View>
          </View>
          <View style={{ flexDirection: "row", width: "100%", justifyContent: "space-between", marginTop: 24.5 }}>
            <TouchableOpacity style={{ flex: 0.3 }}>
              <Text style={[styles.title3]}>Name</Text>
            </TouchableOpacity>
            <TouchableOpacity style={{ flex: 0.177 }}>
              <Text style={[styles.title3]}>Clinical team</Text>
            </TouchableOpacity>
            <TouchableOpacity style={{ flex: 0.147 }}>
              {" "}
              <Text style={[styles.title3]}>Hospital number</Text>
            </TouchableOpacity>
            <TouchableOpacity style={{ flex: 0.277 }}>
              <Text style={[styles.title3]}>Email address</Text>
            </TouchableOpacity>
            <TouchableOpacity style={{ flex: 0.177 }}>
              <Text style={[styles.title3]}>Phone number</Text>
            </TouchableOpacity>
            <TouchableOpacity style={{ flex: 0.03 }}></TouchableOpacity>
          </View>
          <Hairline marginTop={15.5} />
          <FlatList
            scrollEnabled={false}
            style={{ width: "100%", marginBottom: 94 }}
            data={data}
            keyExtractor={(item) => item.id}
            renderItem={({ item, index, separators }) => (
              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                  marginTop: 24.5,
                  backgroundColor: "#ffffff",
                  height: 94,
                  alignItems: "center",
                  borderRadius: 6,
                }}>
                <View style={{ flex: 0.1 }}>
                  <Avatar
                    url={item?.thumburl && global.imagepathurl + encodeURI(item?.thumburl)}
                    size={46}
                    radius={23}
                    firstname={item.firstname}
                    lastname={item.lastname}
                    style={{ marginLeft: 24, borderWidth: 1, marginRight: 18 }}
                  />
                </View>
                <View style={{ flex: 0.2 }}>
                  <Text style={[styles.title4]}>
                    {item.firstname} {item.lastname}
                  </Text>
                </View>
                <Text style={[styles.title4, { flex: 0.177 }]}>{item?.clinicalteam?.name}</Text>
                <Text style={[styles.title4, { flex: 0.147 }]}>-</Text>
                <Text style={[styles.title4, { flex: 0.277 }]}>{item?.email}</Text>
                <Text style={[styles.title4, { flex: 0.177 }]}>
                  +{item?.gsmcountrycode} {item?.gsm}
                </Text>
                <Text style={[styles.title4, styles.titlecenter, { flex: 0.03 }]}></Text>
              </View>
            )}
          />
        </View>
      </MainFrame>
    </View>
  );
});

export default TempScreen;

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  openButton: {
    backgroundColor: "#F194FF",
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },

  searchicon: {
    marginTop: 2,
    width: 12.33,
    height: 13.33,
  },
  watchcloselycircleicon: {
    width: 15,
    height: 15,
  },
  unreadmsgicon: {
    width: 8,
    height: 8,
  },
  flagicon: {
    width: 15,
    height: 18.333,
  },

  postopinput: {
    flex: 1,
    outlineWidth: 0,
    color: "#1F2E99",
    fontFamily: "Lato",
    fontSize: 15,
    marginRight: 10,
    marginLeft: 20,
  },
  shadowinput: {
    marginTop: 15,
    height: 44,
    flexDirection: "row",
    borderRadius: 15,
    shadowColor: "#818181",
    backgroundColor: "#FFFFFF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",

    height: "100%",
  },
  title1: {
    fontSize: 24,
    fontFamily: "LatoBold",
    color: "#1F2E99",

    marginTop: 32,
  },
  title2: {
    marginTop: 12,

    fontSize: 15,
    fontFamily: "Lato",
    color: "#1F2E99",
  },
  title3: {
    fontSize: 13,
    fontFamily: "Lato",
    color: "#1F2E99",
  },
  titlecenter: { width: "100%", textAlign: "center" },
  title4: {
    fontSize: 15,
    fontFamily: "Lato",
    color: "#000000",
  },
  title5: {
    color: "#0009EC",
    fontFamily: "Lato",
    fontSize: 13,
    marginLeft: 6,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
});
