import React from "react";

import validate from "../../screens/validate_wrapper";
import { useNavigation } from "@react-navigation/native";

import UserLayout from "./UserLayout";
import { requests } from "../../misc";
import { RequestError } from "../../misc/requests";
import { toastError, toastSuccess } from "../Notifications";
import { EyePassword } from "./EyePassword";

export interface EnrollmentProps {
  admin?: boolean;
  postopadmin?: boolean;
}

const UnifiedEnrollment = (props: EnrollmentProps) => {
  const navigation = useNavigation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [tempPassword, setTempPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");

  const apigateway = props?.admin ? "hospitaladmins" : props?.postopadmin ? "postopadmins" : "clinicians";

  async function inviteAgain() {
    if (isLoading) return false;

    setEmailError("");

    const emailError = validate("email", email);

    if (emailError) {
      setEmailError(emailError);
      return;
    }

    try {
      setIsLoading(true);
      await requests.post(`/${apigateway}/inviteagain`, {
        email,
      });

      toastSuccess("Mail sent. Please check your mailbox.");
    } catch (err: any) {
      if (err instanceof RequestError && err.response.status === 401) {
        toastError("Invalid email address. Please verify and try again.");
        return;
      }
      console.error("Failed to check login:", err);
      toastError("We're sorry, we couldn't resend your invitation. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }

  async function checkInvitationCode(e: React.FormEvent<Element>) {
    e.preventDefault();
    if (isLoading) return false;

    setEmailError("");
    setPasswordError("");

    const passwordError = validate("passwordlogin", tempPassword);
    const emailError = validate("email", email);

    if (passwordError || emailError) {
      setEmailError(emailError);
      setPasswordError(passwordError);
      return;
    }

    try {
      setIsLoading(true);
      await requests.post(`/${apigateway}/checkinvite`, {
        email,
        temppassword: tempPassword,
      });

      if (props?.admin) {
        navigation.push("HospitalAdminRegisterSetPasswordScreen", { email, tempPassword });
      } else if (props?.postopadmin) {
        navigation.push("PostopAdminRegisterSetPasswordScreen", { email, tempPassword });
      } else {
        navigation.push("ClinicianRegisterSetPasswordScreen", { email, tempPassword });
      }
    } catch (err: any) {
      if (err.data?.result === false) {
        toastError("Invalid credentials. Verify your email and password, then try again.");
        return;
      }

      console.error("Failed to check login:", err);
      toastError("We couldn't verify your credentials. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <UserLayout
      title="Let's get started by setting up your account."
      body="Insert your email address and the temporary password you have received via email">
      <>
        <p className="small-instructions">
          Didn't receive the email?{" "}
          <a href="#" onClick={inviteAgain}>
            Click to resend
          </a>
          .
        </p>
        <form onSubmit={checkInvitationCode}>
          <div className="input-block">
            <label htmlFor="email">Email address</label>
            <input
              className={`standard-shadow ${emailError ? "input-error" : ""}`}
              type="email"
              name="email"
              id="email"
              onChange={(ev) => {
                setEmail(ev.target.value.trim());
              }}
            />
            <div className={emailError ? "error" : "no-error"}>{emailError}</div>
            <p className="small-instructions">
              This is the email address where you received your temporary password. Use it to log in to your Post Op
              account
            </p>
          </div>
          <div className="input-block">
            <label htmlFor="password">Temporary Password</label>
            <EyePassword
              id="password"
              error={passwordError}
              onChange={(ev) => {
                setTempPassword(ev.target.value.trim());
              }}
            />
          </div>
          <p>
            <button type="submit" id="enter" disabled={isLoading || email.length == 0 || tempPassword.length == 0}>
              Create Account
            </button>
          </p>
        </form>
        <p className="instructions">
          Have an account?{" "}
          <a href={props?.admin ? "/hospitaladmin/login" : props?.postopadmin ? "/postopadmin/login" : "/login"}>
            Log in
          </a>
        </p>
      </>
    </UserLayout>
  );
};
export default UnifiedEnrollment;
