import { Dimensions, Image, ImageBackground, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import {
  HospitalAdminOverallComplienceTypeChart,
  HospitalAdminPatientTypeChart,
  OutComeScoresChart,
} from "../../components/Charts";
import React, { useEffect } from "react";

import { Hairline } from "../../components/Hairline";
import { MainFrame } from "../../components/MainFrame";
import Moment from "moment";
import { ValueChange } from "../../components/ValueChange";
import { withStore } from "../../misc/utils";

const DashboardScreen = withStore(({ userStore }) => {
  const [data, setData] = React.useState<any>(null);
  const [patientData, setPatientData] = React.useState<any>(null);
  const [selectedTab, setSelectedTab] = React.useState<number>(1);
  const [averageCompliance, setAverageCompliance] = React.useState("-");

  const [minvalue, setMinvalue] = React.useState<number>(0);
  const [maxvalue, setMaxvalue] = React.useState<number>(0);

  const [datachart2, setDatachart2] = React.useState(null);
  const [datachart3, setDatachart3] = React.useState(null);
  const [deltaMonth, setDeltaMonth] = React.useState<number>(0);
  const [deltaMonth2, setDeltaMonth2] = React.useState<number>(0);
  const [deltaMonth3, setDeltaMonth3] = React.useState<number>(0);
  const [deltaMonth4, setDeltaMonth4] = React.useState<number>(0);

  const [chart1months, setChart1months] = React.useState<string[]>([]);
  const [chart1proms, setChart1proms] = React.useState<{ x: number; y: number | string }[]>([]);
  const [chart1prems, setChart1prems] = React.useState<{ x: number; y: number | string }[]>([]);
  const yaxisoutcomescores = [
    [0, 12, 24, 36, 48],
    [0, 12, 24, 36, 48],
    [0, 8, 16, 24, 32, 41],
    [0, 9, 18, 27],
  ];

  async function getchartdata(whichchart: number, ctype: number, dmonth: number) {
    let chartapiurl = "";
    /* if (whichchart==1)  {
       setDeltaMonth(dmonth);
       chartapiurl="getdashboardpatientdata";

     } */

    if (whichchart === 0) {
      setDeltaMonth(dmonth);

      chartapiurl = "getdashboardpatientdata";
    }

    if (whichchart === 1) {
      setDeltaMonth2(dmonth);

      chartapiurl = "getdashboardpromsprems";
    }

    if (whichchart === 2) {
      setDeltaMonth3(dmonth);

      chartapiurl = "getdashboardcompliancetdata";
    }

    if (whichchart === 3) {
      // setDeltaMonth3(dmonth);
      setDeltaMonth4(dmonth);

      chartapiurl = "getdashboardchartdata";
    }

    if (whichchart === 4) {
      setSelectedTab(ctype);
      setDeltaMonth4(dmonth);
      chartapiurl = "getdashboardchartdata";
    }

    await fetch(global.apiurl + "/hospitaladmins/" + chartapiurl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
      body: JSON.stringify({
        deltamonth: dmonth,
        charttype: ctype,
      }),
    })
      .then(async (response) => await response.json())
      .then((resData) => {
        console.log(resData);
        if (resData.result) {
          if (whichchart === 0) setPatientData(resData.data);

          if (whichchart === 1) {
            setChart1months([
              Moment()
                .add(dmonth - 4, "months")
                .format("MMM"),
              Moment()
                .add(dmonth - 3, "months")
                .format("MMM"),
              Moment()
                .add(dmonth - 2, "months")
                .format("MMM"),
              Moment()
                .add(dmonth - 1, "months")
                .format("MMM"),
              Moment().add(dmonth, "months").format("MMM"),
            ]);
            setChart1proms([
              { x: 1, y: resData.data.dashboard8.y1 },
              { x: 2, y: resData.data.dashboard8.y2 },
              { x: 3, y: resData.data.dashboard8.y3 },
              { x: 4, y: resData.data.dashboard8.y4 },
              { x: 5, y: resData.data.dashboard8.y5 },
            ]);
            setChart1prems([
              { x: 1, y: resData.data.dashboard9.y1 },
              { x: 2, y: resData.data.dashboard9.y2 },
              { x: 3, y: resData.data.dashboard9.y3 },
              { x: 4, y: resData.data.dashboard9.y4 },
              { x: 5, y: resData.data.dashboard9.y5 },
            ]);
          }
          if (whichchart === 2) {
            setDatachart2(resData.data.dashchart2);
            setAverageCompliance(resData.data.dashboard10);
          }
          if (whichchart === 3) setDatachart3(resData.data);
        }
      });
  }

  useEffect(() => {
    async function fetchData() {
      fetch(global.apiurl + "/hospitaladmins/getdashboard", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: global.tokenparam || "",
        },
      })
        .then(async (response) => await response.json())
        .then((resData) => {
          console.log(resData);
          if (resData.result) {
            setData(resData.data);

            setPatientData(resData.data);

            setMinvalue(Math.min(resData.data.dashboard3, resData.data.dashboard4, resData.data.dashboard5));
            setMaxvalue(Math.max(resData.data.dashboard3, resData.data.dashboard4, resData.data.dashboard5));

            setAverageCompliance(resData.data.dashboard10);

            setDatachart2(resData.data.dashchart2);
            setDatachart3(resData.data.dashchart3);

            setChart1months([
              Moment().add(-4, "months").format("MMM"),
              Moment().add(-3, "months").format("MMM"),
              Moment().add(-2, "months").format("MMM"),
              Moment().add(-1, "months").format("MMM"),
              Moment().format("MMM"),
            ]);
            setChart1proms([
              { x: 1, y: resData.data.dashboard8.y1 },
              { x: 2, y: resData.data.dashboard8.y2 },
              { x: 3, y: resData.data.dashboard8.y3 },
              { x: 4, y: resData.data.dashboard8.y4 },
              { x: 5, y: resData.data.dashboard8.y5 },
            ]);
            setChart1prems([
              { x: 1, y: resData.data.dashboard9.y1 },
              { x: 2, y: resData.data.dashboard9.y2 },
              { x: 3, y: resData.data.dashboard9.y3 },
              { x: 4, y: resData.data.dashboard9.y4 },
              { x: 5, y: resData.data.dashboard9.y5 },
            ]);

            // setTimeout(() =>setCircularchartpercent((100*data.dashboard1/(data.dashboard1+data.dashboard2+0.0001))), 300);
          } else {
            console.log("no data - no result");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, []);

  const dimensions = Dimensions.get("window");
  const miniboxwidth = Math.min(230.5, (Math.max(1200, dimensions.width) - 328 - 32 * 6) / 4);

  return (
    <View style={styles.container}>
      <MainFrame admin dashboard userStore={userStore}>
        <View style={{ flexDirection: "row", marginTop: 37, marginLeft: 32, marginRight: 32 }}>
          <View>
            <View style={{ flexDirection: "row" }}>
              <View style={[styles.whitebox, { width: 851, height: 222 }]}>
                <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                  {" "}
                  <Text style={[styles.title3, { marginLeft: 17, marginTop: 16 }]}>Patients</Text>
                  <View style={{ flexDirection: "row", alignItems: "center", marginTop: 16 }}>
                    <TouchableOpacity
                      style={{ marginRight: 16.12 }}
                      onPress={() => {
                        getchartdata(0, selectedTab, deltaMonth - 1);
                      }}>
                      <Image
                        style={{ width: 20, height: 20 }}
                        source={require("../../assets/images/careplanprevbutton.png")}
                      />
                    </TouchableOpacity>
                    <Text style={[styles.title11, { marginRight: 16.12, width: 40 }]}>
                      {Moment().add(deltaMonth, "months").format("MMM YY")}'
                    </Text>

                    <TouchableOpacity
                      style={{ marginRight: 20 }}
                      onPress={() => {
                        getchartdata(0, selectedTab, deltaMonth + 1);
                      }}>
                      <Image
                        style={{ width: 20, height: 20 }}
                        source={require("../../assets/images/careplannextbutton.png")}
                      />
                    </TouchableOpacity>
                  </View>
                </View>

                <Hairline marginTop={16} />

                <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                  <View style={{ flexDirection: "row" }}>
                    <View
                      style={{
                        marginTop: 29,
                        marginLeft: 27,
                        width: 103,
                        height: 103,
                        background:
                          "radial-gradient(white 65%, transparent 41%), conic-gradient(#ffffff 0% 0.5%,#0077FF 2% " +
                          (100 * patientData?.dashboard1) /
                            (patientData?.dashboard1 + patientData?.dashboard2 + 0.0001) +
                          "%, #34B53A " +
                          ((100 * patientData?.dashboard1) /
                            (patientData?.dashboard1 + patientData?.dashboard2 + 0.0001) +
                            5) +
                          "% 99.5%,#ffffff 99.5% 100%)",

                        margin: 3,
                        borderRadius: "50%",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                      }}>
                      <Text style={{ fontFamily: "Lato", fontSize: 24, color: "#000000" }}>
                        {patientData && patientData?.dashboard1 + patientData?.dashboard2}
                      </Text>
                      <Text style={{ fontFamily: "Lato", fontSize: 11, color: "#798599", textAlign: "center" }}>
                        {" "}
                        Total <br />
                        patients
                      </Text>
                    </View>

                    <View style={{ marginLeft: 43, marginRight: 25, justifyContent: "flex-start" }}>
                      <View style={{ flexDirection: "row", alignItems: "center", marginTop: 42 }}>
                        <View
                          style={{
                            width: 20,
                            height: 20,
                            borderRadius: 7,
                            backgroundColor: "#0077FF",
                            alignItems: "center",
                          }}
                        />
                        <Text style={{ fontFamily: "Lato", fontSize: 13, width: 47, color: "#B4B7CC", marginLeft: 7 }}>
                          Hip
                        </Text>
                        <Text style={[styles.title10, { width: 30, textAlign: "right", marginRight: 5 }]}>
                          {patientData?.dashboard1}
                        </Text>{" "}
                        <ValueChange margintop={0} marginleft={10} deltavalue={patientData?.dashboard1change} />{" "}
                      </View>
                      <View style={{ flexDirection: "row", alignItems: "center", marginTop: 31 }}>
                        <View
                          style={{
                            width: 20,
                            height: 20,
                            borderRadius: 7,
                            backgroundColor: "#6DD803",
                            alignItems: "center",
                          }}
                        />
                        <Text style={{ fontFamily: "Lato", fontSize: 13, width: 47, color: "#B4B7CC", marginLeft: 7 }}>
                          Knee
                        </Text>
                        <Text style={[styles.title10, { width: 30, textAlign: "right", marginRight: 5 }]}>
                          {patientData?.dashboard2}
                        </Text>{" "}
                        <ValueChange margintop={0} marginleft={10} deltavalue={patientData?.dashboard2change} />{" "}
                      </View>
                    </View>
                  </View>
                  <View style={{ flexDirection: "row", marginTop: 17 }}>
                    <View style={{ marginRight: 30, alignItems: "center" }}>
                      <View style={{ width: 69, height: 69, alignItems: "center", justifyContent: "center" }}>
                        <ImageBackground
                          source={require("../../assets/images/admincharbluebg.png")}
                          style={{
                            width: 39 + ((patientData?.dashboard3 - minvalue) / maxvalue) * 30,
                            height: 39 + ((patientData?.dashboard3 - minvalue) / maxvalue) * 30,
                            alignItems: "center",
                            justifyContent: "center",
                          }}>
                          <Text style={{ color: "#ffffff", fontFamily: "LatoBold", fontSize: 15 }}>
                            {patientData?.dashboard3}
                          </Text>
                        </ImageBackground>
                      </View>
                      <ValueChange margintop={11} deltavalue={patientData?.dashboard3change} />
                      <Text
                        style={{
                          color: "#616368",
                          fontFamily: "Lato",
                          fontSize: 13,
                          textAlign: "center",
                          marginTop: 13,
                        }}>
                        &lt; 3 <br />
                        months
                      </Text>
                    </View>

                    <View style={{ marginRight: 30, alignItems: "center" }}>
                      <View style={{ width: 69, height: 69, alignItems: "center", justifyContent: "center" }}>
                        <ImageBackground
                          source={require("../../assets/images/admincharbluebg.png")}
                          style={{
                            width: 39 + ((patientData?.dashboard4 - minvalue) / maxvalue) * 30,
                            height: 39 + ((patientData?.dashboard4 - minvalue) / maxvalue) * 30,
                            alignItems: "center",
                            justifyContent: "center",
                          }}>
                          <Text style={{ color: "#ffffff", fontFamily: "LatoBold", fontSize: 15 }}>
                            {patientData?.dashboard4}
                          </Text>
                        </ImageBackground>
                      </View>
                      <ValueChange margintop={11} deltavalue={patientData?.dashboard4change} />

                      <Text
                        style={{
                          color: "#616368",
                          fontFamily: "Lato",
                          fontSize: 13,
                          textAlign: "center",
                          marginTop: 13,
                        }}>
                        3-6 <br />
                        months
                      </Text>
                    </View>

                    <View style={{ marginRight: 60, alignItems: "center" }}>
                      <View style={{ width: 69, height: 69, alignItems: "center", justifyContent: "center" }}>
                        <ImageBackground
                          source={require("../../assets/images/admincharbluebg.png")}
                          style={{
                            width: 39 + ((patientData?.dashboard5 - minvalue) / maxvalue) * 30,
                            height: 39 + ((patientData?.dashboard5 - minvalue) / maxvalue) * 30,
                            alignItems: "center",
                            justifyContent: "center",
                          }}>
                          <Text style={{ color: "#ffffff", fontFamily: "LatoBold", fontSize: 15 }}>
                            {patientData?.dashboard5}
                          </Text>
                        </ImageBackground>
                      </View>
                      <ValueChange margintop={11} deltavalue={patientData?.dashboard5change} />

                      <Text
                        style={{
                          color: "#616368",
                          fontFamily: "Lato",
                          fontSize: 13,
                          textAlign: "center",
                          marginTop: 13,
                        }}>
                        6-12 <br />
                        months
                      </Text>
                    </View>
                  </View>
                </View>
              </View>

              <View
                style={[styles.whitebox, { width: miniboxwidth, height: 222, marginLeft: 32, alignItems: "center" }]}>
                <Image
                  source={require("../../assets/images/dashboardiconchat.png")}
                  style={{ width: 42, height: 42, marginTop: 24 }}
                />
                <Text style={[styles.title1, { fontSize: 20 }]}>
                  {data?.dashboard6 ? `${data?.dashboard6}  hrs` : "-"}
                </Text>
                <ValueChange margintop={5} deltavalue={patientData?.dashboard6change} />
                <Text style={styles.title2}>Avg. response time this month</Text>
              </View>

              <View
                style={[styles.whitebox, { width: miniboxwidth, height: 222, marginLeft: 32, alignItems: "center" }]}>
                <Image
                  source={require("../../assets/images/dashboardiconinfection.png")}
                  style={{ width: 42, height: 42, marginTop: 24 }}
                />
                <Text style={[styles.title1, { fontSize: 20 }]}>
                  {data?.dashboard7 ? `${data?.dashboard7}  %` : "-"}
                </Text>
                <ValueChange margintop={5} deltavalue={patientData?.dashboard7change} />
                <Text style={styles.title2}>SSIS infection rate</Text>
              </View>
            </View>

            <View style={{ flexDirection: "row", marginTop: 32 }}>
              <View style={[styles.whitebox, { width: 410, height: 420, paddingTop: 17 }]}>
                <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                  {" "}
                  <Text style={[styles.title3, { marginLeft: 17 }]}>PROMS/PREMS</Text>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Text style={[styles.title11, { marginRight: 16.12 }]}>Month</Text>

                    <TouchableOpacity
                      style={{ marginRight: 16.12 }}
                      onPress={() => {
                        getchartdata(1, selectedTab, deltaMonth2 - 1);
                      }}>
                      <Image
                        style={{ width: 20, height: 20 }}
                        source={require("../../assets/images/careplanprevbutton.png")}
                      />
                    </TouchableOpacity>

                    <TouchableOpacity
                      style={{ marginRight: 20 }}
                      onPress={() => {
                        getchartdata(1, selectedTab, deltaMonth2 + 1);
                      }}>
                      <Image
                        style={{ width: 20, height: 20 }}
                        source={require("../../assets/images/careplannextbutton.png")}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
                <Hairline marginTop={15} />
                <Text style={[styles.title9, { marginTop: 20, marginLeft: 27 }]}>
                  Avg. percentage of questionnaires answered
                </Text>

                <View
                  style={{
                    flexDirection: "row",
                    marginTop: 14,
                    marginLeft: 27,
                    marginRight: 43,
                    justifyContent: "space-between",
                  }}>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <View
                      style={{
                        width: 20,
                        height: 20,
                        borderRadius: 7,
                        backgroundColor: "#0077FF",
                        alignItems: "center",
                      }}
                    />
                    <Text style={{ fontFamily: "Lato", fontSize: 11, color: "#9A9A9A", marginLeft: 12 }}> PROMS</Text>
                    <Text style={styles.title10}>
                      {chart1proms[4] ? (chart1proms[4].y ? chart1proms[4].y : "-") : "-"}%
                    </Text>{" "}
                  </View>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <View
                      style={{
                        width: 20,
                        height: 20,
                        borderRadius: 7,
                        backgroundColor: "#6DD803",
                        alignItems: "center",
                      }}
                    />
                    <Text style={{ fontFamily: "Lato", fontSize: 11, color: "#9A9A9A", marginLeft: 12 }}> PREMS</Text>
                    <Text style={styles.title10}>
                      {chart1prems[4] ? (chart1prems[4].y ? chart1prems[4].y : "-") : "-"}%
                    </Text>{" "}
                  </View>
                </View>

                <Text style={[styles.title9, { marginTop: 30, marginLeft: 27 }]}>Percentage</Text>

                <HospitalAdminPatientTypeChart
                  months={chart1months}
                  proms={chart1proms}
                  prems={chart1prems}></HospitalAdminPatientTypeChart>
              </View>

              <View style={[styles.whitebox, { width: 410, height: 420, paddingTop: 17, marginLeft: 32 }]}>
                <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                  {" "}
                  <Text style={[styles.title3, { marginLeft: 17 }]}>Overall Compliance</Text>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <TouchableOpacity
                      style={{ marginRight: 16.12 }}
                      onPress={() => {
                        getchartdata(2, selectedTab, deltaMonth3 - 1);
                      }}>
                      <Image
                        style={{ width: 20, height: 20 }}
                        source={require("../../assets/images/careplanprevbutton.png")}
                      />
                    </TouchableOpacity>
                    <Text style={[styles.title11, { marginRight: 16.12, width: 40 }]}>
                      {Moment().add(deltaMonth3, "months").format("MMM YY")}'
                    </Text>

                    <TouchableOpacity
                      style={{ marginRight: 20 }}
                      onPress={() => {
                        getchartdata(2, selectedTab, deltaMonth3 + 1);
                      }}>
                      <Image
                        style={{ width: 20, height: 20 }}
                        source={require("../../assets/images/careplannextbutton.png")}
                      />
                    </TouchableOpacity>
                  </View>
                </View>

                <Hairline marginTop={15} />

                <Text style={[styles.title9, { marginTop: 20, marginLeft: 27 }]}>Average Rate</Text>
                <Text style={[styles.title10, { marginTop: 8, marginLeft: 27 }]}>{averageCompliance || "-"}%</Text>

                <Text style={[styles.title9, { marginTop: 24, marginLeft: 27 }]}>Percentage</Text>

                <HospitalAdminOverallComplienceTypeChart data={datachart2}></HospitalAdminOverallComplienceTypeChart>
              </View>

              <View style={[styles.whitebox, { width: 493, height: 420, paddingTop: 17, marginLeft: 32 }]}>
                <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                  {" "}
                  <Text style={[styles.title3, { marginLeft: 17 }]}>Outcome scores</Text>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <TouchableOpacity
                      style={{ marginRight: 16.12 }}
                      onPress={() => {
                        getchartdata(3, selectedTab, deltaMonth4 - 1);
                      }}>
                      <Image
                        style={{ width: 20, height: 20 }}
                        source={require("../../assets/images/careplanprevbutton.png")}
                      />
                    </TouchableOpacity>
                    <Text style={[styles.title11, { marginRight: 16.12, width: 40 }]}>
                      {Moment().add(deltaMonth4, "months").format("MMM YY")}'
                    </Text>

                    <TouchableOpacity
                      style={{ marginRight: 20 }}
                      onPress={() => {
                        getchartdata(3, selectedTab, deltaMonth4 + 1);
                      }}>
                      <Image
                        style={{ width: 20, height: 20 }}
                        source={require("../../assets/images/careplannextbutton.png")}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
                <Hairline marginTop={15} />
                <View style={{ backgroundColor: "#FBFBFB", height: 42, flexDirection: "row", alignContent: "center" }}>
                  <TouchableOpacity
                    onPress={() => {
                      setSelectedTab(1);
                      getchartdata(3, 1, deltaMonth4);
                    }}
                    style={[
                      { marginLeft: 26, width: 27, borderBottomColor: "#0009EC" },
                      selectedTab === 1 && { borderBottomWidth: 2 },
                    ]}>
                    <Text style={[styles.title8]}>OKS</Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    onPress={() => {
                      setSelectedTab(2);
                      getchartdata(3, 2, deltaMonth4);
                    }}
                    style={[
                      { marginLeft: 42, width: 28, borderBottomColor: "#0009EC" },
                      selectedTab === 2 && { borderBottomWidth: 2 },
                    ]}>
                    <Text style={[styles.title8]}>OHS</Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    onPress={() => {
                      setSelectedTab(3);
                      getchartdata(3, 3, deltaMonth4);
                    }}
                    style={[
                      { marginLeft: 42, width: 25, borderBottomColor: "#0009EC" },
                      selectedTab === 3 && { borderBottomWidth: 2 },
                    ]}>
                    <Text style={[styles.title8]}>SSIS</Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    onPress={() => {
                      setSelectedTab(4);
                      getchartdata(3, 4, deltaMonth4);
                    }}
                    style={[
                      { marginLeft: 42, width: 36, borderBottomColor: "#0009EC" },
                      selectedTab === 4 && { borderBottomWidth: 2 },
                    ]}>
                    <Text style={[styles.title8]}>PHQ9</Text>
                  </TouchableOpacity>
                </View>
                <Hairline marginTop={0} />
                <Text style={[styles.title9, { marginTop: 30, marginLeft: 27 }]}>Score (avg.)</Text>

                <OutComeScoresChart yaxis={yaxisoutcomescores[selectedTab - 1]} data={datachart3}></OutComeScoresChart>

                <Text style={[styles.title9, { textAlign: "center" }]}>Patient recovery period</Text>
              </View>
            </View>
          </View>
        </View>
      </MainFrame>
    </View>
  );
});
export default DashboardScreen;

const styles = StyleSheet.create({
  titlegreen: {
    fontFamily: "LatoBold",
    fontSize: 13,
    color: "#6DD803",
  },

  titlered: {
    fontFamily: "LatoBold",
    fontSize: 13,
    color: "#6DD803",
    marginTop: 5,
  },
  title1: {
    fontFamily: "LatoBold",
    fontSize: 42,
    height: 50,
    color: "#1F2E99",
    marginTop: 17,
  },
  whitebox: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    shadowColor: "#818181",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",

    height: "100%",
  },

  title2: {
    fontSize: 13,
    fontFamily: "Lato",
    color: "#616368",
    top: 11,
    textAlign: "center",
    marginTop: 11,
    maxWidth: 147,
  },
  title3: {
    fontSize: 18,
    fontFamily: "LatoBold",
    color: "#1F2E99",
  },

  title4: {
    fontSize: 11,
    fontFamily: "Lato",
    color: "#1F2E99",
  },
  title5: {
    fontSize: 11,
    fontFamily: "Lato",
    color: "#798599",
  },
  title6: {
    fontSize: 13,
    fontFamily: "Lato",
    color: "#B4B7CC",
  },
  title7: {
    fontSize: 15,
    fontFamily: "LatoBold",
    color: "#000000",
  },
  title11: {
    fontSize: 11,
    fontFamily: "Lato",
    color: "#1F2E99",
  },

  title8: {
    fontSize: 13,
    marginTop: 13,
    fontFamily: "Lato",
    color: "#000000",
  },

  title9: {
    fontFamily: "Lato",
    fontSize: 11,
    color: "#9A9A9A",
  },
  title10: {
    fontSize: 15,
    fontFamily: "LatoBold",
    color: "#000000",
    marginLeft: 12,
  },
});
