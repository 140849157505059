import type { iNotification } from "react-notifications-component";
import { Store } from "react-notifications-component";

export interface ToastOptions {
  title?: string;
  message?: string;
  type: iNotification["type"];
  insert?: iNotification["insert"];
  container?: iNotification["container"];
  animationIn?: string[];
  animationOut?: string[];
  dismiss?: {
    duration?: number;
    onScreen?: boolean;
  };
}

export function toast({ dismiss: _dismiss, ...options }: ToastOptions) {
  const dismiss = {
    duration: _dismiss?.duration ?? 5000,
    onScreen: _dismiss?.onScreen ?? true,
  };

  return Store.addNotification({
    container: "top-right",
    dismiss,
    insert: "top",
    animationIn: ["animate__animated", "animate_fadeIn"],
    animationOut: ["animate__animated", "animate_fadeOut"],
    ...options,
  });
}

export function toastSuccess(message: string): ReturnType<typeof toast>;
export function toastSuccess(options: Omit<ToastOptions, "type">): ReturnType<typeof toast>;
export function toastSuccess(param: any) {
  if (typeof param === "string") {
    return toast({ type: "success", message: param });
  } else {
    return toast({ type: "success", ...param });
  }
}

export function toastError(message: string, err?: any): ReturnType<typeof toast>;
export function toastError(options: Omit<ToastOptions, "type">): ReturnType<typeof toast>;
export function toastError(param: any, err?: any) {
  if (typeof param === "string") {
    const message = err?.message ? `${param} (${err.name} - ${err.message})` : param;
    return toast({ type: "danger", message });
  } else {
    return toast({ type: "danger", ...param });
  }
}

export function successMessage(message: string) {
  Store.addNotification({
    title: "Success!",
    message,
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
}

async function errorDetails(response: Response | undefined) {
  let details = "";

  const data = await response?.json();
  details += data?.message ? " " + data?.message : "";
  details += data?.name ? " " + data?.name : "";
  details += data?.errors ? " " + data?.errors : "";

  return details;
}

export async function errorMessage(message: string, response: Response | undefined) {
  let show_message = message + (response?.status ? " (" + response?.status + ")" : "");
  show_message += response?.statusText ? " " + response?.statusText : "";

  // check if response body is json
  if (response?.headers?.get("Content-Type")?.includes("application/json")) {
    show_message += await errorDetails(response);
  }

  Store.addNotification({
    title: "Error!",
    message: show_message,
    type: "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
}
