import React, { useCallback, useEffect } from "react";

import CreateHospitalModal from "../../components/CreateHospital";
import { Avatar } from "../../components/Avatar";

import { withStore } from "../../misc/utils";
import PostopAdminLayout from "../../components/layouts/PostopAdminLayout";
import { css } from "@emotion/css";
import { theme } from "../../misc/constants";
import { Button } from "../../components/Button";
import type { RenderCellCallback } from "../../components/ui/DataTable";
import { DataTable } from "../../components/ui/DataTable";
import type { IHospital } from "../../misc/types";
import { useNav } from "../../hooks/useNav";
import Link from "../../components/ui/Link";
import { SearchInput } from "../../components/ui/Input";

const POAHospitalListScreen = withStore(({ userStore }) => {
  const nav = useNav<"postopAdmin">();

  const [showCreateHospitalModal, setShowCreateHospitalModal] = React.useState(false);
  const [data, setData] = React.useState<any[] | null>(null);
  const [originalData, setOriginalData] = React.useState<any[] | null>(null);

  function filter(text: string) {
    if (text == "") {
      console.log("updatesearch:null");
      setData(originalData);
      return;
    }
    setData(originalData?.filter((item: any) => item.name.toLowerCase().includes(text.toLowerCase())) ?? null);
  }

  async function fetchData() {
    fetch(global.apiurl + "/postopadmins/gethospitals", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
    })
      .then(async (response) => await response.json())
      .then((resData) => {
        console.log(resData);
        if (resData.result) {
          setData(resData.data);
          setOriginalData(resData.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const renderCell = useCallback<RenderCellCallback<IHospital>>((key, item) => {
    switch (key) {
      case "Avatar":
        return (
          <Avatar
            imageUrl={item?.thumburl ?? undefined}
            size={46}
            firstName={item.name}
            lastName={"H"}
            className={css`
              margin: 0 18px 0 24px;
            `}
          />
        );
      case "Name": {
        const parts = [item.name];
        if (item.disabled) parts.push("[DISABLED]");
        if (item.readonly) parts.push("[READONLY]");
        return parts.join(" ");
      }
      case "Phone number":
        return `+${item.gsmcountrycode} ${item.gsm}`;
      case "Location":
        return `${item.address ?? ""} ${item.city ?? ""}`.trim();
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PostopAdminLayout className={`POAHospitals ${styles.root}`}>
      <CreateHospitalModal
        show={showCreateHospitalModal}
        onDismiss={() => {
          setShowCreateHospitalModal(false);
          fetchData();
        }}
      />

      <header className="ContentHeader">
        <div className="POAHospitals-title">
          <h1>List of Hospitals</h1>
          <h2>{data?.length} hospitals</h2>
        </div>
        <Button
          left={
            <img
              src={require("../../assets/images/adduser.png")}
              className={css`
                width: 16.6px;
                height: 13px;
                margin-right: 8.33px;
              `}
            />
          }
          onClick={() => {
            setShowCreateHospitalModal(true);
          }}
          style={{ height: 40 }}>
          Create new hospital
        </Button>
      </header>

      <main className="Content">
        <SearchInput onChangeText={filter} />

        <DataTable<IHospital>
          heads={[["Avatar", ""], "Name", ["email", "Email address"], "Phone number", "Location"]}
          records={data ?? []}
          renderCell={renderCell}
          renderActions={(item) => (
            <ul className={styles.popover}>
              <li>
                <Link url={`/postopadmin/hospital-admins?hospitalId=${item.id}`}>List of Hospital Admins</Link>
              </li>
              <li>
                <Link url={`/postopadmin/hospitals/${item.id}/clinicians`}>List of Clinicians</Link>
              </li>
            </ul>
          )}
          onClickRow={(item) => {
            nav.navigate("HospitalDetailsScreen", {
              hospitalId: item.id,
            });
          }}
          className={css`
            width: 100%;
          `}
        />
      </main>
    </PostopAdminLayout>
  );
});

export default POAHospitalListScreen;

const styles = Object.freeze({
  root: css`
    flex: 1;
    background: white;
    align-items: center;
    min-height: 100vh;

    header.ContentHeader {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .POAHospitals-title {
        color: ${theme.primary};
        h1 {
          font-family: Lato;
          font-size: 24px;
        }
        h2 {
          font-family: Lato;
          font-size: 15px;
        }
      }

      .POAHospitals-invite {
        height: 40px;
      }
    }
  `,
  popover: css`
    li:hover {
      color: ${theme.primary} !important;
    }
  `,
});
