import * as React from "react";
import { Text, View, StyleSheet, Image, TouchableOpacity } from "react-native";
import Menu, { MenuOptions, MenuOption, MenuTrigger, renderers } from "react-native-popup-menu";
const Specialty = {
  ENT: 3,
  "General Surgery": 5,
  "T&O": 6,
  Other: 0,
};
type PatientFilterProps = {
  specialties: number[];
  setSpecialtiesFilter: (p: (s: number[]) => number[]) => void;
  style?: any;
};
export const PatientFilter: React.FC<PatientFilterProps> = (props) => {
  const { Popover } = renderers;
  const toggleSpecialtyFilter = React.useCallback(
    (specialty: number) => {
      props.setSpecialtiesFilter((prevFilter) => {
        const found = prevFilter.find((f) => specialty === f);
        let newFilter = [...prevFilter];
        if (found !== undefined) {
          newFilter = newFilter.filter((f) => f !== specialty);
        } else {
          newFilter.push(specialty);
        }
        return newFilter;
      });
    },
    [props.setSpecialtiesFilter],
  );
  return (
    <View
      style={[
        { marginLeft: 40, marginTop: 8, flexDirection: "row", alignItems: "center" },
        props.style && props.style,
      ]}>
      <View style={{ flexDirection: "row", alignItems: "center", height: 44, marginRight: 11 }}>
        <Image source={require("../assets/images/filtericon.png")} style={styles.filtericon} />

        <Text style={[styles.title3, { marginLeft: 6.67 }]}>Filter by</Text>
      </View>
      {props.specialties.map((specialty) => (
        <TouchableOpacity
          key={specialty}
          onPress={() => {
            toggleSpecialtyFilter(specialty);
          }}
          style={styles.title1}>
          <Text style={[styles.title3]}>
            {Object.keys(Specialty).find((specialtyKey) => Specialty[specialtyKey] === specialty)}
          </Text>
          <Image source={require("../assets/images/filtercloseicon.png")} style={styles.filtercloseicon} />{" "}
        </TouchableOpacity>
      ))}

      <Menu renderer={Popover} rendererProps={{ placement: "bottom" }}>
        <MenuTrigger>
          <Text
            style={[
              styles.title3,
              {
                paddingVertical: 6,
                paddingHorizontal: 11,
                borderRadius: 4,
                backgroundColor: "#FCFCFC",
                border: "0.75px dashed #1F2E99",
              },
            ]}>
            + Add new filter
          </Text>
        </MenuTrigger>
        <MenuOptions customStyles={{ borderRadius: 8 }}>
          {Object.keys(Specialty).map((specialtykey) => (
            <MenuOption
              key={specialtykey}
              text={specialtykey}
              onSelect={() => {
                toggleSpecialtyFilter(Specialty[specialtykey]);
              }}
            />
          ))}
        </MenuOptions>
      </Menu>
    </View>
  );
};

const styles = StyleSheet.create({
  title1: {
    paddingVertical: 6,
    paddingLeft: 12,
    paddingRight: 9.27,
    borderRadius: 4,
    backgroundColor: "#E0E3F7",
    marginRight: 8,
    flexDirection: "row",
    alignItems: "center",
  },
  filtercloseicon: {
    width: 8,
    height: 8,
    marginLeft: 7,
    marginTop: 3,
  },
  title3: {
    fontSize: 13,
    fontFamily: "Lato",
    color: "#1F2E99",
  },
  filtericon: {
    width: 13.33,
    height: 12,
  },
});
