import type { CommonIconProps } from "./icon-types";

export default function SendIcon({ color = "currentColor", size = 18 }: CommonIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.0031 1L8.20312 9.8" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M17 1L11.4 17L8.2 9.8L1 6.6L17 1Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
