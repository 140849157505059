import { useEffect, useReducer } from "react";

export default function useWindowResize() {
  const [, dispatch] = useReducer((counter) => counter+1, 0);
  useEffect(() => {
    window.addEventListener("resize", dispatch);
    return () => {
      window.removeEventListener("resize", dispatch);
    };
  }, []);
}
