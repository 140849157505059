import { css, cx } from "@emotion/css";

import { theme } from "../../misc/constants";
import { Button } from "../Button";
import SaveIcon from "../icons/SaveIcon";
import StarsIcon from "../icons/StarsIcon";
import { POTextNu as POText } from "../POText";
import ReviewStatusDropdown from "../ui/ReviewStatusDropdown";
import useSegmentAPI from "./useSegmentAPI";
import { useWoundVizContext } from "./WoundVizContext";

export interface MagicCutProps {
  /**
   * Whether this image has an embedding. If no embedding is available, naturally the tools won't
   * work yet, so we instead display a message.
   */
  hasEmbedding: boolean;
}

export function MagicCut({ hasEmbedding }: Readonly<MagicCutProps>) {
  const [{ media }] = useWoundVizContext();
  const segmenter = useSegmentAPI();
  const ICON_SIZE = 32;

  return (
    <section className={cx("POWoundVizTools-root", "standard-shadow", styles.root)}>
      <div className={`POWoundVizTools-header ${styles.labelContainer}`}>
        <div className="POWoundVizTools-icon">
          <StarsIcon color="currentColor" size={ICON_SIZE} />
        </div>
        <div className="POWoundVizTools-label">Wound Visualiser PRO</div>
      </div>

      <div className="POWoundVizTools-content">
        {hasEmbedding ? (
          <div className="POWoundVizTools-buttons">
            <Button
              left={<SaveIcon size={22} />}
              onClick={() => {
                segmenter.createSegment();
              }}>
              Save Segment
            </Button>
            {media && <ReviewStatusDropdown media={media} />}
          </div>
        ) : (
          <POText
            className={css`
              margin: 12px;
              padding: 0 4px;
              line-height: 1.5em;
            `}>
            This image has not yet been pre-processed (embedded) by our AI. Please try again later.
          </POText>
        )}
      </div>
    </section>
  );
}

const styles = Object.freeze({
  root: css`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-radius: 8px;
    background: white;
    padding-bottom: 10px;
    overflow: hidden;
    color: ${theme.primary};

    .POWoundVizTools-header {
      height: 56px;
      background: #f8f8f8;
    }

    .POWoundVizTools-icon {
      display: flex;
      flex-shrink: 0;
      width: 32px;
      height: 32px;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }

    .POWoundVizTools-label {
      flex: 1;
      font-size: 18px;
      font-weight: 700;
    }

    .POWoundVizTools-content {
      padding: 12px 16px;
      line-height: 1.5;
    }

    .POWoundVizTools-buttons {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;

      > * {
        flex-basis: calc(50% - 10px);
        min-height: 40px;
        padding: 0 10px;
        margin-bottom: 10px;
      }

      .POButton-root {
        color: ${theme.primary};
        background: #ffffff;
        border: 1px solid #dfe1e6;

        &:hover {
          background: #f8f8f8;
        }
      }

      .PODropdown-value {
        width: 100%;
        font-family: Questrial;
        font-size: 16px;
      }
    }
  `,
  labelContainer: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    padding: 8px 18px;
    color: ${theme.primary};

    svg {
      display: block;
    }

    .POWoundVizTools-root.expanded .POWoundVizTools-body & {
      color: ${theme.secondary};
    }
  `,
});
