import * as React from "react";
import { View, Text, StyleSheet, Image } from "react-native";
import { A } from "@expo/html-elements";

import ResetPassword from "../../components/user/ResetPassword";
import SplitFrame from "../../components/SplitFrame";
import { withStore } from "../../misc/utils";

export const ClinicianResetPassword = withStore(({ userStore }) => {
  return (
    <View style={[styles.container, { backgroundColor: "#E8F0FF" }]}>
      <View
        style={{
          zIndex: 100,
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          top: 32,
          left: 24,
        }}>
        <A href="/login">
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Image
              source={require("../../assets/images/backicon.png")}
              style={{ width: 12.5, height: 8, marginRight: 11.5 }}
            />
            <Text style={{ fontFamily: "Lato", fontSize: 13, color: "#1F2E99" }}>Back </Text>
          </View>
        </A>
      </View>
      <SplitFrame bgcolor={"#E8F0FF"}>
        <ResetPassword bgcolor={"#E8F0FF"} userStore={userStore} />
      </SplitFrame>
    </View>
  );
});

export const HospitalAdminResetPassword = withStore(({ userStore }) => {
  return (
    <View style={styles.container}>
      <View
        style={{
          zIndex: 100,
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          top: 32,
          left: 24,
        }}>
        <A href="/hospitaladmin/login">
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Image
              source={require("../../assets/images/backicon.png")}
              style={{ width: 12.5, height: 8, marginRight: 11.5 }}
            />
            <Text style={{ fontFamily: "Lato", fontSize: 13, color: "#1F2E99" }}>Back </Text>
          </View>
        </A>
      </View>
      <SplitFrame>
        <ResetPassword admin userStore={userStore} />
      </SplitFrame>
    </View>
  );
});

export const PostopAdminResetPassword = withStore(({ userStore }) => {
  return (
    <View style={styles.container}>
      <View
        style={{
          zIndex: 100,
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          top: 32,
          left: 24,
        }}>
        <A href="/postopadmin/login">
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Image
              source={require("../../assets/images/backicon.png")}
              style={{ width: 12.5, height: 8, marginRight: 11.5 }}
            />
            <Text style={{ fontFamily: "Lato", fontSize: 13, color: "#1F2E99" }}>Back </Text>
          </View>
        </A>
      </View>
      <SplitFrame>
        <ResetPassword postopadmin userStore={userStore} />
      </SplitFrame>
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
