import { css, cx } from "@emotion/css";
import { Popover } from "react-tiny-popover";
import { theme } from "../../misc/constants";

export interface PopoverMenuProps {
  children: JSX.Element;
  label: JSX.Element;
  show?: boolean;
  className?: string;
  style?: Partial<CSSStyleDeclaration>;
  onClickOutside?: () => void;
}

export default function PopoverMenu({
  children,
  label,
  show,
  className,
  style,
  onClickOutside,
}: Readonly<PopoverMenuProps>) {
  return (
    <Popover
      isOpen={!!show}
      positions={["bottom", "top"]}
      align="end"
      content={children}
      onClickOutside={onClickOutside}
      padding={8}
      containerClassName={cx("POPopoverMenu-root", "standard-shadow", styles, className)}
      containerStyle={style}
      reposition={false}>
      {label}
    </Popover>
  );
}

const styles = css`
  padding: 8px;
  background: white;
  border: 1px solid ${theme.line};
  border-radius: 10px;
  overflow: hidden;

  ul,
  ol {
    list-style: none;
    padding: 0;
    margin: 0;

    > li {
      margin: 0;
      padding: 4px 8px;
      &:hover {
        color: black;
      }
    }
  }
`;
