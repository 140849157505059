import { forwardRef, useCallback } from "react";

import { css, cx } from "@emotion/css";

import { useLocation } from "../../hooks/useLocation";
import { useNav } from "../../hooks/useNav";
import { theme } from "../../misc/constants";
import { zip } from "../../misc/utils";
import { LoggedInLinking, WelcomeLinking } from "../../navigation/linkingOptions";

import type { MouseEvent, CSSProperties } from "react";
export interface LinkProps {
  as?: string | React.ComponentType<any>;
  url: string;
  title?: string;
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  onClick?: (e: MouseEvent) => void;
}

const Link = forwardRef<HTMLElement, LinkProps>(({ children, url, title, className, onClick, ...props }, ref) => {
  const Component = props.as ?? "a";
  const nav = useNav<any>();
  const loc = useLocation();

  const handleClick = useCallback(
    (e: MouseEvent) => {
      onClick?.(e);
      // attempt to match URL against linking options & call nav.navigate instead to reloading the page
      const match = resolveLink(url);
      if (!e.isDefaultPrevented() && match) {
        e.preventDefault();
        nav.navigate(match[0], match[1]);
      }
    },
    [url],
  );

  return (
    <Component
      ref={ref}
      title={title}
      href={url}
      className={cx("POLink", style, className, loc.startsWith(url) && "active")}
      onClick={handleClick}>
      {children}
    </Component>
  );
});

export default Link;

function resolveLink(url: string): [string, {}] | null {
  let results = Object.entries(WelcomeLinking.config?.screens ?? {})
    .map((linking) => matchLink(url, linking))
    .filter(Boolean) as Array<[string, unknown]>;
  if (results.length === 0) {
    results = Object.entries(LoggedInLinking.config?.screens ?? {})
      .map((linking) => matchLink(url, linking))
      .filter(Boolean) as Array<[string, unknown]>;
  }

  if (results.length > 1) {
    console.debug("Multiple matches found for url, returning longest", url, results);
    results.sort(([, a], [, b]) => Object.entries(b as any).length - Object.entries(a as any).length);
    return results[0] as any;
  }
  return (results[0] as any) ?? null;
}

function matchLink(url: string, [screen, pattern]: [string, unknown]): [string, unknown] | null {
  if (typeof pattern !== "string") {
    console.error("Pattern must be a string", pattern);
    return null;
  }

  const parts = zip(pattern.replace(/^\//, "").split("/"), url.replace(/^\//, "").split("/"));
  const params: any = {};
  for (const [patternPart, urlPart] of parts) {
    if (patternPart?.startsWith(":")) {
      if (urlPart === undefined) return null;
      params[patternPart.slice(1)] = urlPart;
      continue;
    }
    if (patternPart !== urlPart) return null;
  }
  return [screen, params];
}

const style = css`
  text-decoration: none;

  &:link,
  &:visited {
    color: ${theme.primary};
  }

  &:hover,
  &:active,
  &.active {
    color: ${theme.primaryLight};
  }
`;
