import { BarLoader } from "react-spinners";

import { css } from "@emotion/css";

import { CenterContainer } from "../../components/Container";
import ScreenBase from "../../components/ScreenBase";
import WoundVisualizerPro from "../../components/wounds/WoundVisualizerPro";
import useAsyncResource from "../../hooks/useAsyncResource";
import useParams from "../../hooks/useParams";
import { requests } from "../../misc";
import { theme } from "../../misc/constants";
import MediaHeadBar from "../../components/ui/MediaHeadBar";
import { useCallback } from "react";
import { useNav } from "../../hooks/useNav";
import moment from "moment";

export default function WoundAnnotator() {
  const { mediaId } = useParams("postopAdmin", "WoundAnnotator");
  const nav = useNav<"postopAdmin">();

  const { resource: media } = useAsyncResource(
    async () => await requests.get(`/v1/patient-media/${mediaId}`).then((res) => res.data),
    [mediaId],
  );

  const handleClose = useCallback(() => {
    if (nav.canGoBack()) nav.goBack();
    else nav.navigate("PostopAdminMedia");
  }, []);

  return (
    <ScreenBase className={styles.screen}>
      <MediaHeadBar onClose={handleClose}>
        {media ? (
          <>
            <div className="POWoundAnnotator-headbarMeta">
              <span>Photo ID : {media.id}</span>
              <span>Patient ID : {media.patientid}</span>
            </div>
            <div className="POWoundAnnotator-headbarLastUpdate">
              Last annotated:{" "}
              {media.annotated_at ? (
                <>
                  {moment(media.annotated_at).format("DD.MM.YY hh:mm")}
                  {" - by "}
                  {media.annotator.name}
                </>
              ) : (
                "Never"
              )}
            </div>
          </>
        ) : (
          <span className="italic">Loading...</span>
        )}
      </MediaHeadBar>
      {media ? (
        <WoundVisualizerPro media={media} withAnnotations />
      ) : (
        <CenterContainer>
          <BarLoader color={theme.primary} />
        </CenterContainer>
      )}
    </ScreenBase>
  );
}

const styles = Object.freeze({
  screen: css`
    width: 100vw;
    height: 100vh;
    overflow: auto;

    .POContainer-center {
      width: 100%;
      height: 100%;
    }

    .POMediaHeadBar-root {
      padding-right: 25px;
    }

    .POWoundAnnotator-headbarMeta {
      display: flex;
      flex: 1;
      flex-direction: row;
      gap: 25px;
    }

    .POWoundAnnotator-headbarLastUpdate {
      font-size: 13px;
      color: #868686;
    }
  `,
});
