import validation from "./validation";
import validatejs from "validate.js";
import { validateNHSNumber } from "../misc/validation";

export default function validate(fieldName: keyof typeof validation, value: any) {
  validatejs.validators.nhsnumber = function (value, options, key, attributes) {
    if (!validateNHSNumber(value)) return "Invalid NHS number";
  };
  validatejs.validators.checkboxChecked = function (value, options, key, attributes) {
    if (value !== true) {
      // Checkbox is not checked
      return options.message || "must be checked";
    }
  };

  // Validate.js validates your values as an object
  // e.g. var form = {email: 'email@example.com'}
  // Line 8-9 creates an object based on the field name and field value
  const formValues: any = {};
  formValues[fieldName] = value;

  // Line 13-14 creates an temporary form with the validation fields
  // e.g. var formFields = {
  //                        email: {
  //                         presence: {
  //                          message: 'Email is blank'
  //                         }
  //                       }
  const formFields: any = {};
  formFields[fieldName] = validation[fieldName];

  // The formValues and validated against the formFields
  // the variable result hold the error messages of the field
  const result = validatejs(formValues, formFields);

  // If there is an error message, return it!
  if (result) {
    // Return only the field error message if there are multiple
    return result[fieldName][0];
  }

  return null;
}
