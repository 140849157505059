import React from "react";

export interface CheckmarkProps {
  value: boolean;
}
export const Checkmark: React.FC<CheckmarkProps> = ({ value }: CheckmarkProps) => (
  <div className="checkmark">
    {!value && <div style={{ width: 20, height: 20, backgroundColor: "#C4C4C4", borderRadius: 10 }} />}
    {value && (
      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="20.0588" height="20.0588" rx="10.0294" fill="#6DD803" />
        <path
          d="M15.4375 6.5293L8.67401 12.5293L5.4375 9.66007"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )}
  </div>
);
