import React, { useEffect, useMemo, useState } from "react";
import PostopAdminLayout from "../../components/layouts/PostopAdminLayout";
import { requests } from "../../misc";
import { toastError, toastSuccess } from "../../components/Notifications";
import { css } from "@emotion/css";

interface WoundTag {
  id: number;
  label: string;
  color: string;
}

function PostopAdminWoundTags(): JSX.Element {
  const [tags, setTags] = useState<WoundTag[]>([]);
  const [showAdd, setShowAdd] = useState(false);
  const getTags = useMemo(
    () => () => {
      requests.get("/v1/wound-tags").then((res) => {
        setTags(res.data.reverse());
      });
    },
    [],
  );

  useEffect(() => {
    getTags();
  }, []);

  return (
    <PostopAdminLayout active="tags">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}>
        <h1>Wound tags</h1>
        <button
          className={styles.grayButton}
          style={{ height: "30px", margin: "auto 0" }}
          onClick={() => {
            setShowAdd(true);
          }}>
          add tag
        </button>
      </div>
      {tags && tags.length > 0 ? (
        <table className="POAdminResults">
          <thead>
            <tr>
              <th>Id</th>
              <th>label</th>
              <th>color</th>
            </tr>
          </thead>
          <tbody>
            {showAdd && (
              <TagRowEdit
                tag={{ id: 0, label: "", color: "" }}
                onClose={() => {
                  getTags();
                  setShowAdd(false);
                }}
              />
            )}
            {tags.map(
              (p: WoundTag): JSX.Element => (
                <TagRow key={p.id} tag={p} onUpdate={getTags} />
              ),
            )}
          </tbody>
        </table>
      ) : (
        <div
          style={{
            margin: "auto",
            textAlign: "center",
            width: "60%",
            marginTop: 30,
          }}>
          Please enter a few letters or numbers from name or phone of patient.
        </div>
      )}
    </PostopAdminLayout>
  );
}

function TagRow({ tag, onUpdate }: Readonly<{ tag: WoundTag; onUpdate: () => void }>): JSX.Element {
  const [editing, setEditing] = useState(false);
  if (editing)
    return (
      <TagRowEdit
        tag={tag}
        onClose={() => {
          setEditing(false);
          onUpdate();
        }}
      />
    );
  else
    return (
      <tr>
        <td>{tag.id}</td>
        <td>{tag.label}</td>
        <td>
          <div style={{ display: "flex" }}>
            {tag.color}
            <div
              style={{
                background: tag.color,
                width: "20px",
                height: "20px",
                marginLeft: "5px",
              }}></div>
          </div>
        </td>
        <td>
          <button
            className={styles.greenButton}
            onClick={() => {
              setEditing(true);
            }}>
            Edit
          </button>
          <button
            className={styles.redButton}
            style={{ marginLeft: "10px" }}
            onClick={() => {
              if (confirm(`Are you sure you want to delete "${tag.label}" tag?`)) {
                requests
                  .del(`/v1/wound-tags/${tag.id}`)
                  .then(() => {
                    toastSuccess("Tag deleted");
                    onUpdate();
                  })
                  .catch(() => {
                    toastError("Error deleting tag");
                  });
              }
            }}>
            Delete
          </button>
        </td>
      </tr>
    );
}

function TagRowEdit({ tag, onClose }: Readonly<{ tag: WoundTag; onClose: () => void }>): JSX.Element {
  const [label, setLabel] = useState(tag.label);
  const [color, setColor] = useState(tag.color);
  const [saving, setSaving] = useState(false);
  const save = async () => {
    try {
      setSaving(true);
      if (tag.id) await requests.put(`/v1/wound-tags/${tag.id}`, { label, color });
      else await requests.post(`/v1/wound-tags`, { label, color });
      toastSuccess("Tag updated");
    } catch (e) {
      toastError("Error updating tag");
    }
    setSaving(false);
    onClose();
  };
  return (
    <tr>
      <td>{tag.id}</td>
      <td>
        <input
          type="text"
          value={label}
          onChange={(e) => {
            setLabel(e.target.value);
          }}
        />
      </td>
      <td>
        <div style={{ display: "flex" }}>
          <input
            type="text"
            value={color}
            onChange={(e) => {
              setColor(e.target.value);
            }}
          />
          <div
            style={{
              background: color,
              width: "20px",
              height: "20px",
              marginLeft: "5px",
            }}></div>
        </div>
      </td>
      <td>
        <button id="submit" disabled={saving} className={styles.greenButton} onClick={save}>
          save
        </button>
        <button
          disabled={saving}
          className={styles.grayButton}
          style={{ marginLeft: "10px" }}
          onClick={() => {
            onClose();
          }}>
          cancel
        </button>
      </td>
    </tr>
  );
}
const styles = Object.freeze({
  greenButton: css`
    border: none;
    background-color: #4fd854;
    color: white;
    padding: 5px 10px;
    border-radius: 8px;
    text-decoration: none;
    &:hover {
      background-color: #4caf50;
    }
  `,
  grayButton: css`
    background-color: #aeaeae;
    border: none;
    padding: 5px 10px;
    border-radius: 8px;
    text-decoration: none;
    &:hover {
      background-color: #d2cfcf;
    }
  `,
  redButton: css`
    background-color: red;
    border: none;
    padding: 5px 10px;
    text-decoration: none;
    color: white;
    border-radius: 8px;
    &:hover {
      background-color: darkred;
    }
  `,
});
export default PostopAdminWoundTags;
