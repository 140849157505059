import { css, cx } from "@emotion/css";
import * as React from "react";
import { theme } from "../misc/constants";

export interface HairlineProps {
  marginTop?: number;
  marginBottom?: number;
  color?: string;
}

export const Hairline = ({ marginTop, marginBottom, color = theme.line }: Readonly<HairlineProps>) => {
  return (
    <div
      className={cx(
        style,
        css`
          margin-top: ${marginTop}px;
          margin-bottom: ${marginBottom}px;
          background: ${color};
        `,
      )}
    />
  );
};

const style = css`
  height: 1px;
  width: 100%;
`;
