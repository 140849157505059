import Constants from "expo-constants";
import React, { useRef } from "react";
import { Image, StyleSheet, Text, TextInput, TouchableOpacity, View } from "react-native";

import { A } from "@expo/html-elements";

import validate from "../../screens/validate_wrapper";
import { OrangeButton } from "../OrangeButton";
import Login2FA from "./Login2FA";
import { requests } from "../../misc";
import { toastError } from "../Notifications";

export interface LoginProps {
  admin?: boolean;
  postopadmin?: boolean;
  bgcolor?: string;
}

export default function Login(props: LoginProps) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [errorText, setErrorText] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [secure, setSecure] = React.useState(true);
  const [loginVerified, setLoginVerified] = React.useState(false);
  const passwordInputRef = useRef<TextInput>(null);

  const apigateway = props?.admin ? "hospitaladmins" : props?.postopadmin ? "postopadmins" : "clinicians";

  function validateForm() {
    if (isLoading) return false;
    setIsLoading(true);
    setErrorText("");
    const passwordError = validate("passwordlogin", password);
    const emailError = validate("email", email);

    if (!passwordError && !emailError) {
      login();
    } else {
      setIsLoading(false);
      setErrorText((emailError ? "\n" + emailError + "\n" : "") + (passwordError ? "\n" + passwordError + "\n" : ""));
    }
  }

  async function login() {
    try {
      await requests.post(`/${apigateway}/checklogin`, {
        email,
        password,
      });
      setLoginVerified(true);
    } catch (err: any) {
      console.error("Failed to check login:", err);
      toastError("Unable to connect.\nPlease check your connection.");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <View>
      {" "}
      {!loginVerified && (
        <div>
          <View
            style={[
              { width: 350, flexShrink: 0.5, backgroundColor: "#ffffff" },
              { backgroundColor: props.bgcolor ? props.bgcolor : "#ffffff" },
            ]}>
            <Image
              source={require("../../assets/images/logopostop1.1.png")}
              style={{ width: 186.1, height: 61, marginTop: 93 }}
            />
            <Text style={styles.title1}>Login to your Post Op account.</Text>

            <Text style={[styles.title5, { marginTop: 48 }]}>Email address</Text>
            <View style={styles.shadowinput}>
              <TextInput
                style={styles.phoneinput}
                keyboardType="email-address"
                onChangeText={(text) => {
                  setEmail(text.replace(" ", ""));
                }}
                value={email}
                returnKeyType="done"
                onSubmitEditing={() => {
                  passwordInputRef.current.focus();
                }}
              />
            </View>

            <Text style={styles.title5}>Password</Text>
            <View style={styles.shadowinput}>
              <TextInput
                ref={passwordInputRef}
                returnKeyType="done"
                style={styles.passwordinput}
                keyboardType="email-address"
                secureTextEntry={secure}
                onChangeText={(text) => {
                  setPassword(text.trim());
                }}
                onSubmitEditing={() => {
                  validateForm();
                }}
                value={password}
              />

              <TouchableOpacity
                onPress={() => {
                  setSecure(!secure);
                }}>
                <Image source={require("../../assets/images/passwordshow.png")} style={styles.passwordshow} />
              </TouchableOpacity>
            </View>

            <View style={{ flex: 1, marginTop: 18 }}>
              <A href="resetpassword">
                <Text style={styles.title4}>Forgot password?</Text>
              </A>
            </View>

            <View style={{ justifyContent: "flex-end  ", position: "absolute", top: 568, height: 60 }}>
              <Text style={{ color: "#FF6D6D" }}>{errorText}</Text>
            </View>

            <OrangeButton
              disabled={isLoading}
              onPress={() => {
                validateForm();
              }}
              title="Login"
              on
              style={{ marginTop: 93 }}
            />

            <View style={{ flexDirection: "row", marginTop: 16, width: 350, justifyContent: "center" }}>
              <Text style={{ fontFamily: "Lato", fontSize: 15, color: "#000000", marginLeft: 30 }}>
                Haven’t created your account yet?
              </Text>
              <A
                href={
                  props?.admin ? "/hospitaladmin/register" : props?.postopadmin ? "/postopadmin/register" : "/register"
                }>
                <Text
                  style={{
                    fontFamily: "Lato",
                    marginLeft: 4,
                    fontSize: 15,
                    color: "#0009EC",
                    textDecorationLine: "underline",
                  }}>
                  Register
                </Text>
              </A>
            </View>
          </View>
          <div>
            {Constants.expoConfig!.extra!.environment === "LOCAL" && (
              <div style={{ fontSize: "80%", margin: "2em auto", width: "100%", textAlign: "center" }}>
                <a href="/login">Clinician Login</a> | <a href="/hospitaladmin/login">Hospital Login</a> |{" "}
                <a href="/postopadmin/login">Postop Login</a>
              </div>
            )}
          </div>
        </div>
      )}
      {loginVerified && <Login2FA {...props} email={email} password={password} />}
    </View>
  );
}

const styles = StyleSheet.create({
  image: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",
    height: "100%",
  },
  phoneinput: {
    flex: 1,
    outlineWidth: 0,
    color: "#1F2E99",
    fontFamily: "Lato",
    fontSize: 15,
    marginRight: 10,
    marginLeft: 20,
  },
  shadowinput: {
    alignItems: "center",
    marginTop: 15,
    height: 48,
    flexDirection: "row",
    borderRadius: 15,
    shadowColor: "#818181",
    backgroundColor: "#FFFFFF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    elevation: 5,
  },
  title1: {
    fontSize: 32,
    fontFamily: "LatoBold",
    color: "#1F2E99",
    marginTop: 39.6,
    width: 244,
  },
  title4: {
    fontSize: 13,
    lineHeight: 14,
    color: "#0009EC",
    fontFamily: "Lato",
    textDecorationLine: "underline",
  },
  title5: {
    marginTop: 32,
    fontSize: 13,
    color: "#798599",
    fontFamily: "Lato",
  },
  passwordshow: {
    width: 24,
    height: 15,
    position: "relative",
    right: 15,
  },
  passwordinput: {
    width: "100%",
    flex: 1,
    outlineWidth: 0,
    color: "#1F2E99",
    fontFamily: "Lato",
    fontSize: 15,
    marginRight: 22,
    marginLeft: 20,
  },
});
