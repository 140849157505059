import type { CSSProperties } from "react";
import React, { useState } from "react";

import { css, cx } from "@emotion/css";

import { Avatar } from "../components/Avatar";
import { getImageUrl, theme } from "../misc/constants";
import type { IHospital } from "../misc/types";
import Clickable from "./Clickable";
import { EllipsisIcon } from "./icons/EllipsisIcon";
import PopoverMenu from "./ui/PopoverMenu";

export interface HospitalHeaderProps {
  hospital: IHospital;
  className?: string;
  style?: CSSProperties;
}

export const HospitalMeta = ({ hospital, className, style }: Readonly<HospitalHeaderProps>) => {
  const [showPopoverMenu, setShowPopoverMenu] = useState(false);

  async function setReadonly(readonly: boolean) {
    fetch(global.apiurl + "/postopadmins/setreadonly", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
      body: JSON.stringify({
        hospitalid: hospital.id,
        readonly: Number(readonly),
      }),
    })
      .then(async (response) => await response.json())
      .then((resData) => {
        console.log(resData);
        if (resData.result) {
          if (Number(readonly) === 1) alert("Hospital is set as read-only.");
          if (Number(readonly) === 0) alert("Hospital is set as fully operational.");
          window.location.reload();
        } else {
          alert("Please try again later.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function setDisabled(disabled: boolean) {
    fetch(global.apiurl + "/postopadmins/sethospitaldisabled", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
      body: JSON.stringify({
        hospitalid: hospital.id,
        disabled: Number(disabled),
      }),
    })
      .then(async (response) => await response.json())
      .then((resData) => {
        console.log(resData);
        if (resData.result) {
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (!hospital) return null;

  const hospitalNameParts = [hospital.name];
  if (hospital.disabled) hospitalNameParts.push("[DISABLED]");
  if (hospital.readonly) hospitalNameParts.push("[READONLY]");
  const hospitalName = hospitalNameParts.join(" ").trim();

  return (
    <div className={cx("POHospitalMeta", styles.root, className)} style={style}>
      <div className="POHospitalMeta-name flex-2">
        <Avatar
          imageUrl={hospital.thumburl ? getImageUrl(hospital.thumburl) : undefined}
          size={56}
          firstName={hospital.name}
          lastName="H"
        />
        {hospitalName}
      </div>

      <div className="POHospitalMeta-item flex-1">
        <div className="POHospitalMeta-itemLabel">Email address</div>
        <div className="POHospitalMeta-itemValue">{hospital.email}</div>
      </div>

      <div className="POHospitalMeta-item flex-1">
        <div className="POHospitalMeta-itemLabel">Phone number</div>
        <div className="POHospitalMeta-itemValue">
          (+{hospital.gsmcountrycode}) {hospital.gsm}
        </div>
      </div>

      <div className="POHospitalMeta-item flex-1">
        <div className="POHospitalMeta-itemLabel">Location</div>
        <div className="POHospitalMeta-itemValue">
          {hospital.address} {hospital.city}
        </div>
      </div>

      <PopoverMenu
        show={showPopoverMenu}
        label={
          <Clickable
            onClick={() => {
              setShowPopoverMenu(true);
            }}
            className={styles.popoverButton}>
            <EllipsisIcon vertical size={16} />
          </Clickable>
        }
        onClickOutside={() => {
          setShowPopoverMenu(false);
        }}>
        <ul>
          <li>
            <Clickable
              onClick={() => {
                const prompt = hospital.readonly
                  ? "Are you sure you want to relieve this hospital from read-only access?"
                  : "Are you sure you want to restrict this hospital to read-only access?";
                if (confirm(prompt)) {
                  setReadonly(!hospital.readonly);
                }
              }}>
              {hospital.readonly ? "Relieve from Read Only Access" : "Restrict to Read Only Access"}
            </Clickable>
          </li>
          <li>
            <Clickable
              onClick={() => {
                const prompt = hospital.disabled
                  ? "Are you sure you want to enable this hospital? Patients and clinicians will be allowed into the system."
                  : "Are you sure you want to disable this hospital? Patients and clinicians will be denied access to the system.";
                if (confirm(prompt)) {
                  setDisabled(!hospital.disabled);
                }
              }}>
              {hospital.disabled ? "Enable Hospital" : "Disable Hospital"}
            </Clickable>
          </li>
        </ul>
      </PopoverMenu>
    </div>
  );
};

const styles = Object.freeze({
  root: css`
    display: flex;
    flex-direction: row;

    .POHospitalMeta-name {
      display: flex;
      flex: 2;
      flex-direction: row;
      align-items: center;
      gap: 12px;
      font-size: 18px;
      font-weight: bold;
      color: ${theme.primary};
    }

    .POHospitalMeta-item {
      flex: 1;

      .POHospitalMeta-itemLabel {
        font-family: Lato;
        font-size: 13px;
        color: #868686;
        margin-bottom: 8px;
      }
      .POHospitalMeta-itemValue {
        font-family: Lato;
        font-size: 15px;
        color: ${theme.primary};
      }
    }

    .POClickable-root {
      align-self: center;
    }
  `,
  popoverButton: css`
    display: flex;
    width: 35px;
    height: 35px;
    align-items: center;
    justify-content: center;
    background: white;
    border: 1px solid ${theme.primary};
    border-radius: 8px;
  `,
});
