import * as React from "react";
import { TextInput, Text, View, StyleSheet, Dimensions, Image, TouchableOpacity } from "react-native";
import CountrySelector from "../components/CountrySelector";
import { Picker } from "@react-native-picker/picker";

import { OrangeButton } from "./OrangeButton";
import { Hairline } from "./Hairline";

export const EditClinician = (props) => {
  const [firstname, setFirstname] = React.useState(props.clinician.firstname);
  const [lastname, setLastname] = React.useState(props.clinician.lastname);
  const [email, setEmail] = React.useState(props.clinician.email);
  const [gsm, setGsm] = React.useState(props.clinician.gsm);
  const [gsmcountry, setGsmcountry] = React.useState(props.clinician.gsmcountry);
  const [countrycode, setCountrycode] = React.useState(props.clinician.countrycode);
  const [registrationnumber, setRegistrationnumber] = React.useState(props.clinician.registrationnumber);

  const [cliniciantypes, setCliniciantypes] = React.useState();
  const [cliniciantypeid, setCliniciantypeid] = React.useState(props.clinician?.cliniciantype?.id);

  const titleCase = (str) => {
    return str.replace(/\w\S*/g, (t) => {
      return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase();
    });
  };

  function removeView() {
    props.removeView();
  }

  function sendform() {
    fetch(global.apiurl + "/hospitaladmins/updateclinician", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
      body: JSON.stringify({
        clinicianid: props.clinician.id,
        firstname,
        lastname,
        email,
        gsmcountry,
        countrycode,
        gsm,
        registrationnumber,
        cliniciantype_id: cliniciantypeid,
      }),
    })
      .then(async (res) => {
        if (res.status === 422) {
          throw new Error("Request failed.");
        }
        if (res.status !== 200 && res.status !== 201) {
          throw new Error("Could not authenticate you!");
        } else {
          return await res.json();
        }
      })
      .then((resData) => {
        if (resData.result) {
          props.onFinish();
        } else {
          alert("Unable to update, please try later");
        }
      })
      .catch((err) => {
        throw new Error("Unable to update, please try later: " + err.message);
      });
  }

  React.useEffect(() => {
    async function fetchData() {
      fetch(global.apiurl + "/hospitaladmins/getcliniciantypes", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: global.tokenparam || "",
        },
      })
        .then(async (response) => await response.json())
        .then((resData) => {
          console.log(resData);
          if (resData.result) {
            setCliniciantypes(resData.data);
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Please check your connection.");
        });
    }

    fetchData();
  }, []);

  const dimensions = Dimensions.get("window");
  const modalmargin = (dimensions.height - 702) / 2;

  return (
    <View style={[{ backgroundColor: "rgba(52, 52, 52, 0.8)", paddingTop: modalmargin, paddingBottom: modalmargin }]}>
      <View
        style={{
          borderRadius: 8,
          backgroundColor: "#ffffff",
          width: 460,
          height: 702,
          marginBottom: 1000,
          alignSelf: "center",
        }}>
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            borderBottomColor: "#E8E9ED",
            borderBottomWidth: 1,
            height: 70,
          }}>
          <Text style={styles.title1}>Update clinician details</Text>
          <TouchableOpacity
            style={{ position: "absolute", right: 0 }}
            onPress={() => {
              removeView();
            }}>
            <View
              style={{
                backgroundColor: "#FCF5EF",
                width: 93,
                height: 70,
                flexDirection: "row",
                borderColor: "#E8E9ED",
                borderWidth: 1,
                borderTopRightRadius: 8,
                alignItems: "center",
              }}>
              <Image
                source={require("../assets/images/closecrossicon.png")}
                style={{ width: 10, height: 10, marginRight: 8, marginLeft: 23 }}
              />
              <Text style={{ fontFamily: "Lato", fontSize: 13, color: "#1F2E99" }}>Close</Text>
            </View>
          </TouchableOpacity>
        </View>

        {/* ===================== */}

        <View style={{ flexDirection: "row" }}>
          <View style={{ width: 652 }}>
            <View style={{}}>
              <Text style={styles.title2}>Personal details</Text>

              <View style={{ flexDirection: "column", marginTop: 24, paddingLeft: 24 }}>
                {/* First name */}
                <View style={{ width: 250 }}>
                  <Text style={styles.title3}>First name</Text>
                  <View style={[styles.shadowinput, { width: 250 }]}>
                    <TextInput
                      style={[styles.postopinput, { textAlign: "left", width: 250 }]}
                      keyboardType="name-phone-pad"
                      maxLength={50}
                      numberOfLines={1}
                      onChangeText={(text) => {
                        setFirstname(titleCase(text));
                      }}
                      value={firstname}
                      returnKeyType="done"
                    />
                  </View>
                </View>

                {/* Last name */}
                <View style={{ width: 250 }}>
                  <Text style={styles.title3}>Last name</Text>
                  <View style={[styles.shadowinput, { width: 250 }]}>
                    <TextInput
                      style={[styles.postopinput, { textAlign: "left", width: 250 }]}
                      keyboardType="name-phone-pad"
                      maxLength={50}
                      numberOfLines={1}
                      onChangeText={(text) => {
                        setLastname(titleCase(text));
                      }}
                      value={lastname}
                      returnKeyType="done"
                    />
                  </View>
                </View>

                {/* Phone */}
                <View style={{ width: 250 }}>
                  <Text style={[styles.title3]}>Phone number</Text>
                  <View style={[styles.shadowinput]}>
                    <View style={{ width: 1, height: 48, backgroundColor: "#DFE1E6" }}></View>
                    <CountrySelector
                      countrycode={countrycode}
                      gsmcountrycode={gsmcountry}
                      setGsmcountrycode={(value) => {
                        setGsmcountry(value);
                      }}
                      setCountrycode={(value) => {
                        setCountrycode(value);
                      }}
                    />

                    <TextInput
                      keyboardType={"number-pad"}
                      onChangeText={(text) => {
                        setGsm(text);
                      }}
                      value={gsm}
                      maxLength={15}
                      style={{ color: "#1F2E99", fontFamily: "Lato", fontSize: 15, marginRight: 10, marginTop: 1 }}
                    />
                  </View>
                </View>

                {/* Email */}
                <View style={{ width: 250 }}>
                  <Text style={[styles.title3]}>Work email address</Text>

                  <View style={[styles.shadowinput, { width: 250 }]}>
                    <TextInput
                      style={[styles.postopinput, { textAlign: "left", width: 250 }]}
                      keyboardType="email-address"
                      maxLength={50}
                      numberOfLines={1}
                      onChangeText={(text) => {
                        setEmail(text || null);
                      }}
                      value={email}
                      returnKeyType="done"
                    />
                  </View>
                </View>

                {/* <Hairline marginTop={32} /> */}
                <Text style={styles.title2}>Clinical details</Text>

                {/* Role */}
                <View style={{ width: 250 }}>
                  <Text style={[styles.title3]}>Role</Text>

                  <View
                    style={[styles.shadowinput, { zIndex: 100, width: 250, alignItems: "center", textAlign: "left" }]}>
                    <Picker
                      // style={{"-webkit-appearance":"none", "text-align":"center","text-align-last":"center","-moz-text-align-last":"center",width:76,height:24,borderRadius:20,fontFamily:"LatoBold",fontSize:13,color:"#ffffff",backgroundColor:"#6DD803",alignItems:"center",borderWidth:0}}
                      style={[styles.select]}
                      selectedValue={cliniciantypeid}
                      onValueChange={(itemValue, itemIndex) => {
                        setCliniciantypeid(itemValue);
                      }}>
                      {cliniciantypes?.map((clinicaltype) => (
                        <Picker.Item label={clinicaltype.title} value={clinicaltype.id} />
                      ))}
                    </Picker>
                  </View>
                </View>

                {/* Registration number */}
                <View style={{ width: 250 }}>
                  <Text style={[styles.title3]}>Registration number (optional)</Text>

                  <View style={{ flexDirection: "row" }}>
                    <View style={[styles.shadowinput, { width: 250 }]}>
                      <TextInput
                        style={styles.postopinput}
                        keyboardType="number-pad"
                        maxLength={10}
                        onChangeText={(text) => {
                          setRegistrationnumber(text);
                        }}
                        value={registrationnumber}
                        returnKeyType="done"
                      />
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>

        {/* ===================== */}

        <View style={{ position: "absolute", bottom: 16, alignItems: "center", width: 460 }}>
          <Hairline />

          <OrangeButton
            onPress={() => {
              sendform();
            }}
            title="Update Clinician"
            style={{ width: 250, marginTop: 16 }}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  select: {
    marginLeft: 20,
    //  "text-align":"center","text-align-last":"center",
    "-moz-appearance": "none",
    "-webkit-appearance": "none",
    appearance: "none",
    backgroundImage:
      "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAVCAYAAAAuJkyQAAAAmUlEQVR42r3OwQmAQAxE0S3JTrUDvairl6ANBG1MySFeFrz8kIGBZRnIK55ZtLOWnPzf3c67r8f1WO2dhannPTZ3TeefXhtmYrxm+UAARTAtyLLKNTEUxyyig28gimNW0akZcxTHAFQCBqAwJgEFMACFMRkojuEojolHcUw8CmLiUQCTgAIYhAKYJBTH8FTRAWLis4t21hKQF5HBtPSQyO2PAAAAAElFTkSuQmCC)",
    backgroundPosition: "87%",
    backgroundSize: "12px 7px",
    "background-repeat": "no-repeat",
    borderWidth: 0,
    width: 250,
    height: 24,
    borderRadius: 20,
    fontFamily: "Lato",
    fontSize: 15,
    color: "#1F2E99",
    textAlign: "left",
  },

  menuitemwait: {
    fontFamily: "Lato",
    fontSize: 15,
    color: "#959DAD",
    marginTop: 27,
    height: 18,
  },
  menuitemdone: {
    fontFamily: "Lato",
    fontSize: 15,
    color: "#1F2E99",
    marginTop: 27,
    height: 18,
  },
  menuitemactive: {
    fontFamily: "LatoBold",
    fontSize: 15,
    color: "#1F2E99",
    marginTop: 27,
    height: 18,
  },

  postopinput: {
    flex: 1,
    outlineWidth: 0,
    color: "#1F2E99",
    fontFamily: "Lato",
    fontSize: 15,
    marginRight: 10,
    marginLeft: 20,
  },
  countryflag: {
    width: 26,
    height: 18,
    marginHorizontal: 16,
    marginVertical: 19,
  },
  shadowinput: {
    marginTop: 8,
    height: 48,
    flexDirection: "row",
    borderRadius: 15,
    shadowColor: "#818181",
    backgroundColor: "#FFFFFF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
  title1: {
    fontSize: 18,
    fontFamily: "LatoBold",
    color: "#1F2E99",
    marginTop: 26,
    marginLeft: 24,
  },

  title2: {
    paddingLeft: 24,
    fontSize: 15,
    fontFamily: "LatoBold",
    color: "#1F2E99",
    marginTop: 24,
  },

  calendaricon: {
    width: 18,
    height: 18,
  },

  title3: {
    fontSize: 13,
    fontFamily: "Lato",
    color: "#798599",
  },
});
