import type { CommonIconProps } from "./icon-types";

export interface EllipsisIconProps extends CommonIconProps {
  vertical?: boolean;
}

export function EllipsisIcon({ vertical, ...props }: Readonly<EllipsisIconProps>) {
  if (vertical) {
    return <EllipsisVerticalIcon {...props} />;
  } else {
    return <EllipsisHorizontalIcon {...props} />;
  }
}

function EllipsisVerticalIcon({ size = 20, color = "currentColor", className }: Readonly<CommonIconProps>) {
  return (
    <svg
      className={className}
      width={size / 5}
      height={size}
      viewBox="0 0 4 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 2C0 0.89543 0.89543 0 2 0C3.10457 0 4 0.89543 4 2C4 3.10457 3.10457 4 2 4C0.89543 4 0 3.10457 0 2Z"
        id="Oval-Copy-3"
        fill={color}
        fillRule="evenodd"
        stroke="none"
      />
      <path
        d="M0 10C0 8.89543 0.89543 8 2 8C3.10457 8 4 8.89543 4 10C4 11.1046 3.10457 12 2 12C0.89543 12 0 11.1046 0 10Z"
        id="Oval-Copy-4"
        fill={color}
        fillRule="evenodd"
        stroke="none"
      />
      <path
        d="M0 18C0 16.8954 0.89543 16 2 16C3.10457 16 4 16.8954 4 18C4 19.1046 3.10457 20 2 20C0.89543 20 0 19.1046 0 18Z"
        id="Oval-Copy-5"
        fill={color}
        fillRule="evenodd"
        stroke="none"
      />
    </svg>
  );
}

function EllipsisHorizontalIcon({ size = 20, color = "currentColor", className }: Readonly<CommonIconProps>) {
  return (
    <svg
      className={className}
      width={size}
      height={size / 5}
      viewBox="0 0 20 4"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 2C0 0.89543 0.89543 0 2 0C3.10457 0 4 0.89543 4 2C4 3.10457 3.10457 4 2 4C0.89543 4 0 3.10457 0 2Z"
        id="Oval"
        fill={color}
        fillRule="evenodd"
        stroke="none"
      />
      <path
        d="M8 2C8 0.89543 8.89543 0 10 0C11.1046 0 12 0.89543 12 2C12 3.10457 11.1046 4 10 4C8.89543 4 8 3.10457 8 2Z"
        id="Oval-Copy"
        fill={color}
        fillRule="evenodd"
        stroke="none"
      />
      <path
        d="M16 2C16 0.89543 16.8954 0 18 0C19.1046 0 20 0.89543 20 2C20 3.10457 19.1046 4 18 4C16.8954 4 16 3.10457 16 2Z"
        id="Oval-Copy-2"
        fill={color}
        fillRule="evenodd"
        stroke="none"
      />
    </svg>
  );
}
