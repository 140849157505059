import { TouchableOpacity, View, Text, StyleSheet, FlatList } from "react-native";
import React, { useEffect } from "react";
import { Hairline } from "../../components/Hairline";
import { Avatar } from "../../components/Avatar";

import { inject, observer } from "mobx-react";
import { MainFrameHospitalAdmin } from "../../components/MainFrameHospitalAdmin";
// TODO: page seems deprecated
const HospitalAdminDetails = inject("userStore")(
  observer(({ userStore, route }) => {
    const { hospitaladminid } = route.params;

    const [data, setData] = React.useState(null);
    const [hospitaladmin, setHospitaladmin] = React.useState(null);

    async function fetchData() {
      fetch(global.apiurl + "/postopadmins/gethospitaladmindetails", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: global.tokenparam || "",
        },

        body: JSON.stringify({
          hospitaladminid,
        }),
      })
        .then(async (response) => await response.json())
        .then((resData) => {
          console.log(resData);
          if (resData.result) {
            setData(resData.data);
            setHospitaladmin(resData.hospitaladmin);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    useEffect(() => {
      fetchData();
    }, []);

    return (
      <View style={styles.container}>
        <MainFrameHospitalAdmin
          hospitaladmin={hospitaladmin}
          postopadmin
          hospitaladmins
          userStore={userStore}
          selected={1}
          patientlist
          style={{}}>
          <View style={[styles.whitebox, { alignItems: "flex-start", width: 1131, marginBottom: 94 }]}>
            <View style={{ flexDirection: "row", alignItems: "center", marginLeft: 25, marginTop: 24 }}>
              <Text style={[styles.title1]}>Assigned hospital </Text>
            </View>
            <Hairline marginTop={13} />
            <View style={{ flex: 1, width: "100%", paddingHorizontal: 24 }}>
              <View style={{ flexDirection: "row", width: "100%", justifyContent: "space-between", marginTop: 24.5 }}>
                <TouchableOpacity style={{ flex: 0.3 }}>
                  <Text style={[styles.title3, { marginLeft: 24 }]}>Name</Text>
                </TouchableOpacity>
                <TouchableOpacity style={{ flex: 0.177 }}>
                  {" "}
                  <Text style={[styles.title3]}>Email address</Text>
                </TouchableOpacity>
                <TouchableOpacity style={{ flex: 0.277 }}>
                  <Text style={[styles.title3]}>Phone number</Text>
                </TouchableOpacity>
                <TouchableOpacity style={{ flex: 0.08 }}></TouchableOpacity>
              </View>
              <Hairline marginTop={15.5} />
              <FlatList
                scrollEnabled={false}
                xextraData={data}
                style={{ width: "100%" }}
                data={data}
                keyExtractor={(item) => item.id}
                renderItem={({ item, index, separators }) => (
                  <View
                    style={{
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                      backgroundColor: "#ffffff",
                      height: 78,
                      alignItems: "center",
                      borderRadius: 6,
                      borderBottomColor: "#E8E9ED",
                      borderBottomWidth: StyleSheet.hairlineWidth,
                    }}>
                    <View style={{ flex: 0.3, flexDirection: "row", alignItems: "center" }}>
                      <Avatar
                        url={item?.thumburl && global.imagepathurl + encodeURI(item?.thumburl)}
                        size={46}
                        radius={23}
                        firstname={item.name}
                        lastname={"H"}
                        style={{ marginLeft: 24, borderWidth: 1, marginRight: 18 }}
                      />
                      <Text style={[styles.title4]}>{item.name}</Text>
                    </View>
                    <Text style={[styles.title4, { flex: 0.177 }]}>{item?.email}</Text>
                    <Text style={[styles.title4, { flex: 0.277 }]}>
                      +{item?.gsmcountrycode} {item?.gsm}
                    </Text>
                    <View style={{ flex: 0.08, flexDirection: "row", alignItems: "center" }}></View>
                  </View>
                )}
              />
            </View>
          </View>
        </MainFrameHospitalAdmin>
      </View>
    );
  }),
);

export default HospitalAdminDetails;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
  },
  title1: {
    fontSize: 18,
    fontFamily: "Lato",
    color: "#1F2E99",
  },
  title2: {
    width: "84%",
    fontSize: 20,
    fontFamily: "Lato",
    color: "#1F2E99",
    position: "absolute",
    top: "55%",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },

  searchicon: {
    marginTop: 2,
    width: 12.33,
    height: 13.33,
  },
  postopinput: {
    flex: 1,
    outlineWidth: 0,
    color: "#1F2E99",
    fontFamily: "Lato",
    fontSize: 15,
    marginRight: 10,
    marginLeft: 20,
  },
  shadowinput: {
    height: 44,
    flexDirection: "row",
    borderRadius: 15,
    shadowColor: "#818181",
    backgroundColor: "#FFFFFF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
  whitebox: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    shadowColor: "#818181",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
  threedots: {
    width: 16,
    height: 3.66,
  },
});
