import { css, cx } from "@emotion/css";
import type { CSSProperties, ComponentType, ReactNode } from "react";
import React, { useMemo } from "react";
import type { StyleProp, TextStyle } from "react-native";
import { Text } from "react-native";

export type POTextProps = {
  children?: ReactNode;
  flex?: number;
  fontFamily?: string;
  fontSize?: number;
  fontWeight?: "normal" | "bold" | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
  lineHeight?: number;
  textAlign?: TextStyle["textAlign"];
  textAlignVertical?: TextStyle["textAlignVertical"];
  color?: string;
  italic?: boolean;
  className?: string;
  style?: StyleProp<TextStyle>;
};

export type POTextNuProps = Omit<POTextProps, "style"> & {
  as?: string | ComponentType<{ children?: ReactNode; className: string; style?: CSSProperties }>;
  className?: string;
  style?: CSSProperties;
};

export function POText({
  flex,
  fontFamily = "Lato",
  fontSize,
  fontWeight,
  lineHeight,
  textAlign,
  textAlignVertical,
  color,
  italic,
  ...props
}: POTextProps): JSX.Element {
  const style = useMemo<TextStyle>(
    () => ({
      flex,
      fontFamily,
      fontSize,
      fontWeight: (fontWeight + "") as any,
      lineHeight,
      textAlign,
      textAlignVertical,
      color,
      fontStyle: italic ? "italic" : undefined,
    }),
    [flex, fontFamily, fontSize, fontWeight, lineHeight, textAlign, textAlignVertical, color],
  );

  // @ts-expect-error
  return <Text {...props} style={[style, props.style]} />;
}

export function POTextNu({
  as: Component = "span",
  flex,
  fontFamily = "Lato",
  fontSize,
  fontWeight,
  lineHeight,
  textAlign,
  textAlignVertical,
  color,
  italic,
  className,
  ...props
}: POTextNuProps) {
  const style = useMemo(
    () =>
      css({
        flex,
        fontFamily,
        fontSize,
        fontWeight: (fontWeight + "") as any,
        lineHeight,
        textAlign: textAlign === "auto" ? "inherit" : textAlign,
        textAlignVertical,
        color,
        fontStyle: italic ? "italic" : undefined,
      }),
    [flex, fontFamily, fontSize, fontWeight, lineHeight, textAlign, textAlignVertical, color],
  );

  return <Component {...props} className={cx("POText", style, className)} />;
}

export const POTitle = bakeText({
  fontFamily: "LatoBold",
  fontSize: 18,
  color: "#1F2E99",
});

export function bakeText(_props: POTextProps) {
  return (props: POTextProps) => <POText {..._props} {...props} className={cx(_props.className, props.className)} />;
}
