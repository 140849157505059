import React, { useRef } from "react";
import { TextInput, View, Text, StyleSheet, Image, TouchableOpacity } from "react-native";

import { OrangeButton } from "../OrangeButton";
import validate from "../../screens/validate_wrapper";
import { PasswordStrengthMeter } from "../PasswordStrengthMeter";
import { ValidCheckmark } from "../ValidCheckmark";
import { useNavigation } from "@react-navigation/native";
import { A } from "@expo/html-elements";
import type { UserStore } from "../../mobx/store";

const Temp = (props) => {
  const { userStore, route } = props as { userStore: UserStore; route: any };
  const navigation = useNavigation();

  const [isLoading, setIsLoading] = React.useState(false);

  const [newpassword, setNewpassword] = React.useState("");
  const [newpassword2, setNewpassword2] = React.useState("");
  const [secure, setSecure] = React.useState(true);
  const [secure2, setSecure2] = React.useState(true);
  const { email, temppassword } = route.params;
  const [passwordstrength, setPasswordstrength] = React.useState(0);
  const passwordInputRef = useRef<TextInput>(null);

  const apigateway = props.admin ? "hospitaladmins" : props.postopadmin ? "postopadmins" : "clinicians";

  function validateForm() {
    setIsLoading(true);
    console.log("validateForm::firstpassword");
    const passwordError = validate("password", newpassword);
    let password2Error = null;
    const passwordStrengthError = null;

    console.log(validate("password", newpassword));
    const passwordMixedError = validate("passwordmixed", newpassword);

    if (newpassword != newpassword2) {
      password2Error = "Password's don't match.";
    }

    if (!passwordError && !password2Error && !passwordMixedError) {
      console.log("no error setting password");
      console.log(passwordError);
      setfirstpassword();
      setIsLoading(false);
    } else {
      setIsLoading(false);
      alert(
        "\n" +
          (passwordMixedError ? "\n" + passwordMixedError + "\n" : "") +
          (passwordError ? "\n" + passwordError + "\n" : "") +
          (password2Error ? "\n" + password2Error + "\n" : "") +
          "\n",
      );
    }
  }

  function setfirstpassword() {
    console.log("setting password");

    fetch(global.apiurl + "/" + apigateway + "/setfirstpassword", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        temppassword,
        newpassword,
      }),
    })
      .then(async (res) => {
        setIsLoading(false);

        if (res.status === 422) {
          throw new Error("Request failed.");
        }
        if (res.status !== 200 && res.status !== 201) {
          console.log("Error!");
          throw new Error("Could not authenticate you!");
        }
        return await res.json();
      })
      .then(async (resData) => {
        console.log(resData);
        if (resData.result) {
          console.log("DONE");
          console.log("****", JSON.stringify(resData));
          await userStore.setFullLoggedinIsAdmin(
            resData.token,
            resData.photourl,
            resData.firstname,
            resData.lastname,
            // usertype in backend has difrent values , fix this !!!
            resData.usertype - 1,
            resData.readonly,
          );
          //  navigation.replace('RegisterUpdateDetailsScreen');
          if (props?.admin) {
            location.href = "/hospitaladmin/registerprofile";
          } else {
            if (props?.postopadmin) {
              location.href = "/postopadmin/registerprofile";
            } else {
              location.href = "/registerprofile";
            }
          }
        } else {
          alert("Unable change password\n\nPlease check your password");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <View
      style={[
        { width: 350, flexShrink: 0.5, backgroundColor: "#ffffff" },
        { backgroundColor: props.bgcolor ? props.bgcolor : "#ffffff" },
      ]}>
      <Image
        source={require("../../assets/images/logopostop1.1.png")}
        style={{ width: 186.1, height: 61, marginTop: 93 }}
      />
      <Text style={styles.title1}>
        Account created!
        <br />
        Now, set a new password <br />
        for your account.
      </Text>

      <Text style={styles.title5}>Set new password </Text>
      <View style={styles.shadowinput}>
        <TextInput
          returnKeyType="done"
          style={styles.passwordinput}
          keyboardType="email-address"
          secureTextEntry={secure}
          onChangeText={(text) => {
            setNewpassword(text.trim());
          }}
          value={newpassword}
          onSubmitEditing={() => {
            passwordInputRef.current.focus();
          }}
        />

        <TouchableOpacity
          onPress={() => {
            setSecure(!secure);
          }}>
          <Image source={require("../../assets/images/passwordshow.png")} style={styles.passwordshow} />
        </TouchableOpacity>
      </View>

      <PasswordStrengthMeter
        style={{ marginTop: 11 }}
        pw={newpassword}
        setLabel={(x) => {
          console.log(x);
        }}
        setStrenght={(x) => {
          setPasswordstrength(x);
          console.log("pw strength:" + x);
        }}
      />

      <View style={{ flexDirection: "row", marginTop: 22 }}>
        <ValidCheckmark valid={newpassword?.length > 11} />
        <Text style={styles.title7}>Minimum of 12 characters</Text>
      </View>
      <View style={{ flexDirection: "row", marginTop: 8 }}>
        <ValidCheckmark valid={!validate("passwordmixed", newpassword)} />
        <Text style={styles.title7}>Must include capital, lowercase, number and a special character</Text>
      </View>

      <Text style={styles.title5}>Repeat new password</Text>
      <View style={styles.shadowinput}>
        <TextInput
          ref={passwordInputRef}
          returnKeyType="done"
          style={styles.passwordinput}
          keyboardType="email-address"
          secureTextEntry={secure2}
          onChangeText={(text) => {
            setNewpassword2(text.trim());
          }}
          value={newpassword2}
          onSubmitEditing={() => {
            validateForm();
          }}
        />

        <TouchableOpacity
          onPress={() => {
            setSecure2(!secure2);
          }}>
          <Image source={require("../../assets/images/passwordshow.png")} style={styles.passwordshow} />
        </TouchableOpacity>
      </View>

      <OrangeButton
        disabled={isLoading}
        onPress={() => {
          validateForm();
        }}
        title="Create Account"
        on
        style={{ marginTop: 59 }}
      />

      <A
        href=" https://postop.ai/password-policy/"
        target="_black"
        style={{ marginTop: 11, paddingBottom: 30, alignSelf: "center", width: "100%" }}>
        <View
          style={[
            styles.shadowbutton,
            { backgroundColor: "white", borderColor: "#DFE1E6", borderWidth: 1, borderRadius: 10, width: "100%" },
          ]}
          onPress={() => navigation.push("BrowserScreen", { url: "https://postop.ai/password-policy/" })}>
          <View
            style={{
              flexDirection: "row",
              backgroundColor: "transparent",
              alignContent: "center",
              height: 38,
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Image source={require("../../assets/images/passwordpolicyshield.png")} style={{ width: 18, height: 22 }} />
            <Text style={[{ color: "#1F2E99", fontFamily: "Questrial", fontSize: 18, marginLeft: 17 }]}>
              Read our password policy
            </Text>
          </View>
        </View>
      </A>
    </View>
  );
};

export default Temp;
const styles = StyleSheet.create({
  shadowbutton: {
    shadowColor: "#818181",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    elevation: 5,
  },
  phoneinput: {
    flex: 1,
    outlineWidth: 0,
    color: "#1F2E99",
    fontFamily: "Lato",
    fontSize: 15,
    marginRight: 10,
    marginLeft: 20,
  },
  shadowinput: {
    alignItems: "center",
    marginTop: 15,
    height: 48,
    flexDirection: "row",
    borderRadius: 15,
    shadowColor: "#818181",
    backgroundColor: "#FFFFFF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    elevation: 5,
  },
  title1: {
    fontSize: 32,
    fontFamily: "LatoBold",
    color: "#1F2E99",
    marginTop: 32,
    width: 386,
  },
  title5: {
    marginTop: 32,
    fontSize: 13,
    color: "#798599",
    fontFamily: "Lato",
  },
  title7: {
    fontSize: 15,
    lineHeight: 18,
    color: "#798599",
    fontFamily: "Lato",
    marginLeft: 13,
  },
  passwordshow: {
    width: 24,
    height: 15,
    position: "relative",
    right: 15,
  },
  passwordinput: {
    width: "100%",
    flex: 1,
    outlineWidth: 0,
    color: "#1F2E99",
    fontFamily: "Lato",
    fontSize: 15,
    marginRight: 22,
    marginLeft: 20,
  },
});
