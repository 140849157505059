import { css, cx } from "@emotion/css";
import React from "react";

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  value: string;
  options: { label: string | number; value: string | number; disabled?: boolean }[];
  onValueChange: (value: string) => void;
}

const Select: React.FC<SelectProps> = ({ value, options, onValueChange, ...props }) => {
  return (
    <select
      className={cx(style.select)}
      value={value}
      onChange={(e) => {
        onValueChange(e.target?.value);
      }}
      {...props}>
      {options.map((t) => (
        <option key={t.value} value={t.value} disabled={t.disabled}>
          {t.label}
        </option>
      ))}
    </select>
  );
};
const style = Object.freeze({
  select: css`
    box-shadow: rgba(129, 129, 129, 0.5) 0px 0px 5px;
    height: 25px;
    border-width: 0px;
    padding: 1px 10px 2px 10px;
    width: 200px;
    mozappearance: none;
    webkitappearance: none;
    appearance: none;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAVCAYAAAAuJkyQAAAAmUlEQVR42r3OwQmAQAxE0S3JTrUDvairl6ANBG1MySFeFrz8kIGBZRnIK55ZtLOWnPzf3c67r8f1WO2dhannPTZ3TeefXhtmYrxm+UAARTAtyLLKNTEUxyyig28gimNW0akZcxTHAFQCBqAwJgEFMACFMRkojuEojolHcUw8CmLiUQCTgAIYhAKYJBTH8FTRAWLis4t21hKQF5HBtPSQyO2PAAAAAElFTkSuQmCC);
    background-position: center right 10px;
    background-size: 12px 7px;
    background-repeat: no-repeat;
    border-radius: 20px;
    font-family: Lato;
    font-size: 15px;
    color: #1f2e99;
  `,
});

export default Select;
