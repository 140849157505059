import type { CommonIconProps } from "./icon-types";

export default function CirclePlusIcon({ size = 25, color = "currentColor", className }: Readonly<CommonIconProps>) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="12.5" cy="12.5" r="12.5" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4446 5H10.5557V10.5535H5V14.4424H10.5557V20H14.4446V14.4424H20V10.5535H14.4446V5Z"
        fill="white"
      />
    </svg>
  );
}
