import {
  Animated,
  Dimensions,
  TouchableOpacity,
  View,
  Text,
  StyleSheet,
  TextInput,
  Image,
  FlatList,
} from "react-native";
import React, { useEffect } from "react";

import { InviteClinician } from "../../components/InviteClinician";
import { BlueButton } from "../../components/BlueButton";
import { Hairline } from "../../components/Hairline";

import { Avatar } from "../../components/Avatar";
import { MainFrame } from "../../components/MainFrame";

import { A } from "@expo/html-elements";
import Modal from "modal-react-native-web";

import { withStore } from "../../misc/utils";

const TempScreen = withStore(({ userStore }) => {
  const [searchtext, setSearchtext] = React.useState("");
  const [invitemodalvisible, setInvitemodalvisible] = React.useState(false);
  const dimensions = Dimensions.get("window");
  const [data, setData] = React.useState<any[] | null>(null);
  const [tempData, setTempData] = React.useState<any[] | null>(null);

  const opacity = new Animated.Value(1);

  function fadeIn() {
    setInvitemodalvisible(true);
  }

  function fadeOut() {
    Animated.timing(opacity, {
      toValue: 0,
      duration: 175,
      useNativeDriver: false,
    }).start(() => {
      setInvitemodalvisible(false);
    });
    fetchData();
  }

  function updateSearch(text: string) {
    setSearchtext(text);
    if (text == "") {
      console.log("updatesearch:null");
      setData(tempData);
      return;
    }
    setData(
      tempData?.filter(function (item) {
        return (item.firstname + " " + item.lastname).toLowerCase().includes(text.toLowerCase());
      }) || [],
    );
  }

  async function fetchData() {
    fetch(global.apiurl + "/hospitaladmins/getclinicians", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
    })
      .then(async (response) => await response.json())
      .then((resData) => {
        console.log(resData);
        if (resData.result) {
          setData(resData.data);
          setTempData(resData.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  /**
     *
    [{ title: 'Title Text', key: 'item1' },{ title: 'Title Text', key: 'item2' },{ title: 'Title Text', key: 'item13' }]
     */
  return (
    <View style={styles.container}>
      <Modal
        buggy={userStore.screenupdate}
        onBackdropPress={() => {
          setInvitemodalvisible(false);
        }}
        animationType="fade"
        transparent={true}
        visible={invitemodalvisible}
        onDismiss={() => {
          fetchData();
          //  alert('Modal has been closed.');
        }}>
        {!!invitemodalvisible && (
          <Animated.View
            style={{
              position: "absolute",
              top: 0,
              zIndex: 100,
              width: "100%",
              opacity,
              height: Math.max(dimensions.height, 826),
            }}>
            <InviteClinician
              userStore={userStore}
              onFinish={fadeOut}
              visible={invitemodalvisible}
              removeView={fadeOut}></InviteClinician>
          </Animated.View>
        )}
      </Modal>

      <MainFrame admin userStore={userStore} clinicianlist style={{}}>
        <View style={{ alignItems: "flex-start", width: 1000 }}>
          <Text style={[styles.title1]}>List of clinicians</Text>
          <Text style={[styles.title2]}>{data?.length} clinical team members</Text>
          {userStore?.readonly == 0 && (
            <BlueButton
              onPress={() => {
                fadeIn();
              }}
              invitenewuser
              title="Invite new clinician"
              style={{
                position: "absolute",
                right: 0,
                top: 32,
                width: 193,
                justifyContent: "center",
                alignItems: "center",
              }}
              fontsize={15}
            />
          )}
          <View style={{ flexDirection: "row", alignItems: "center", marginTop: 24 }}>
            <View style={[styles.shadowinput, { width: 267 }]}>
              <TextInput
                style={[styles.postopinput, { textAlign: "left", width: 220 }]}
                keyboardType="email-address"
                maxLength={50}
                numberOfLines={1}
                onChangeText={(text) => {
                  updateSearch(text);
                }}
                placeholder={"Search"}
                placeholderTextColor={"#1F2E99"}
                value={searchtext}
              />
              <TouchableOpacity style={{ position: "absolute", right: 10, top: 13 }}>
                <Image source={require("../../assets/images/searchicon.png")} style={styles.searchicon} />
              </TouchableOpacity>
            </View>
          </View>
          <View style={{ flexDirection: "row", width: "100%", justifyContent: "space-between", marginTop: 24.5 }}>
            <TouchableOpacity style={{ flex: 0.15 }}>
              <Text style={[styles.title3, { marginLeft: 24 }]}>Name</Text>
            </TouchableOpacity>
            <TouchableOpacity style={{ flex: 0.1 }}>
              <Text style={[styles.title3, { marginLeft: 24 }]}></Text>
            </TouchableOpacity>
            <TouchableOpacity style={{ flex: 0.12 }}>
              <Text style={[styles.title3]}>Role</Text>
            </TouchableOpacity>
            <TouchableOpacity style={{ flex: 0.2 }}>
              {" "}
              <Text style={[styles.title3]}>Clinical teams</Text>
            </TouchableOpacity>
            <TouchableOpacity style={{ flex: 0.177 }}>
              <Text style={[styles.title3, styles.titlecenter]}>SSIS Infection Rate</Text>
            </TouchableOpacity>
            <TouchableOpacity style={{ flex: 0.177 }}>
              <Text style={[styles.title3, styles.titlecenter]}>PROMS/PREMS completion</Text>
            </TouchableOpacity>
            <TouchableOpacity style={{ flex: 0.03 }}></TouchableOpacity>
          </View>
          <Hairline marginTop={15.5} />
          <FlatList
            scrollEnabled={false}
            xextraData={data}
            style={{ width: "100%", marginBottom: 94 }}
            data={data}
            keyExtractor={(item) => item.id}
            renderItem={({ item, index, separators }) => (
              <A href={"clinician?clinicianid=" + item.id} key={item.id} style={{ textDecorationLine: "none" }}>
                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                    marginTop: 24.5,
                    backgroundColor: "#ffffff",
                    height: 94,
                    alignItems: "center",
                    borderRadius: 6,
                  }}>
                  <View style={{ flex: 0.1 }}>
                    <Avatar
                      url={item?.thumburl && global.imagepathurl + encodeURI(item?.thumburl)}
                      size={46}
                      radius={23}
                      firstname={item.firstname}
                      lastname={item.lastname}
                      style={{ marginLeft: 24, borderWidth: 1, marginRight: 18 }}
                    />
                  </View>
                  <View style={{ flex: 0.15 }}>
                    <Text style={[styles.title4]}>
                      {item.firstname} {item.lastname}
                    </Text>

                    {item.inviteaccepted != 1 && (
                      <View style={{ flexDirection: "row", alignItems: "center", marginTop: 8 }}>
                        <Image
                          source={require("../../assets/images/clinicianinvitedicon.png")}
                          style={styles.clinicianinvitedicon}
                        />
                        <Text style={styles.title5}>Invitation sent</Text>
                      </View>
                    )}

                    {item.disabled == 1 && (
                      <View style={{ flexDirection: "row", alignItems: "center", marginTop: 8 }}>
                        <Text style={[styles.title5, { color: "#ff0000" }]}>[ACCESS DISABLED]</Text>
                      </View>
                    )}
                  </View>

                  <Text style={[styles.title4, { flex: 0.12 }]}>{item?.cliniciantype?.title}</Text>
                  <View style={{ flex: 0.2, flexDirection: "row", alignItems: "center" }}>
                    {item.clinicalteams.slice(0, 3).map((el: { name: string }, index: number) => (
                      <Text style={styles.title4}>
                        {el.name}
                        {index < item.clinicalteams.slice(0, 3).length - 1 ? ", " : ""}
                      </Text>
                    ))}
                    {item.clinicalteams.length > 3 && (
                      <Avatar
                        fontsize={13}
                        size={28}
                        firstname={"+"}
                        lastname={String(item.clinicalteams.length - 3)}
                        style={{ backgroundColor: "#E0E3F7", marginLeft: 7 }}
                        fontcolor={"#000000"}
                      />
                    )}
                  </View>
                  <Text
                    style={[
                      styles.title4,
                      styles.titlecenter,
                      { flex: 0.177 },
                      item?.ssisinfectionrate > 10 && { color: "#ED0000" },
                    ]}>
                    {item?.ssisinfectionrate ? Math.round(item.ssisinfectionrate) + "%" : "-"}
                  </Text>

                  <Text style={[styles.title4, styles.titlecenter, { flex: 0.177 }]}>
                    {item?.ssisinfectionrate ? Math.round(item.promspremscompletion) + "%" : "-"}
                  </Text>
                  <TouchableOpacity style={{ flex: 0.03 }}></TouchableOpacity>
                </View>
              </A>
            )}
          />
        </View>
      </MainFrame>
    </View>
  );
});

export default TempScreen;

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  openButton: {
    backgroundColor: "#F194FF",
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },

  searchicon: {
    marginTop: 2,
    width: 12.33,
    height: 13.33,
  },
  watchcloselycircleicon: {
    width: 15,
    height: 15,
  },
  clinicianinvitedicon: {
    width: 8,
    height: 8,
  },
  flagicon: {
    width: 15,
    height: 18.333,
  },

  postopinput: {
    flex: 1,
    outlineWidth: 0,
    color: "#1F2E99",
    fontFamily: "Lato",
    fontSize: 15,
    marginRight: 10,
    marginLeft: 20,
  },
  shadowinput: {
    marginTop: 15,
    height: 44,
    flexDirection: "row",
    borderRadius: 15,
    shadowColor: "#818181",
    backgroundColor: "#FFFFFF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",

    height: "100%",
  },
  title1: {
    fontSize: 24,
    fontFamily: "LatoBold",
    color: "#1F2E99",

    marginTop: 28,
  },
  title2: {
    marginTop: 12,

    fontSize: 15,
    fontFamily: "Lato",
    color: "#1F2E99",
  },
  title3: {
    fontSize: 13,
    fontFamily: "Lato",
    color: "#1F2E99",
  },
  titlecenter: { width: "100%", textAlign: "center" },
  title4: {
    fontSize: 15,
    fontFamily: "Lato",
    color: "#000000",
  },
  title5: {
    color: "#0009EC",
    fontFamily: "Lato",
    fontSize: 13,
    marginLeft: 6,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
});
