import React, { useState, useEffect } from "react";
import { Text, View, StyleSheet, Image, TouchableOpacity } from "react-native";

import { Avatar } from "../components/Avatar";

import Menu, { MenuOptions, MenuOption, MenuTrigger, renderers } from "react-native-popup-menu";

export const HospitalHeader = (props) => {
  const { ContextMenu, SlideInMenu, Popover } = renderers;

  async function setreadonlyHospital(readonly) {
    fetch(global.apiurl + "/postopadmins/setreadonly", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
      body: JSON.stringify({
        hospitalid: props.hospital.id,
        readonly,
      }),
    })
      .then(async (response) => await response.json())
      .then((resData) => {
        console.log(resData);
        if (resData.result) {
          if (readonly === 1) alert("Hospital is set as read-only.");
          if (readonly === 0) alert("Hospital is set as fully operational.");
          window.location.reload();
        } else {
          alert("Please try again later.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  async function disableHospital(disabled) {
    fetch(global.apiurl + "/postopadmins/sethospitaldisabled", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
      body: JSON.stringify({
        hospitalid: props.hospital.id,
        disabled,
      }),
    })
      .then(async (response) => await response.json())
      .then((resData) => {
        console.log(resData);
        if (resData.result) {
          window.location.reload(true);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <View style={{ height: 106, alignSelf: "stretch" }}>
      <View style={{ flexDirection: "row", marginTop: 22, width: 1131 }}>
        <View style={{ flexDirection: "row", width: 362, alignItems: "center" }}>
          <Avatar
            url={props.hospital?.thumburl && global.imagepathurl + encodeURI(props.hospital?.thumburl)}
            size={56}
            radius={28}
            firstname={props?.hospital?.name}
            lastname={"H"}
          />
          <View style={{ marginLeft: 16 }}>
            <Text style={styles.title3}>
              {props?.hospital?.name}
              {"\n"}
              {props?.hospital?.disabled ? "[DISABLED]" : ""}
              {props?.hospital?.readonly ? " [READONLY]" : ""}{" "}
            </Text>
          </View>
        </View>

        <View style={{ marginTop: 8, marginLeft: 10, width: 238 }}>
          {" "}
          <Text style={styles.title4}>Email address</Text>
          <Text style={styles.title5}>{props?.hospital?.email}</Text>
        </View>

        <View style={{ marginTop: 8, marginLeft: 10, width: 161 }}>
          {" "}
          <Text style={styles.title4}>Phone number</Text>
          <Text style={styles.title5}>
            +{props?.hospital?.gsmcountry} {props?.hospital?.gsm}
          </Text>
        </View>

        <View style={{ marginTop: 8, marginLeft: 10, width: 235 }}>
          {" "}
          <Text style={styles.title4}>Location</Text>
          <Text style={styles.title5}>
            {props?.hospital?.address} {props?.hospital?.city}
          </Text>
        </View>

        <Menu
          style={{ position: "absolute", right: 0, top: 10 }}
          renderer={Popover}
          rendererProps={{ placement: "bottom" }}>
          <MenuTrigger style={{}}>
            <Image source={require("../assets/images/morebutton.png")} style={{ width: 42, height: 42 }} />
          </MenuTrigger>
          <MenuOptions customStyles={{ borderRadius: 8, padding: 15 }}>
            {!props.hospital?.readonly && (
              <MenuOption
                text={"Turn on Read Only Mode"}
                onSelect={() => {
                  if (confirm(" Are you sure?")) {
                    setreadonlyHospital(1);
                  }
                }}
              />
            )}

            {props.hospital?.readonly == 1 && (
              <MenuOption
                text={"Turn off Read Only Mode"}
                onSelect={() => {
                  if (confirm(" Are you sure?")) {
                    setreadonlyHospital(0);
                  }
                }}
              />
            )}

            {!props.hospital?.disabled && (
              <MenuOption
                text={"Disable Hospital"}
                onSelect={() => {
                  if (confirm("Clinicians and patients won't be able to login. Are you sure?")) {
                    disableHospital(1);
                  }
                }}
              />
            )}

            {props.hospital?.disabled == 1 && (
              <MenuOption
                text={"Enable Hospital"}
                onSelect={() => {
                  if (confirm("Clinicians and patients will able to login. Are you sure?")) {
                    disableHospital(0);
                  }
                }}
              />
            )}
          </MenuOptions>
        </Menu>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  title3: {
    fontFamily: "LatoBold",
    fontSize: 18,
    color: "#1F2E99",
  },
  title4: {
    fontFamily: "Lato",
    fontSize: 13,
    color: "#868686",
  },

  title5: {
    fontFamily: "Lato",
    fontSize: 15,
    color: "#1F2E99",
    marginTop: 12,
  },
});
