import { css, cx } from "@emotion/css";
import React from "react";
import Clickable from "../Clickable";
import { POText } from "../POText";

export interface CloseModalProps {
  disabled?: boolean;
  onClick?: () => void;
}

export function CloseModal({ disabled, onClick }: CloseModalProps) {
  return (
    <Clickable disabled={disabled} onClick={onClick} className={cx("POCloseModal-root", styles.root)}>
      <span className={cx("POCloseModal-cross")}>&times;</span>
      <POText fontFamily="Lato" fontSize={13} color="#000E29" className="POCloseModal-label">
        Close
      </POText>
    </Clickable>
  );
}

const styles = Object.freeze({
  root: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    font-size: 13px;
    border: 1px solid #e0e3f7;
    background-color: #fcf5ef;
    cursor: pointer;
  `,
});
