import * as React from "react";
import { FlatList, TextInput, Text, View, StyleSheet, Dimensions, Image, TouchableOpacity } from "react-native";

import { OrangeButton } from "./OrangeButton";
import { Hairline } from "./Hairline";

export const AddTeamToClinician = (props) => {
  const [isShowingResults, setisShowingResults] = React.useState(false);
  const [searchtext, setSearchtext] = React.useState("");
  const [searchResults, setSearchResults] = React.useState<any[] | null>(null);

  const [registrationnumber, setRegistrationnumber] = React.useState(null);
  const [clinicalteams, setClinicalteams] = React.useState<any[]>([]);
  const [selectedclinicalteams, setSelectedclinicalteams] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  function updateSearch(text) {
    setSearchtext(text);
    if (text == "") {
      console.log("updatesearch:null");
      setSearchResults(clinicalteams);
      return;
    }

    setSearchResults(
      clinicalteams?.filter(function (item) {
        return item.name.toLowerCase().includes(text.toLowerCase());
      }),
    );
  }

  React.useEffect(() => {
    async function fetchData() {
      fetch(global.apiurl + "/hospitaladmins/getclinicalteamsforclinician", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: global.tokenparam || "",
        },
        body: JSON.stringify({
          clinicianid: props.clinician.id,
        }),
      })
        .then(async (response) => await response.json())
        .then((resData) => {
          console.log(resData);
          if (resData.result) {
            setClinicalteams(resData.data);
            setSearchResults(resData.data);
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Please check your connection.");
        });
    }
    fetchData();
  }, []);

  function removeView() {
    props.removeView();
  }

  function sendform() {
    console.log("adding teams to clinician");

    fetch(global.apiurl + "/hospitaladmins/addteamtoclinician", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenparam || "",
      },
      body: JSON.stringify({
        clinicianid: props.clinician.id,
        clinicalteams: selectedclinicalteams,
      }),
    })
      .then(async (res) => {
        if (res.status === 422) {
          throw new Error("Request failed.");
        }
        if (res.status !== 200 && res.status !== 201) {
          console.log("Error!");
          throw new Error("Could not authenticate you!");
        }
        return await res.json();
      })
      .then((resData) => {
        console.log(resData);
        if (resData.result) {
          console.log("DONE");
          props.onFinish();
          // todo close ve listede clientı gorsun
        } else {
          alert("Unable to add, please try later");
        }
      })
      .catch((err) => {
        alert("Unable to add, please try later");

        console.log(err);
      });
  }

  const dimensions = Dimensions.get("window");
  const modalmargin = (dimensions.height - 702) / 2;

  return (
    <View style={[{ backgroundColor: "rgba(52, 52, 52, 0.8)", paddingTop: modalmargin, paddingBottom: modalmargin }]}>
      <View
        style={{
          borderRadius: 8,
          backgroundColor: "#ffffff",
          width: 460,
          height: 702,
          marginBottom: 1000,
          alignSelf: "center",
        }}>
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            borderBottomColor: "#E8E9ED",
            borderBottomWidth: 1,
            height: 70,
          }}>
          <Text style={styles.title1}>Assign new clinical team to clinician</Text>
          <TouchableOpacity
            style={{ position: "absolute", right: 0 }}
            onPress={() => {
              removeView();
            }}>
            <View
              style={{
                backgroundColor: "#FCF5EF",
                width: 93,
                height: 70,
                flexDirection: "row",
                borderColor: "#E8E9ED",
                borderWidth: 1,
                borderTopRightRadius: 8,
                alignItems: "center",
              }}>
              <Image
                source={require("../assets/images/closecrossicon.png")}
                style={{ width: 10, height: 10, marginRight: 8, marginLeft: 23 }}
              />
              <Text style={{ fontFamily: "Lato", fontSize: 13, color: "#1F2E99" }}>Close</Text>
            </View>
          </TouchableOpacity>
        </View>

        <View style={{ width: 460 }}>
          <Text style={styles.title2}>Clinician name</Text>
          <Text style={[styles.title3, { marginTop: 12 }]}>
            {props?.clinician?.firstname} {props?.clinician?.lastname}
          </Text>
          <View style={[styles.shadowinput2, { width: 412, marginLeft: 24, marginTop: 32, zIndex: 10 }]}>
            <TouchableOpacity style={{ top: 16 }}>
              <Image source={require("../assets/images/searchicon.png")} style={styles.searchicon} />
            </TouchableOpacity>

            <TextInput
              style={[styles.postopinput, { textAlign: "left", width: 220 }]}
              keyboardType="email-address"
              maxLength={50}
              numberOfLines={1}
              onChangeText={(text) => {
                updateSearch(text);
              }}
              value={searchtext}
              onFocus={() => {
                setisShowingResults(true);
              }}
              onBlur={() =>
                setTimeout(function () {
                  setisShowingResults(false);
                }, 300)
              }
            />

            {(isShowingResults || searchtext.length > 0) && (
              <FlatList
                data={searchResults}
                renderItem={({ item, index }) => {
                  return (
                    <TouchableOpacity
                      style={styles.resultItem}
                      onPress={() => {
                        if (!selectedclinicalteams.includes(item))
                          setSelectedclinicalteams((selectedclinicalteams) => [...selectedclinicalteams, item]);
                        updateSearch("");
                      }}>
                      <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                        {" "}
                        <Text style={styles.title4}>{item.name}</Text>
                        {item.clinicians.length > 0 && <Text style={styles.title5}>Already in this team</Text>}
                      </View>
                    </TouchableOpacity>
                  );
                }}
                keyExtractor={(item) => item.id}
                style={styles.searchResultsContainer}
              />
            )}
          </View>

          <Hairline marginTop={24}></Hairline>
          <Text style={styles.title2}>Name</Text>
          <Hairline marginTop={15.5}></Hairline>
          <FlatList
            data={selectedclinicalteams}
            renderItem={({ item, index }) => {
              return (
                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    height: 78,
                    alignItems: "center",
                    zIndex: -100,
                    borderBottomWidth: StyleSheet.hairlineWidth,
                    borderBottomColor: "#E8E9ED",
                    justifyContent: "space-between",
                  }}>
                  {" "}
                  <Text style={[styles.title3, { zIndex: -100 }]}>{item.name}</Text>
                  <TouchableOpacity
                    onPress={() => {
                      setSelectedclinicalteams((selectedclinicalteams) =>
                        selectedclinicalteams.filter((xitem) => xitem.id !== item.id),
                      );
                    }}>
                    <Image
                      source={require("../assets/images/removeicon.png")}
                      style={{ width: 10, height: 10, marginRight: 28 }}
                    />{" "}
                  </TouchableOpacity>
                </View>
              );
            }}
            keyExtractor={(item) => item.id}
            style={styles.selectedClinicalTeams}
          />
        </View>

        <View style={{ position: "absolute", bottom: 16, alignItems: "center", width: 460 }}>
          <Hairline />

          <OrangeButton
            onPress={() => {
              sendform();
            }}
            title="Assign clinical team"
            style={{ width: 250, marginTop: 16 }}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  autocompleteContainer: {
    zIndex: 1,
  },
  selectedClinicalTeams: {
    height: 328,
    zIndex: -100,
  },
  searchResultsContainer: {
    width: 412,
    paddingBottom: 23,
    height: 219,
    backgroundColor: "#ffffff",
    position: "absolute",
    top: 50,
    zIndex: 100,
    borderRadius: 8,
    paddingTop: 5,

    shadowColor: "#818181",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
  dummmy: {
    width: 600,
    height: 200,
    backgroundColor: "hotpink",
    marginTop: 20,
  },
  resultItem: {
    width: "100%",
    justifyContent: "center",
    height: 34,

    paddingLeft: 15,
    backgroundColor: "#ffffff",
    zIndex: 120,
  },
  searchBox: {
    width: 340,
    height: 50,
    fontSize: 18,
    borderRadius: 8,
    borderColor: "#aaa",
    color: "#000",
    backgroundColor: "#fff",
    borderWidth: 1.5,
    paddingLeft: 15,
  },
  container: {
    flex: 1,
    backgroundColor: "lightblue",
    alignItems: "center",
  },

  postopinput: {
    flex: 1,
    outlineWidth: 0,
    color: "#1F2E99",
    fontFamily: "Lato",
    fontSize: 15,
    marginRight: 10,
    marginLeft: 20,
  },
  countryflag: {
    width: 26,
    height: 18,
    marginHorizontal: 16,
    marginVertical: 19,
  },
  shadowinput2: {
    height: 45,
    flexDirection: "row",
    borderRadius: 15,
    shadowColor: "#818181",
    backgroundColor: "#FFFFFF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
  },
  title1: {
    fontSize: 18,
    fontFamily: "LatoBold",
    color: "#1F2E99",
    marginTop: 26,
    marginLeft: 24,
  },

  title2: {
    paddingLeft: 24,
    fontSize: 13,
    fontFamily: "Lato",
    color: "#798599",
    marginTop: 24,
  },
  title3: {
    fontSize: 15,
    fontFamily: "Lato",
    color: "#000000",
    paddingLeft: 24,
  },
  title4: {
    fontSize: 13,
    fontFamily: "Lato",
    color: "#1F2E99",
    paddingLeft: 16,
  },
  title5: {
    fontSize: 11,
    fontFamily: "Lato",
    color: "#868686",
    paddingRight: 21,
  },
  calendaricon: {
    width: 18,
    height: 18,
  },

  removeicon: {
    width: 10,
    height: 10,
  },
  searchicon: {
    marginLeft: 16,
    width: 12,
    height: 12.86,
  },
  autocompleteContainer: {
    backgroundColor: "#ffffff",
    borderWidth: 0,
  },
  descriptionContainer: {
    flex: 1,
    justifyContent: "center",
  },
  itemText: {
    fontSize: 15,
    paddingTop: 5,
    paddingBottom: 5,
    margin: 2,
  },
  infoText: {
    textAlign: "center",
    fontSize: 16,
  },
});
