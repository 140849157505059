import type { ViewStyle } from "@expo/html-elements/build/primitives/View";
import * as React from "react";
import type { StyleProp } from "react-native";
import { Text, View, StyleSheet, TouchableOpacity } from "react-native";

export interface OrangeBorderButtonProps {
  title: string;
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
  onPress?: () => void;
}

export const OrangeBorderButton = ({ title, disabled, style, onPress }: OrangeBorderButtonProps) => {
  return (
    <TouchableOpacity disabled={disabled} style={[styles.listbutton, style]} onPress={onPress}>
      <View style={{ flex: 1, flexDirection: "row", backgroundColor: "transparent" }}>
        <Text style={{ color: "#FE5935", fontFamily: "Questrial", fontSize: 16 }}>{title}</Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  listbutton: {
    paddingTop: 13,
    alignItems: "center",
    height: 42,
    width: 144,
    borderRadius: 15,
    backgroundColor: "#FFFFFF",
    borderColor: "#FE5935",
    borderWidth: 1,
    alignContent: "center",
  },
});
