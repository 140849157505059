import React, { useEffect, useMemo, useState } from "react";
import PostopAdminLayout from "../../components/layouts/PostopAdminLayout";
import { requests } from "../../misc";
import { css } from "@emotion/css";
import { getImageUrl } from "../../misc/constants";
import Moment from "moment";
import ReviewStatusDropdown from "../../components/ui/ReviewStatusDropdown";
import { useRoute } from "@react-navigation/native";
import { useNav } from "../../hooks/useNav";
import Toggle from "../../components/ui/Toggle";
import Clickable from "../../components/Clickable";


enum ShowVoidPhotosStatus {
  Hide = 0,
  Show = 1,
}

function PostopAdminMedia(): JSX.Element {
  const [medias, setMedias] = useState<any[]>([]);
  const params = useRoute().params as { page?: number };
  const [page, setPage] = useState(Number(params?.page) || 1);
  const navigation = useNav<"postopAdmin">();
  const [showVoidStatus, setShowVoidStatus] = useState<ShowVoidPhotosStatus>(ShowVoidPhotosStatus.Hide);

  useEffect(() => {
    getPage();
  }, [page, showVoidStatus]);

  async function getPage() {
    await requests
      .get("/v1/patient-media", {
        query: { page: String(page - 1), limit: "20", show_void_status: String(showVoidStatus) },
      })
      .then((res) => {
        setMedias(res.data);
      });
  }
  const [onNext, onPrev] = useMemo(() => {
    return [
      () => {
        setPage(page + 1);
        navigation.setParams({ page: page + 1 });
      },
      () => {
        setPage(page - 1);
        navigation.setParams({ page: page - 1 });
      },
    ];
  }, [page]);

  function toggleSwitch() {
    setShowVoidStatus((prevStatus) =>
      prevStatus === ShowVoidPhotosStatus.Show ? ShowVoidPhotosStatus.Hide : ShowVoidPhotosStatus.Show,
    );
  }

  return (
    <PostopAdminLayout active="media">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}>
        <h1>Patient Media</h1>
        <PageSelector page={page} onNext={onNext} onPrev={onPrev} />
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px", marginBottom: "5px" }}>
        <span style={{ marginRight: "10px", marginTop: "5px" }}>Show / Hide Void Photos</span>
        <Toggle on={Boolean(showVoidStatus)} onClick={toggleSwitch} />
      </div>
      {medias && medias.length > 0 ? (
        <table className="POAdminResults">
          <thead>
            <tr>
              <th>Wound Photo</th>
              <th>Photo ID</th>
              <th>Patient ID</th>
              <th>Status</th>
              <th>Last Update</th>
              <th>Annotator</th>
            </tr>
          </thead>
          <tbody>
            {medias.map(
              (media): JSX.Element => (
                <tr key={media.id}>
                  <td>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        navigation.push("WoundAnnotator", { mediaId: media.id });
                      }}>
                      <img src={getImageUrl(media.photourl || "")} style={{ width: "150px", height: "150px" }} />
                    </a>
                  </td>
                  <td>{media.id}</td>
                  <td>{media.patient_id}</td>
                  <td>
                    <ReviewStatusDropdown
                      media={media}
                      onChange={async () => {
                        await getPage();
                      }}
                    />
                  </td>
                  <td>{media.annotated_at ? Moment(media.annotated_at).format("DD MMM YYYY - hh:mm") : ""}</td>
                  <td>{media.name}</td>
                </tr>
              ),
            )}
          </tbody>
        </table>
      ) : (
        <div
          className={css`
            margin: auto;
            text-align: center;
            width: 60%;
            margin-top: 30px;
          `}>
          no media found
        </div>
      )}
      <div
        style={{
          marginLeft: "auto",
          width: "fit-content",
          padding: "7px",
        }}>
        <PageSelector page={page} onNext={onNext} onPrev={onPrev} />
      </div>
    </PostopAdminLayout>
  );
}

const PageSelector: React.FC<{
  page: number;
  onNext: () => void;
  onPrev: () => void;
}> = ({ page, onNext, onPrev }) => {
  return (
    <div style={{ margin: "auto 0" }}>
      <div style={{ display: "flex" }}>
        Page {page}
        {"  "}
        {page > 1 && (
          <Clickable onClick={onPrev}>
            <img style={{ width: 20, height: 20 }} src={require("../../assets/images/careplanprevbutton.png")} />
          </Clickable>
        )}
        <Clickable onClick={onNext}>
          <img
            onClick={onNext}
            style={{ width: 20, height: 20 }}
            src={require("../../assets/images/careplannextbutton.png")}
          />
        </Clickable>
      </div>
    </div>
  );
};

export default PostopAdminMedia;
